import { rhWindow } from "@common/utils/rhWindow";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { useAtAGlanceCarouselStyles } from "@portal/components/AtAGlanceCarousel/AtAGlanceCarousel.styles";
import { AtAGlanceItem } from "@portal/components/AtAGlanceCarousel/AtAGlanceItem";
import { useDebounce } from "@portal/hooks/useDebounce";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { FAQ_HOME_URL, myAccountPath } from "@portal/routes/routePaths";
import React, { FC, useEffect, useRef } from "react";

const DELAY_MS = 300;
const WINDOW_RIGHT_PADDING = 25;

interface AtAGlanceCarouselProps {
  pickedBillingDueDate: boolean;
  optedInEBill: boolean;
  onScrollEnd?: (atEnd: boolean) => void;
  containerWidth?: number | null;
}

export const AtAGlanceCarousel: FC<AtAGlanceCarouselProps> = ({
  pickedBillingDueDate,
  optedInEBill,
  onScrollEnd,
  containerWidth,
}) => {
  const classes = useAtAGlanceCarouselStyles();

  const { t } = useRhIntl();
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (lastItemRef.current !== null && onScrollEnd !== undefined) {
      const { right } = lastItemRef.current.getBoundingClientRect();
      const { innerWidth } = rhWindow;
      const horizontalOffset = (innerWidth - (containerWidth || 0)) / 2;

      if (right < innerWidth - (horizontalOffset + WINDOW_RIGHT_PADDING)) {
        onScrollEnd(true);
      } else {
        onScrollEnd(false);
      }
    }
  };

  const debouncedHandleScroll = useDebounce(handleScroll, DELAY_MS);

  useEffect(() => {
    if (
      Boolean(onScrollEnd) &&
      carouselRef !== null &&
      carouselRef.current !== null
    ) {
      const currentRef = carouselRef.current;

      currentRef.addEventListener("scroll", debouncedHandleScroll);
      return () =>
        currentRef.removeEventListener("scroll", debouncedHandleScroll);
    }
  }, [carouselRef, onScrollEnd, debouncedHandleScroll]);

  const saveEnergyTitle = t("AtAGlanceCarousel.energySavingTips");
  const faqCta = t("AtAGlanceCarousel.cta.faq");
  const pickDueDateTitle = t("AtAGlanceCarousel.pickADueDate");
  const changeBillingDateCta = t("AtAGlanceCarousel.cta.changeBillingDate");
  const dueDatePicked = t("AtAGlanceCarousel.dueDatePicked");
  const signedInToEBills = t("AtAGlanceCarousel.signedUpForEBills");
  const optInCta = t("AtAGlanceCarousel.cta.optIn");
  const optInToEBills = t("AtAGlanceCarousel.optInToEBills");

  return (
    // MUI BoxProps type doesn't allow for `ref`.  This is a workaround
    // (https://github.com/mui-org/material-ui/issues/17010#issuecomment-584223410)
    <div ref={carouselRef} className={classes.root}>
      <RhFlexBox justifyContent="flex-start" pr={1}>
        <AtAGlanceItem
          title={pickDueDateTitle}
          disabledTitle={dueDatePicked}
          cta={{
            text: changeBillingDateCta,
            linkTo: {
              pathname: myAccountPath(),
              state: { anchorId: "billingPreferences" },
            },
          }}
          disabled={pickedBillingDueDate}
        />
      </RhFlexBox>
      <RhFlexBox justifyContent="center" pr={2} pl={2} position="relative">
        <AtAGlanceItem
          title={optInToEBills}
          disabledTitle={signedInToEBills}
          cta={{
            text: optInCta,
            linkTo: {
              pathname: myAccountPath(),
              state: { anchorId: "billingPreferences" },
            },
          }}
          disabled={optedInEBill}
          containerProps={{ classes: { root: classes.itemContainer } }}
        />
      </RhFlexBox>
      <RhFlexBox justifyContent="flex-end" pl={2} pr={1} position="relative">
        <AtAGlanceItem
          ref={lastItemRef}
          title={saveEnergyTitle}
          cta={{ text: faqCta, linkTo: FAQ_HOME_URL, external: true }}
          containerProps={{ classes: { root: classes.itemContainer } }}
        />
      </RhFlexBox>
    </div>
  );
};
