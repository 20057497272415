import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { useLoaderStyles } from "@portal/components/Loader/Loader.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import bike from "@portal/images/bike-loader.lottie.json";
import {
  customerHomePath,
  myAccountPath,
  notificationsPath,
  payBillPath,
  rewardsPath,
} from "@portal/routes/routePaths";
import React, { FC } from "react";
import Lottie from "react-lottie";

import { useLoader } from "./useLoader";

export const pagesWithLoader = [
  customerHomePath(),
  payBillPath(),
  rewardsPath(),
  myAccountPath(),
  notificationsPath(),
];

export const Loader: FC = () => {
  const classes = useLoaderStyles();
  const { t } = useRhIntl();
  const { isLoading } = useLoader();

  const title = t("Loader.bike");
  const imageSize = { height: 120, width: 151 };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bike,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (!isLoading) {
    return null;
  }

  return (
    <RhFlexBox role="progressbar" className={classes.loaderContainer}>
      <Lottie title={title} options={defaultOptions} {...imageSize} />
    </RhFlexBox>
  );
};
