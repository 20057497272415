import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhIconButton } from "@design-system/components/RhIconButton/RhIconButton";
import { LeftChevron } from "@design-system/icons";
import { SvgIcon, useMediaQuery, useTheme } from "@material-ui/core";
import { useSignUpBackButtonStyles } from "@portal/components/SignUpBackButton/SignUpBackButton.style";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { ActionType } from "@portal/services/segment.service";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

interface SignUpBackButtonProps {
  handleClick?: () => void;
}

export const SignUpBackButton: FC<SignUpBackButtonProps> = ({
  handleClick,
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const { currentStep, trackEvent } = useSignUpFlow();

  const backButtonCTA = formatMessage({ id: "signUpBackButton.backCTA" });

  const clickHandler = () => {
    trackEvent({
      action: ActionType.clickedBackPage,
      label: `${currentStep}BackPage`,
    });

    if (handleClick) {
      handleClick();
    } else {
      history.goBack();
    }
  };

  const classes = useSignUpBackButtonStyles();

  const icon = (
    <SvgIcon
      viewBox="5 0 47 90"
      fill="none"
      component={LeftChevron}
      title={backButtonCTA}
      fontSize="inherit"
    />
  );

  return isTabletDown ? (
    <RhButton
      aria-label={backButtonCTA}
      role="link"
      color="default"
      variant="text"
      onClick={clickHandler}
      startIcon={icon}
      className={classes.tabletDownButton}
    >
      {backButtonCTA}
    </RhButton>
  ) : (
    <RhIconButton aria-label={backButtonCTA} role="link" onClick={clickHandler}>
      {icon}
    </RhIconButton>
  );
};
