import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core/styles";
import { LOGGED_OUT_FIELD_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";

export const useResetPasswordStyles = makeStyles<Theme, "caption">((theme) =>
  createStyles({
    caption: {
      color: theme.palette.grey[400],
      marginTop: theme.spacing(LOGGED_OUT_FIELD_SPACING),
    },
  })
);
