import { changePasswordApi } from "@common/api/customerApi";
import { ChangePasswordResponseType } from "@common/api/customerResponseTypes";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isEqual, isNotEqual, isRequired } from "@common/forms/validators";
import { Customer } from "@common/models/Customer.model";
import { FormError, OKTA_REQUEST_ERROR } from "@common/types/errorTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { PortalPasswordField } from "@portal/components/PortalPasswordField/PortalPasswordField";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { FormApi } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";

import { useMyAccountPasswordChangeStyles } from "./MyAccountPasswordChange.style";

type FinalFormSubmitFunction<T> = (
  values: T,
  formApi: FormApi<T>
) => Promise<void | FormError>;

type MyAccountPasswordChangeProps = {
  customer: Customer;
};

export type MyAccountPasswordChangeFormValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const passwordChangeFormInitialValues = Object.freeze<MyAccountPasswordChangeFormValues>(
  {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  }
);

export const MyAccountPasswordChange: FC<MyAccountPasswordChangeProps> = ({
  customer,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { t } = useRhIntl();
  const classes = useMyAccountPasswordChangeStyles();
  const flash = useRhFlash();

  const title = t("myAccountPasswordChange.title");
  const currentPasswordLabel = t("myAccountPasswordChange.currentPassword");
  const newPasswordLabel = t("myAccountPasswordChange.newPassword");
  const newPasswordConfirmationLabel = t(
    "myAccountPasswordChange.confirmNewPassword"
  );
  const submitButtonLabel = t("rhythm.button.save");
  const successMessage = t("myAccountPasswordChange.success");
  const newPasswordMustBeDifferentMessage = t(
    "myAccountPasswordChange.notNewPassword"
  );
  const newPasswordsMustMatchMessage = t(
    "myAccountPasswordChange.newPasswordsMustMatch"
  );

  const passwordRequirements = t("common.password.minimumRequirements");

  const myAccountPasswordChangeFormValidator = generateValidationErrorCollector<MyAccountPasswordChangeFormValues>(
    {
      oldPassword: [isRequired],
      newPassword: [
        isRequired,
        (value, { oldPassword }) =>
          isNotEqual(value, oldPassword, newPasswordMustBeDifferentMessage),
      ],
      newPasswordConfirmation: [
        isRequired,
        (value, { newPassword }) =>
          isEqual(value, newPassword, newPasswordsMustMatchMessage),
      ],
    }
  );

  const onSubmit: FinalFormSubmitFunction<MyAccountPasswordChangeFormValues> = async (
    values
  ) => {
    const [error] = await handleAjaxCall<ChangePasswordResponseType>(
      changePasswordApi(
        customer.id,
        values.oldPassword,
        values.newPassword,
        values.newPasswordConfirmation
      )
    );

    if (error) {
      let errorMessage;

      const errorType = error.data.errorCode;

      if (errorType === OKTA_REQUEST_ERROR) {
        errorMessage = t("myAccountPasswordChange.okta.request.failed");
        return { newPassword: [errorMessage] } as FormError;
      } else {
        flash.error(t("myAccountPasswordChange.nonOktaErrorUpdatingPassword"));
      }
    } else {
      flash.success(successMessage);
    }
  };

  return (
    <RhCard title={title}>
      <Form<MyAccountPasswordChangeFormValues>
        initialValues={passwordChangeFormInitialValues}
        onSubmit={onSubmit}
        validate={myAccountPasswordChangeFormValidator}
        render={({ handleSubmit, form }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            data-testid="myAccountPasswordChangeForm"
          >
            <div className={classes.fieldsContainer}>
              <PortalPasswordField name="oldPassword">
                {currentPasswordLabel}
              </PortalPasswordField>
              <Box>
                <PortalPasswordField name="newPassword">
                  {newPasswordLabel}
                </PortalPasswordField>
                <RhTypography
                  variant="body2"
                  color="textSecondary"
                  className={classes.passwordRequirements}
                >
                  {passwordRequirements}
                </RhTypography>
              </Box>
              <PortalPasswordField name="newPasswordConfirmation">
                {newPasswordConfirmationLabel}
              </PortalPasswordField>
            </div>
            <RhFlexBox justifyContent="flex-end">
              <RhSubmitButton
                fullWidth={isMobile}
                disallowPristineSubmit
                restartOnSuccess
              >
                {submitButtonLabel}
              </RhSubmitButton>
            </RhFlexBox>
          </form>
        )}
      />
    </RhCard>
  );
};
