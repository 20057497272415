import { translations as enTranslations } from "@common/i18n/common.en.i18n";
import { translations as esTranslations } from "@common/i18n/common.es.i18n";
import { globalIntl } from "@common/i18n/globalIntl";
import dayjs from "dayjs";
import { IntlShape, createIntl } from "react-intl";

export const generateTranslations = (
  locale: "es" | "en" = navigator?.language as "es" | "en"
): IntlShape => {
  const translations = locale === "es" ? esTranslations : enTranslations;

  const intl = createIntl({
    locale,
    messages: translations,
  });

  dayjs.locale(locale);

  Object.assign(globalIntl, intl);

  return intl;
};
