import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhToggle } from "@design-system/components/RhToggle/RhToggle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useNotificationOptionSwitchesStyles } from "@portal/components/NotificationOptionSwitches/NotificationOptionSwitches.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";
import { useField } from "react-final-form";

// These values are defined by the Iterable API
// https://support.iterable.com/hc/en-us/articles/204780579-API-Overview-and-Sample-Payloads-#subscribe-a-user-to-a-single-channel-message-type-or-list
export type SubscriptionGroupType =
  | "messageChannel"
  | "messageType"
  | "emailList";

export interface UpdateSubscriptionParams {
  action: "subscribe" | "unsubscribe";
  subscriptionGroup: SubscriptionGroupType;
  channelId: string;
}

export interface IterableOptionType {
  subscriptionGroup: SubscriptionGroupType;
  channelNameIntlId: string;
  messageId: string;
  commMedium: "email" | "sms";
}

interface NotificationOptionRowComponentProps {
  option: IterableOptionType;
  customerId: IdType;
}

export interface ChannelSubscriptionStatus {
  unsubscribedChannelIds: number[];
}

export const NotificationOptionRow: FC<NotificationOptionRowComponentProps> = ({
  option,
  customerId,
}) => {
  const classes = useNotificationOptionSwitchesStyles();
  const { t } = useRhIntl();
  const flash = useRhFlash();

  const optionMessageName = `messageId${option.messageId}`;
  const {
    input: { checked, onChange },
  } = useField<boolean>(optionMessageName, { type: "checkbox" });

  const updateSubscriptionStatus = () => {
    const updateStatus = !checked;
    const action = updateStatus ? "subscribe" : "unsubscribe";
    const { subscriptionGroup, messageId } = option;

    api.customers
      .updateSubscriptionStatus(customerId, {
        channelId: messageId,
        subscriptionGroup,
        action,
      })
      .then(() => {
        flash.success(t("NotificationOptionSwitches.successfullyUpdated"));
        onChange(updateStatus);
      })
      .catch((error: RhApiError) => {
        flash.error(
          t("NotificationOptionSwitches.apiErrorUpdatingNotifications")
        );
      });
  };

  return (
    <RhFlexBox className={classes.optionRow}>
      <RhTypography variant="subtitle2" className={classes.optionText}>
        {t(option.channelNameIntlId)}
      </RhTypography>
      <RhToggle
        name={optionMessageName}
        checked={checked}
        onChange={updateSubscriptionStatus}
      />
    </RhFlexBox>
  );
};
