import { EN_DASH } from "@common/constants/characters.constant";
import { formatCurrency, separateNumber } from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { AutopayIcon, EBillIcon } from "@design-system/icons";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { SignUpSwitchInput } from "@portal/components/SignUpSwitchInput/SignUpSwitchInput";
import React, { FC } from "react";
import { useField } from "react-final-form";
import { useIntl } from "react-intl";

export const SignUpBillingPreferencesFields: FC = () => {
  const EBILL_REWARD_POINTS = 500;
  const AUTOPAY_REWARD_POINTS = 500;
  const { formatMessage } = useIntl();
  const {
    input: {
      checked: invoiceByPrint,
      onChange: invoiceByPrintOnChange,
      ...eBillOnlyInput
    },
  } = useField<boolean>("invoiceByPrint", {
    type: "checkbox",
  });
  const {
    input: { checked: autopay, onChange: autopayOnChange, ...autopayInput },
  } = useField<boolean>("autopay", {
    type: "checkbox",
  });
  const eBillLabel = formatMessage({
    id: "SignUpBillingPreferences.eBillOnly.label",
  });
  const eBillDescriptor = formatMessage({
    id: "SignUpBillingPreferences.eBillOnly.descriptor",
  });
  const eBillPointsDescription = formatMessage(
    {
      id: "SignUpBillingPreferences.pointsAndValue",
    },
    {
      pointsChunk: (content) => (
        <RhTypography component="span" color="secondary">
          {content}
        </RhTypography>
      ),
      points: separateNumber(EBILL_REWARD_POINTS),
      value: formatCurrency(rewardPointsToDollars(EBILL_REWARD_POINTS)),
      dash: EN_DASH,
    }
  );
  const autopayLabel = formatMessage({
    id: "SignUpBillingPreferences.autoPay.label",
  });
  const autopayDescriptor = formatMessage({
    id: "SignUpBillingPreferences.autoPay.descriptor",
  });
  const autopayPointsDescription = formatMessage(
    {
      id: "SignUpBillingPreferences.pointsAndValue",
    },
    {
      pointsChunk: (content) => (
        <RhTypography component="span" color="secondary">
          {content}
        </RhTypography>
      ),
      points: separateNumber(AUTOPAY_REWARD_POINTS),
      value: formatCurrency(rewardPointsToDollars(AUTOPAY_REWARD_POINTS)),
      dash: EN_DASH,
    }
  );

  return (
    <LoggedOutFieldsLayout dense>
      <SignUpSwitchInput
        {...eBillOnlyInput}
        checked={!invoiceByPrint}
        onChange={() => invoiceByPrintOnChange(!invoiceByPrint)}
        label={eBillLabel}
        descriptor={eBillDescriptor}
        iconComponent={EBillIcon}
        subDescriptor={eBillPointsDescription}
      />
      <SignUpSwitchInput
        {...autopayInput}
        checked={autopay}
        onChange={autopayOnChange}
        label={autopayLabel}
        descriptor={autopayDescriptor}
        iconComponent={AutopayIcon}
        subDescriptor={autopayPointsDescription}
      />
    </LoggedOutFieldsLayout>
  );
};
