import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useMyAccountMailingAddressFieldsStyles = rhMakeStyles<
  typeof rhythmTheme,
  "fieldsContainer"
>((theme) =>
  createStyles({
    fieldsContainer: {
      "& > *": {
        marginBottom: theme.spacing(2.5),
      },
      [theme.breakpoints.up("md")]: {
        "& > *": {
          flex: "1 0 50%",
          flexGrow: "initial",
        },
        "& > *:nth-child(odd)": {
          paddingRight: theme.spacing(2),
        },
        display: "flex",
        flexWrap: "wrap",
      },
    },
  })
);
