import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhPaper } from "@design-system/components/RhPaper/RhPaper";
import { RhToggle } from "@design-system/components/RhToggle/RhToggle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, SwitchProps } from "@material-ui/core";
import { useSignUpSwitchInputStyles } from "@portal/components/SignUpSwitchInput/SignUpSwitchInput.style";
import React, { FC, FunctionComponent, ReactNode, SVGProps } from "react";

interface SignUpSwitchInputProps
  extends Omit<
    SwitchProps,
    "color" | "classes" | "icon" | "checkedIcon" | "size" | "id" | "className"
  > {
  name: string;
  label: string;
  descriptor: string;
  subDescriptor?: string | ReactNode;
  iconComponent: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
}

export const SignUpSwitchInput: FC<SignUpSwitchInputProps> = ({
  label,
  name,
  checked,
  descriptor,
  subDescriptor,
  iconComponent,
  onChange,
}) => {
  const classes = useSignUpSwitchInputStyles("root");

  return (
    <RhPaper className={classes.paper}>
      <RhFlexBox className={classes.root}>
        <Box className={classes.icon}>{React.createElement(iconComponent)}</Box>
        <Box>
          <RhTypography
            classes={{ root: classes.label }}
            component="label"
            color="textPrimary"
            variant="subtitle2"
            fontWeight="Bold"
            display="block"
            htmlFor={name}
          >
            {label}
          </RhTypography>
          <RhTypography
            variant="body2"
            color="textSecondary"
            classes={{ root: classes.descriptor }}
          >
            {descriptor}
          </RhTypography>
          {Boolean(subDescriptor) && (
            <RhTypography variant="body2" color="textSecondary">
              {subDescriptor}
            </RhTypography>
          )}
        </Box>
        <RhToggle
          className={classes.input}
          id={name}
          checked={checked}
          onChange={onChange}
        />
      </RhFlexBox>
    </RhPaper>
  );
};
