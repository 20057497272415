import { DynamicLink } from "@common/components/DynamicLink/DynamicLink";
import { RhPaper } from "@design-system/components/RhPaper/RhPaper";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PaperProps } from "@material-ui/core";
import { useAtAGlanceItemStyles } from "@portal/components/AtAGlanceCarousel/AtAGlanceItem.styles";
import React, { FC, RefAttributes } from "react";
import { LinkProps } from "react-router-dom";

interface AtAGlanceItemProps {
  title: string;
  cta: {
    text: string;
    linkTo: LinkProps["to"];
    external?: boolean;
  };
  disabledTitle?: string;
  disabled?: boolean;
  containerProps?: Omit<PaperProps, "elevation">;
}

export const AtAGlanceItem: FC<
  AtAGlanceItemProps & RefAttributes<unknown>
> = React.forwardRef(
  ({ title, cta, disabledTitle, disabled = false, containerProps }, ref) => {
    const classes = useAtAGlanceItemStyles({ disabled });
    const displayTitle = disabled ? disabledTitle : title;

    return (
      <RhPaper ref={ref} className={classes.root} {...containerProps}>
        <RhTypography
          variant="body1"
          color="textPrimary"
          classes={{ colorTextPrimary: classes.textPrimary }}
        >
          {displayTitle}
        </RhTypography>
        {!disabled && (
          <DynamicLink to={cta.linkTo} external={cta.external ?? false}>
            <RhTypography variant="body2" color="secondary">
              {cta.text}
            </RhTypography>
          </DynamicLink>
        )}
      </RhPaper>
    );
  }
);
