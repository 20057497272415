import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { purple } from "@design-system/theme/palette.colors";
import { Theme, createStyles } from "@material-ui/core/styles";

export const useOfferFeaturesStyles = makeStyles<
  Theme,
  | "hoverListItemIcon"
  | "listItem"
  | "listItemIcon"
  | "listItemText"
  | "rhythmPromiseDetails"
  | "secondaryDarken"
>((theme) => {
  const iconSharedStyles = {
    color: theme.palette.primary.main,
    transition: "opacity 500ms",
  };

  return createStyles({
    hoverListItemIcon: {
      "&.hover-active": {
        opacity: 0,
      },
      opacity: 100,
      position: "absolute",
      ...iconSharedStyles,
    },
    listItem: {
      paddingRight: theme.spacing(3),
      position: "relative",
    },
    listItemIcon: {
      "&.hover-active": {
        opacity: 100,
      },
      opacity: 0,
      ...iconSharedStyles,
    },
    listItemText: {
      color: theme.palette.primary.main,
    },
    rhythmPromiseDetails: {
      backgroundColor: purple[200],
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(-3),
        paddingRight: theme.spacing(3),
        width: `calc(100% + ${theme.spacing(6)}px)`,
      },
    },
    secondaryDarken: {
      color: theme.palette.grey[700],
    },
  });
});
