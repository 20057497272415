import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, Link } from "@material-ui/core";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import React, { FC } from "react";
import { useIntl } from "react-intl";

const OPS_PHONE_NUMBER = "+18887749846";
const OPS_DISPLAY_PHONE_NUMBER = "1-888-7RHYTHM";
const OPS_SUPPORT_EMAIL = "support@gotrhythm.com";

export const SignUpReviewRequiredPage: FC = () => {
  const { formatMessage } = useIntl();

  const toStartServiceText = formatMessage(
    { id: "SignUpReviewRequiredPage.toStartService" },
    {
      email: OPS_SUPPORT_EMAIL,
      emailTag: (content) => (
        <Link href={`mailto:${OPS_SUPPORT_EMAIL}`}>{content}</Link>
      ),
    }
  );
  const questionsText = formatMessage(
    { id: "SignUpReviewRequiredPage.questions" },
    {
      phone: OPS_DISPLAY_PHONE_NUMBER,
      phoneTag: (content) => (
        <Link href={`tel:${OPS_PHONE_NUMBER}`} color="textSecondary">
          {content}
        </Link>
      ),
    }
  );

  return (
    <SignUpPageLayout hideBackButton>
      <LoggedOutPageHeader headerTextId="SignUpReviewRequiredPage.getSetUp" />
      <Box mt={LOGGED_OUT_CONTAINER_SPACING}>
        <Box mb={2}>
          <RhTypography
            variant="subtitle1"
            align="center"
            color="textSecondary"
          >
            {toStartServiceText}
          </RhTypography>
        </Box>
        <RhTypography variant="subtitle1" align="center" color="textSecondary">
          {questionsText}
        </RhTypography>
      </Box>
    </SignUpPageLayout>
  );
};
