import { Offer } from "@common/models/Offer.model";
import { OfferType } from "@common/types/offerTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhFullScreenDialog } from "@design-system/components/RhFullScreenDialog/RhFullScreenDialog";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { LegalDocuments } from "@portal/components/LegalDocuments/LegalDocuments";
import { OfferCard } from "@portal/components/OfferCard/OfferCard";
import { OfferLearnMoreFAQ } from "@portal/components/OfferLearnMoreFAQ/OfferLearnMoreFAQ";
import { OfferLearnMoreHeaderLayout } from "@portal/components/OfferLearnMoreHeaderLayout/OfferLearnMoreHeaderLayout";
import { OfferUsageTable } from "@portal/components/OfferUsageTable/OfferUsageTable";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { ActionType, AnalyticsEvent } from "@portal/services/segment.service";
import React, { FC, useState } from "react";

interface SignUpOfferCoordinatorProps {
  index: number;
  offer: OfferType;
  onSelectOffer: (offer: OfferType) => void;
  isActiveSlide?: boolean;
}

export const SignUpOfferCoordinator: FC<SignUpOfferCoordinatorProps> = ({
  index,
  offer: offerData,
  onSelectOffer,
  isActiveSlide,
}) => {
  const { t } = useRhIntl();
  const selectPlan = t("SignUpOfferCoordinator.selectCTA");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { trackEvent } = useSignUpFlow();

  const trackDetailsEvent = (event: Partial<AnalyticsEvent>) =>
    trackEvent({ ...event, category: "onboarding.planDetails" });

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const handleToggleDialog = () => {
    if (dialogOpen) {
      trackDetailsEvent({
        action: ActionType.click,
        label: `card${index + 1}Close`,
        value: `offerId${offer.id}`,
      });
    }
    setDialogOpen(!dialogOpen);
  };
  const handleSelect = () => {
    onSelectOffer(offerData);
  };

  const selectButtonProps = (dialog = false) => ({
    onClick: () => {
      trackEvent({
        action: ActionType.click,
        label: dialog ? "selectPlan" : `card${index + 1}SelectPlan`,
        value: `offerId${offer.id}`,
      });
      return handleSelect();
    },
    text: selectPlan,
  });

  const moreButtonProps = {
    onClick: () => {
      trackEvent({
        action: ActionType.click,
        label: `card${index + 1}LearnMore`,
        value: `offerId${offer.id}`,
      });
      return handleToggleDialog();
    },
    text: t("SignUpOfferCoordinator.moreCTA"),
  };

  const offer = new Offer(offerData);

  return (
    <>
      <OfferCard
        offer={offer}
        moreButtonProps={moreButtonProps}
        selectButtonProps={selectButtonProps()}
        isActiveSlide={isActiveSlide}
      />
      <RhFullScreenDialog open={dialogOpen} onClose={handleToggleDialog}>
        <OfferLearnMoreHeaderLayout
          offer={offer}
          selectButtonProps={selectButtonProps(true)}
        />

        <OfferUsageTable offer={offer} />

        <RhDivider light />

        <LegalDocuments trackEvent={trackDetailsEvent} offer={offer} />

        <RhDivider light />

        <OfferLearnMoreFAQ offer={offer} trackEvent={trackDetailsEvent} />

        {!isMobile && (
          <RhFlexBox justifyContent="center" mt={4}>
            <RhButton onClick={handleSelect} color="primary">
              {selectPlan}
            </RhButton>
          </RhFlexBox>
        )}

        {isMobile && <Box width="100%" height={10} />}
      </RhFullScreenDialog>
    </>
  );
};
