import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/styles";

export const useSignUpStepperIconStyles = makeStyles<
  typeof rhythmTheme,
  { active?: boolean; completed?: boolean },
  "root"
>(
  (theme) =>
    createStyles({
      root: {
        color: ({ active, completed }) =>
          active || completed
            ? theme.palette.primary.main
            : theme.palette.grey[200],
        height: 14,
        margin: "0 auto",
        width: 14,
      },
    }),
  { index: 1 }
);
