import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { darkPurple } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useLegalDocumentsStyle = makeStyles<
  typeof rhythmTheme,
  "description" | "icon" | "iconContainer"
>((theme) =>
  createStyles({
    description: {
      color: theme.palette.text.primary,
      marginTop: theme.spacing(0.5),
    },
    icon: {
      color: darkPurple[500],
    },
    iconContainer: {
      alignItems: "flex-start",
      display: "flex",
      height: "24px",
      justifyContent: "center",
      marginRight: theme.spacing(1.5),
      width: "24px",
    },
  })
);
