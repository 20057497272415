import { borders } from "@design-system/constants/borders";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useMyAccountContactStyles = rhMakeStyles<
  typeof rhythmTheme,
  "fields" | "label" | "readOnlyValue"
>((theme) =>
  createStyles({
    fields: {
      "& > *": {
        paddingBottom: theme.spacing(2.5),
      },
      [theme.breakpoints.up("md")]: {
        "& > *": {
          flexBasis: "50%",
          flexGrow: "initial",
          paddingRight: theme.spacing(2),
        },
        display: "flex",
        flexWrap: "wrap",
      },
    },
    label: {
      color: theme.palette.grey[400],
      fontWeight: FontWeight.Medium,
      position: "absolute",
    },
    readOnlyValue: {
      borderBottom: borders[400],
      color: theme.palette.grey[400],
      fontSize: 16,
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(1.5),
    },
  })
);
