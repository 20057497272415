import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";
import { BILLING_PREFERENCE_DETAIL_MAX_WIDTH } from "@portal/components/PreferencesSwitchInput/PreferencesSwitchInput.style";

export const usePickYourOwnDueDayBoxStyles = rhMakeStyles<
  typeof rhythmTheme,
  "buttonOrMessage" | "innerCard" | "detail"
>((theme) =>
  createStyles({
    buttonOrMessage: {
      alignSelf: "flex-start",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    detail: {
      maxWidth: BILLING_PREFERENCE_DETAIL_MAX_WIDTH,
    },
    innerCard: {
      [theme.breakpoints.down("sm")]: {
        flexFlow: "column nowrap",
      },
      alignItems: "center",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
    },
  })
);
