import { formatCurrency } from "@common/utils/dataFormatters";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { RedeemRewardsDialogContentProps } from "@portal/components/RedeemRewards/RedeemRewards.component";
import { useRedeemRewardsStyle } from "@portal/components/RedeemRewards/RedeemRewards.style";
import React, { FC } from "react";
import { useIntl } from "react-intl";

export const RewardRedemptionCTA: FC<RedeemRewardsDialogContentProps> = ({
  value,
  rewardPointBalance,
  onApplyCredit,
  onCancel,
}) => {
  const classes = useRedeemRewardsStyle({});
  const { formatMessage } = useIntl();
  const cancelCta = formatMessage({ id: "RedeemRewards.cancelCTA" });
  const applyCreditCta = formatMessage({
    id: "RedeemRewards.yesApplyCreditCTA",
  });

  return (
    <>
      <RhDialogTitle className={classes.dialogTitle}>
        {formatMessage(
          { id: "RedeemRewards.yourPointsAreWorth" },
          {
            rewardPointBalance: rewardPointBalance.toLocaleString(),
            value: formatCurrency(value),
          }
        )}
      </RhDialogTitle>
      <RhDialogContentWithButtons
        primaryButton={{
          label: applyCreditCta,
          onClick: onApplyCredit,
        }}
        secondaryButton={{
          label: cancelCta,
          onClick: onCancel,
        }}
      >
        <RhTypography
          variant="subtitle2"
          color="textSecondary"
          className={classes.dialogBody}
        >
          {formatMessage({
            id: "RedeemRewards.wouldYouLikeToApplyCredit",
          })}
        </RhTypography>
      </RhDialogContentWithButtons>
    </>
  );
};
