import { api } from "@common/api/api";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useOktaAuth } from "@okta/okta-react";
import {
  createPasswordPath,
  expiredPasswordPath,
} from "@portal/routes/routePaths";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

export const ActivationController = () => {
  const { activationToken } = useParams<{
    activationToken: string;
  }>();
  const { authService } = useOktaAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    api.customers.password
      .getStateToken({ activationToken })
      .then(({ stateToken }) => {
        dispatch(setSignUpInfo({ stateToken }));
        history.push(createPasswordPath());
      })
      .catch(() => {
        dispatch(setSignUpInfo({}));
        history.push(expiredPasswordPath());
      });
  }, [authService, activationToken, dispatch, history]);

  return <RhCircularProgress />;
};
