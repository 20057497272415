import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useCustomerRewardsSectionStyle = makeStyles<
  typeof rhythmTheme,
  | "card"
  | "illustrationContainer"
  | "listPoints"
  | "rewardsContainer"
  | "rewardMethod"
  | "textContainer"
  | "unlockTextContainer"
>((theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.grey[50],
      height: "100%",
      padding: theme.spacing(3),
    },
    illustrationContainer: {
      flexBasis: 94,
    },
    listPoints: {
      "& > li": {
        flex: "1 0 33%",
        marginBottom: theme.spacing(1.5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      "& > li::marker": {
        color: theme.palette.primary.main,
      },
      display: "flex",
      flexWrap: "wrap",
      listStyle: "disc",
    },
    rewardMethod: {
      marginBottom: theme.spacing(2),
    },
    rewardsContainer: {
      "& > *:first-child": {
        flex: "1 0 48%",
      },
      "& > *:nth-child(2)": {
        flex: "1 0 52%",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    textContainer: {
      flex: 1,
      marginRight: theme.spacing(1),
    },
    unlockTextContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);
