import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const ACCOUNT_MENU_WIDTH = 130;

export const useAccountMenuStyles = makeStyles<
  typeof rhythmTheme,
  | "addressLoading"
  | "backdrop"
  | "mapPinIconContainer"
  | "menuCtaContainer"
  | "menuItemSpacer"
  | "menuItem"
  | "clickableContainer"
  | "chevronIconContainer"
  | "chevronIcon"
  | "popupAnchor"
>((theme) =>
  createStyles({
    addressLoading: {
      opacity: 0,
      width: 40,
    },
    backdrop: {
      background: theme.palette.grey[700],
      cursor: "pointer",
    },
    chevronIcon: {
      height: 10,
      width: 10,
    },
    chevronIconContainer: {
      color: theme.palette.primary.main,
      paddingLeft: theme.spacing(1),
    },
    clickableContainer: {
      "&:hover": {
        cursor: "pointer",
      },
      position: "relative",
    },
    mapPinIconContainer: {
      color: theme.palette.primary.main,
      height: 14,
      paddingRight: theme.spacing(1),
    },
    menuCtaContainer: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      transition: "opacity 250ms ease-in-out",
    },
    menuItem: {
      margin: `${theme.spacing(0.5)}px 0`,
      paddingRight: 0,
    },
    menuItemSpacer: {
      "& a": {
        // this increases the clickable area of links
        paddingRight: 100,
      },
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      width: ACCOUNT_MENU_WIDTH,
    },
    popupAnchor: {
      height: 10,
      position: "absolute",
      right: 0,
    },
  })
);
