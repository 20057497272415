import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  RhTypography,
  RhTypographyVariant,
} from "@design-system/components/RhTypography/RhTypography";
import { AddACH } from "@portal/components/AddACH/AddACH";
import { AddPaymentCard } from "@portal/components/AddPaymentCard/AddPaymentCard";
import { PaymentMethodRadioField } from "@portal/components/PaymentMethodRadioField/PaymentMethodRadioField";
import { usePaymentMethodsSectionStyles } from "@portal/components/PaymentMethodsSection/PaymentMethodsSection.style";
import { PaymentMethodView } from "@portal/components/PaymentMethodView/PaymentMethodView";
import { usePaymentMethods } from "@portal/hooks/billingHooks";
import { useFeatureFlags } from "@portal/hooks/useFeatureFlags";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import {
  billingDetailsRefetch,
  paymentMethodSetAsDefaultRequest,
} from "@portal/slices/billingDetailsSlice";
import React, { FC } from "react";
import { useDispatch } from "react-redux";

export interface PaymentMethodsSectionProps {
  sectionTitleText?: string;
  variant?: RhTypographyVariant;
  allowMethodChange?: boolean;
}

export const PaymentMethodsSection: FC<PaymentMethodsSectionProps> = ({
  sectionTitleText,
  variant = "h3",
  allowMethodChange = false,
}) => {
  const classes = usePaymentMethodsSectionStyles();
  const dispatch = useDispatch();
  const { t } = useRhIntl();
  const { addAchFeature } = useFeatureFlags();

  const { paymentMethods, requestMonitor } = usePaymentMethods();

  const assignDefaultAndRefetch = (
    creditCardResponse: ZuoraAddPaymentResponseType
  ) => {
    if (paymentMethods?.length === 0) {
      dispatch(
        paymentMethodSetAsDefaultRequest({ id: creditCardResponse.refId })
      );
    } else {
      dispatch(billingDetailsRefetch());
    }
  };

  if (requestMonitor.didFail) {
    return (
      <RhTypography variant="body1">
        {t("account.billing.paymentMethodSection.error")}
      </RhTypography>
    );
  }

  if (requestMonitor.isIdle) {
    return <RhCircularProgress />;
  }

  return (
    <div>
      {sectionTitleText && (
        <RhTypography variant={variant} className={classes.sectionTitle}>
          {sectionTitleText}
        </RhTypography>
      )}
      {paymentMethods?.map((paymentMethod) =>
        allowMethodChange ? (
          <PaymentMethodRadioField
            key={paymentMethod.id}
            name="selectedPaymentMethodId"
            paymentMethod={paymentMethod}
          />
        ) : (
          <PaymentMethodView
            paymentMethod={paymentMethod}
            key={paymentMethod.id}
            displayOptions
          />
        )
      )}
      {requestMonitor.isPending && <RhCircularProgress />}
      <AddPaymentCard onCardAdded={assignDefaultAndRefetch} />
      {addAchFeature && <AddACH />}
    </div>
  );
};
