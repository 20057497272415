import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useAuthenticatedUserGroups } from "@portal/hooks/useAuthenticatedUserGroups";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { SignUpBillingPreferencesFields } from "@portal/pages/SignUpBillingPreferencesPage/SignUpBillingPreferencesFields";
import { SignUpStepType } from "@portal/routes/routePaths";
import { selectBillingPreferencesFormValues } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { isOpsOrCSRs } from "@portal/utils/signUpHelpers";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export interface SignUpBillingPreferencesFormValues {
  invoiceByPrint: boolean;
  autopay: boolean;
}

export const SignUpBillingPreferencesPage: FC = () => {
  const { formatMessage } = useIntl();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const currentValues = useSelector(selectBillingPreferencesFormValues);
  const { depositAmount, ssnRequired } = useSelector<
    PortalStoreState,
    { depositAmount: number | null; ssnRequired?: boolean }
    // eslint-disable-next-line @typescript-eslint/no-shadow
  >(({ signUp: { depositAmount, ssnRequired } }) => ({
    depositAmount,
    ssnRequired,
  }));

  const { data: groups, requestMonitor } = useAuthenticatedUserGroups();

  const onSubmit = (values: SignUpBillingPreferencesFormValues) => {
    let nextStep: SignUpStepType;

    if (ssnRequired) {
      nextStep = "credit-check";
    } else if (isOpsOrCSRs(groups)) {
      nextStep = "ivr-payment";
    } else if (depositAmount) {
      nextStep = "deposit";
    } else {
      nextStep = "payment";
    }

    signUpClickNextStepHandler({ signUpData: values, nextStep, track: true });
  };

  const submitCTA = formatMessage({
    id: "SignUpBillingPreferences.submitCTA",
  });

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerTextId="SignUpBillingPreferences.letsSetUpBilling"
        subHeaderTextId="SignUpBillingPreferences.earnRewards"
      />

      <Form<SignUpBillingPreferencesFormValues>
        initialValues={{ ...currentValues }}
        onSubmit={onSubmit}
        render={({ handleSubmit }) =>
          requestMonitor.isPending ? (
            <RhCircularProgress marginTop={2} />
          ) : (
            <LoggedOutForm
              onSubmit={handleSubmit}
              onChange={(event) => {
                const {
                  target: { checked, id },
                } = (event as unknown) as React.ChangeEvent<HTMLInputElement>;

                trackEvent({
                  label: id,
                  action: ActionType.click,
                  value: checked,
                });
              }}
              submitButtonText={submitCTA}
            >
              <SignUpBillingPreferencesFields />
            </LoggedOutForm>
          )
        }
      />
    </SignUpPageLayout>
  );
};
