import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useReferAFriendDialogStyles = makeStyles<
  typeof rhythmTheme,
  "subtitle" | "dialogContainer"
>((theme) => {
  return createStyles({
    dialogContainer: {
      padding: theme.spacing(6),
      textAlign: "center",
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  });
});
