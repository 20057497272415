import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { useSignUpStepperStyles } from "@portal/components/SignUpStepper/SignUpStepper.style";
import { SignUpStepperIcon } from "@portal/components/SignUpStepper/SignUpStepperIcon";
import { StepperKeysInOrder } from "@portal/components/SignUpStepper/signUpStepperTypes";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { SignUpStepType } from "@portal/routes/routePaths";
import React, { FC } from "react";
import { useIntl } from "react-intl";

const StepIndexMap: Record<SignUpStepType, 1 | 2 | 3 | null> = {
  availability: null,
  "availability-address": null,
  "choose-utility": null,
  plans: null,
  updates: null,
  name: 1,
  contact: 1,
  address: 1,
  "confirm-address": 1,
  details: 1,
  payment: 2,
  "ivr-payment": 2,
  "billing-preferences": 2,
  "credit-check": 2,
  deposit: 2,
  summary: 3,
  "call-us": 3,
};

export const SignUpStepper: FC = () => {
  const { currentStep } = useSignUpFlow();
  const { formatMessage } = useIntl();
  const {
    stepperRoot,
    connectorLine,
    connectorRoot,
  } = useSignUpStepperStyles();

  const activeStepIndex = StepIndexMap[currentStep];

  return activeStepIndex ? (
    <Box mb={6} mx="auto" width={LOGGED_OUT_FORM_WIDTH} clone>
      <Stepper
        activeStep={activeStepIndex}
        orientation="horizontal"
        alternativeLabel
        classes={{
          root: stepperRoot,
        }}
        connector={
          <StepConnector
            classes={{
              root: connectorRoot,
              line: connectorLine,
            }}
          />
        }
      >
        {StepperKeysInOrder.map((step) => (
          <Step key={step}>
            <StepLabel
              StepIconComponent={SignUpStepperIcon}
              StepIconProps={{
                icon: step,
              }}
            >
              {formatMessage({ id: `SignUpStepper.${step}` })}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  ) : null;
};
