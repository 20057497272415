import { Customer } from "@common/models/Customer.model";
import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { RhApiError } from "@common/types/errorTypes";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";

interface CustomerSelectorType {
  customer: Customer | null;
  status: RequestMonitor;
  error: RhApiError | null;
}

export const selectCustomer = ({
  customer,
}: PortalStoreState): CustomerSelectorType => {
  const { data, error, requestStatus } = customer;

  return {
    customer: data ? new Customer(data) : null,
    status: new RequestMonitor(requestStatus),
    error,
  };
};
