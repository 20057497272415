import {
  OKTA_PASSWORD_REQUIREMENTS_ERROR,
  RhApiError,
} from "@common/types/errorTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { PortalPasswordField } from "@portal/components/PortalPasswordField/PortalPasswordField";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useCreatePasswordStyles } from "@portal/pages/CreatePasswordPage/CreatePasswordPage.style";
import { ReactComponent as HighFives } from "@portal/pages/CreatePasswordPage/high_five_90x130.svg";
import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

interface SignUpCreatePasswordFormValues {
  password: string;
}

export interface SignUpCreatePasswordPageValues {
  activationToken?: string;
  firstName: string;
  stateToken?: string;
  sessionToken?: string;
}

export const CreatePasswordPage: FC = () => {
  const flash = useRhFlash();

  const {
    activationToken,
    firstName,
    stateToken: existingStateToken,
  } = useSelector<PortalStoreState, SignUpCreatePasswordPageValues>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ signUp: { activationToken, firstName, stateToken } }) => ({
      activationToken,
      firstName,
      stateToken,
    })
  );

  // On success, sign-up data is wiped, which will cause flicker in the header
  // To avoid this, save a local copy of the first name, which is discarded on unmount
  const [firstNameLocalCopy] = useState<string>(firstName);
  const [stateToken, setStateToken] = useState<string | undefined>(
    existingStateToken
  );

  const { t } = useRhIntl();

  const classes = useCreatePasswordStyles();

  const createPassword = ({ password }: SignUpCreatePasswordFormValues) => {
    return portalAuthClient
      .createPassword({
        activationToken,
        password,
        stateToken,
      })
      .catch((error: RhApiError) => {
        setStateToken(error.data.stateToken || "");

        let messageId: string;

        switch (error.data.errorCode) {
          case OKTA_PASSWORD_REQUIREMENTS_ERROR: {
            messageId = "CreatePasswordPage.invalidPassword";
            break;
          }
          default: {
            messageId = "CreatePasswordPage.unknownOktaIssue";
          }
        }

        const errorMessage = t(messageId);

        flash.error(errorMessage);

        return {
          password: [errorMessage],
        };
      });
  };

  // If we've arrived here from a link in your email, we don't know your name
  const welcomeTextId = firstNameLocalCopy
    ? "CreatePasswordPage.welcomeWithName"
    : "CreatePasswordPage.welcome";

  const password = t("CreatePasswordPage.password");
  const submitCTA = t("CreatePasswordPage.submitCTA");
  const inputCaption = t("common.password.minimumRequirements");
  const illustrationTitle = t("CreatePasswordPage.twoFriendsHighFiving");

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader
        headerTextId={welcomeTextId}
        headerTextValues={{
          firstName: firstNameLocalCopy,
        }}
        subHeaderTextId="CreatePasswordPage.createAPasswordToAccess"
        illustration={<HighFives title={illustrationTitle} />}
      />
      <Form<SignUpCreatePasswordFormValues>
        onSubmit={createPassword}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={submitCTA}
            showProgress
          >
            <LoggedOutFieldsLayout>
              <PortalPasswordField name="password">
                {password}
              </PortalPasswordField>
              <RhTypography
                variant="caption"
                classes={{ root: classes.caption }}
              >
                {inputCaption}
              </RhTypography>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </LoggedOutPageLayout>
  );
};
