import { urls } from "@common/api/urls";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { AccountSummary } from "@common/models/AccountSummary.model";
import { Customer } from "@common/models/Customer.model";
import { RhApiError } from "@common/types/errorTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { BalanceDetails } from "@portal/components/BalanceDetails/BalanceDetails";
import { useBillingSectionStyles } from "@portal/components/BillingSection/BillingSection.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { payBillPath } from "@portal/routes/routePaths";
import { authenticatedFileDownload } from "@portal/utils/authenticatedFileDownload";
import { buildInvoiceFilename } from "@portal/utils/invoiceDownloadHelpers";
import React, { FC, MouseEventHandler } from "react";

interface BillingSectionProps {
  accountSummary: AccountSummary;
  customer: Customer;
  className?: string;
}

export const BillingSection: FC<BillingSectionProps> = ({
  accountSummary,
  customer,
  className,
}) => {
  const [
    { requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<boolean>(false);

  const { t } = useRhIntl();
  const classes = useBillingSectionStyles();
  const flash = useRhFlash();
  const payBillCTA = t("BillingSection.makePaymentCTA");
  const viewBillCTA = t(
    accountSummary.noBalanceDue
      ? "BillingSection.viewPreviousBill"
      : "BillingSection.viewBill"
  );

  const invoice = accountSummary.invoices[0];
  const handleDownload: MouseEventHandler = () => {
    const url = urls.customers.invoice(customer.id, invoice.id);
    const filename = buildInvoiceFilename(customer, invoice);
    const mimeType = { type: "application/pdf" } as MimeType;

    setPending();
    authenticatedFileDownload(url, filename, mimeType)
      .then(() => {
        setSuccess(true);
      })
      .catch((error: RhApiError) => {
        setFailure(error);
        flash.error(t("BillingSection.errorViewingBill"));
      });
  };

  return (
    <RhCard className={`${classes.billingSectionContainer} ${className}`}>
      <RhFlexBox
        flexDirection="column"
        alignItems="center"
        width="100%"
        paddingY={2}
        paddingX={2}
      >
        <Box marginBottom={2}>
          <BalanceDetails
            accountSummary={accountSummary}
            autopayEnabled={customer.autopay}
          />
        </Box>
        <Box marginBottom={2} width="100%">
          <RhLinkButton href={payBillPath()} color="primary" size="medium">
            {payBillCTA}
          </RhLinkButton>
        </Box>
        <Box marginBottom={2} width="100%">
          <RhButton
            onClick={handleDownload}
            variant="outlined"
            color="primary"
            fullWidth
            disabled={Boolean(
              requestMonitor.isPending || accountSummary.invoices.length === 0
            )}
          >
            {viewBillCTA}
          </RhButton>
        </Box>
      </RhFlexBox>
    </RhCard>
  );
};
