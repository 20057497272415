import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { trueWhite } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { ANNOUNCEMENT_HEIGHT } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core/styles";

export const useRhAnnouncementStyles = rhMakeStyles<
  typeof rhythmTheme,
  "error" | "root" | "notice"
>((theme) =>
  createStyles({
    error: {
      "& a": {
        "&:hover": {
          color: theme.palette.grey[100],
        },
        color: trueWhite,
      },
      backgroundColor: theme.palette.error[500],
      color: trueWhite,
    },
    notice: {
      "& a": {
        "&:hover": {
          color: theme.palette.success[500],
        },
        color: theme.palette.success[700],
      },
      backgroundColor: theme.palette.success[100],
      color: theme.palette.success[700],
    },
    root: {
      "& a": {
        fontWeight: FontWeight.Regular,
        textDecoration: "underline",
      },
      alignItems: "center",
      display: "flex",
      fontSize: 12,
      fontWeight: FontWeight.Bold,
      height: theme.spacing(ANNOUNCEMENT_HEIGHT),
      justifyContent: "center",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      textAlign: "center",
      width: "100%",
    },
  })
);
