import { urls } from "@common/api/urls";
import { IdType } from "@common/types/apiTypes";
import { Link } from "@material-ui/core";
import { AXIOS_BASE_URL, CDN_URL } from "@portal/settings/config";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import React, { FC } from "react";

const baseDocumentLinkProps = {
  rel: "noopener noreferrer",
  target: "_blank",
};

interface DocumentLinkProps {
  onClick?: () => void;
  locale: SupportedPortalLanguage;
}

interface EFLLinkProps extends DocumentLinkProps {
  offerId: IdType;
}
export const EFLLink: FC<EFLLinkProps> = ({
  offerId,
  onClick,
  locale,
  children,
}) => (
  <Link
    {...baseDocumentLinkProps}
    href={`${AXIOS_BASE_URL}${urls.pricing.offers.efl(offerId, locale)}`}
    onClick={onClick}
  >
    {children}
  </Link>
);

export const TOSLink: FC<DocumentLinkProps> = ({
  onClick,
  locale,
  children,
}) => (
  <Link
    {...baseDocumentLinkProps}
    href={`${CDN_URL}/RhythmTOS_${locale}.pdf`}
    onClick={onClick}
  >
    {children}
  </Link>
);

export const YRACLink: FC<DocumentLinkProps> = ({
  onClick,
  locale,
  children,
}) => (
  <Link
    {...baseDocumentLinkProps}
    href={`${CDN_URL}/RhythmYRAC_${locale}.pdf`}
    onClick={onClick}
  >
    {children}
  </Link>
);
