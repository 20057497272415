import { api } from "@common/api/api";
import { RhApiError } from "@common/types/errorTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { PlaidSuccessResponse } from "@portal/components/AddACH/PlaidLink";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { usePlaidLink } from "react-plaid-link";

interface SignUpPlaidLinkProps {
  token: string;
  onPlaidModalStateChange?: (isOpen: boolean) => void;
  triggerOpen: boolean;
}

export const SignUpPlaidLink: FC<SignUpPlaidLinkProps> = ({
  token,
  onPlaidModalStateChange,
  triggerOpen,
}: SignUpPlaidLinkProps) => {
  const flash = useRhFlash();
  const { formatMessage } = useIntl();
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);

  const handlePlaidModalStateChange = (isOpen: boolean) => {
    if (onPlaidModalStateChange) {
      onPlaidModalStateChange(isOpen);
    }
  };

  const { open, ready, error } = usePlaidLink({
    token,
    onSuccess: (
      plaidPublicToken: string,
      { account_id: plaidAccountId }: PlaidSuccessResponse
    ) => {
      api.prospects
        .createAch({ plaidPublicToken, plaidAccountId })
        .then(({ billingPaymentMethodId }) => {
          signUpClickNextStepHandler({
            signUpData: { billingPaymentMethodId },
            nextStep: "summary",
            track: true,
          });
        })
        .catch((_error: RhApiError) => {
          flash.error(formatMessage({ id: "AddACH.plaidIssues" }));
          handlePlaidModalStateChange(false);
          setDisplayLoading(false);
        });

      if (error) {
        flash.error(formatMessage({ id: "AddACH.plaidIssues" }));
        handlePlaidModalStateChange(false);
      }
    },
    onLoad: () => {
      setDisplayLoading(true);
      handlePlaidModalStateChange(true);
    },
    onExit: () => {
      setDisplayLoading(true);
      handlePlaidModalStateChange(false);
    },
  });

  useEffect(() => {
    if (ready && triggerOpen) {
      open();
    }
  }, [ready, open, triggerOpen]);

  if (!triggerOpen) {
    return null;
  }

  return displayLoading ? (
    <Box marginTop={4}>{displayLoading && <RhCircularProgress />}</Box>
  ) : (
    <LoggedOutPageHeader headerTextId="SignUpPaymentPage.preferredPaymentMethod" />
  );
};
