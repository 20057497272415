import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useEnergyUsageSectionStyles = makeStyles<
  typeof rhythmTheme,
  "card" | "cardTitle"
>((theme) =>
  createStyles({
    card: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    cardTitle: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: 20,
        marginRight: 20,
        width: "auto",
      },
    },
  })
);
