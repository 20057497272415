import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import {
  loadReduxSlice,
  watchPersistedReduxSlice,
} from "@common/services/persistReduxSlice";
import { accountSummaryReducer } from "@portal/slices/accountSummarySlice";
import { billingDetailsReducer } from "@portal/slices/billingDetailsSlice";
import { customerPreferencesReducer } from "@portal/slices/customerPreferencesSlice";
import { customerReducer } from "@portal/slices/customerSlice";
import {
  initialOfferState,
  offerReducer,
  offerSliceName,
} from "@portal/slices/offerSlice";
import { rewardsReducer } from "@portal/slices/rewardsSlice";
import {
  initialSignUpState,
  signUpReducer,
  signUpSliceName,
} from "@portal/slices/signUpSlice";
import { rootSaga } from "@portal/store/root.saga";
import { initialPortalStoreState } from "@portal/types/portalSlicesTypes";
import {
  ReducersMapObject,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

export const reducers: ReducersMapObject = {
  accountSummary: accountSummaryReducer,
  billingDetails: billingDetailsReducer,
  customer: customerReducer,
  customerPreferences: customerPreferencesReducer,
  rewards: rewardsReducer,
  signUp: signUpReducer,
  offer: offerReducer,
};

const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware(), sagaMiddleware];

export const store = (() => {
  const innerStore = configureStore({
    devTools: !isProduction(),
    preloadedState: {
      ...initialPortalStoreState,
      signUp: loadReduxSlice(signUpSliceName, initialSignUpState),
      offer: loadReduxSlice(offerSliceName, initialOfferState),
    },
    reducer: combineReducers(reducers),
    middleware,
  });

  watchPersistedReduxSlice(signUpSliceName, innerStore);
  watchPersistedReduxSlice(offerSliceName, innerStore);
  sagaMiddleware.run(rootSaga);

  return innerStore;
})();
