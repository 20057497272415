import { Offer } from "@common/models/Offer.model";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

interface OfferRateAndMonthlyProps {
  offer: Offer;
}

export const OfferRateAndEstimate: FC<OfferRateAndMonthlyProps> = ({
  offer,
}) => {
  const { t } = useRhIntl();
  const estimatedMonthlyUsage = DEFAULT_AVERAGE_MONTHLY_USAGE;

  return (
    <>
      <Box pb={0.5}>
        <RhTypography variant="h3">
          {formatDollarsToCents(
            offer.averagePricePerKWH(estimatedMonthlyUsage)
          )}
        </RhTypography>
      </Box>
      <RhTypography variant="body3" color="textSecondary">
        {t("OfferRateAndEstimate.perKWH")}
      </RhTypography>
      <RhTypography color="textSecondary" variant="body3">
        {t("OfferRateAndEstimate.basedOn", { amount: estimatedMonthlyUsage })}
      </RhTypography>
    </>
  );
};
