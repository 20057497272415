import "dayjs/locale/es";

import { EnvTypes } from "@common/enums/envTypes.enum";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import en from "@portal/translations/en.json";
import es from "@portal/translations/es.json";
import { generateTranslations } from "@portal/utils/generateTranslations.util";
import { captureException as sentryCaptureException } from "@sentry/react";
import dayjs from "dayjs";
import { IntlShape, createIntl } from "react-intl";

export const englishLanguageCode = "en";
export const spanishLanguageCode = "es";

export const AllSupportedPortalLanguages = [
  englishLanguageCode,
  spanishLanguageCode,
] as const;
export type SupportedPortalLanguage = typeof AllSupportedPortalLanguages[number];

export const translations: Record<
  SupportedPortalLanguage,
  Record<string, string>
> = {
  [englishLanguageCode]: en,
  [spanishLanguageCode]: es,
};

export const findSupportedLanguage = (
  language: string
): SupportedPortalLanguage => {
  // 0th index is language code, 1st index is nationality, e.g. es-MX
  const languageCode = language.split("-")[0];

  return (
    AllSupportedPortalLanguages.find(
      (supportedLanguage) => supportedLanguage === languageCode
    ) || englishLanguageCode
  );
};

const env = getEnv();

export const generateIntl = (
  customerLanguagePreference?: SupportedPortalLanguage | string | null
): IntlShape => {
  const locale = findSupportedLanguage(
    customerLanguagePreference || navigator.language || ""
  );

  dayjs.locale(locale);
  generateTranslations(locale);

  return createIntl({
    locale,
    messages: translations[locale],
    onError: (error) => {
      sentryCaptureException(error);
      if (env !== EnvTypes.production) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });
};
