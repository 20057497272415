import { formatKwh } from "@common/utils/dataFormatters";
import { noop } from "@common/utils/genericFunctions";
import { RhSlider } from "@design-system/components/RhSlider/RhSlider";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { SliderOnChangeFunction } from "@design-system/types/domEventTypes";
import { Tooltip } from "@material-ui/core";
import { useMonthlyUsageSliderInputStyles } from "@portal/components/MonthlyUsageSlider/MonthlyUsageSliderInput.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC, ReactElement } from "react";

interface SliderLabelProps {
  open: boolean;
  value: number;
  // This makes it in line with MuiTooltip types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any, any>;
}
const SliderLabel = ({ open, value, children }: SliderLabelProps) => {
  const { t } = useRhIntl();
  const {
    labelText: labelTextClass,
    ...tooltipClasses
  } = useMonthlyUsageSliderInputStyles();
  const theAverageHomeUses = t("MonthlyUsageSliderInput.theAverageHomeUses");

  if (!value) {
    return null;
  }

  const label = (
    <>
      {formatKwh(value, 0)}
      {value === 1000 && (
        <RhTypography
          className={labelTextClass}
          component="div"
          variant="caption"
          fontWeight={FontWeight.Regular}
        >
          {theAverageHomeUses}
        </RhTypography>
      )}
    </>
  );

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom-start"
      title={label}
      classes={tooltipClasses}
      PopperProps={{
        popperOptions: {
          modifiers: {
            flip: { enabled: false },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

const RANGE_START = 500;
const RANGE_END = 3500;
const RANGE_STEP = 500;

interface MonthlyUsageSliderInputProps {
  onChange?: SliderOnChangeFunction;
  onChangeCommitted?: SliderOnChangeFunction;
  initialValue: number;
}
export const MonthlyUsageSliderInput: FC<MonthlyUsageSliderInputProps> = ({
  onChange = noop,
  onChangeCommitted,
  initialValue,
}) => {
  const { t } = useRhIntl();

  return (
    <RhSlider
      aria-label={t("MonthlyUsageSliderInput.sliderLabel")}
      defaultValue={initialValue}
      min={RANGE_START}
      max={RANGE_END}
      step={RANGE_STEP}
      marks
      valueLabelDisplay="on"
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      ValueLabelComponent={SliderLabel}
    />
  );
};
