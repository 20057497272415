import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Paper } from "@material-ui/core";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

import { useEnergyUsageChartMissingStyles } from "./EnergyUsageChartMissing.style";

export const EnergyUsageChartMissing: FC = () => {
  const { t } = useRhIntl();

  const classes = useEnergyUsageChartMissingStyles();

  return (
    <Paper className={classes.card} elevation={0}>
      <RhTypography
        className={classes.subtitle}
        variant="subtitle1"
        color="textSecondary"
      >
        {t("EnergyUsageChart.dataWillAppear")}
      </RhTypography>
    </Paper>
  );
};
