import { maskPhone, unmaskPhone } from "@common/forms/phone.mask";
import { RhCheckbox } from "@design-system/components/RhCheckbox/RhCheckbox";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";

interface SignUpContactFieldsProps {
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleMarketingPromoChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

export const SignUpContactFields: React.FC<SignUpContactFieldsProps> = ({
  handleMarketingPromoChange,
  onFocus,
}) => {
  const { t } = useRhIntl();
  const email = t("signUpContactFields.email");
  const confirmEmail = t("signUpContactFields.confirmEmail");
  const phone = t("signUpContactFields.phone");
  const sendMarketingPromos = t("signUpContactFields.sendMarketingPromos");

  const fieldInputProps = {
    onFocus,
  };

  return (
    <LoggedOutFieldsLayout>
      <RhTextField
        name="email"
        type="email"
        autoComplete="email"
        InputProps={fieldInputProps}
      >
        {email}
      </RhTextField>
      <RhTextField
        name="confirmEmail"
        type="email"
        autoComplete="email"
        inputMode="email"
        InputProps={fieldInputProps}
      >
        {confirmEmail}
      </RhTextField>
      <RhTextField
        autoComplete="phone"
        format={maskPhone}
        parse={unmaskPhone}
        name="phone"
        type="tel"
        inputMode="numeric"
        placeholder="555-555-5555"
        InputProps={fieldInputProps}
      >
        {phone}
      </RhTextField>
      <RhCheckbox
        onClick={handleMarketingPromoChange}
        label={sendMarketingPromos}
        name="sendMarketingPromos"
      />
    </LoggedOutFieldsLayout>
  );
};
