import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { Theme, createStyles } from "@material-ui/core/styles";

export const useLoggedOutPageHeaderStyles = makeStyles<
  Theme,
  "illustrationContainer" | "root"
>((theme) =>
  createStyles({
    illustrationContainer: {
      margin: `0 auto ${theme.spacing(3)}px`,
      width: 90,
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        width: LOGGED_OUT_FORM_WIDTH,
      },
    },
  })
);
