import { passwordTokenParam } from "@common/services/resetPassword";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { LoginCallback } from "@okta/okta-react";
import { ActivationController } from "@portal/components/ActivationController/ActivationController";
import { CSROrOpsSignUpRoute } from "@portal/components/CSROrOpsSignUpRoute/CSROrOpsSignUpRoute";
import { LoggedOutRoute } from "@portal/components/LoggedOutRoute/LoggedOutRoute";
import { SignUpRoute } from "@portal/components/SignUpRoute/SignUpRoute";
import { useAuthenticateUser } from "@portal/hooks/useAuthenticateUser";
import { useFeatureFlags } from "@portal/hooks/useFeatureFlags";
import { useSignUpQueryParams } from "@portal/hooks/useSignUpQueryParams";
import { CreatePasswordPage } from "@portal/pages/CreatePasswordPage/CreatePasswordPage";
import { CustomerHomePage } from "@portal/pages/CustomerHomePage/CustomerHomePage";
import { Error404Page } from "@portal/pages/Error404Page/Error404Page";
import { ExpiredPasswordTokenPage } from "@portal/pages/ExpiredPasswordTokenPage/ExpiredPasswordTokenPage";
import { ForgotPasswordPage } from "@portal/pages/ForgotPasswordPage/ForgotPasswordPage";
import { MyAccountPage } from "@portal/pages/MyAccountPage/MyAccountPage";
import { NotificationsPage } from "@portal/pages/NotificationsPage/NotificationsPage";
import { PayBillPage } from "@portal/pages/PayBillPage/PayBillPage";
import { PayBillSuccessPage } from "@portal/pages/PayBillSuccessPage/PayBillSuccessPage";
import { ResetPasswordPage } from "@portal/pages/ResetPasswordPage/ResetPasswordPage";
import { RewardsPage } from "@portal/pages/RewardsPage/RewardsPage";
import { SignInPage } from "@portal/pages/SignInPage/SignInPage";
import { SignUpAvailabilityPage } from "@portal/pages/SignUpAvailabilityPage/SignUpAvailabilityPage";
import { SignUpBillingPreferencesPage } from "@portal/pages/SignUpBillingPreferencesPage/SignUpBillingPreferencesPage";
import { SignUpChooseUtilityPage } from "@portal/pages/SignUpChooseUtilityPage/SignUpChooseUtilityPage";
import { SignUpConfirmAddressPage } from "@portal/pages/SignUpConfirmAddressPage/SignUpConfirmAddressPage";
import { SignUpContactPage } from "@portal/pages/SignUpContactPage/SignUpContactPage";
import { SignUpCSRSuccessPage } from "@portal/pages/SignUpCSRSuccessPage/SignUpCSRSuccessPage";
import { SignUpDepositRequiredPage } from "@portal/pages/SignUpDepositRequiredPage/SignUpDepositRequiredPage";
import { SignUpEnrollmentPage } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentPage";
import { SignUpIVRPaymentPage } from "@portal/pages/SignUpIVRPaymentPage/SignUpIVRPaymentPage";
import { SignUpMultipleUtilitiesPage } from "@portal/pages/SignUpMultipleUtilitiesPage/SignUpMultipleUtilitiesPage";
import { SignUpNamePage } from "@portal/pages/SignUpNamePage/SignUpNamePage";
import { SignUpNoCoveragePage } from "@portal/pages/SignUpNoCoveragePage/SignUpNoCoveragePage";
import { SignUpOfferPage } from "@portal/pages/SignUpOfferPage/SignUpOfferPage";
import { SignUpPaymentPage } from "@portal/pages/SignUpPaymentPage/SignUpPaymentPage";
import { SignUpZuoraPaymentPage } from "@portal/pages/SignUpPaymentPage/SignUpZuoraPaymentPage";
import { SignUpPremisePage } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import { SignUpReviewRequiredPage } from "@portal/pages/SignUpReviewRequiredPage/SignUpReviewRequiredPage";
import { SignUpSSNRequiredPage } from "@portal/pages/SignUpSSNRequiredPage/SignUpSSNRequiredPage";
import { SignUpSummaryPage } from "@portal/pages/SignUpSummaryPage/SignUpSummaryPage";
import { UnauthorizedPage } from "@portal/pages/UnauthorizedPage/UnauthorizedPage";
import { RhSecurePortalRoute } from "@portal/routes/RhSecurePortalRoute";
import {
  SignUpStepType,
  activatePath,
  createPasswordPath,
  customerHomePath,
  expiredPasswordPath,
  forgotPasswordPath,
  homePath,
  loginCallbackPath,
  myAccountPath,
  notificationsPath,
  payBillPath,
  payBillSuccessPath,
  resetPasswordPath,
  rewardsPath,
  signInPath,
  signOutPath,
  signUpBasePath,
  signUpCSRSuccessPath,
  signUpStepPath,
  unauthorizedPath,
} from "@portal/routes/routePaths";
import { SignOut } from "@portal/routes/SignOut";
import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const PortalRoutes: FC = () => {
  const queryParamRequest = useSignUpQueryParams();
  const { achSignUpFeature } = useFeatureFlags();

  useAuthenticateUser();

  if (queryParamRequest.isWaiting) {
    return <RhCircularProgress />;
  }

  const signUpSteps: { step: SignUpStepType; component: React.FC }[] = [
    { step: "availability", component: SignUpAvailabilityPage },
    { step: "updates", component: SignUpNoCoveragePage },
    { step: "call-us", component: SignUpReviewRequiredPage },
    { step: "availability-address", component: SignUpMultipleUtilitiesPage },
    { step: "choose-utility", component: SignUpChooseUtilityPage },
    { step: "plans", component: SignUpOfferPage },
    { step: "name", component: SignUpNamePage },
    { step: "contact", component: SignUpContactPage },
    { step: "address", component: SignUpPremisePage },
    { step: "confirm-address", component: SignUpConfirmAddressPage },
    { step: "details", component: SignUpEnrollmentPage },
    { step: "billing-preferences", component: SignUpBillingPreferencesPage },
    {
      step: "payment",
      component: achSignUpFeature ? SignUpPaymentPage : SignUpZuoraPaymentPage,
    },
    { step: "ivr-payment", component: SignUpIVRPaymentPage },
    { step: "credit-check", component: SignUpSSNRequiredPage },
    { step: "deposit", component: SignUpDepositRequiredPage },
    { step: "summary", component: SignUpSummaryPage },
  ];

  return (
    <Switch>
      <Redirect exact from={homePath()} to={customerHomePath()} />
      <RhSecurePortalRoute
        Component={CustomerHomePage}
        exact
        path={customerHomePath()}
      />
      <RhSecurePortalRoute
        Component={PayBillSuccessPage}
        exact
        path={payBillSuccessPath()}
      />
      <RhSecurePortalRoute Component={PayBillPage} exact path={payBillPath()} />
      <RhSecurePortalRoute Component={RewardsPage} exact path={rewardsPath()} />
      <RhSecurePortalRoute
        Component={MyAccountPage}
        exact
        path={myAccountPath()}
      />
      <RhSecurePortalRoute
        Component={NotificationsPage}
        exact
        path={notificationsPath()}
      />
      <Route exact path={signOutPath()} component={SignOut} />
      <LoggedOutRoute
        path={createPasswordPath()}
        component={CreatePasswordPage}
      />
      <LoggedOutRoute
        path={expiredPasswordPath()}
        component={ExpiredPasswordTokenPage}
      />
      <LoggedOutRoute
        exact
        path={activatePath(":activationToken")}
        component={ActivationController}
      />
      <LoggedOutRoute exact path={signInPath()} component={SignInPage} />
      <LoggedOutRoute
        exact
        path={forgotPasswordPath()}
        component={ForgotPasswordPage}
      />
      <LoggedOutRoute
        exact
        path={resetPasswordPath(passwordTokenParam)}
        component={ResetPasswordPage}
      />
      <Redirect
        exact
        path={signUpBasePath()}
        to={signUpStepPath("availability")}
      />
      {signUpSteps.map(({ step, component }) => (
        <SignUpRoute
          key={step}
          path={signUpStepPath(step)}
          component={component}
        />
      ))}
      <Redirect
        exact
        path={`/csr${signUpBasePath()}`}
        to={`/csr${signUpStepPath("availability")}`}
      />
      {signUpSteps.map(({ step, component }) => (
        <CSROrOpsSignUpRoute
          key={`csr-${step}`}
          path={`/csr${signUpStepPath(step)}`}
          component={component}
        />
      ))}
      <CSROrOpsSignUpRoute
        path={signUpCSRSuccessPath()}
        component={SignUpCSRSuccessPage}
      />
      <Route path={unauthorizedPath()} component={UnauthorizedPage} />
      <Route exact path={loginCallbackPath()} component={LoginCallback} />
      <Route component={Error404Page} />
    </Switch>
  );
};
