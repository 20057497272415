import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { Box, Grid } from "@material-ui/core";
import { SignUpFieldsLayoutContainer } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { StateSelectField } from "@portal/components/StateSelectField/StateSelectField";
import { SignUpPremiseFormValues } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import React, { FC, useEffect } from "react";
import { useFormState } from "react-final-form";
import { useIntl } from "react-intl";

interface SignUpPremiseFieldsProps {
  onAddressChange(): void;
  onFocus?: (label: string) => void;
}

export const SignUpPremiseFields: FC<SignUpPremiseFieldsProps> = ({
  onAddressChange,
  onFocus = () => null,
}) => {
  const { formatMessage } = useIntl();

  const address = formatMessage({ id: "SignUpPremiseFields.address" });
  const unitNumber = formatMessage({ id: "SignUpPremiseFields.unitNumber" });
  const city = formatMessage({ id: "SignUpPremiseFields.city" });
  const zipCode = formatMessage({ id: "SignUpPremiseFields.zipCode" });

  /**
   * Explaining this as RFF is hard to understand and it is
   * not intuitive to understand this solution...
   *
   * Problem: We want to know when an onBlur happens, so we
   * can rerender the map (which is in the parent component SignUpPageLayout)
   *
   * Solution: Active changes from undefined to a string (field name)
   * or vice versa when you either focus or blur. So this solution actually does
   * onBlur and onFocus, which I'm fine with it
   */
  const { active } = useFormState<SignUpPremiseFormValues>({
    subscription: { active: true },
  });

  useEffect(() => {
    onAddressChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const handleFocus: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => onFocus(event.target.id);
  const fieldInputProps = {
    onFocus: handleFocus,
  };

  return (
    <SignUpFieldsLayoutContainer>
      <Grid item>
        <RhFlexBox>
          <Box flexBasis="75%" paddingRight="15px">
            <RhTextField
              name="serviceAddress.addressLine1"
              autoComplete="address-line1"
              autoFocus
              InputProps={fieldInputProps}
            >
              {address}
            </RhTextField>
          </Box>
          <Box flex={1}>
            <RhTextField
              name="serviceAddress.unitNumber"
              autoComplete="address-line2"
              InputProps={fieldInputProps}
            >
              {unitNumber}
            </RhTextField>
          </Box>
        </RhFlexBox>
      </Grid>
      <Grid item>
        <RhTextField
          name="serviceAddress.city"
          autoComplete="address-level2"
          InputProps={fieldInputProps}
        >
          {city}
        </RhTextField>
      </Grid>
      <Grid item>
        <RhFlexBox alignItems="flex-end">
          <Box flexBasis="75%" paddingRight="15px">
            <StateSelectField
              name="serviceAddress.state"
              translationId="SignUpPremiseFields.state"
              selectProps={{ onFocus: () => onFocus("state") }}
            />
          </Box>
          <Box flex={1}>
            <RhTextField
              name="serviceAddress.zipCode"
              autoComplete="postal-code"
              inputMode="numeric"
              InputProps={fieldInputProps}
            >
              {zipCode}
            </RhTextField>
          </Box>
        </RhFlexBox>
      </Grid>
    </SignUpFieldsLayoutContainer>
  );
};
