import { KILOWATT_HOURS_ABBREV } from "@common/constants/other.constant";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { useMonthlyUsageInputStyles } from "@portal/components/MonthlyUsageInput/MonthlyUsageInput.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import IMask from "imask";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";

interface MonthlyUsageInputProps {
  onChange: (value: number) => void;
  initialValue: number;
}

type InputMaskType = IMask.InputMask<{
  mask: string;
  lazy: false;
  placeholderChar: string;
  blocks: {
    UP_TO_FOUR_DIGITS: {
      mask: string;
    };
  };
}>;

export const MonthlyUsageInput: FC<MonthlyUsageInputProps> = ({
  onChange,
  initialValue,
}) => {
  const { t } = useRhIntl();
  const classes = useMonthlyUsageInputStyles();
  const input = useRef<HTMLElement | null>(null);

  const [value, setValue] = useState<string>(
    initialValue ? initialValue.toString() : ""
  );
  const [mask, setMask] = useState<InputMaskType>();

  useEffect(() => {
    if (input.current) {
      const monthlyUsageMask = IMask(
        input.current.querySelector("input") as HTMLElement,
        {
          mask: `UP_TO_FOUR_DIGITS ${KILOWATT_HOURS_ABBREV}`,
          lazy: false,
          placeholderChar: " ",

          blocks: {
            UP_TO_FOUR_DIGITS: {
              mask: "[0000]",
            },
          },
        }
      );

      monthlyUsageMask.value = value;
      setMask(monthlyUsageMask);
      setValue(monthlyUsageMask.value);
    }
  }, [value]);

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev?.currentTarget?.value ?? "";

    if (mask) {
      mask.value = inputValue;
      mask.updateValue();
      setValue(mask.value);
      onChange(parseInt(mask.unmaskedValue, 10));
    }
  };

  const placeholder = t("MonthlyUsageInput.placeholder");
  const label = t("MonthlyUsageInput.howMuchDoYouUse");

  return (
    <RhTextInput
      className={classes.root}
      color="primary"
      name="monthlyUsage"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      inputMode="numeric"
      ref={input}
      autoFocus
    >
      {label}
    </RhTextInput>
  );
};
