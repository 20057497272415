import { LanguagePreferenceType } from "@common/types/customerTypes";
import { RhRadioCardInput } from "@design-system/components/RhRadioCardInput/RhRadioCardInput";
import React, { FC } from "react";
import { useField } from "react-final-form";
import { useIntl } from "react-intl";

export const LanguagePreferenceRadioField: FC<{
  translationId: string;
  value: LanguagePreferenceType;
}> = ({ translationId, value }) => {
  const { input } = useField<LanguagePreferenceType>("languagePreference", {
    type: "radio",
    value,
  });
  const label = useIntl().formatMessage({ id: translationId });
  const inputId = `translation-radio-${value}`;

  return <RhRadioCardInput inputId={inputId} label={label} {...input} />;
};
