import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { forgotPasswordPath } from "@portal/routes/routePaths";
import React, { FC } from "react";

export interface ExpiredPasswordTokenFormValues {
  id: string;
}

export const ExpiredPasswordTokenPage: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { t } = useRhIntl();
  const linkButtonText = t("expiredPasswordTokenPage.resetCTA");

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader headerTextId="expiredPasswordTokenPage.expired" />
      <Box width={LOGGED_OUT_FORM_WIDTH}>
        <Box clone my={LOGGED_OUT_CONTAINER_SPACING}>
          <RhLinkButton
            color="primary"
            variant="contained"
            size={isMobile ? "medium" : "large"}
            fullWidth
            href={forgotPasswordPath()}
          >
            {linkButtonText}
          </RhLinkButton>
        </Box>
      </Box>
    </LoggedOutPageLayout>
  );
};
