import { Link as MuiLink, LinkProps as MuiLinkProps } from "@material-ui/core";
import React, { FC } from "react";
import { LinkProps, Link as ReactRouterLink } from "react-router-dom";

export interface DynamicLinkProps extends MuiLinkProps {
  to: LinkProps["to"];
  external: boolean;
  target?: string;
}

export const DynamicLink: FC<DynamicLinkProps> = ({
  to,
  external,
  children,
  target = "_blank",
  ...rest
}) =>
  external && typeof to === "string" ? (
    <MuiLink href={to} target={target} rel="noopener noreferrer" {...rest}>
      {children}
    </MuiLink>
  ) : (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <MuiLink component={ReactRouterLink} to={to as any} {...rest}>
      {children}
    </MuiLink>
  );
