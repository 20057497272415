import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import React, { FC } from "react";
import { useField } from "react-final-form";
import { useIntl } from "react-intl";

export enum RunCreditCheckType {
  RunCheck = "runCheck",
  SkipCheck = "skipCheck",
}

interface RunCreditCheckRadioGroupProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const RunCreditCheckRadioGroup: FC<RunCreditCheckRadioGroupProps> = ({
  onClick,
}) => {
  const { formatMessage } = useIntl();
  const { input: runCreditCheck } = useField<RunCreditCheckType>(
    "runCreditCheckType",
    {
      type: "radio",
      value: RunCreditCheckType.RunCheck,
    }
  );
  const { input: dontRunCreditCheck } = useField<RunCreditCheckType>(
    "runCreditCheckType",
    {
      type: "radio",
      value: RunCreditCheckType.SkipCheck,
    }
  );

  return (
    <RadioGroup aria-label="run credit check">
      <FormControlLabel
        control={<RhRadioInput onClick={onClick} {...runCreditCheck} />}
        label={formatMessage({ id: "SignUpSSNRequiredPage.runCreditCheck" })}
      />
      <FormControlLabel
        control={<RhRadioInput onClick={onClick} {...dontRunCreditCheck} />}
        label={formatMessage({ id: "SignUpSSNRequiredPage.skipCreditCheck" })}
      />
    </RadioGroup>
  );
};
