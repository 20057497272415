import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isValidUSAddress } from "@common/forms/validators";
import { AddressFormType } from "@common/types/customerTypes";
import {
  AREA_NOT_SERVICED,
  IRONHIDE_CUSTOMER,
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
  RhApiError,
} from "@common/types/errorTypes";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { IronhideCustomerMessage } from "@portal/components/IronhideCustomerMessage/IronhideCustomerMessage";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { SignUpMultipleUtilitiesFields } from "@portal/pages/SignUpMultipleUtilitiesPage/SignUpMultipleUtilitiesFields";
import { SignUpPremiseFormValues } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import { selectSignUpPremiseFormValues } from "@portal/selectors/signUpSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

export interface SignUpMultipleUtilitiesFormValues {
  serviceAddress: AddressFormType;
}

const signUpMultipleUtilitiesFormValidator = generateValidationErrorCollector<SignUpMultipleUtilitiesFormValues>(
  {
    serviceAddress: isValidUSAddress,
  }
);

const MULTIPLE_UTILITIES_ERRORS = new Set([
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
]);

export const SignUpMultipleUtilitiesPage: FC = () => {
  const { t } = useRhIntl();
  const { announceError } = useRhAnnouncement();
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const flash = useRhFlash();
  const { serviceAddress } = useSelector<
    PortalStoreState,
    SignUpPremiseFormValues
  >(selectSignUpPremiseFormValues);

  const onSubmit = ({
    serviceAddress: serviceAddressSubmission,
  }: SignUpMultipleUtilitiesFormValues) => {
    return api.edi
      .meterAvailability({
        streetLine: serviceAddressSubmission.addressLine1,
        secondary: serviceAddressSubmission.unitNumber,
        city: serviceAddressSubmission.city,
        state: serviceAddressSubmission.state,
        zipcode: serviceAddressSubmission.zipCode,
      })
      .then(({ esiId, dunsNumber }) => {
        signUpClickNextStepHandler({
          signUpData: {
            serviceAddress: serviceAddressSubmission,
            esiId,
            dunsNumber,
          },
          nextStep: "plans",
          track: true,
        });
      })
      .catch((error: RhApiError) => {
        if (error?.data.errorCode === AREA_NOT_SERVICED) {
          signUpClickNextStepHandler({ nextStep: "updates", track: true });
        } else if (
          error?.data.errorCode &&
          MULTIPLE_UTILITIES_ERRORS.has(error?.data.errorCode)
        ) {
          signUpClickNextStepHandler({
            signUpData: { serviceAddress: serviceAddressSubmission },
            nextStep: "choose-utility",
            track: true,
          });
        } else if (error?.data.errorCode === IRONHIDE_CUSTOMER) {
          announceError(<IronhideCustomerMessage />);
        } else {
          const errorMessage = t(
            "SignUpMultipleUtilitiesPage.unknownErrorApiMeterAvailability"
          );

          flash.error(errorMessage);

          return { addressLine1: [errorMessage] };
        }
      });
  };

  const submitCTA = t("SignUpPremiseFields.submitCTA");

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerTextId="SignUpMultipleUtilities.moreThanOneUtilityServesYourZip"
        subHeaderTextId="SignUpMultipleUtilities.pleaseEnterYourAddress"
      />
      <Form<SignUpMultipleUtilitiesFormValues>
        initialValues={{
          serviceAddress,
        }}
        onSubmit={onSubmit}
        validate={signUpMultipleUtilitiesFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
            <SignUpMultipleUtilitiesFields />
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
