import { RhRoundTabItem } from "@design-system/components/RhRoundTabItem/RhRoundTabItem";
import { useRhRoundTabsStyles } from "@design-system/components/RhRoundTabs/RhRoundTabs.style";
import { Tabs, TabsProps } from "@material-ui/core";
import React, { Children, FC, cloneElement, useState } from "react";

interface RhRoundTabsProps extends Omit<TabsProps, "value" | "classes"> {
  children: JSX.Element | JSX.Element[];
  align?: "left" | "center";
}

export const RhRoundTabs: FC<RhRoundTabsProps> = ({
  children,
  align = "center",
  ...restProps
}): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useRhRoundTabsStyles({ align });

  return (
    <Tabs classes={classes} value={tabIndex} {...restProps}>
      {Children.map(children, (child: JSX.Element, index: number) => {
        if (child.type?.name !== RhRoundTabItem.name) {
          throw new Error(
            "RhRoundTabs only accepts RhRoundTabItem as children"
          );
        }

        return cloneElement(child, {
          className: index === tabIndex ? "selected" : "",
          onClick: () => {
            child.props?.onClick?.();
            setTabIndex(index);
          },
        });
      })}
    </Tabs>
  );
};
