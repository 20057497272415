import { accountSummaryWatcher } from "@portal/sagas/accountSummary.saga";
import { billingDetailsWatcher } from "@portal/sagas/billingDetails.saga";
import { customerWatcher } from "@portal/sagas/customer.saga";
import {
  refetchProspectWatcher,
  upsertProspectWatcher,
} from "@portal/sagas/prospect.saga";
import { rewardsWatcher } from "@portal/sagas/rewards.saga";
import { all } from "redux-saga/effects";

// Register your sagas here
const sagas = [
  accountSummaryWatcher(),
  billingDetailsWatcher(),
  customerWatcher(),
  refetchProspectWatcher(),
  rewardsWatcher(),
  upsertProspectWatcher(),
];

export function* rootSaga() {
  yield all(sagas);
}
