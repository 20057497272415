import { borders } from "@design-system/constants/borders";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { darkPurple } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useMyAccountYourPlanCardStyles = rhMakeStyles<
  typeof rhythmTheme,
  | "infoColumn"
  | "listItemIcon"
  | "planInfo"
  | "title"
  | "legalDocTitle"
  | "legalDocDescription"
  | "legalDocIcon"
  | "legalDocIconContainer"
>((theme) =>
  createStyles({
    infoColumn: {
      flexBasis: "100%",
      [theme.breakpoints.up("md")]: {
        flexBasis: `calc(90% - ${theme.spacing(2)}px)`,
      },
    },
    legalDocDescription: {
      color: theme.palette.text.primary,
      marginTop: theme.spacing(0.5),
    },
    legalDocIcon: {
      color: darkPurple[500],
    },
    legalDocIconContainer: {
      alignItems: "flex-start",
      display: "flex",
      height: "24px",
      justifyContent: "center",
      marginRight: theme.spacing(1.5),
      width: "24px",
    },
    legalDocTitle: {
      marginBottom: theme.spacing(2),
      textTransform: "uppercase",
    },
    listItemIcon: {
      color: theme.palette.primary.main,
    },
    planInfo: {
      border: borders[100],
      marginRight: 0,
    },
    title: {
      marginBottom: theme.spacing(1),
    },
  })
);
