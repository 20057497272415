import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { purple } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

type SignUpSummaryInfoStyleClasses =
  | "billingPreferenceLabel"
  | "billingPreferenceValue"
  | "cell"
  | "labelContainer"
  | "promoCode"
  | "promoPoints"
  | "valueContainer";

export const useSignUpSummaryInfoStyles = makeStyles<
  typeof rhythmTheme,
  SignUpSummaryInfoStyleClasses
>((theme) => {
  const containerStyles = {
    [theme.breakpoints.only("xs")]: {
      flexBasis: "100%",
      width: "100%",
    },
    marginBottom: theme.spacing(2),
    marginInlineStart: 0,
    overflow: "hidden",
  };

  const labelPercentageWidth = 38;
  const valuePercentageWidth = 100 - labelPercentageWidth;

  return createStyles({
    billingPreferenceLabel: {
      flexBasis: 80,
    },
    billingPreferenceValue: {
      color: theme.palette.grey[500],
    },
    cell: {
      wordBreak: "break-word",
    },
    labelContainer: {
      ...containerStyles,
      flexBasis: `${labelPercentageWidth}%`,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 4,
      },
    },
    promoCode: {
      color: theme.palette.grey[300],
      marginBottom: 1,
    },
    promoPoints: {
      color: purple[500],
    },
    valueContainer: {
      ...containerStyles,
      flexBasis: `${valuePercentageWidth}%`,
    },
  });
});
