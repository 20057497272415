import { api } from "@common/api/api";
import { BASE_URL_REGEX } from "@common/constants/regex.constant";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { CUSTOMER_NOT_FOUND_ERROR } from "@common/types/errorTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useOktaAuth } from "@okta/okta-react";
import { useLoggedOutFormStyles } from "@portal/components/LoggedOutForm/LoggedOutForm.style";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { ForgotPasswordFields } from "@portal/pages/ForgotPasswordPage/ForgotPasswordFields";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";

interface ForgotPasswordFormValues {
  email: string;
}

export const forgotPasswordFormValidator = generateValidationErrorCollector<ForgotPasswordFormValues>(
  {
    email: [isRequired, isValidEmail],
  }
);

export const ForgotPasswordPage = () => {
  const { t } = useRhIntl();
  const { authService } = useOktaAuth();

  const uri = authService.getFromUri();
  const referrer = uri.replace(BASE_URL_REGEX, "");
  const classes = useLoggedOutFormStyles();
  const flash = useRhFlash();

  const sendResetEmail = async ({ email }: ForgotPasswordFormValues) => {
    const [error] = await handleAjaxCall(
      api.customers.password.sendResetEmail(email, referrer)
    );

    if (error) {
      if (error.data.errorCode === CUSTOMER_NOT_FOUND_ERROR) {
        // Do not surface any errors to indicate customer was not found.
        return;
      }

      const errorMessage = t("forgotPassword.errorSendingInfo");

      flash.error(errorMessage);

      return { [FORM_ERROR]: [errorMessage] };
    }
  };

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader headerTextId="forgotPassword.title" />
      <Form<ForgotPasswordFormValues>
        onSubmit={sendResetEmail}
        validate={forgotPasswordFormValidator}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <ForgotPasswordFields />
          </form>
        )}
      />
    </LoggedOutPageLayout>
  );
};
