import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { RightChevron } from "@design-system/icons";
import { Box, DialogContent } from "@material-ui/core";
import { useAddPaymentCardStyles } from "@portal/components/AddPaymentCard/AddPaymentCard.style";
import { ZuoraCardForm } from "@portal/components/ZuoraCardForm/ZuoraCardForm";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC, useState } from "react";

interface AddPaymentCardProps {
  onCardAdded(response: ZuoraAddPaymentResponseType): void;
}

export const AddPaymentCard: FC<AddPaymentCardProps> = (
  props: AddPaymentCardProps
) => {
  const { t } = useRhIntl();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const classes = useAddPaymentCardStyles();
  const flash = useRhFlash();

  return (
    <>
      <Box className={classes.root} onClick={() => setIsDialogOpen(true)}>
        <RhInnerCard>
          <div className={classes.content}>
            <RhTypography variant="subtitle2" fontWeight={FontWeight.Semibold}>
              {t("AddPaymentCard.addNewCard")}
            </RhTypography>
            <RightChevron />
          </div>
        </RhInnerCard>
      </Box>
      <RhDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        size="large"
      >
        <RhDialogTitle>
          <Box data-testid="AddPaymentCard__Dialog--Title">
            {t("AddPaymentCard.addNewCard")}
          </Box>
        </RhDialogTitle>
        <DialogContent>
          <ZuoraCardForm
            onSuccess={(response) => {
              props.onCardAdded(response);
              setIsDialogOpen(false);
              flash.success(t("AddPaymentCard.newCardAddedSuccessfully"));
            }}
            onFailure={(errorMessage) => {
              setIsDialogOpen(false);
              flash.error(errorMessage);
            }}
          />
        </DialogContent>
      </RhDialog>
    </>
  );
};
