import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import createStyles from "@material-ui/styles/createStyles";

export const useMonthlyUsageInputStyles = makeStyles<
  typeof rhythmTheme,
  "root"
>(() =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);
