import { unmaskCurrency } from "@common/forms/currency.mask";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import { AmountType } from "@portal/enums/AmountType.enum";
import { PayBillFormValues } from "@portal/pages/PayBillPage/PayBillPage";
import React, { FC } from "react";
import { useFormState } from "react-final-form";
import { useIntl } from "react-intl";

export const RemainingBillBalance: FC = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormState<PayBillFormValues>({
    subscription: { values: true },
  });

  if (values.amountType === AmountType.Total) {
    return null;
  }

  const unmaskedCustomAmount = Number(
    unmaskCurrency(values.maskedCustomAmount)
  );
  const amount = formatCurrency(values.totalAmount - unmaskedCustomAmount);

  return (
    <Box textAlign="end">
      <RhTypography variant="body1" color="textSecondary">
        {formatMessage(
          {
            id: "account.billing.RemainingBalance",
          },
          { amount }
        )}
      </RhTypography>
    </Box>
  );
};
