import { VOUCHERIFY_NOT_READY } from "@common/types/errorTypes";
import { formatCurrency, separateNumber } from "@common/utils/dataFormatters";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { Box, Link } from "@material-ui/core";
import { useCustomerRewardsSectionStyle } from "@portal/components/CustomerRewardsSection/CustomerRewardsSection.style";
import { ReactComponent as CoinMan } from "@portal/components/CustomerRewardsSection/portal-coin-man.svg";
import { RedeemRewards } from "@portal/components/RedeemRewards/RedeemRewards.component";
import { useGetCustomerRewards } from "@portal/hooks/rewardsHooks";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { CustomerHomeSectionErrorMessage } from "@portal/pages/CustomerHomePage/CustomerHomeSectionErrorMessage/CustomerHomeSectionErrorMessage";
import { REWARDS_FAQ_URL, rewardsPath } from "@portal/routes/routePaths";
import React, { FC } from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

interface RewardsTextType {
  methodTextId: string;
  valueTextId: string;
}

const rewardsTextIds: RewardsTextType[] = [
  {
    methodTextId: "CustomerRewardsSection.autoPay",
    valueTextId: "CustomerRewardsSection.autoPayPoints",
  },
  {
    methodTextId: "CustomerRewardsSection.eBill",
    valueTextId: "CustomerRewardsSection.eBillPoints",
  },
  {
    methodTextId: "CustomerRewardsSection.friend",
    valueTextId: "CustomerRewardsSection.friendPoints",
  },
];

export const CustomerRewardsSection: FC = () => {
  const isRewardsPage = useRouteMatch(rewardsPath());
  const { t } = useRhIntl();
  const classes = useCustomerRewardsSectionStyle();
  const { rewards, requestMonitor, error } = useGetCustomerRewards();

  const title = t("CustomerRewardsSection.rewards");

  if (requestMonitor.isWaiting) {
    return (
      <RhCard title={title}>
        <RhCircularProgress />
      </RhCard>
    );
  }

  const learnMoreText = t("CustomerRewardsSection.learnMore");
  const explainerText = t("CustomerRewardsSection.explainer");
  const moreDetailsText = t("CustomerRewardsSection.moreDetails", {
    linkChunk: (content) => (
      <Link href={REWARDS_FAQ_URL} target="_blank" rel="noopener noreferrer">
        {content}
      </Link>
    ),
  });
  const illustrationAltText = t("CustomerRewardsSection.manWavingWithCoin");

  return (
    <RhCard title={title}>
      <div className={classes.rewardsContainer}>
        <RhInnerCard>
          {error || !rewards || rewards.balance === undefined ? (
            <CustomerHomeSectionErrorMessage>
              {error?.data.errorCode === VOUCHERIFY_NOT_READY
                ? t("CustomerRewardsSection.voucherifyNotReady")
                : t("CustomerRewardsSection.voucherifyError")}
            </CustomerHomeSectionErrorMessage>
          ) : (
            <RhFlexBox
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box marginBottom={3}>
                <RhFlexBox>
                  <div className={classes.textContainer}>
                    <RhTypography variant="h1" component="span">
                      {separateNumber(rewards.balance ?? 0)}
                    </RhTypography>
                    <RhTypography variant="body1" component="span">
                      {" "}
                      {t("CustomerRewardsSection.points")}
                    </RhTypography>

                    <Box mb={1}>
                      <RhTypography variant="body1">
                        {t("CustomerRewardsSection.value", {
                          value: formatCurrency(rewards.value ?? 0),
                        })}
                      </RhTypography>
                    </Box>
                    <RhTypography variant="body2" color="textSecondary">
                      {t("CustomerRewardsSection.lifetime", {
                        lifetime: separateNumber(rewards.lifetime),
                      })}
                    </RhTypography>
                  </div>
                  <div className={classes.illustrationContainer}>
                    <CoinMan title={illustrationAltText} />
                  </div>
                </RhFlexBox>
              </Box>
              <RedeemRewards />
            </RhFlexBox>
          )}
        </RhInnerCard>
        <RhInnerCard noBorder>
          <Box>
            <RhTypography
              variant="body1"
              className={classes.unlockTextContainer}
            >
              {explainerText}
            </RhTypography>
          </Box>
          <ul className={classes.listPoints}>
            {rewardsTextIds.map(({ methodTextId, valueTextId }) => (
              <li key={methodTextId}>
                <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
                  {t(methodTextId)}
                </RhTypography>
                <RhTypography variant="body2" color="textSecondary">
                  {t(valueTextId)}
                </RhTypography>
              </li>
            ))}
          </ul>
          {isRewardsPage ? (
            <RhTypography variant="body1">{moreDetailsText}</RhTypography>
          ) : (
            <Link component={RouterLink} to={rewardsPath()} variant="body1">
              {learnMoreText}
            </Link>
          )}
        </RhInnerCard>
      </div>
    </RhCard>
  );
};
