import { useRhIconButtonStyles } from "@design-system/components/RhIconButton/RhIconButton.style";
import { IconButton, IconButtonProps } from "@material-ui/core";
import React, { FC } from "react";

export type RhIconButtonProps = Omit<IconButtonProps, "classes">;

export const RhIconButton: FC<RhIconButtonProps> = (props) => {
  const classes = useRhIconButtonStyles();

  return <IconButton classes={classes} {...props} />;
};
