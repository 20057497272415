import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, Link } from "@material-ui/core";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { useCustomer } from "@portal/hooks/useCustomer";
import { ReactComponent as PayBillSuccessCheck } from "@portal/pages/PayBillSuccessPage/images/payBillSuccessCheck.svg";
import { homePath } from "@portal/routes/routePaths";
import { selectAccountSummary } from "@portal/selectors/accountSummarySelectors";
import { accountSummaryFetch } from "@portal/slices/accountSummarySlice";
import React, { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

export const PayBillSuccessPage: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { customer } = useCustomer();

  const { accountSummary, status, error } = useSelector(selectAccountSummary);

  useEffect(() => {
    dispatch(accountSummaryFetch());
  }, [customer?.id, dispatch]);

  if (status.isPending) {
    return <RhCircularProgress />;
  }
  if (error) {
    return null;
  }

  const goHomeCTA = formatMessage({ id: "PayBillSuccessPage.goHomeCTA" });
  const thankYou = formatMessage({ id: "PayBillSuccessPage.thankYou" });
  const balance = formatCurrency(
    (accountSummary && accountSummary.totalBalance) || 0
  );
  const yourRemainingBalance = formatMessage(
    {
      id: "PayBillSuccessPage.yourRemainingBalance",
    },
    { balance }
  );
  const payBillSuccessImageTitle = formatMessage({
    id: "PayBillSuccessPage.payBillSuccessImageTitle",
  });

  return (
    <PortalPageLayout>
      <RhFlexBox justifyContent="center">
        <Box clone margin="auto">
          <RhFlexBox
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box width={140} marginBottom={2}>
              <PayBillSuccessCheck title={payBillSuccessImageTitle} />
            </Box>
            <Box marginBottom={2}>
              <RhTypography variant="h2">{thankYou}</RhTypography>
            </Box>
            <Box marginBottom={5}>
              <RhTypography variant="subtitle2">
                {yourRemainingBalance}
              </RhTypography>
            </Box>
            <Box clone width="100%">
              <Link
                component={RouterLink}
                to={homePath()}
                underline="none"
                title={goHomeCTA}
              >
                <RhButton color="primary" fullWidth>
                  {goHomeCTA}
                </RhButton>
              </Link>
            </Box>
          </RhFlexBox>
        </Box>
      </RhFlexBox>
    </PortalPageLayout>
  );
};
