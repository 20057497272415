import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useLoaderStyles = makeStyles<
  typeof rhythmTheme,
  "loaderContainer"
>((theme) =>
  createStyles({
    loaderContainer: {
      background: theme.palette.common.white,
      height: "100vh",
      paddingTop: "20vh",
      position: "absolute",
      width: "100vw",
      zIndex: 1,
    },
  })
);
