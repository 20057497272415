import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";
import { LOGGED_OUT_FIELD_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";

const zuoraFormHeight = 454;
const zuoraFormWidth = 338;

export const useSignUpPaymentPageStyles = makeStyles<
  typeof rhythmTheme,
  "skipPaymentContainer" | "zuoraCardFormContainer"
>((theme) =>
  createStyles({
    skipPaymentContainer: {
      marginTop: theme.spacing(LOGGED_OUT_FIELD_SPACING),
      textAlign: "center",
    },
    zuoraCardFormContainer: {
      "& iframe": {
        height: zuoraFormHeight,
        width: zuoraFormWidth,
      },
      height: zuoraFormHeight,

      width: zuoraFormWidth,
    },
  })
);
