import { AccountSummary } from "@common/models/AccountSummary.model";
import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { RhApiError } from "@common/types/errorTypes";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";

interface AccountSummarySelectorType {
  accountSummary: AccountSummary | null;
  status: RequestMonitor;
  error: RhApiError | null;
}
export const selectAccountSummary = ({
  accountSummary,
}: PortalStoreState): AccountSummarySelectorType => {
  const { data, requestStatus, error } = accountSummary;

  return {
    accountSummary: data ? new AccountSummary(data) : null,
    status: new RequestMonitor(requestStatus),
    error,
  };
};
