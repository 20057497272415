import { selectAccountSummary } from "@portal/selectors/accountSummarySelectors";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import { accountSummaryFetch } from "@portal/slices/accountSummarySlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useAccountSummary = () => {
  const customerId = useSelector(selectCurrentCustomerId);
  const { accountSummary, status, error } = useSelector(selectAccountSummary);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerId) {
      dispatch(accountSummaryFetch());
    }
  }, [customerId, dispatch]);

  return {
    accountSummary,
    requestMonitor: status,
    error,
  };
};
