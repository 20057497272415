import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { darkPurple } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhRoundTabsStyles = makeStyles<
  typeof rhythmTheme,
  { align: "left" | "center" },
  "root" | "flexContainer" | "scroller" | "indicator"
>(({ palette, breakpoints }) => {
  return createStyles({
    flexContainer: {
      display: "flex",
      justifyContent: ({ align }) =>
        align === "left" ? "flex-start" : "space-between",
      position: "relative",
      zIndex: 1,
    },
    indicator: {
      "&:after": {
        backgroundColor: darkPurple[50],
        borderRadius: 18,
        bottom: 1,
        content: '""',
        display: "block",
        height: 28,
        left: 2,
        position: "absolute",
        right: 2,
        top: -1,
      },
      background: "none",
      bottom: 3,
      height: "auto",
      right: 3,
      top: 3,
    },
    root: {
      [breakpoints.down("sm")]: {
        width: "100%",
      },
      height: 28,
    },
    scroller: {
      [breakpoints.up("md")]: {
        padding: "0 8px",
      },
    },
  });
});
