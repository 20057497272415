import { GENERAL_ERROR } from "@common/types/errorTypes";
import { getCookieByKey } from "@common/utils/getCookies";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  CREATE_PASSWORD,
  CSR_SUCCESS,
  SignUpStepType,
  createPasswordPath,
  signUpBasePath,
  signUpCSRSuccessPath,
  signUpStepPath,
} from "@portal/routes/routePaths";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import {
  ActionType,
  EventType,
  TrackEventHandler,
  createTrackEvent,
} from "@portal/services/segment.service";
import {
  SignUpStateType,
  completedSignUp,
  setSignUpInfo,
} from "@portal/slices/signUpSlice";
import { isOpsOrCSRs } from "@portal/utils/signUpHelpers";
import { camelize } from "humps";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useAuthenticatedUserGroups } from "./useAuthenticatedUserGroups";

interface SignUpClickNextStepHandlerParams {
  signUpData?: Partial<SignUpStateType>;
  nextStep?: SignUpStepType | typeof CSR_SUCCESS | typeof CREATE_PASSWORD;
  track: boolean;
}

export type SignUpClickNextStepHandler = (
  params: SignUpClickNextStepHandlerParams
) => void;

export interface SignUpFlow {
  signUpClickNextStepHandler: SignUpClickNextStepHandler;
  trackEvent: TrackEventHandler;
  currentStep: SignUpStepType;
  visitedSteps: SignUpStepType[];
}

export function useSignUpFlow(): SignUpFlow {
  const { formatMessage } = useIntl();
  const { data: groups } = useAuthenticatedUserGroups();
  const dispatch = useDispatch();
  const flash = useRhFlash();
  const history = useHistory();
  const { visitedSteps } = useSelector(selectSignUpState);
  const { pathname } = history.location;
  const currentStep =
    pathname === signUpBasePath()
      ? "availability"
      : (pathname.split("/").slice(-1)[0] as SignUpStepType);

  const trackingCategory = `onboarding.${camelize(currentStep)}`;
  const trackEvent = createTrackEvent({
    event: EventType.enrollmentClick,
    category: trackingCategory,
  });
  const trackNextPage = () =>
    trackEvent({
      action: ActionType.clickedNextPage,
      label: `${currentStep}NextPage`,
    });

  const signUpClickNextStepHandler: SignUpClickNextStepHandler = ({
    signUpData = {},
    nextStep,
    track,
  }) => {
    if (track) {
      trackNextPage();
    }
    const newVisitedSteps = nextStep &&
      nextStep !== CREATE_PASSWORD &&
      nextStep !== CSR_SUCCESS &&
      !visitedSteps.includes(nextStep) && [...visitedSteps, nextStep];
    const googleClientId = getCookieByKey("_ga");
    const segmentAnonId = getCookieByKey("ajs_anonymous_id");

    const payload: Partial<SignUpStateType> = {
      ...signUpData,
      visitedSteps: newVisitedSteps || visitedSteps,
      googleClientId,
      segmentAnonId,
    };

    if (signUpData.id) {
      // customer has been persisted
      dispatch(completedSignUp(payload));
    } else {
      dispatch(setSignUpInfo(payload));
    }
    let nextPath: string | null = null;

    if (nextStep === CSR_SUCCESS) {
      nextPath = signUpCSRSuccessPath();
    } else if (nextStep === CREATE_PASSWORD) {
      nextPath = createPasswordPath();
    } else if (nextStep) {
      nextPath = signUpStepPath(nextStep);
      if (isOpsOrCSRs(groups)) {
        nextPath = `/csr${nextPath}`;
      }
    }

    if (nextStep === CREATE_PASSWORD && !signUpData.email) {
      flash.error(
        formatMessage({
          id: GENERAL_ERROR,
        })
      );
    } else if (nextPath) {
      history.push(nextPath);
    }
  };

  return {
    signUpClickNextStepHandler,
    trackEvent,
    currentStep,
    visitedSteps,
  };
}
