import { EN_DASH } from "@common/constants/characters.constant";
import {
  DOB_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
  SHORT_DATE_FORMAT,
} from "@common/constants/date.constant";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { MaskedDate } from "imask";

// plugin to allow strict date parsing
dayjs.extend(customParseFormat);

const dateOfBirthMask = new MaskedDate({
  mask: Date,
  min: new Date(1900, 0, 1),
  max: new Date(),
  pattern: "m/d/Y0",
});

export const formatDateOfBirth = (value: string) => {
  dateOfBirthMask.value = value ?? "";
  return dateOfBirthMask.value.replace(/\./g, "/");
};

export const unformatDateOfBirth = (value: string) => {
  dateOfBirthMask.value = value ?? "";
  const parsedDate = dayjs(dateOfBirthMask.value, DOB_DATE_FORMAT, true);

  return parsedDate.isValid() ? parsedDate.format(ISO8601_DATE_FORMAT) : "";
};

export const formatDateOfBirthFromDatabase = (value: string) => {
  const parsedDate = dayjs(value, ISO8601_DATE_FORMAT, true);

  return parsedDate.isValid() ? parsedDate.format(DOB_DATE_FORMAT) : "";
};

export const formatShortDate = (value: string): string => {
  const parsedDate = dayjs(value);

  return parsedDate.isValid() ? parsedDate.format(SHORT_DATE_FORMAT) : "";
};

export const formatDateRange = (startDate: string, endDate: string): string => {
  return `${startDate}${EN_DASH}${endDate}`;
};
