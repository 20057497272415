import { MOVE_IN } from "@common/types/customerTypes";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import { useCustomer } from "@portal/hooks/useCustomer";
import { useCustomerHomeNewSignupSectionStyle } from "@portal/pages/CustomerHomePage/CustomerHomeNewSignupSection/CustomerHomeNewSignupSection.style";
import { ReactComponent as NewMoverWelcomeImage } from "@portal/pages/CustomerHomePage/images/newMoverWelcomeImage.svg";
import { ReactComponent as NewSwitcherWelcomeImage } from "@portal/pages/CustomerHomePage/images/newSwitcherWelcomeImage.svg";
import { ReactComponent as StandardWelcomeImage } from "@portal/pages/CustomerHomePage/images/standardWelcomeImage.svg";
import dayjs from "dayjs";
import React, { FC } from "react";
import { useIntl } from "react-intl";

export const CustomerHomeNewSignupSection: FC = () => {
  const { formatMessage } = useIntl();
  const { customer, requestMonitor } = useCustomer();
  const classes = useCustomerHomeNewSignupSectionStyle();

  if (requestMonitor.didFail || !customer) {
    return <RhCard noShadow />;
  }

  const isNewSignUp = [
    "ENROLLMENT_REQUESTED",
    "ENROLLMENT_ACCEPTED",
    "PRE_ENROLL",
  ].includes(customer.enrollmentStatus);
  const isMoveIn = customer.enrollmentType === MOVE_IN;
  const welcomeImages: Record<string, () => JSX.Element> = {
    standard: () => (
      <StandardWelcomeImage
        title={formatMessage({
          id: "CustomerHomePage.standardWelcomeImageAltText",
        })}
      />
    ),
    newMover: () => (
      <NewMoverWelcomeImage
        title={formatMessage({
          id: "CustomerHomePage.newMoverWelcomeImageAltText",
        })}
      />
    ),
    newSwitcher: () => (
      <NewSwitcherWelcomeImage
        title={formatMessage({
          id: "CustomerHomePage.newSwitcherWelcomeImageAltText",
        })}
      />
    ),
  };

  const rhythmGivesYouPower = formatMessage({
    id: "CustomerHomePage.givingYouThePower",
  });
  let welcomeGreeting: string = formatMessage(
    { id: "CustomerHomePage.welcomeBack" },
    { name: customer.firstName }
  );
  let welcomeImage: keyof typeof welcomeImages = "standard";

  if (isNewSignUp && isMoveIn) {
    welcomeGreeting = formatMessage(
      { id: "CustomerHomePage.congratsOnTheMove" },
      { name: customer.firstName }
    );
    welcomeImage = "newMover";
  } else if (isNewSignUp) {
    welcomeGreeting = formatMessage(
      { id: "CustomerHomePage.welcome" },
      { name: customer.firstName }
    );
    welcomeImage = "newSwitcher";
  }

  const serviceStartDate = dayjs(customer.serviceStartDate);
  const today = dayjs().format("YYYY-MM-DD");
  const daysUntilServiceStarts = serviceStartDate.diff(today, "day");

  const constructCountdownMessage = () => {
    const startingDate = dayjs(customer.serviceStartDate).format(
      "dddd, MMMM D"
    );
    const startingDateToday = dayjs(customer.serviceStartDate).isToday();
    const startingDateTomorrow = dayjs(customer.serviceStartDate).isTomorrow();

    let countdownMessage;

    if (startingDateToday) {
      countdownMessage = formatMessage(
        {
          id: `${
            isMoveIn
              ? "CustomerHomePage.sendingPowerYourWayToday"
              : "CustomerHomePage.makingTheSwitchToday"
          }`,
        },
        { bold: (str) => <strong>{str}</strong> }
      );
    } else if (startingDateTomorrow) {
      countdownMessage = formatMessage(
        {
          id: `${
            isMoveIn
              ? "CustomerHomePage.sendingPowerYourWayTomorrow"
              : "CustomerHomePage.makingTheSwitchTomorrow"
          }`,
        },
        { bold: (str) => <strong>{str}</strong> }
      );
    } else {
      countdownMessage = formatMessage(
        {
          id: `${
            isMoveIn
              ? "CustomerHomePage.sendingPowerYourWay"
              : "CustomerHomePage.makingTheSwitch"
          }`,
        },
        {
          days: daysUntilServiceStarts,
          starting: startingDate,
          bold: (str) => <strong>{str}</strong>,
        }
      );
    }
    return countdownMessage;
  };

  return (
    <RhFlexBox className={classes.root}>
      <Box className={classes.welcomeImageContainer}>
        {welcomeImages[welcomeImage]()}
      </Box>
      <Box>
        <RhTypography variant="h1">{welcomeGreeting}</RhTypography>
        <Box className={classes.subheader}>
          <RhTypography variant="subtitle2">
            {isNewSignUp && daysUntilServiceStarts >= 0
              ? constructCountdownMessage()
              : rhythmGivesYouPower}
          </RhTypography>
        </Box>
      </Box>
    </RhFlexBox>
  );
};
