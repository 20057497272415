import { useRhIntl } from "@portal/hooks/useRhIntl";
import { ChangingLightBulb } from "@portal/images";
import { BaseErrorPage } from "@portal/pages/BaseErrorPage/BaseErrorPage";
import React, { FC } from "react";

export const Error500Page: FC = () => {
  const { t } = useRhIntl();

  const header = t("Error500Page.oops");
  const message = t("Error500Page.technicalDifficulties");

  return (
    <BaseErrorPage
      header={header}
      message={message}
      illustration={<ChangingLightBulb title={header} />}
      showLinkToHome={false}
    />
  );
};
