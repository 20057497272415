import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidZipCode } from "@common/forms/validators";
import { AddressFormType } from "@common/types/customerTypes";
import {
  AREA_NOT_SERVICED,
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
  RhApiError,
} from "@common/types/errorTypes";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectSignUpAvailabilityFormValues } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { FORM_ERROR } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

export const availabilityErrorPaths: Record<
  string,
  "updates" | "availability-address" | "plans"
> = {
  [AREA_NOT_SERVICED]: "updates",
  [PRICING_OFFERS_MULTIPLE_UTILITIES]: "availability-address",
  [MULTIPLE_METERS]: "plans",
};

const signUpAvailabilityFormValidator = generateValidationErrorCollector<SignUpAvailabilityFormValues>(
  {
    zipCode: [isRequired, isValidZipCode],
  }
);

export interface SignUpAvailabilityFormValues {
  zipCode: AddressFormType["zipCode"];
}

export const SignUpAvailabilityPage: FC = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const flash = useRhFlash();
  const { t } = useRhIntl();

  const trackZipCodeFocus = () =>
    trackEvent({
      action: ActionType.focus,
      label: "zipCodeEntry",
    });

  const submitCTA = t("SignUpAvailabilityPage.submitCTA");
  const zipCodeLabel = t("SignUpAvailabilityPage.zipCode");
  const zipCodePlaceholder = t("SignUpAvailabilityPage.zipCodePlaceholder");

  const currentValues = useSelector<
    PortalStoreState,
    SignUpAvailabilityFormValues
  >(selectSignUpAvailabilityFormValues);

  const onSubmit = ({ zipCode }: SignUpAvailabilityFormValues) => {
    const serviceAddress = {
      addressLine1: "",
      unitNumber: "",
      city: "",
      state: "",
      zipCode: zipCode ?? "",
    };

    return api.pricing.offers
      .availability({ zipCode })
      .then(({ dunsNumber }) => {
        signUpClickNextStepHandler({
          signUpData: { serviceAddress, dunsNumber, areaNotCovered: false },
          nextStep: "plans",
          track: true,
        });
      })
      .catch((error: RhApiError) => {
        const errorCode = error?.data.errorCode;
        const errorMessage = t(
          "SignUpAvailabilityPage.error.findingAvailability"
        );

        if (errorCode && availabilityErrorPaths[errorCode]) {
          signUpClickNextStepHandler({
            signUpData: {
              serviceAddress,
              areaNotCovered: errorCode === AREA_NOT_SERVICED,
            },
            nextStep: availabilityErrorPaths[errorCode],
            track: true,
          });
        } else if (error) {
          flash.error(errorMessage);
        }

        return { [FORM_ERROR]: [errorMessage] };
      });
  };

  return (
    <SignUpPageLayout hideBackButton>
      <LoggedOutPageHeader headerTextId="SignUpAvailabilityPage.findOut" />
      <Form<SignUpAvailabilityFormValues>
        initialValues={currentValues}
        onSubmit={onSubmit}
        validate={signUpAvailabilityFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
            <LoggedOutFieldsLayout>
              <RhTextField
                name="zipCode"
                autoComplete="postal-code"
                inputMode="numeric"
                placeholder={zipCodePlaceholder}
                type="number"
                InputProps={{ onFocus: trackZipCodeFocus }}
              >
                {zipCodeLabel}
              </RhTextField>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
