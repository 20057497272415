import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { NotificationMessagesResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { ChannelSubscriptionStatus } from "@common/types/customerTypes";
import { noop } from "@common/utils/genericFunctions";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import {
  IterableOptionType,
  NotificationOptionRow,
} from "@portal/components/NotificationOptionSwitches/NotificationOptionRow";
import { useNotificationOptionSwitchesStyles } from "@portal/components/NotificationOptionSwitches/NotificationOptionSwitches.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC, useEffect } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

export const NotificationOptionSwitches: FC = () => {
  const classes = useNotificationOptionSwitchesStyles();
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const currentCustomerId =
    useSelector<PortalStoreState, IdType | null | undefined>(
      selectCurrentCustomerId
    ) || "";
  const [
    { requestMonitor, data },
    { setFailure, setSuccess, setPending },
  ] = useAjaxState<
    [NotificationMessagesResponseType, ChannelSubscriptionStatus]
  >();

  useEffect(() => {
    setPending();
    const channels = api.customers.notificationMessages.list();
    const subscriptions = api.customers.unsubscribedChannelIds(
      currentCustomerId
    );

    Promise.all([channels, subscriptions])
      .then(setSuccess)
      .catch((err) => {
        flash.error(
          t("NotificationOptionSwitches.apiErrorGettingNotifications")
        );
        setFailure(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomerId]);

  if (requestMonitor.isWaiting) {
    return <RhCircularProgress />;
  }

  if (requestMonitor.didFail || !data) {
    return null;
  }

  const [notificationChannels, { unsubscribedChannelIds }] = data;

  // TODO: fetch notificationOptions from iterable when channel and message patterns are established
  // this is a hardcoded list for now with plans to fetch and construct it from Iterable in the future
  // If you change these ID's make sure to check the backend (`iterable_types.py`) and change there
  // at the same time.
  const notificationOptions: IterableOptionType[] = [
    {
      subscriptionGroup: "messageType",
      channelNameIntlId: "NotificationOptionSwitches.myBillIsHigher",
      messageId: notificationChannels?.emailBillSpike,
      commMedium: "email",
    },
    {
      subscriptionGroup: "messageType",
      channelNameIntlId: "NotificationOptionSwitches.myWeeklyEnergySummary",
      messageId: notificationChannels?.emailWeeklySummary,
      commMedium: "email",
    },
    {
      subscriptionGroup: "messageType",
      channelNameIntlId: "NotificationOptionSwitches.sendMarketingPromos",
      messageId: notificationChannels?.emailMarketingAndPromos,
      commMedium: "email",
    },
    {
      subscriptionGroup: "messageType",
      channelNameIntlId: "NotificationOptionSwitches.myBillIsAvailable",
      messageId: notificationChannels?.smsBillingAndPayments,
      commMedium: "sms",
    },
    {
      subscriptionGroup: "messageType",
      channelNameIntlId: "NotificationOptionSwitches.myBillIsHigher",
      messageId: notificationChannels?.smsBillSpike,
      commMedium: "sms",
    },
    {
      subscriptionGroup: "messageType",
      channelNameIntlId: "NotificationOptionSwitches.myWeeklyEnergySummary",
      messageId: notificationChannels?.smsWeeklySummary,
      commMedium: "sms",
    },
  ]; // NOTE: this order is intentional. check with product before changing this order

  const initialValues = notificationOptions.reduce((result, option) => {
    const { messageId } = option;

    // eslint-disable-next-line no-param-reassign
    result[`messageId${messageId}`] =
      unsubscribedChannelIds.indexOf(messageId) === -1;
    return result;
  }, {} as { [key: string]: boolean });

  return (
    <Form
      onSubmit={noop}
      initialValues={initialValues}
      render={() =>
        ["email", "sms"].map((commMedium) => (
          <Box key={commMedium} className={classes.commMediumContainer}>
            <Box className={classes.commMediumHeader}>
              <RhTypography variant="h3" fontWeight={FontWeight.Semibold}>
                {t(`NotificationOptionSwitches.${commMedium}`)}
              </RhTypography>
            </Box>
            {notificationOptions.map(
              (option) =>
                option.commMedium === commMedium && (
                  <NotificationOptionRow
                    key={option.messageId}
                    option={option}
                    customerId={currentCustomerId}
                  />
                )
            )}
          </Box>
        ))
      }
    />
  );
};
