import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";

type ZuoraSetEventHandler = (actionName: string, handler: () => void) => void;

/* eslint-disable @typescript-eslint/no-explicit-any */
type ZuoraRenderWithErrorHandler = (
  params: Record<string, any>,
  initFields: Record<string, any>,
  callback: (response: ZuoraAddPaymentResponseType) => Promise<any>,
  clientErrorMessageCallback: (
    errorType: string,
    errorCode: string,
    errorDescription: string
  ) => void
) => void;

export interface Zuora {
  setEventHandler: ZuoraSetEventHandler;
  renderWithErrorHandler: ZuoraRenderWithErrorHandler;
}

export const zuora = {
  setEventHandler(actionName: string, handler: () => void) {
    ((window as any).Z as Zuora).setEventHandler(actionName, handler);
  },
  renderWithErrorHandler(
    params: Record<string, any>,
    initFields: Record<string, any>,
    callback: (response: ZuoraAddPaymentResponseType) => Promise<any>,
    clientErrorMessageCallback: (
      errorType: string,
      errorCode: string,
      errorDescription: string
    ) => void
  ) {
    ((window as any).Z as Zuora).renderWithErrorHandler(
      params,
      initFields,
      callback,
      clientErrorMessageCallback
    );
    /* eslint-enable @typescript-eslint/no-explicit-any */
  },
};
