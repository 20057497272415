import { IdType } from "@common/types/apiTypes";
import { CampaignPromoType } from "@common/types/campaignTypes";
import { PremiseType, ValidInboundEDIType } from "@common/types/ediTypes";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import { OfferType } from "@common/types/offerTypes";
import { RewardsType } from "@common/types/rewardsTypes";

export enum HttpStatusCodes {
  HTTP_403_FORBIDDEN = 403,
  HTTP_500_INTERNAL_SERVER_ERROR = 500,
}

export interface AuthenticationResponseType {
  email: string;
  id: IdType;
  languagePreference: SupportedPortalLanguage | null;
}

export interface CreatePaymentResponseType {
  invoiceNumber: string;
}

export interface ZuoraAddPaymentResponseType {
  redirectUrl: string;
  refId: string;
  responseFrom: string;
  signature: string;
  success: boolean;
  token: string;
  errorCode?: string;
  errorMessage?: string;
}

export interface RSASignatureResponseType {
  key: string;
  signature: string;
  success: boolean;
  tenantId: string;
  token: string;
}

export interface RSASignatureErrorResponseType {
  processId: string;
  reasons: { code: number; message: string }[];
  success: boolean;
}

export interface ValidInboundEDIResponseType {
  valid: ValidInboundEDIType[];
}

export interface MeterAvailabilityResponseType {
  esiId: string;
  dunsNumber: string;
}

export interface OffersAvailabilityResponseType {
  dunsNumber: string;
}
export interface PlaidLinkTokenResponse {
  linkToken: string;
}

export interface OffersSearchResponseType {
  campaignPromo: CampaignPromoType | null;
  offers: OfferType[];
}

export interface TriggerEdiResponseType {
  success: boolean;
}
export type GetSetPasswordStateTokenResponseType = { stateToken: string };

export type SetPasswordResponseType = { sessionToken: string };

export type LoyaltyProgramRedemptionResponseType = { balance: number };

export interface NotificationChannelsResponseType {
  smsMarketingChannel: string;
  emailMarketingChannel: string;
}

export interface NotificationMessagesResponseType {
  emailBillSpike: string;
  emailWeeklySummary: string;
  emailMarketingAndPromos: string;
  smsBillSpike: string;
  smsWeeklySummary: string;
  smsBillingAndPayments: string;
}

export type PremiseUpdateResponseType = PremiseType;

export interface AveragedBillingMonthlyChargeResponseType {
  averagedBillingMonthlyCharge: number;
}

export type RewardsResponseType = RewardsType;

export type EnrollmentStatusesResponseType = {
  enrollmentStatuses: string[];
};

export type EnrollmentStatusUpdatedResponseType = {
  id: IdType;
  enrollmentStatus: string;
};

export interface AddACHResponseType {
  id: string;
  success: boolean;
}
