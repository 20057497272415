import { Customer } from "@common/models/Customer.model";
import * as FullStory from "@fullstory/browser";

// Fullstory adds _fs_namespace to window when loaded
declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    _fs_initialized?: boolean;
  }
}

// eslint-disable-next-line no-underscore-dangle
export const isLoaded = () => !!window._fs_initialized;

export const identify = (customer: Customer) => {
  if (isLoaded()) {
    FullStory.identify(customer.id, {
      displayName: customer.fullName,
      email: customer.email,
    });
  }
};

export const anonymize = () => {
  if (isLoaded()) {
    FullStory.anonymize();
  }
};
