import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useCustomerPaymentFieldStyles = rhMakeStyles<
  typeof rhythmTheme,
  "root"
>((theme) =>
  createStyles({
    root: {
      "& label + .MuiInput-formControl": {
        marginTop: 0,
      },
      flexBasis: "34%",
    },
  })
);
