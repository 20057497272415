import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PDFIcon } from "@design-system/icons";
import { Box } from "@material-ui/core";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@portal/components/LegalDocuments/LegalDocumentLinks";
import { useMyAccountYourPlanCardStyles } from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC } from "react";
import { useSelector } from "react-redux";

interface MyAccountLegalDocumentsProps {
  offerId: string;
}

export const MyAccountLegalDocuments: FC<MyAccountLegalDocumentsProps> = ({
  offerId,
}) => {
  const { t } = useRhIntl();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );
  const classes = useMyAccountYourPlanCardStyles();

  const yracTitle = t("MyAccountYourPlanCard.yrac");
  const tosTitle = t("MyAccountYourPlanCard.tos");
  const eflTitle = t("MyAccountYourPlanCard.efl");

  return (
    <Box width="90%">
      <div className={classes.legalDocTitle}>
        <RhTypography variant="caption">
          {t("MyAccountYourPlanCard.contractDocuments")}
        </RhTypography>
      </div>

      <RhFlexBox flexDirection="row" mb={2.5}>
        <div className={classes.legalDocIconContainer}>
          <EFLLink offerId={offerId} locale={locale}>
            <PDFIcon className={classes.legalDocIcon} title={eflTitle} />
          </EFLLink>
        </div>
        <div>
          <EFLLink offerId={offerId} locale={locale}>
            <RhTypography variant="body2">{eflTitle}</RhTypography>
          </EFLLink>
          <RhTypography
            variant="body2"
            color="textSecondary"
            className={classes.legalDocDescription}
          >
            {t("MyAccountYourPlanCard.eflDescription")}
          </RhTypography>
        </div>
      </RhFlexBox>

      <RhFlexBox flexDirection="row" mb={2.5}>
        <div className={classes.legalDocIconContainer}>
          <TOSLink locale={locale}>
            <PDFIcon className={classes.legalDocIcon} title={tosTitle} />
          </TOSLink>
        </div>
        <div>
          <TOSLink locale={locale}>
            <RhTypography variant="body2">{tosTitle}</RhTypography>
          </TOSLink>
          <RhTypography
            variant="body2"
            color="textSecondary"
            className={classes.legalDocDescription}
          >
            {t("MyAccountYourPlanCard.tosDescription")}
          </RhTypography>
        </div>
      </RhFlexBox>

      <RhFlexBox flexDirection="row">
        <div className={classes.legalDocIconContainer}>
          <YRACLink locale={locale}>
            <PDFIcon className={classes.legalDocIcon} title={yracTitle} />
          </YRACLink>
        </div>
        <Box mb={2}>
          <YRACLink locale={locale}>
            <RhTypography variant="body2">{yracTitle}</RhTypography>
          </YRACLink>
          <RhTypography
            variant="body2"
            color="textSecondary"
            className={classes.legalDocDescription}
          >
            {t("MyAccountYourPlanCard.yracDescription")}
          </RhTypography>
        </Box>
      </RhFlexBox>
    </Box>
  );
};
