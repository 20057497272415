import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Link } from "@material-ui/core";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useBaseErrorPageStyle } from "@portal/pages/BaseErrorPage/BaseErrorPage.style";
import { HOME } from "@portal/routes/routePaths";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

interface BaseErrorPageProps {
  code?: string;
  header: string;
  message: string;
  illustration?: JSX.Element;
  showLinkToHome: boolean;
}
export const BaseErrorPage: FC<BaseErrorPageProps> = ({
  code,
  header,
  message,
  illustration,
  showLinkToHome,
}) => {
  const withIllustration = Boolean(illustration);
  const classes = useBaseErrorPageStyle({
    withIllustration,
  });
  const { t } = useRhIntl();
  const returnHome = t("BaseErrorPage.returnHome");

  return (
    <div className={classes.root}>
      {withIllustration && (
        <div className={classes.illustrationContainer}>{illustration}</div>
      )}
      <div className={classes.bodyContainer}>
        {Boolean(code) && (
          <RhTypography
            className={classes.errorCode}
            color="inherit"
            variant="h1"
          >
            {code}
          </RhTypography>
        )}
        <RhTypography variant="h1" className={classes.errorTitle}>
          {header}
        </RhTypography>
        <RhTypography variant="subtitle1" className={classes.errorMessage}>
          {message}
        </RhTypography>
        {showLinkToHome && (
          <Link
            to={`/${HOME}`}
            component={RouterLink}
            color="primary"
            variant="subtitle2"
          >
            {returnHome}
          </Link>
        )}
      </div>
    </div>
  );
};
