import { AjaxStatus, AjaxStatuses } from "@common/types/apiTypes";
import { CustomerType } from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { CreditCardType } from "@common/types/paymentMethodTypes";
import { userLoggedOut } from "@portal/slices/authenticationSlice";
import { paymentMethodSetAsDefault } from "@portal/slices/billingDetailsSlice";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import merge from "lodash/merge";

export type CustomerStateType = {
  data: CustomerType | null;
  error: RhApiError | null;
  requestStatus: AjaxStatus;
};

export const initialCustomerState = Object.freeze<CustomerStateType>({
  data: null,
  error: null,
  requestStatus: AjaxStatuses.Idle,
});

// Handlers
export const customerUpdatedHandler: CaseReducer<
  CustomerStateType,
  PayloadAction<Partial<CustomerType>>
> = (state, action) => ({
  error: state.error,
  requestStatus: state.requestStatus,
  data: merge({}, state.data, action.payload),
});

export const customerRequestedHandler: CaseReducer<
  CustomerStateType,
  PayloadAction
> = (state) => ({ ...state, requestStatus: AjaxStatuses.Pending });

export const customerReceivedHandler: CaseReducer<
  CustomerStateType,
  PayloadAction<CustomerType>
> = (state, action) => ({
  requestStatus: AjaxStatuses.Success,
  data: action.payload,
  error: null,
});

export const customerErroredHandler: CaseReducer<
  CustomerStateType,
  PayloadAction<RhApiError>
> = (state, action) => ({
  requestStatus: AjaxStatuses.Failure,
  error: action.payload,
  data: state.data,
});

export const customerLoggedOutHandler: CaseReducer<
  CustomerStateType,
  PayloadAction
> = (_state) => ({ ...initialCustomerState });

export const customerBillingPaymentMethodIdUpdated: CaseReducer<
  CustomerStateType,
  PayloadAction<Pick<CreditCardType, "id">>
> = (state, { payload }) => ({
  ...state,
  data: merge({}, state.data, { billingPaymentMethodId: payload.id }),
});

const customerReducers = {
  updated: customerUpdatedHandler,
  requested: customerRequestedHandler,
  received: customerReceivedHandler,
  errored: customerErroredHandler,
};

const customerSlice = createSlice<CustomerStateType, typeof customerReducers>({
  name: "customer",
  initialState: initialCustomerState,
  reducers: customerReducers,
  extraReducers: {
    [userLoggedOut.type]: customerLoggedOutHandler,
    [paymentMethodSetAsDefault.type]: customerBillingPaymentMethodIdUpdated,
  },
});

export const customerReducer = customerSlice.reducer;
export const customerUpdated = customerSlice.actions.updated;
export const customerRequested = customerSlice.actions.requested;
export const customerErrored = customerSlice.actions.errored;
export const customerReceived = customerSlice.actions.received;

export const customerFetch = createAction(`${customerSlice.name}/fetch`);
export const customerRefetch = createAction(`${customerSlice.name}/refetch`);
