import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, Grid } from "@material-ui/core";
import { CustomerRewardsSection } from "@portal/components/CustomerRewardsSection/CustomerRewardsSection";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { ReferAFriendSection } from "@portal/components/ReferAFriendSection/ReferAFriendSection";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { RewardsHistoryTable } from "@portal/pages/RewardsPage/RewardsHistoryTable";
import React, { FC } from "react";
import { Helmet } from "react-helmet";

export const RewardsPage: FC = () => {
  const { t } = useRhIntl();

  const pageTitle = t("RewardsPage.pageTitle");
  const siteTitle = t("navigation.title.rhythm");
  const rewardsHeaderText = t("RewardsPage.rewards");
  const descriptionText = t("RewardsPage.description");

  return (
    <>
      <Helmet>
        <title>
          {pageTitle} - {siteTitle}
        </title>
      </Helmet>
      <PortalPageLayout>
        <RhFlexBox justifyContent="center">
          <Box clone margin="auto">
            <Grid container item xs={12} md={8}>
              <Box marginBottom={2.5}>
                <RhTypography
                  variant="h1"
                  data-testid="RewardsPage__rewards--title"
                >
                  {rewardsHeaderText}
                </RhTypography>
                <Box mt={1}>
                  <RhTypography variant="subtitle1" color="textSecondary">
                    {descriptionText}
                  </RhTypography>
                </Box>
              </Box>
              <CustomerRewardsSection />
              <RewardsHistoryTable />
              <ReferAFriendSection />
            </Grid>
          </Box>
        </RhFlexBox>
      </PortalPageLayout>
    </>
  );
};
