import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import { NotificationOptionSwitches } from "@portal/components/NotificationOptionSwitches/NotificationOptionSwitches";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

export const NotificationsPage: FC = () => {
  const { formatMessage } = useIntl();

  const pageHeader = formatMessage({ id: "NotificationsPage.notifications" });
  const siteTitle = formatMessage({ id: "navigation.title.rhythm" });

  return (
    <>
      <Helmet>
        <title>{`${pageHeader} - ${siteTitle}`}</title>
      </Helmet>
      <PortalPageLayout>
        <RhFlexBox justifyContent="center">
          <RhFlexBox flexDirection="column" width={525}>
            <Box
              component="header"
              marginBottom={3}
              style={{ textAlign: "center" }}
            >
              <RhTypography variant="h1">{pageHeader}</RhTypography>
            </Box>
            <NotificationOptionSwitches />
          </RhFlexBox>
        </RhFlexBox>
      </PortalPageLayout>
    </>
  );
};
