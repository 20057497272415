import { api } from "@common/api/api";
import { RhApiError } from "@common/types/errorTypes";
import { selectAccountSummary } from "@portal/selectors/accountSummarySelectors";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import {
  accountSummaryErrored,
  accountSummaryFetch,
  accountSummaryReceived,
  accountSummaryRefetch,
  accountSummaryRequested,
} from "@portal/slices/accountSummarySlice";
import {
  SagaReturnType,
  all,
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

export function* fetchAccountSummaryWorker() {
  const { accountSummary } = yield select(selectAccountSummary);

  if (!accountSummary) {
    yield call(refetchAccountSummaryWorker);
  }
}

export function* refetchAccountSummaryWorker() {
  const customerId: SagaReturnType<
    typeof selectCurrentCustomerId
  > = yield select(selectCurrentCustomerId);

  if (customerId) {
    yield put(accountSummaryRequested());
    try {
      const accountSummary: SagaReturnType<
        typeof api.customers.accountSummary.retrieve
      > = yield call(api.customers.accountSummary.retrieve, customerId);

      yield put(accountSummaryReceived(accountSummary));
    } catch (err: unknown) {
      yield put(accountSummaryErrored(err as RhApiError));
    }
  }
}

export function* accountSummaryWatcher() {
  yield all([
    takeLatest(accountSummaryFetch.type, fetchAccountSummaryWorker),
    takeLatest(accountSummaryRefetch.type, refetchAccountSummaryWorker),
  ]);
}
