import { api } from "@common/api/api";
import { formatMaskNumber } from "@common/forms/formatters";
import {
  CustomerBillingPreferences,
  CustomerType,
} from "@common/types/customerTypes";
import { CUSTOMER_UPDATE_FAILED } from "@common/types/errorTypes";
import { formatMonthDayYearDate } from "@common/utils/dataFormatters";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { AutoPayDialog } from "@portal/components/AutoPayDialog/AutoPayDialog";
import { AveragedBillingDialog } from "@portal/components/AveragedBillingDialog/AveragedBillingDialog";
import { useMyAccountBillingPreferencesCardStyles } from "@portal/components/MyAccountBillingPreferencesCard/MyAccountBillingPreferencesCard.style";
import { PickYourOwnDueDayBox } from "@portal/components/MyAccountBillingPreferencesCard/PickYourOwnDueDayBox";
import { PickYourOwnDueDateDialog } from "@portal/components/PickYourOwnDueDateDialog/PickYourOwnDueDateDialog";
import { PreferencesSwitchInput } from "@portal/components/PreferencesSwitchInput/PreferencesSwitchInput";
import { useFeatureFlags } from "@portal/hooks/useFeatureFlags";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectAccountSummary } from "@portal/selectors/accountSummarySelectors";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import { selectDefaultPaymentMethod } from "@portal/selectors/billingDetailsSelectors";
import { accountSummaryFetch } from "@portal/slices/accountSummarySlice";
import { customerUpdated } from "@portal/slices/customerSlice";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export interface MyAccountBillingPreferencesCardProps {
  hasHistoricUsage: boolean;
  averagedBillingMonthlyCharge: number | undefined;
  canPickBillingDueDay: boolean;
  initialPreferences: CustomerBillingPreferences;
}

interface AccountModalStates {
  autoPay: boolean;
  averagedBilling: boolean;
  pickYourOwnDueDay: boolean;
}

export const MyAccountBillingPreferencesCard: FC<MyAccountBillingPreferencesCardProps> = ({
  hasHistoricUsage,
  averagedBillingMonthlyCharge,
  canPickBillingDueDay,
  initialPreferences,
}) => {
  const customerId = useSelector(selectCurrentCustomerId);
  const { accountSummary } = useSelector(selectAccountSummary);
  const dispatch = useDispatch();
  const { t } = useRhIntl();
  const flash = useRhFlash();
  const { averageBillingFeature, pickYourDueDateFeature } = useFeatureFlags();

  const [modals, setModals] = useState<AccountModalStates>({
    autoPay: false,
    averagedBilling: false,
    pickYourOwnDueDay: false,
  });

  const [updatingPreferences, setUpdatingPreferences] = useState<boolean>(
    false
  );
  const [preferences, setPreferences] = useState<CustomerBillingPreferences>({
    ...initialPreferences,
  });
  const showAveragedBillingToggle =
    averageBillingFeature &&
    (hasHistoricUsage ||
      preferences.averagedBilling ||
      averagedBillingMonthlyCharge);

  useEffect(() => {
    dispatch(accountSummaryFetch());
  }, [customerId, dispatch]);

  const { accountDueDate, totalBalance, pastDueBalance } = accountSummary || {};

  const title = t("MyAccountBillingPreferencesCard.title");

  const updatePreferences = async (
    updatedPreferences: Partial<CustomerBillingPreferences>
  ) => {
    if (updatingPreferences) {
      return;
    }
    setUpdatingPreferences(true);

    const oldPreferences = { ...preferences };

    // automatically switch the toggle so user perceives no lagging while endpoint is resolving
    // if there is an error, then just switch it back to its original state
    setPreferences({
      ...preferences,
      ...updatedPreferences,
    });
    const [error, updatedCustomerData] = await handleAjaxCall<CustomerType>(
      api.customers.update(customerId, updatedPreferences)
    );

    if (error) {
      setPreferences(oldPreferences);
      flash.error(t(CUSTOMER_UPDATE_FAILED));
    } else if (updatedCustomerData) {
      dispatch(customerUpdated(updatedCustomerData));
      flash.success(t("MyAccountBillingPreferencesCard.preferencesUpdated"));
    }

    setUpdatingPreferences(false);
  };

  const toggleModalOpen = (
    modalName: keyof AccountModalStates,
    isOpen: boolean
  ) => {
    setModals({
      ...modals,
      [modalName]: isOpen,
    });
  };

  const classes = useMyAccountBillingPreferencesCardStyles({});
  const { defaultPaymentMethod } = useSelector(selectDefaultPaymentMethod);

  const allToggleProps = { disabled: updatingPreferences };

  const handleToggleAveragedBilling = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    if (checked) {
      toggleModalOpen("averagedBilling", true);
    } else {
      updatePreferences({
        averagedBilling: false,
      });
    }
  };

  return (
    <RhCard title={title}>
      {showAveragedBillingToggle && (
        <Box className={classes.preferenceCard}>
          <PreferencesSwitchInput
            {...allToggleProps}
            name="averagedBilling"
            checked={preferences.averagedBilling}
            onChange={handleToggleAveragedBilling}
            label={t("MyAccountBillingPreferencesCard.averagedBilling")}
            detail={t(
              "MyAccountBillingPreferencesCard.averagedBilling.useAverageBilling"
            )}
          />
        </Box>
      )}
      {canPickBillingDueDay && pickYourDueDateFeature && (
        <Box className={classes.preferenceCard}>
          <PickYourOwnDueDayBox
            name="pickBillingDueDay"
            pickedBillingDueDay={preferences.pickedBillingDueDay}
            openModal={() => toggleModalOpen("pickYourOwnDueDay", true)}
          />
        </Box>
      )}
      <Box className={classes.preferenceCard}>
        <PreferencesSwitchInput
          {...allToggleProps}
          name="invoiceByPrint"
          checked={!preferences.invoiceByPrint}
          onChange={() => {
            updatePreferences({
              invoiceByPrint: !preferences.invoiceByPrint,
            });
          }}
          label={t("MyAccountBillingPreferencesCard.eBillOnly")}
          detail={t("MyAccountBillingPreferencesCard.eBillOnly.receiveEBills")}
        />
      </Box>
      <Box className={classes.preferenceCard}>
        <PreferencesSwitchInput
          {...allToggleProps}
          name="autopay"
          checked={preferences.autopay}
          onChange={(_e, checked) => {
            if (checked) {
              toggleModalOpen("autoPay", true);
            } else {
              updatePreferences({
                autopay: false,
              });
            }
          }}
          label={t("MyAccountBillingPreferencesCard.autoPay")}
          detail={
            preferences.autopay ? (
              <>
                <span>
                  {defaultPaymentMethod?.companyName}{" "}
                  {defaultPaymentMethod &&
                    formatMaskNumber(defaultPaymentMethod.maskedNumber)}
                </span>
                <Link
                  className={classes.link}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModalOpen("autoPay", true);
                  }}
                >
                  {t("MyAccountBillingPreferencesCard.autoPay.change")}
                </Link>
                {Boolean(accountDueDate) && Boolean(totalBalance) && (
                  <>
                    <br />
                    {t("MyAccountBillingPreferencesCard.autoPay.nextCharge", {
                      date: formatMonthDayYearDate(accountDueDate as string),
                    })}
                  </>
                )}
              </>
            ) : (
              t("MyAccountBillingPreferencesCard.autoPay.setUpAutoPay")
            )
          }
        />
      </Box>

      {modals.autoPay && (
        <AutoPayDialog
          balance={totalBalance}
          dueDate={accountDueDate}
          pastDueBalance={pastDueBalance}
          onClose={() => toggleModalOpen("autoPay", false)}
          onSuccess={() => {
            toggleModalOpen("autoPay", false);
            updatePreferences({
              autopay: true,
            });
          }}
        />
      )}

      {modals.averagedBilling && (
        <AveragedBillingDialog
          averagedBillingMonthlyCharge={
            averagedBillingMonthlyCharge?.toString() || ""
          }
          isOpen={modals.averagedBilling}
          onClose={() => toggleModalOpen("averagedBilling", false)}
          onSubmit={() => {
            updatePreferences({
              averagedBilling: true,
            });
            toggleModalOpen("averagedBilling", false);
          }}
        />
      )}

      {modals.pickYourOwnDueDay && (
        <PickYourOwnDueDateDialog
          onClose={() => toggleModalOpen("pickYourOwnDueDay", false)}
          onSubmit={(value: number | null) => {
            toggleModalOpen("pickYourOwnDueDay", false);

            updatePreferences({
              pickedBillingDueDay: value,
            });
          }}
        />
      )}
    </RhCard>
  );
};
