import { OfferType } from "@common/types/offerTypes";
import {
  userLoggedIn,
  userLoggedOut,
} from "@portal/slices/authenticationSlice";
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

export type SelectedOfferType = null | OfferType;

export interface OfferStateType {
  selected: SelectedOfferType;
}

export type SetSelectedOfferAction = PayloadAction<SelectedOfferType>;

// Handlers
export const setSelectedOfferHandler: CaseReducer<
  OfferStateType,
  SetSelectedOfferAction
> = (state, action): OfferStateType => ({
  ...state,
  selected: action.payload ?? null,
});

export const clearSelectedOfferHandler: CaseReducer<
  OfferStateType,
  PayloadAction
> = (state) => ({
  ...state,
  selected: null,
});

// Reducer
export const initialOfferState: OfferStateType = {
  selected: null,
};

const reducers = {
  updated: setSelectedOfferHandler,
};

export const offerSlice = createSlice<OfferStateType, typeof reducers>({
  name: "offer",
  initialState: initialOfferState,
  reducers,
  extraReducers: {
    [userLoggedIn.type]: clearSelectedOfferHandler,
    [userLoggedOut.type]: clearSelectedOfferHandler,
  },
});

export const offerSliceName = offerSlice.name;
export const offerReducer = offerSlice.reducer;
export const setSelectedOffer = offerSlice.actions.updated;
