import { formatDateOfBirth } from "@common/forms/formatters";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import React from "react";
import { useIntl } from "react-intl";

interface SignUpNameFieldsProps {
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const SignUpNameFields: React.FC<SignUpNameFieldsProps> = ({
  onFocus,
}) => {
  const { formatMessage } = useIntl();
  const firstName = formatMessage({ id: "signUpNameFields.firstName" });
  const lastName = formatMessage({ id: "signUpNameFields.lastName" });
  const dateOfBirth = formatMessage({ id: "signUpNameFields.dateOfBirth" });

  const fieldInputProps = {
    onFocus,
  };

  return (
    <LoggedOutFieldsLayout>
      <RhTextField
        name="firstName"
        autoComplete="given-name"
        autoFocus
        InputProps={fieldInputProps}
      >
        {firstName}
      </RhTextField>
      <RhTextField
        name="lastName"
        autoComplete="family-name"
        InputProps={fieldInputProps}
      >
        {lastName}
      </RhTextField>
      <RhTextField
        autoComplete="bday"
        format={formatDateOfBirth}
        parse={formatDateOfBirth}
        name="dateOfBirth"
        placeholder="MM/DD/YYYY"
        inputMode="numeric"
        InputProps={fieldInputProps}
      >
        {dateOfBirth}
      </RhTextField>
    </LoggedOutFieldsLayout>
  );
};
