import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { formatMaskNumber, formatPhoneNumber } from "@common/forms/formatters";
import { OfferType } from "@common/types/offerTypes";
import {
  PaymentMethodRetrieveType,
  PaymentMethodTypes,
} from "@common/types/paymentMethodTypes";
import {
  formatCurrency,
  formatDollarsToCents,
  separateNumber,
} from "@common/utils/dataFormatters";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { Box } from "@material-ui/core";
import { useSignUpSummaryInfoStyles } from "@portal/components/SignUpSummaryInfo/SignUpSummaryInfo.style";
import { ESI_ID_NOT_FOUND } from "@portal/constants/offer.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { SignUpStateType } from "@portal/slices/signUpSlice";
import dayjs from "dayjs";
import React, { FC } from "react";

interface SignUpSummaryInfoProps {
  signUpData: SignUpStateType;
  offerDetails: OfferType;
  paymentMethodDetails: PaymentMethodRetrieveType | null;
}

const LabelCell: FC = ({ children }) => {
  const classes = useSignUpSummaryInfoStyles();

  return (
    <Box component="dt" className={classes.labelContainer}>
      <RhTypography
        variant="body1"
        color="textSecondary"
        className={classes.cell}
      >
        {children}
      </RhTypography>
    </Box>
  );
};
const ValueCell: FC = ({ children }) => {
  const classes = useSignUpSummaryInfoStyles();

  return (
    <Box component="dd" className={classes.valueContainer}>
      <RhTypography variant="body1" component="div" className={classes.cell}>
        {children}
      </RhTypography>
    </Box>
  );
};

export const SignUpSummaryInfo: FC<SignUpSummaryInfoProps> = ({
  signUpData,
  offerDetails,
  paymentMethodDetails,
}) => {
  const classes = useSignUpSummaryInfoStyles();
  const { t } = useRhIntl();
  const fieldLabel = (field: string): string => t(`SignUpSummaryPage.${field}`);

  const hasPromo = Boolean(offerDetails.promo || signUpData.campaignPromo);

  const getPaymentMethod: () => string = () => {
    if (!paymentMethodDetails) {
      return NOT_APPLICABLE_ABBREVIATION;
    }

    if (paymentMethodDetails.type === PaymentMethodTypes.CreditCard) {
      return t("SignUpSummaryPage.creditCard");
    } else {
      return paymentMethodDetails.achBankName;
    }
  };

  const getPaymentMethodAccountNumberMask: () => string | null = () => {
    if (!paymentMethodDetails) {
      return null;
    }

    const maskNumber =
      paymentMethodDetails.type === PaymentMethodTypes.CreditCard
        ? paymentMethodDetails.creditCardMaskNumber
        : paymentMethodDetails.achAccountNumberMask;

    return formatMaskNumber(maskNumber);
  };

  return (
    <RhFlexBox component="dl" width="100%" flexDirection="row" flexWrap="wrap">
      <LabelCell>{fieldLabel("name")}</LabelCell>
      <ValueCell>
        {signUpData.firstName} {signUpData.lastName}
      </ValueCell>
      <LabelCell>{fieldLabel("address")}</LabelCell>
      <ValueCell>
        <div>
          {signUpData.serviceAddress.addressLine1}{" "}
          {signUpData.serviceAddress.unitNumber}
        </div>
        <div>
          {signUpData.serviceAddress.city}, {signUpData.serviceAddress.state}
        </div>
        <div>{signUpData.serviceAddress.zipCode}</div>
      </ValueCell>
      <LabelCell>{t("SignUpSummaryPage.esiId")}</LabelCell>
      <ValueCell>
        {signUpData.esiId === ESI_ID_NOT_FOUND
          ? NOT_APPLICABLE_ABBREVIATION
          : signUpData.esiId}
      </ValueCell>
      <LabelCell>{fieldLabel("contactInfo")}</LabelCell>
      <ValueCell>
        <div>{formatPhoneNumber(signUpData.phone)}</div>
        <div>{signUpData.email}</div>
      </ValueCell>
      <LabelCell>{fieldLabel("billingPreferences")}</LabelCell>
      <ValueCell>
        <RhFlexBox>
          <div className={classes.billingPreferenceLabel}>
            {fieldLabel("autoPay")}
          </div>
          <div className={classes.billingPreferenceValue}>
            {signUpData.autopay ? fieldLabel("on") : fieldLabel("off")}
          </div>
        </RhFlexBox>
        <RhFlexBox>
          <div className={classes.billingPreferenceLabel}>
            {fieldLabel("eBill")}
          </div>
          <div className={classes.billingPreferenceValue}>
            {!signUpData.invoiceByPrint ? fieldLabel("on") : fieldLabel("off")}
          </div>
        </RhFlexBox>
      </ValueCell>

      <LabelCell>{fieldLabel("paymentMethod")}</LabelCell>
      <ValueCell>
        <div>{getPaymentMethod()}</div>
        <div>{getPaymentMethodAccountNumberMask()}</div>
      </ValueCell>
      {Boolean(signUpData.depositAmount) && (
        <>
          <LabelCell>{fieldLabel("deposit")}</LabelCell>
          <ValueCell>{formatCurrency(signUpData.depositAmount || 0)}</ValueCell>
        </>
      )}
      <LabelCell>{t("SignUpSummaryPage.planLabel")}</LabelCell>
      <ValueCell>
        <RhTypography component="div" fontWeight={FontWeight.Bold}>
          {offerDetails.title}
        </RhTypography>
        <div>
          {t("SignUpSummaryPage.rate", {
            price: formatDollarsToCents(offerDetails?.price),
          })}
        </div>
        <div>
          {t("SignUpSummaryPage.rateBilledMonthly", {
            termMonths: offerDetails.termMonths,
          })}
        </div>
      </ValueCell>
      <LabelCell>{fieldLabel("startDate")}</LabelCell>
      <ValueCell>
        {dayjs(signUpData.serviceStartDate).format("MMMM DD, YYYY")}
      </ValueCell>
      {hasPromo && (
        <>
          <LabelCell>{fieldLabel("promoCode")}</LabelCell>
          <ValueCell>
            <RhTypography variant="subtitle2" className={classes.promoCode}>
              {offerDetails.promo?.code || signUpData.campaignPromo?.code}
            </RhTypography>
            <RhTypography color="textSecondary" variant="body2">
              {t("SignUpSummaryPage.promoCodeApplied")}
            </RhTypography>
            {offerDetails.promo?.code && (
              <RhTypography color="textSecondary" variant="body2">
                {t("SignUpSummaryPage.promoCodeValue", {
                  value: formatCurrency(offerDetails.promo?.value || 0, 0),
                  points: separateNumber(
                    (offerDetails.promo?.value || 0) * Math.pow(10, 2)
                  ),
                  pointsChunk: (content) => (
                    <span className={classes.promoPoints}>{content}</span>
                  ),
                })}
              </RhTypography>
            )}
          </ValueCell>
        </>
      )}
    </RhFlexBox>
  );
};
