import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { CopyIcon } from "@design-system/icons";
import Input from "@material-ui/core/Input";
import { useCopyReferralLinkButtonStyles } from "@portal/components/CopyReferralLinkButton/CopyReferralLinkButton.styles";
import { useCustomer } from "@portal/hooks/useCustomer";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { signUpReferralUrl } from "@portal/routes/routePaths";
import React, { FC, useEffect, useRef, useState } from "react";

export const CLEAR_LINK_COPIED_TIME = 2000;

interface CopyReferralLinkButtonProps {
  dense?: boolean;
}

export const CopyReferralLinkButton: FC<CopyReferralLinkButtonProps> = ({
  dense,
}) => {
  const classes = useCopyReferralLinkButtonStyles({ dense });

  const copyLinkInputRef = useRef<HTMLElement>(null);
  const { t } = useRhIntl();
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const { customer, requestMonitor: customerRequestMonitor } = useCustomer();
  const isDisabled =
    customerRequestMonitor.isWaiting ||
    !customer?.referralToFriendCode ||
    isLinkCopied;

  useEffect(() => {
    if (isLinkCopied) {
      const timeoutId = setTimeout(() => {
        setIsLinkCopied(false);
      }, CLEAR_LINK_COPIED_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLinkCopied]);

  const copyTextToClipboard = () => {
    if (!isLinkCopied) {
      const referralUrlInput = copyLinkInputRef?.current?.firstElementChild as
        | HTMLInputElement
        | undefined;

      referralUrlInput?.select();
      document.execCommand("copy");
      setIsLinkCopied(true);
      document.getSelection()?.removeAllRanges(); // this deselects the text on chrome & safari
      referralUrlInput?.blur(); // this deselects the text on firefox & ie
    }
  };

  const copyLinkCTA = t("CopyReferralLinkButton.copyLinkCTA");
  const linkCopiedText = t("CopyReferralLinkButton.copyLinkCopied");
  const buttonText = isLinkCopied ? linkCopiedText : copyLinkCTA;

  if (!customer) {
    return (
      <RhFlexBox justifyContent="flex-end">
        <RhButton
          className={classes.button}
          size="small"
          color="primary"
          disabled
        >
          <CopyIcon className={classes.copyIcon} />
          {copyLinkCTA}
        </RhButton>
      </RhFlexBox>
    );
  }

  const referralUrl =
    customer.referralToFriendCode &&
    signUpReferralUrl(customer.referralToFriendCode);

  return (
    <RhFlexBox className={classes.root}>
      {referralUrl && (
        <Input
          className={classes.input}
          readOnly
          ref={copyLinkInputRef}
          value={referralUrl}
        />
      )}
      <RhButton
        className={classes.button}
        size="small"
        color="primary"
        disabled={isDisabled}
        onClick={copyTextToClipboard}
      >
        <CopyIcon className={classes.copyIcon} />
        {buttonText}
      </RhButton>
    </RhFlexBox>
  );
};
