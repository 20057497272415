import { api } from "@common/api/api";
import { formatMaskNumber } from "@common/forms/formatters";
import { RhApiError } from "@common/types/errorTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { usePaymentMethodStyles } from "@portal/components/PaymentMethodView/PaymentMethodView.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { PaymentMethod } from "@portal/models/PaymentMethod.model";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import {
  billingDetailsRefetch,
  paymentMethodSetAsDefault,
} from "@portal/slices/billingDetailsSlice";
import classNames from "classnames";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface PaymentMethodProps {
  paymentMethod: PaymentMethod;
  displayOptions?: boolean;
  highlighted?: boolean;
}

export const PaymentMethodView: FC<PaymentMethodProps> = ({
  paymentMethod,
  highlighted,
  displayOptions = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useRhIntl();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const classes = usePaymentMethodStyles({ isDisabled });
  const customerId = useSelector(selectCurrentCustomerId);
  const flash = useRhFlash();

  const setPaymentMethodAsDefault = (paymentMethodId: string) => {
    setIsDisabled(true);

    api.billing.paymentMethods
      .default(paymentMethodId, { customerId })
      .then(() => {
        dispatch(paymentMethodSetAsDefault({ id: paymentMethodId }));
        flash.success(t("PaymentMethodView.preferencesUpdated"));
      })
      .catch((error: RhApiError) => {
        flash.error(t("PaymentMethodView.switchDefaultPaymentApiError"));
      })
      .finally(() => setIsDisabled(false));
  };

  const cardClasses = classNames(
    classes.root,
    paymentMethod.defaultPaymentMethod ? classes.defaultCard : "",
    highlighted ? "cardHighlighted" : ""
  );
  const deleteCard = (paymentMethodId: string) => {
    setIsDisabled(true);

    api.billing.paymentMethods
      .delete(paymentMethodId)
      .then(() => {
        dispatch(billingDetailsRefetch());
        flash.success(t("PaymentMethodView.preferencesUpdated"));
      })
      .catch((error: RhApiError) => {
        flash.error(t("PaymentMethodView.deletePaymentApiError"));
      })
      .finally(() => setIsDisabled(false));
  };

  return (
    <RhInnerCard
      key={paymentMethod.id}
      data-testid="PaymentMethod__single"
      className={cardClasses}
    >
      <RhFlexBox className={classes.cardInfo}>
        <RhTypography variant="button">
          {paymentMethod.companyName}

          <RhTypography
            component="span"
            variant="body1"
            color="textSecondary"
            className={classes.cardExpiration}
          >
            {paymentMethod.secondaryInfo}
          </RhTypography>
        </RhTypography>

        <RhTypography variant="body1">
          {formatMaskNumber(paymentMethod.maskedNumber)}
        </RhTypography>
      </RhFlexBox>
      <RhFlexBox
        className={classes.cardOptions}
        data-testid={`PaymentMethod__options__${paymentMethod.id}`}
      >
        {paymentMethod.defaultPaymentMethod && (
          <Box className={classes.defaultPaymentText}>
            <RhTypography variant="body1" color="textSecondary">
              {t("PaymentMethodView.DefaultPayment")}
            </RhTypography>
          </Box>
        )}

        {displayOptions && !paymentMethod.defaultPaymentMethod && (
          <>
            <Box className={classes.cardOption}>
              <RhTypography
                component="a"
                variant="body1"
                color="textSecondary"
                onClick={() => setPaymentMethodAsDefault(paymentMethod.id)}
              >
                {t("PaymentMethodView.SetAsDefault")}
              </RhTypography>
            </Box>

            <Box className={classes.cardOption}>
              <RhTypography
                component="a"
                variant="body1"
                color="textSecondary"
                onClick={() => deleteCard(paymentMethod.id)}
              >
                {t("PaymentMethodView.Delete")}
              </RhTypography>
            </Box>
          </>
        )}
      </RhFlexBox>
    </RhInnerCard>
  );
};
