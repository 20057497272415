import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useCustomerHomeNewSignupSectionStyle = makeStyles<
  typeof rhythmTheme,
  "subheader" | "makeWorkWithYourSchedule" | "root" | "welcomeImageContainer"
>((theme) =>
  createStyles({
    makeWorkWithYourSchedule: {
      marginBottom: theme.spacing(2),
    },
    root: {
      alignItems: "center",
      marginRight: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        marginRight: 0,
      },
    },
    subheader: {
      color: theme.palette.grey[500],
      marginTop: theme.spacing(2),
    },
    welcomeImageContainer: {
      flexGrow: 0,
      flexShrink: 0,
      paddingRight: theme.spacing(3),
      width: 180,
      [theme.breakpoints.down("sm")]: {
        padding: `0 0 ${theme.spacing(5)}px 0`,
      },
    },
  })
);
