import { formatKwh } from "@common/utils/dataFormatters";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  SliderOnChangeEvent,
  SliderOnChangeFunction,
  SliderOnChangeValueType,
} from "@design-system/types/domEventTypes";
import {
  House1000,
  House1500,
  House2000,
  House2500,
  House3000,
  House3500,
  House500,
} from "@portal/components/MonthlyUsageSlider/images";
import { useMonthlyUsageSliderStyles } from "@portal/components/MonthlyUsageSlider/MonthlyUsageSlider.style";
import { MonthlyUsageSliderInput } from "@portal/components/MonthlyUsageSlider/MonthlyUsageSliderInput";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { findClosest } from "@portal/utils/arrayUtils";
import React, { FC, useEffect, useState } from "react";

type DescriptionRecord = {
  Icon: JSX.Element;
  descriptor: string;
  preference: string;
};

interface MonthlyUsageSliderProps {
  onChange: SliderOnChangeFunction;
  initialValue: number;
}

export const monthlyUsageSliderConfig: Record<string, DescriptionRecord> = {
  500: {
    Icon: <House500 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-500",
    preference: "MonthlyUsageSlider.homePrefDescriptor-500",
  },
  1000: {
    Icon: <House1000 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-1000",
    preference: "MonthlyUsageSlider.homePrefDescriptor-1000",
  },
  1500: {
    Icon: <House1500 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-1500",
    preference: "MonthlyUsageSlider.homePrefDescriptor-1500",
  },
  2000: {
    Icon: <House2000 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-2000",
    preference: "MonthlyUsageSlider.homePrefDescriptor-2000",
  },
  2500: {
    Icon: <House2500 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-2500",
    preference: "MonthlyUsageSlider.homePrefDescriptor-2500",
  },
  3000: {
    Icon: <House3000 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-3000",
    preference: "MonthlyUsageSlider.homePrefDescriptor-3000",
  },
  3500: {
    Icon: <House3500 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-3500",
    preference: "MonthlyUsageSlider.homePrefDescriptor-3500",
  },
};

export const monthlyUsageSliderMarks = Object.keys(monthlyUsageSliderConfig);
export const monthlyUsageSliderMarksValues = monthlyUsageSliderMarks.map((x) =>
  parseInt(x, 10)
);

export const MonthlyUsageSlider: FC<MonthlyUsageSliderProps> = ({
  onChange,
  initialValue,
}) => {
  const { t } = useRhIntl();
  const classes = useMonthlyUsageSliderStyles();

  const [estimatedMonthlyUsage, setEstimatedMonthlyUsage] = useState<number>(
    initialValue
  );

  const handleChange: SliderOnChangeFunction = (
    event: SliderOnChangeEvent,
    value: SliderOnChangeValueType
  ) => {
    if (!Array.isArray(value)) {
      setEstimatedMonthlyUsage(value);
      onChange(event, value);
    }
  };

  useEffect(() => {
    if (!Number.isFinite(initialValue)) {
      setEstimatedMonthlyUsage(DEFAULT_AVERAGE_MONTHLY_USAGE);
    }
  }, [initialValue]);

  const renderDescription = () => {
    if (!(estimatedMonthlyUsage in monthlyUsageSliderConfig)) {
      setEstimatedMonthlyUsage(
        findClosest(monthlyUsageSliderMarksValues, estimatedMonthlyUsage)
      );
      return null;
    } else {
      const { Icon, descriptor, preference } = monthlyUsageSliderConfig[
        estimatedMonthlyUsage.toString()
      ];

      return (
        <RhFlexBox className={classes.descriptionContainer}>
          <div className={classes.descriptionImage}>{Icon}</div>
          <div>
            <RhTypography variant="subtitle2" fontWeight="Bold">
              {formatKwh(estimatedMonthlyUsage, 0)}
            </RhTypography>
            <ul className={classes.list}>
              <li>
                <RhTypography variant="body1">{t(descriptor)}</RhTypography>
              </li>
              <li>
                <RhTypography variant="body1">{t(preference)}</RhTypography>
              </li>
            </ul>
          </div>
        </RhFlexBox>
      );
    }
  };

  if (!estimatedMonthlyUsage) {
    return null;
  }
  return (
    <div className={classes.root}>
      {renderDescription()}
      <MonthlyUsageSliderInput
        initialValue={estimatedMonthlyUsage}
        onChange={handleChange}
      />
    </div>
  );
};
