import { connectApi } from "@common/services/ajax";
import {
  getEnv,
  getEnvApplicationSettings,
} from "@common/services/getEnvApplicationSettings.service";
import DayjsUtils from "@date-io/dayjs";
import { RhAnnouncementProvider } from "@design-system/components/RhAnnouncement/RhAnnouncementProvider";
import { RhFlashProvider } from "@design-system/components/RhFlashProvider/RhFlashProvider";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { init as FullStoryInit } from "@fullstory/browser";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Security } from "@okta/okta-react";
import { App } from "@portal/App";
import { signInPath } from "@portal/routes/routePaths";
import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import {
  AXIOS_BASE_URL,
  AXIOS_TIMEOUT_MS,
  FullStoryConfig,
  GoogleTagManagerConfig,
  OKTA_CONFIG,
  SENTRY_CONFIG,
} from "@portal/settings/config";
import { allEnvApplicationSettings } from "@portal/settings/env";
import { store } from "@portal/store/store";
import { generateTranslations } from "@portal/utils/generateTranslations.util";
import { isBot } from "@portal/utils/isBot";
import { ScrollToTop } from "@portal/utils/ScrollToTop";
import SentryFullStory from "@sentry/fullstory";
import { RewriteFrames } from "@sentry/integrations";
import {
  BrowserOptions,
  ErrorBoundary,
  reactRouterV5Instrumentation,
  init as sentryInit,
} from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import { createBrowserHistory } from "history"; // eslint-disable-line import/no-extraneous-dependencies
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider as StoreProvider } from "react-redux";
import { Router } from "react-router-dom";

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings
);

(async () => {
  TagManager.initialize(GoogleTagManagerConfig);

  if (FullStoryConfig) {
    FullStoryInit(FullStoryConfig);
  }

  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);

  connectApi({
    baseURL: AXIOS_BASE_URL,
    timeout: AXIOS_TIMEOUT_MS,
  });
  const history = createBrowserHistory();

  if (SENTRY_CONFIG.dsn) {
    const integrations: BrowserOptions["integrations"] = [
      new Integrations.BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
      }),
      new RewriteFrames(),
    ];

    if (SENTRY_CONFIG.orgSlug) {
      integrations.push(new SentryFullStory(SENTRY_CONFIG.orgSlug));
    }

    sentryInit({
      dsn: SENTRY_CONFIG.dsn,
      tracesSampleRate: SENTRY_CONFIG.tracesSampleRate,
      environment: getEnv(),
      integrations,
      release: SENTRY_CONFIG.release,
    });
  }

  // As of now, we don't really care about identifying users for LaunchDarkly, however
  // DataDog Synthetic tests are creating 1000s of unique users in LaunchDarkly, taking away
  // gold from Rhythm's treasure trove and therefore affecting my salary and unlimited perks.
  const additionalLaunchDarklyConfig = isBot()
    ? {
        user: {
          key: "beep beep boop I am a robot",
        },
      }
    : {
        user: {
          key: "anonymous",
        },
      };

  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: envApplicationSettings.launchDarklyClientId,
    options: {
      disableSyncEventPost: true,
    },
    ...additionalLaunchDarklyConfig,
  });

  generateTranslations();

  ReactDOM.render(
    <ErrorBoundary>
      <MuiThemeProvider theme={rhythmTheme}>
        <CssBaseline />
        <StoreProvider store={store}>
          <Router history={history}>
            <ScrollToTop />
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <RhFlashProvider>
                <RhAnnouncementProvider>
                  <Security
                    oktaAuth={portalAuthClient.authClient}
                    {...OKTA_CONFIG}
                    onAuthRequired={() => {
                      history.push(signInPath());
                    }}
                  >
                    <LaunchDarklyProvider>
                      <App />
                    </LaunchDarklyProvider>
                  </Security>
                </RhAnnouncementProvider>
              </RhFlashProvider>
            </MuiPickersUtilsProvider>
          </Router>
        </StoreProvider>
      </MuiThemeProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
})();
