import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { useRhRadioCardInputStyles } from "@design-system/components/RhRadioCardInput/RhRadioCardInput.style";
import {
  RhRadioInput,
  RhRadioInputProps,
} from "@design-system/components/RhRadioInput/RhRadioInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, FormControl, FormLabel } from "@material-ui/core";
import React, { FC } from "react";

type RhRadioCardInputProps = {
  inputId: string;
  label: string;
} & RhRadioInputProps;

export const RhRadioCardInput: FC<RhRadioCardInputProps> = ({
  inputId,
  label,
  ...restProps
}) => {
  const classes = useRhRadioCardInputStyles();

  return (
    <FormControl>
      <FormLabel htmlFor={inputId}>
        <RhInnerCard className={classes.card}>
          <Box alignItems="center" display="flex">
            <RhRadioInput
              id={inputId}
              {...restProps}
              className={classes.option}
            />
            <RhTypography>{label}</RhTypography>
          </Box>
        </RhInnerCard>
      </FormLabel>
    </FormControl>
  );
};
