import { boxShadows } from "@design-system/constants/boxShadows";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core/styles";
import { PROMO_HEIGHT } from "@portal/components/OfferCard/OfferCard.style";

const TOP_PADDING_IN_PX = 43;

export const useOfferCarouselStyles = makeStyles<
  Theme,
  { isLoaded: boolean; hasPromos: boolean },
  "root" | "slide" | "sliderLabel" | "mobileStepper" | "swipeablePerks"
>((theme) =>
  createStyles({
    mobileStepper: {
      boxShadow: boxShadows.default,
    },
    root: ({ isLoaded, hasPromos }) => ({
      // see comment on 'SwipeableOffers' for more details regarding the need for this ternary
      height: isLoaded ? "100%" : "100vh",
      padding: `${
        hasPromos ? PROMO_HEIGHT + TOP_PADDING_IN_PX : TOP_PADDING_IN_PX
      }px 49px 15px 20px`,
    }),
    slide: ({ hasPromos }) => ({
      "&:last-child": {
        left: 50,
        position: "absolute",
        top: `-${
          hasPromos ? PROMO_HEIGHT + TOP_PADDING_IN_PX : TOP_PADDING_IN_PX
        }px`,
        width: "210% !important",
      },
      overflow: "visible !important",
      padding: "0 5px",
    }),
    sliderLabel: {
      marginBottom: theme.spacing(2),
    },
    swipeablePerks: {
      color: theme.palette.primary.main,
    },
  })
);
