import { borders } from "@design-system/constants/borders";
import { boxShadows } from "@design-system/constants/boxShadows";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles, makeStyles } from "@material-ui/core/styles";

interface OfferCardStylesProps {
  hasPromo: boolean;
}

export const PROMO_HEIGHT = 40;

export const useOfferCardStyles = makeStyles<
  typeof rhythmTheme,
  OfferCardStylesProps,
  | "cardRoot"
  | "cardHeader"
  | "cardActions"
  | "description"
  | "offerFeatures"
  | "promo"
  | "rateInfo"
  | "rateInfoChild"
  | "rateInfoCalculateCta"
  | "rateInfoCalculateCtaText"
>((theme) => {
  const borderHighlight = `1px solid ${theme.palette.primary.main}`;
  const borderRadius = "6px";
  const rootPadding = 24;

  return createStyles({
    cardActions: {
      "& > button": {
        marginBottom: theme.spacing(1),
      },
      flexDirection: "column",
      paddingTop: theme.spacing(2),
    },
    cardHeader: {
      marginBottom: "auto",
      paddingBottom: 16,
    },
    cardRoot: {
      "&.active": {
        border: borderHighlight,
      },
      "&:hover": {
        [theme.breakpoints.up("md")]: {
          "-moz-transform": "scale(1.05)",
          "-o-transform": "scale(1.05)",
          "-webkit-transform": "scale(1.05)",
          border: borderHighlight,
          transform: "scale(1.05)",
        },
      },
      "-moz-transform": "scale(1)",
      "-o-transform": "scale(1)",
      "-webkit-transform": "scale(1)",
      backgroundColor: theme.palette.common.white,
      border: borders[50],
      borderRadius,
      boxShadow: boxShadows.default,
      margin: ({ hasPromo }) => {
        return hasPromo ? `-${PROMO_HEIGHT}px 12px 0 12px` : "0 12px";
      },
      minWidth: 278,
      padding: rootPadding,
      transform: "scale(1)",
      [theme.breakpoints.down("xs")]: {
        margin: 0,
        minWidth: 0,
      },
      transition: "border 500ms, transform 500ms",
      zIndex: 1,
    },
    description: {
      lineHeight: 1.2,
    },
    offerFeatures: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    promo: {
      alignItems: "center",
      backgroundColor: theme.palette.success[300],
      borderRadius: `${borderRadius} ${borderRadius} 0 0`,
      height: PROMO_HEIGHT,
      justifyContent: "center",
      marginBottom: rootPadding,
      marginLeft: -rootPadding,
      marginRight: -rootPadding,
      marginTop: -rootPadding,
      textAlign: "center",
    },
    rateInfo: {
      alignItems: "center",
    },
    rateInfoCalculateCta: {
      "&:hover": {
        textDecoration: "none !important",
      },
      color: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column",
      pointer: "cursor",
    },
    rateInfoCalculateCtaText: {
      marginTop: "4px",
    },
    rateInfoChild: {
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        borderRight: "none",
        paddingRight: 0,
      },
      borderRight: borders[100],
      flex: 1,
      padding: "8px 14px",
    },
  });
});
