import { formatCurrency } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box, Link } from "@material-ui/core";
import {
  LOGGED_OUT_CONTAINER_SPACING,
  LOGGED_OUT_FIELD_SPACING,
} from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { ZuoraCardForm } from "@portal/components/ZuoraCardForm/ZuoraCardForm";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { faqUrl } from "@portal/routes/routePaths";
import { selectDepositAmount } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export const SignUpDepositRequiredPage: FC = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { formatMessage } = useIntl();
  const depositAmount = useSelector(selectDepositAmount);
  const flash = useRhFlash();

  const couldBe = formatMessage({
    id: "SignUpDepositRequiredPage.couldBe",
  });

  const depositReturned = formatMessage({
    id: "SignUpDepositRequiredPage.depositReturned",
  });

  const forMoreInfoAboutYourDeposit = formatMessage(
    {
      id: "SignUpDepositRequiredPage.forMoreInfoAboutYourDeposit",
    },
    {
      link: (faqPage) => (
        <Link
          href={faqUrl("articles/360052046731-How-can-I-satisfy-a-deposit-")}
          target="_blank"
          onClick={() =>
            trackEvent({ action: ActionType.click, label: "depositFaqSite" })
          }
        >
          {faqPage}
        </Link>
      ),
    }
  );

  return (
    <SignUpPageLayout>
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING}>
        <LoggedOutPageHeader
          headerTextId="SignUpDepositRequiredPage.sorryWeNeedDeposit"
          headerTextValues={{
            deposit: formatCurrency(depositAmount ?? 0),
          }}
        />
      </Box>
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING} textAlign="center">
        <RhTypography variant="subtitle2" color="textSecondary">
          {couldBe}
        </RhTypography>
      </Box>
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING} textAlign="center">
        <RhTypography variant="subtitle2" color="textSecondary">
          {depositReturned}
        </RhTypography>
      </Box>
      <Box paddingBottom={LOGGED_OUT_CONTAINER_SPACING} textAlign="center">
        <RhTypography variant="subtitle2" color="textSecondary">
          {forMoreInfoAboutYourDeposit}
        </RhTypography>
      </Box>
      <ZuoraCardForm
        onSuccess={({ refId }) => {
          signUpClickNextStepHandler({
            signUpData: { billingPaymentMethodId: refId },
            nextStep: "summary",
            track: true,
          });
        }}
        onFailure={flash.error}
      />
    </SignUpPageLayout>
  );
};
