import { IdType } from "@common/types/apiTypes";
import { OfferType } from "@common/types/offerTypes";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { CostEstimator } from "@portal/components/CostEstimator/CostEstimator";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { OfferCarousel } from "@portal/components/OfferCarousel/OfferCarousel";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useSignUpOffers } from "@portal/hooks/useSignUpOffers";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { setSelectedOffer } from "@portal/slices/offerSlice";
import { Show500Page } from "@portal/utils/errors";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

export interface SignUpOfferFormValues {
  offersnapshotId: IdType;
  estimatedMonthlyUsage: number;
}

export const SignUpOfferPage: FC = () => {
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const { data: offers, requestMonitor, error } = useSignUpOffers();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { estimatedMonthlyUsage } = useSelector(selectSignUpState);

  const selectOffer = (offer: OfferType) => {
    const offersnapshotId = offer.id;

    dispatch(setSelectedOffer(offer));
    signUpClickNextStepHandler({
      signUpData: { offersnapshotId, estimatedMonthlyUsage },
      nextStep: "name",
      track: true,
    });
  };

  const headerTextId = isMobile
    ? "SignUpOfferPage.chooseYourPlan"
    : "SignUpOfferPage.chooseThePlan";

  if (requestMonitor.didFail) {
    throw new Show500Page(`Could not fetch offers: ${error?.data?.errorCode}`);
  }

  return (
    <SignUpPageLayout dense={false} hideBackButton>
      <Helmet>
        <link rel="stylesheet" href="/assets/SignUpOfferPage.css" />
      </Helmet>
      <Box mb={3}>
        <LoggedOutPageHeader headerTextId={headerTextId} />
      </Box>
      {requestMonitor.didSucceed && offers && (
        <>
          <OfferCarousel offers={offers} onSelectOffer={selectOffer} />
          <CostEstimator offers={offers} onSelectOffer={selectOffer} />
        </>
      )}
      {requestMonitor.isWaiting && <Box height={600} />}
    </SignUpPageLayout>
  );
};
