import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { IRONHIDE_SIGN_IN_URL } from "@portal/constants/urls.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

export const IronhideCustomerMessage: FC = () => {
  const { t } = useRhIntl();
  const message = t("IronhideCustomerMessage.alreadyACustomer", {
    link: (url: string) => (
      <a href={IRONHIDE_SIGN_IN_URL} rel="noreferrer">
        <RhTypography
          component="span"
          variant="body2"
          fontWeight={FontWeight.Semibold}
        >
          {url}
        </RhTypography>
      </a>
    ),
  });

  return <span>{message}</span>;
};
