import { Offer } from "@common/models/Offer.model";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PDFIcon } from "@design-system/icons";
import { Box, Grid } from "@material-ui/core";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@portal/components/LegalDocuments/LegalDocumentLinks";
import { useLegalDocumentsStyle } from "@portal/components/LegalDocuments/LegalDocuments.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import {
  ActionType,
  TrackEventHandler,
} from "@portal/services/segment.service";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC } from "react";
import { useSelector } from "react-redux";

export interface LegalDocumentsProps {
  offer: Offer;
  trackEvent?: TrackEventHandler;
}

export const LegalDocuments: FC<LegalDocumentsProps> = ({
  offer,
  trackEvent = () => null,
}) => {
  const classes = useLegalDocumentsStyle();
  const { t } = useRhIntl();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );

  const formattedMessage = (name: string) => t(`LegalDocuments.${name}`);

  const makeTracking = (label: string) => () =>
    trackEvent({
      action: ActionType.click,
      label,
    });
  const trackEfl = makeTracking("contractDocumentEfl");
  const trackTos = makeTracking("contractDocumentTos");
  const trackYrac = makeTracking("contractDocumentYrac");

  const yracTitle = formattedMessage("yrac");
  const tosTitle = formattedMessage("tos");
  const eflTitle = formattedMessage("efl");

  return (
    <>
      <Box mt={3}>
        <RhTypography variant="h2">
          {formattedMessage("contractDocuments")}
        </RhTypography>
      </Box>

      <Box mt={3} ml={1}>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <RhFlexBox flexDirection="row">
              <Box className={classes.iconContainer}>
                <EFLLink onClick={trackEfl} offerId={offer.id} locale={locale}>
                  <PDFIcon className={classes.icon} title={eflTitle} />
                </EFLLink>
              </Box>
              <Box>
                <EFLLink onClick={trackEfl} offerId={offer.id} locale={locale}>
                  <RhTypography variant="body2">{eflTitle}</RhTypography>
                </EFLLink>
                <RhTypography
                  variant="body2"
                  color="textSecondary"
                  className={classes.description}
                >
                  {formattedMessage("eflDescription")}
                </RhTypography>
              </Box>
            </RhFlexBox>
          </Grid>

          <Grid item md={4}>
            <RhFlexBox flexDirection="row">
              <Box className={classes.iconContainer}>
                <TOSLink onClick={trackTos} locale={locale}>
                  <PDFIcon className={classes.icon} title={tosTitle} />
                </TOSLink>
              </Box>
              <Box>
                <TOSLink onClick={trackTos} locale={locale}>
                  <RhTypography variant="body2">{tosTitle}</RhTypography>
                </TOSLink>
                <RhTypography
                  variant="body2"
                  color="textSecondary"
                  className={classes.description}
                >
                  {formattedMessage("tosDescription")}
                </RhTypography>
              </Box>
            </RhFlexBox>
          </Grid>

          <Grid item md={4}>
            <RhFlexBox flexDirection="row">
              <Box className={classes.iconContainer}>
                <YRACLink onClick={trackYrac} locale={locale}>
                  <PDFIcon className={classes.icon} title={yracTitle} />
                </YRACLink>
              </Box>
              <Box mb={2}>
                <YRACLink onClick={trackYrac} locale={locale}>
                  <RhTypography variant="body2">{yracTitle}</RhTypography>
                </YRACLink>
                <RhTypography
                  variant="body2"
                  color="textSecondary"
                  className={classes.description}
                >
                  {formattedMessage("yracDescription")}
                </RhTypography>
              </Box>
            </RhFlexBox>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
