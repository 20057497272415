import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { RH_HEADER_HEIGHT } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core/styles";

export const useAppStyles = rhMakeStyles<typeof rhythmTheme, "announcement">(
  (theme) =>
    createStyles({
      announcement: {
        position: "fixed",
        top: theme.spacing(RH_HEADER_HEIGHT),
        zIndex: 100,
      },
    })
);
