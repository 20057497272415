import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { SignUpEnrollmentFormValues } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentFormValuesTypes";

export const signUpEnrollmentFormValidator = generateValidationErrorCollector<SignUpEnrollmentFormValues>(
  {
    enrollmentType: [isRequired],
    serviceStartDate: [isRequired],
  }
);
