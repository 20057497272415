import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const usePaymentMethodsSectionStyles = makeStyles<
  typeof rhythmTheme,
  "sectionTitle"
>((theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: theme.spacing(2),
    },
  })
);
