import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core/styles";

export const MAP_HEIGHT = 140;
export const MAP_WIDTH = LOGGED_OUT_FORM_WIDTH;

export const useStaticMapStyles = makeStyles<
  typeof rhythmTheme,
  "map" | "mapWrapper"
>((theme) =>
  createStyles({
    map: {
      bottom: "0",
      display: "block",
      height: MAP_HEIGHT,
      left: "0",
      position: "absolute",
      right: "0",
      top: "0",
      width: MAP_WIDTH,
    },
    mapWrapper: {
      backgroundColor: theme.palette.grey[50],
      borderRadius: 5,
      margin: `${theme.spacing(5)}px 0`,
      overflow: "hidden",
      position: "relative",
      transition: "height 0.4s ease-in-out",
    },
  })
);
