import { api } from "@common/api/api";
import { cancelMessage } from "@common/api/generateGetHook";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { OfferType } from "@common/types/offerTypes";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useSignUpOffers = () => {
  const [response, { setSuccess, setPending, setFailure }] = useAjaxState<
    OfferType[]
  >();
  const { dunsNumber, campaignSlug } = useSelector(selectSignUpState);
  const dispatch = useDispatch();

  const source = axios.CancelToken.source();
  const cancelPrevRequest = () => {
    source.cancel(cancelMessage);
  };

  useEffect(() => {
    if (response.requestMonitor.isPending) {
      cancelPrevRequest();
    }

    setPending();

    api.pricing.offers
      .search(dunsNumber, campaignSlug ?? "")
      .then(({ campaignPromo, offers }) => {
        setSuccess(offers);
        dispatch(setSignUpInfo({ campaignPromo }));
      })
      .catch(setFailure);

    return cancelPrevRequest;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dunsNumber, campaignSlug]);

  return response;
};
