import { api } from "@common/api/api";
import { OktaGroups } from "@common/constants/okta.constant";
import { maskSSN, unmaskSSN } from "@common/forms/ssn.mask";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isValidSSN } from "@common/forms/validators";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { RhApiError } from "@common/types/errorTypes";
import { ProspectCreditCheckRequestType } from "@common/types/prospectTypes";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useAuthenticatedUserGroups } from "@portal/hooks/useAuthenticatedUserGroups";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import {
  RunCreditCheckRadioGroup,
  RunCreditCheckType,
} from "@portal/pages/SignUpSSNRequiredPage/RunCreditCheckRadioGroup.component";
import { SignUpStepType } from "@portal/routes/routePaths";
import {
  selectCreditCheckValues,
  selectDepositAmount,
  selectProspectId,
} from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { isOpsOrCSRs } from "@portal/utils/signUpHelpers";
import { FORM_ERROR } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

interface SSNFormValues {
  ssn: string;
  runCreditCheckType: RunCreditCheckType;
}

const initialValues = Object.freeze<SSNFormValues>({
  ssn: "",
  runCreditCheckType: RunCreditCheckType.RunCheck,
});

const ssnFormValidator = generateValidationErrorCollector<SSNFormValues>({
  ssn: [isValidSSN],
});

const nextStep = (
  outcome: string,
  newDepositAmount: number | null,
  groups: OktaGroups[] | null
): SignUpStepType => {
  if (outcome === CreditScoreOutcome.manualReview) {
    return "call-us";
  } else if (isOpsOrCSRs(groups)) {
    return "ivr-payment";
  } else if (newDepositAmount) {
    return "deposit";
  } else {
    return "payment";
  }
};

export const SignUpSSNRequiredPage: FC = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { formatMessage } = useIntl();
  const depositAmount = useSelector(selectDepositAmount);
  const customerContactValues = useSelector(selectCreditCheckValues);
  const prospectId = useSelector(selectProspectId);
  const flash = useRhFlash();
  const { data: groups } = useAuthenticatedUserGroups();

  const onSubmit = ({ runCreditCheckType, ssn }: SSNFormValues) => {
    if (runCreditCheckType === RunCreditCheckType.SkipCheck) {
      signUpClickNextStepHandler({
        nextStep: nextStep("", depositAmount, groups),
        track: true,
      });
    } else {
      const contactValuesWithSSN: ProspectCreditCheckRequestType = {
        ...customerContactValues,
        ssn,
        prospectId,
      };

      return api.prospects
        .creditScoreEvaluation(contactValuesWithSSN)
        .then(({ depositAmount: newDepositAmount, outcome }) => {
          signUpClickNextStepHandler({
            signUpData: {
              depositAmount: newDepositAmount,
              creditEvaluation: outcome,
            },
            nextStep: nextStep(outcome, newDepositAmount, groups),
            track: true,
          });
        })
        .catch((_error: RhApiError) => {
          const errorMessage = formatMessage({
            id: "SignUpSSNRequiredPage.creditScoreApiError",
          });

          flash.error(errorMessage);

          return { [FORM_ERROR]: [errorMessage] };
        });
    }
  };

  const ssn = formatMessage({
    id: "SignUpSSNRequiredPage.socialSecurityNumber",
  });
  const submitCTA = formatMessage({ id: "SignUpSSNRequiredPage.next" });

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerTextId="SignUpSSNRequiredPage.timeForQuickCreditCheck"
        subHeaderTextId="SignUpSSNRequiredPage.verifyYourIdentity"
      />
      <Form<SSNFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={ssnFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
            <LoggedOutFieldsLayout>
              <RhTextField
                name="ssn"
                type="text"
                autoComplete="ssn"
                placeholder="123-45-6789"
                inputMode="numeric"
                format={maskSSN}
                parse={unmaskSSN}
                InputProps={{
                  onFocus: (event) => {
                    trackEvent({
                      action: ActionType.focus,
                      label: event.target.id,
                    });
                  },
                }}
              >
                {ssn}
              </RhTextField>
              <RunCreditCheckRadioGroup
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  const {
                    target: { value },
                  } = (event as unknown) as React.ChangeEvent<HTMLInputElement>;
                  const label =
                    value === RunCreditCheckType.RunCheck
                      ? "runCreditRadioButton"
                      : "payDepositRadioButton";

                  trackEvent({
                    action: ActionType.click,
                    label,
                  });
                }}
              />
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
