import { useRhSliderStyles } from "@design-system/components/RhSlider/RhSlider.style";
import { SliderOnChangeFunction } from "@design-system/types/domEventTypes";
import { Slider, SliderProps } from "@material-ui/core";
import React, { FC } from "react";

// the reason to pull out onChange is to subtly change
// the typing.  otherwise it's pretty tedious to make TS happy
type RhSliderProps = Omit<SliderProps, "onChange"> & {
  onChange: SliderOnChangeFunction;
};

export const RhSlider: FC<RhSliderProps> = (props) => {
  const classes = useRhSliderStyles();

  return <Slider {...props} classes={classes} data-testid="RhSlider" />;
};
