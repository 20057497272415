import { Customer } from "@common/models/Customer.model";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { MyAccountMailingAddressForm } from "@portal/components/MyAccountMailingAddressForm/MyAccountMailingAddressForm";
import { useMyAccountServiceInfoStyles } from "@portal/components/MyAccountServiceInfo/MyAccountServiceInfo.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

type MyAccountServiceInfoProps = {
  customer: Customer;
};

export const MyAccountServiceInfo: FC<MyAccountServiceInfoProps> = ({
  customer,
}) => {
  const classes = useMyAccountServiceInfoStyles();
  const { t } = useRhIntl();

  const title = t("MyAccountServiceInfo.serviceInfo");
  const serviceAddress = t("MyAccountServiceInfo.serviceAddress");
  const mailingAddress = t("MyAccountServiceInfo.mailingAddress");
  const esiId = t("MyAccountServiceInfo.esiId");
  const notAvailable = t("MyAccountServiceInfo.notAvailable");

  return (
    <RhCard title={title}>
      <RhFlexBox marginBottom={2.5}>
        <div className={classes.subSection}>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {serviceAddress}
          </RhTypography>
          <RhTypography variant="body1">
            {customer.activePremiseAddress.line1}
          </RhTypography>
          <RhTypography variant="body1">
            {customer.activePremiseAddress.line2}
          </RhTypography>
        </div>
        <div className={classes.subSection}>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {esiId}
          </RhTypography>
          <RhTypography variant="body1">
            {customer.meter.esiId || notAvailable}
          </RhTypography>
        </div>
      </RhFlexBox>
      <div className={classes.subSection}>
        <RhTypography variant="subtitle2" fontWeight="Bold">
          {mailingAddress}
        </RhTypography>

        <MyAccountMailingAddressForm customer={customer} />
      </div>
    </RhCard>
  );
};
