import { api } from "@common/api/api";
import { formatMaskNumber } from "@common/forms/formatters";
import { Invoice } from "@common/models/Invoice.model";
import { fromIso8601toShorthandMonthPlusDay } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { PaymentMethod } from "@portal/models/PaymentMethod.model";
import { myAccountPath } from "@portal/routes/routePaths";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface AutoPayInfoProps {
  autopayEnabled: boolean;
  invoiceToPay: Invoice | null;
  billingPaymentMethodId?: string;
}

export const AutoPayInfo: FC<AutoPayInfoProps> = ({
  autopayEnabled,
  invoiceToPay,
  billingPaymentMethodId,
}) => {
  const { t } = useRhIntl();
  const [paymentMethodMaskNumber, setPaymentMethodMaskNumber] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (autopayEnabled && billingPaymentMethodId !== undefined) {
      api.billing.paymentMethods
        .list()
        // No need to catch error. In this case it is ok to fail silently
        .then((response) => {
          const foundPaymentMethod = response.paymentMethods.find(
            (pm) => pm.id === billingPaymentMethodId
          );

          if (foundPaymentMethod) {
            const paymentMethod = new PaymentMethod(foundPaymentMethod);

            setPaymentMethodMaskNumber(paymentMethod.maskedNumber);
          }
        });
    }
  }, [billingPaymentMethodId, autopayEnabled]);

  if (!autopayEnabled) {
    return (
      <Link
        to={{
          pathname: myAccountPath(),
          state: { anchorId: "billingPreferences" },
        }}
      >
        <RhTypography variant="body1">
          {t("account.autopayInfo.autoPaySetup")}
        </RhTypography>
      </Link>
    );
  }

  // Auto-pay in enabled and there is nothing to pay yet
  let finalMessage: string | ReactNode = t(
    "account.autopayInfo.autoPayIsEnabled"
  );

  // Auto-pay in enabled and there is something to pay
  if (invoiceToPay && invoiceToPay.balance > 0) {
    if (paymentMethodMaskNumber) {
      finalMessage = t("account.autopayInfo.autoPayIsEnabledWithDateAndCard", {
        date: fromIso8601toShorthandMonthPlusDay(invoiceToPay.dueDate),
        card: formatMaskNumber(paymentMethodMaskNumber),
      });
    } else {
      finalMessage = t("account.autopayInfo.autoPayIsEnabledWithDate", {
        date: fromIso8601toShorthandMonthPlusDay(invoiceToPay.dueDate),
      });
    }
  }

  return <RhTypography variant="body1">{finalMessage}</RhTypography>;
};
