import { Grid } from "@material-ui/core";
import { AtAGlanceSection } from "@portal/components/AtAGlanceSection/AtAGlanceSection";
import { BillingHistory } from "@portal/components/BillingHistory/BillingHistory";
import { BillingSection } from "@portal/components/BillingSection/BillingSection";
import { CustomerRewardsSection } from "@portal/components/CustomerRewardsSection/CustomerRewardsSection";
import { EnergyUsageSection } from "@portal/components/EnergyUsageSection/EnergyUsageSection";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { ReferAFriendSection } from "@portal/components/ReferAFriendSection/ReferAFriendSection";
import { useCustomer } from "@portal/hooks/useCustomer";
import { CustomerHomeNewSignupSection } from "@portal/pages/CustomerHomePage/CustomerHomeNewSignupSection/CustomerHomeNewSignupSection";
import { useCustomerHomePageStyle } from "@portal/pages/CustomerHomePage/CustomerHomePage.style";
import { selectAccountSummary } from "@portal/selectors/accountSummarySelectors";
import { accountSummaryFetch } from "@portal/slices/accountSummarySlice";
import { Show500Page } from "@portal/utils/errors";
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

export const CustomerHomePage: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const classes = useCustomerHomePageStyle();

  const {
    customer,
    requestMonitor: customerRequestMonitor,
    error: customerError,
  } = useCustomer();

  const {
    accountSummary,
    status: accountSummaryStatus,
    error: accountSummaryError,
  } = useSelector(selectAccountSummary);

  useEffect(() => {
    dispatch(accountSummaryFetch());
  }, [customer?.id, dispatch]);

  if (accountSummaryStatus.didFail || customerRequestMonitor.didFail) {
    let message = "Could not fetch account summary and customer";

    if (customerError) {
      message = `Could not fetch customer : ${customerError.data?.errorCode}`;
    } else if (accountSummaryError) {
      message = `Could not fetch account summary : ${accountSummaryError.data?.errorCode}`;
    }
    throw new Show500Page(message);
  }

  // NOTE: It is unlikely this will *ever* be true.  It does make typescript happy.
  // We discussed messaging Sentry if we ever did get here but is that even worth it?
  if (!customer || !accountSummary) {
    return null;
  }

  const pageTitle = formatMessage({
    id: "CustomerHomePage.pageTitle",
  });
  const siteTitle = formatMessage({
    id: "navigation.title.rhythm",
  });

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - ${siteTitle}`}</title>
      </Helmet>
      <PortalPageLayout>
        <Grid
          container
          spacing={3}
          direction="row"
          className={classes.gridContainer}
        >
          <Grid item xs={12} md={8} sm={8}>
            <CustomerHomeNewSignupSection />
            <div className={classes.billingSection}>
              <BillingSection
                className={classes.billingSectionSticky}
                customer={customer}
                accountSummary={accountSummary}
              />
            </div>
            <AtAGlanceSection customer={customer} />
            <CustomerRewardsSection />
            <EnergyUsageSection />
            <ReferAFriendSection />
            <BillingHistory
              accountSummary={accountSummary}
              customer={customer}
            />
          </Grid>
        </Grid>
      </PortalPageLayout>
    </>
  );
};
