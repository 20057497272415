import {
  AccountSummaryStateType,
  initialAccountSummaryState,
} from "@portal/slices/accountSummarySlice";
import {
  BillingDetailsStateType,
  initialBillingDetailsState,
} from "@portal/slices/billingDetailsSlice";
import {
  CustomerPreferencesStateType,
  initialCustomerPreferencesState,
} from "@portal/slices/customerPreferencesSlice";
import {
  CustomerStateType,
  initialCustomerState,
} from "@portal/slices/customerSlice";
import { OfferStateType, initialOfferState } from "@portal/slices/offerSlice";
import {
  RewardsStateType,
  initialRewardsState,
} from "@portal/slices/rewardsSlice";
import {
  SignUpStateType,
  initialSignUpState,
} from "@portal/slices/signUpSlice";

export interface PortalStoreState {
  accountSummary: AccountSummaryStateType;
  billingDetails: BillingDetailsStateType;
  customer: CustomerStateType;
  customerPreferences: CustomerPreferencesStateType;
  rewards: RewardsStateType;
  signUp: SignUpStateType;
  offer: OfferStateType;
}

export const initialPortalStoreState: PortalStoreState = {
  accountSummary: initialAccountSummaryState,
  billingDetails: initialBillingDetailsState,
  customer: initialCustomerState,
  customerPreferences: initialCustomerPreferencesState,
  rewards: initialRewardsState,
  signUp: initialSignUpState,
  offer: initialOfferState,
};
