import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const usePortalFooterStyles = makeStyles<
  typeof rhythmTheme,
  "root" | "signUpRoot"
>((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[50],
      borderTop: borders[200],
    },
    signUpRoot: {
      color: theme.palette.grey[400],
    },
  })
);
