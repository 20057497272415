import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useSignUpStepperStyles = makeStyles<
  typeof rhythmTheme,
  "connectorLine" | "connectorRoot" | "stepperRoot"
>(
  (theme) =>
    createStyles({
      connectorLine: {
        border: 0,
        height: 1,
      },
      connectorRoot: {
        left: `calc(-50% + ${theme.spacing(1)}px - 1px)`,
        right: `calc(50% + ${theme.spacing(1)}px - 1px)`,
        top: 7,
      },
      stepperRoot: {
        padding: 0,
      },
    }),
  { index: 1 }
);
