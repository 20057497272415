import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { PaymentMethod } from "@portal/models/PaymentMethod.model";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { createSelector } from "@reduxjs/toolkit";

export const selectBillingDetailsState = (state: PortalStoreState) => {
  const paymentMethods = state.billingDetails.data
    ? state.billingDetails.data.map((pm) => new PaymentMethod(pm))
    : null;

  return {
    data: paymentMethods,
    error: state.billingDetails.error,
    requestMonitor: new RequestMonitor(state.billingDetails.requestStatus),
  };
};

export const selectDefaultPaymentMethod = createSelector(
  selectBillingDetailsState,
  ({ data, error, requestMonitor }) => {
    const defaultPaymentMethod = data
      ? data.find((pm) => pm.defaultPaymentMethod) ?? null
      : null;

    return {
      defaultPaymentMethod,
      error,
      requestMonitor,
    };
  }
);
