import { Store } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { merge } from "lodash";

interface TimestampedState<SliceStateType> {
  state: SliceStateType;
  timestamp?: number;
}

const NAMESPACE = "redux";
const DEFAULT_EXPIRE_MINUTES = 60;

export const loadReduxSlice = <SliceStateType>(
  sliceName: string,
  initialState: SliceStateType,
  expireMinutes = DEFAULT_EXPIRE_MINUTES
): SliceStateType => {
  try {
    const serializedState =
      localStorage.getItem(`${NAMESPACE}_${sliceName}`) ?? "{}";
    const storage: TimestampedState<SliceStateType> = JSON.parse(
      serializedState
    );

    if (
      !storage.timestamp ||
      storage.timestamp < dayjs().subtract(expireMinutes, "minute").valueOf()
    ) {
      return initialState;
    }

    const loadedState = merge({}, initialState, storage.state);

    saveReduxSlice<SliceStateType>(sliceName, loadedState); // refresh expire time
    return loadedState;
  } catch (err) {
    return initialState;
  }
};

export const saveReduxSlice = <SliceStateType>(
  sliceName: string,
  state: SliceStateType
) => {
  const storage: TimestampedState<SliceStateType> = {
    state,
    timestamp: dayjs().valueOf(),
  };

  try {
    localStorage.setItem(`${NAMESPACE}_${sliceName}`, JSON.stringify(storage));
  } catch {
    // ignore write errors
  }
};

export const watchPersistedReduxSlice = <SliceStateType>(
  sliceName: string,
  store: Store
) => {
  let memo: SliceStateType = store.getState()[sliceName];

  store.subscribe(() => {
    const sliceState: SliceStateType = store.getState()[sliceName];

    if (sliceState !== memo) {
      memo = sliceState;
      saveReduxSlice<SliceStateType>(sliceName, sliceState);
    }
  });
};
