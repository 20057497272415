import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core/styles";

export const useSignUpBackButtonStyles = rhMakeStyles<
  Theme,
  "tabletDownButton"
>((theme) =>
  createStyles({
    tabletDownButton: {
      color: theme.palette.text.secondary,
      fontWeight: FontWeight.Regular,
    },
  })
);
