import { useRhAnnouncementStyles } from "@design-system/components/RhAnnouncement/RhAnnouncement.style";
import classNames from "classnames";
import React, { FC } from "react";

export enum AnnouncementSeverity {
  Notice = "notice",
  Error = "error",
}

interface RhAnnouncementProps {
  severity: AnnouncementSeverity;
  className?: string;
}

export const RhAnnouncement: FC<RhAnnouncementProps> = ({
  children,
  severity,
  className = "",
}) => {
  const classes = useRhAnnouncementStyles();

  return (
    <div className={classNames(classes.root, className, classes[severity])}>
      {children}
    </div>
  );
};
