import { useGetKwhUsage } from "@common/api/customerApi";
import {
  GetKwhUsageResponseType,
  UsageSummary,
} from "@common/api/customerResponseTypes";
import { CUSTOMER_USAGE_NOT_FOUND_ERROR } from "@common/types/errorTypes";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { EnergyUsageChart } from "@portal/components/EnergyUsageChart/EnergyUsageChart";
import { EnergyUsageChartMissing } from "@portal/components/EnergyUsageChart/EnergyUsageChartMissing";
import { EnergyUsageRow } from "@portal/components/EnergyUsageRow/EnergyUsageRow";
import { useEnergyUsageSectionStyles } from "@portal/components/EnergyUsageSection/EnergyUsageSection.style";
import { useCustomer } from "@portal/hooks/useCustomer";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC, useEffect, useState } from "react";

const hasUsageData = (data: GetKwhUsageResponseType) => {
  return (
    data.billUsage.length ||
    data.sevenDaysUsage.length ||
    data.thirtyDaysUsage.length
  );
};

export const EnergyUsageSection: FC = () => {
  const { t } = useRhIntl();
  const { customer } = useCustomer();

  const currentCustomerId = customer?.id ?? "";
  const { data, requestMonitor, error } = useGetKwhUsage(currentCustomerId);
  const [showZeroUsage, setShowZeroUsage] = useState(false);

  const classes = useEnergyUsageSectionStyles();

  const title = t("EnergyUsageSection.title");

  useEffect(() => {
    if (error?.data.errorCode === CUSTOMER_USAGE_NOT_FOUND_ERROR) {
      setShowZeroUsage(true);
    }
  }, [error]);

  if (showZeroUsage) {
    const zeroUsageSummary: UsageSummary = { kwh: null, cost: null };

    return (
      <RhCard title={title}>
        <EnergyUsageRow
          title={t("EnergyUsageSection.usageThisCycle")}
          summary={zeroUsageSummary}
          bold
        />
        <EnergyUsageRow
          title={t("EnergyUsageSection.forecastThisCycle")}
          summary={zeroUsageSummary}
        />
        <EnergyUsageChartMissing />
      </RhCard>
    );
  }

  if (!data || error) {
    return (
      <RhCard title={title}>
        {requestMonitor.isPending && <RhCircularProgress />}
      </RhCard>
    );
  }

  const { billUsageSummary, billForecastSummary } = data;

  return (
    <RhCard
      title={title}
      className={classes.card}
      headerClassName={classes.cardTitle}
    >
      <EnergyUsageRow
        title={t("EnergyUsageSection.usageThisCycle")}
        summary={billUsageSummary}
        bold
      />
      <EnergyUsageRow
        title={t("EnergyUsageSection.forecastThisCycle")}
        summary={billForecastSummary}
      />

      {hasUsageData(data) ? (
        <EnergyUsageChart rawChartData={data} />
      ) : (
        <EnergyUsageChartMissing />
      )}
    </RhCard>
  );
};
