import { IdType } from "@common/types/apiTypes";

export enum PaymentMethodTypes {
  CreditCard = "CreditCard",
  ACH = "ACH",
}

export interface CreditCardRetrieveType {
  type: PaymentMethodTypes.CreditCard;
  creditCardMaskNumber: string;
  creditCardType: string;
}

export interface ACHRetrieveType {
  type: PaymentMethodTypes.ACH;
  achAccountNumberMask: string;
  achBankName: string;
}

export type PaymentMethodRetrieveType =
  | CreditCardRetrieveType
  | ACHRetrieveType;

export interface CreditCardType {
  creditCardExpirationMonth: number;
  creditCardExpirationYear: number;
  creditCardMaskNumber: string;
  creditCardType: string;
  id: string;
  type: PaymentMethodTypes.CreditCard;
  defaultPaymentMethod: boolean;
}

export interface ACHType {
  achAccountName: string;
  achAccountNumberMask: string;
  achAccountType: string;
  achBankName: string;
  id: string;
  type: PaymentMethodTypes.ACH;
  defaultPaymentMethod: boolean;
}

export type PaymentMethodType = CreditCardType | ACHType;

export interface PaymentMethodsResponseType {
  paymentMethods: PaymentMethodType[];
}

export interface UpdatePaymentMethodType {
  customerId: IdType;
}

export type PlaidPublicAccountTokenType = {
  plaidPublicToken: string;
  plaidAccountId: string;
};

export type GeneratedInvoiceType = {
  id: IdType;
  customerId: IdType;
  zuoraInvoiceId: string;
  mailed: boolean;
  pdfRendered: boolean;
  autoPay: boolean;
  lobLetterId: string;
  originalJsonInputs: string;
  opsJsonInputs: string;
  createdAt: string;
  updatedAt: string;
};

export type GeneratedInvoiceResponseType = GeneratedInvoiceType[];
