import { PaymentMethodType } from "@common/types/paymentMethodTypes";

export class PaymentMethod {
  constructor(private paymentMethod: PaymentMethodType) {}

  get achType() {
    let achType: null | string = null;

    if (this.paymentMethod.type === "ACH") {
      achType = this.paymentMethod.achAccountType;
    }

    return achType;
  }

  get bankAccountName() {
    let bankAccountName: null | string = null;

    if (this.paymentMethod.type === "ACH") {
      bankAccountName = this.paymentMethod.achAccountName;
    }

    return bankAccountName;
  }

  get bankName() {
    let bankName: null | string = null;

    if (this.paymentMethod.type === "ACH") {
      bankName = this.paymentMethod.achBankName;
    }

    return bankName;
  }

  get defaultPaymentMethod() {
    return this.paymentMethod.defaultPaymentMethod;
  }

  get secondaryInfo() {
    return this.expiration || this.bankAccountName;
  }

  get expiration() {
    let expiration: null | string = null;

    if (this.paymentMethod.type === "CreditCard") {
      const yearString = `${this.paymentMethod.creditCardExpirationYear}`;
      const lastTwo: string = yearString.substr(yearString.length - 2);

      expiration = `${this.paymentMethod.creditCardExpirationMonth}/${lastTwo}`;
    }

    return expiration;
  }

  get id() {
    return this.paymentMethod.id;
  }

  get maskedNumber() {
    return this.paymentMethod.type === "ACH"
      ? this.paymentMethod.achAccountNumberMask
      : this.paymentMethod.creditCardMaskNumber;
  }

  get type() {
    return this.paymentMethod.type;
  }

  get companyName() {
    return this.paymentMethod.type === "ACH"
      ? this.paymentMethod.achBankName
      : this.paymentMethod.creditCardType;
  }
}
