import { AjaxStatus, AjaxStatuses } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RewardsType } from "@common/types/rewardsTypes";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { userLoggedOut } from "@portal/slices/authenticationSlice";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import merge from "lodash/merge";

export type RewardsStateType = {
  rewards: RewardsType | null;
  error: RhApiError | null;
  requestStatus: AjaxStatus;
};

export const initialRewardsState = Object.freeze<RewardsStateType>({
  rewards: null,
  error: null,
  requestStatus: AjaxStatuses.Idle,
});

export interface RewardsRedeemedActionPayload {
  newRewardsBalance: number;
  amountUsed: number;
}

// Handlers
export const rewardsUpdatedHandler: CaseReducer<
  RewardsStateType,
  PayloadAction<Partial<RewardsType>>
> = (state, action) => ({
  error: state.error,
  requestStatus: state.requestStatus,
  rewards: merge({}, state.rewards, action.payload),
});

export const rewardsRedeemedHandler: CaseReducer<
  RewardsStateType,
  PayloadAction<RewardsRedeemedActionPayload>
> = (state, { payload }) => {
  if (!state.rewards) {
    return state;
  }

  return {
    ...state,
    rewards: {
      balance: payload.newRewardsBalance,
      value: rewardPointsToDollars(payload.newRewardsBalance),
      lifetime: state.rewards.lifetime,
      referredCustomers: state.rewards.referredCustomers,
      history: state.rewards.history,
    },
  };
};

export const rewardsRequestedHandler: CaseReducer<
  RewardsStateType,
  PayloadAction
> = (state) => ({
  ...state,
  requestStatus: AjaxStatuses.Pending,
});

export const rewardsReceivedHandler: CaseReducer<
  RewardsStateType,
  PayloadAction<RewardsType>
> = (state, action) => ({
  requestStatus: AjaxStatuses.Success,
  rewards: action.payload,
  error: null,
});

export const rewardsErroredHandler: CaseReducer<
  RewardsStateType,
  PayloadAction<RhApiError>
> = (state, action) => ({
  requestStatus: AjaxStatuses.Failure,
  error: action.payload,
  rewards: state.rewards,
});

export const customerLoggedOutHandler: CaseReducer<
  RewardsStateType,
  PayloadAction
> = (_state) => ({ ...initialRewardsState });

const rewardsReducers = {
  rewardsRedeemed: rewardsRedeemedHandler,
  updated: rewardsUpdatedHandler,
  requested: rewardsRequestedHandler,
  errored: rewardsErroredHandler,
  received: rewardsReceivedHandler,
};

const rewardsSlice = createSlice<RewardsStateType, typeof rewardsReducers>({
  name: "rewards",
  initialState: initialRewardsState,
  reducers: rewardsReducers,
  extraReducers: {
    [userLoggedOut.type]: customerLoggedOutHandler,
  },
});

export const rewardsReducer = rewardsSlice.reducer;
export const { rewardsRedeemed } = rewardsSlice.actions;
export const rewardsRequested = rewardsSlice.actions.requested;
export const rewardsReceived = rewardsSlice.actions.received;
export const rewardsErrored = rewardsSlice.actions.errored;
export const rewardsUpdated = rewardsSlice.actions.updated;

export const rewardsRefetch = createAction(`${rewardsSlice.name}/refetch`);
