import { UsageSummary } from "@common/api/customerResponseTypes";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { Grid } from "@material-ui/core";
import { useEnergyRowSectionStyles } from "@portal/components/EnergyUsageRow/EnergyUsageRow.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

interface EnergyUsageRowProps {
  title: string;
  summary: UsageSummary;
  bold?: boolean;
}

export const EnergyUsageRow: FC<EnergyUsageRowProps> = ({
  title,
  summary,
  bold = false,
}) => {
  const classes = useEnergyRowSectionStyles({ bold });

  const { t } = useRhIntl();
  const fontWeight = bold ? FontWeight.Bold : FontWeight.Regular;

  const hasUsage = summary.kwh !== null;
  const usageToDateKWH = summary.kwh === null ? "" : formatKwh(summary.kwh, 0);
  const usageToDateCharge =
    summary.cost === null
      ? t("EnergyUsageSection.notApplicable")
      : formatCurrency(summary.cost);

  return (
    <Grid container className={classes.usageRow}>
      <Grid item xs={6}>
        <RhTypography noWrap variant="body2" fontWeight={fontWeight}>
          {title}
        </RhTypography>
      </Grid>
      {hasUsage && (
        <Grid item xs={3}>
          <RhTypography variant="body2" fontWeight={fontWeight} align="right">
            {usageToDateKWH}
          </RhTypography>
        </Grid>
      )}
      <Grid item xs={hasUsage ? 3 : 6}>
        <RhTypography variant="body2" fontWeight={fontWeight} align="right">
          {usageToDateCharge}
        </RhTypography>
      </Grid>
    </Grid>
  );
};
