import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { trueWhite } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useMyAccountPageStyles = makeStyles<
  typeof rhythmTheme,
  { isTablet?: boolean; isPhone?: boolean; isSmallDesktop?: boolean },
  | "accountNavContainer"
  | "accountNavFlexContainer"
  | "backToTopButtonContainer"
  | "gridContainer"
  | "header"
  | "links"
  | "myAccountContainer"
  | "upArrow"
>((theme) =>
  createStyles({
    accountNavContainer: {
      position: "fixed",
      top: theme.spacing(20),
      width: "inherit",
    },
    accountNavFlexContainer: ({ isTablet }) => ({
      marginRight: isTablet ? 10 : 23,
      width: isTablet ? 100 : 230,
    }),
    backToTopButtonContainer: {
      alignSelf: "center",
      backgroundColor: trueWhite,
      borderTop: borders[100],
      bottom: 0,
      display: "flex",
      height: "64px",
      justifyContent: "center",
      left: 0,
      position: "fixed",
      width: "100vw",
      zIndex: 2,
    },
    gridContainer: {
      [theme.breakpoints.only("xs")]: {
        "& > .MuiGrid-item": {
          paddingBottom: 0,
        },
      },
    },
    header: {
      borderBottom: borders[100],
      marginBottom: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
    links: {
      [theme.breakpoints.only("xs")]: {
        borderBottom: borders[100],
      },
    },
    myAccountContainer: {
      width: ({ isSmallDesktop, isTablet, isPhone }) => {
        if (!isSmallDesktop && !isTablet && !isPhone) {
          return 812;
        }
        return isSmallDesktop ? 480 : 375;
      },
    },
    upArrow: {
      margin: "0 8px 5px 0",
    },
  })
);
