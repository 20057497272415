import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core";

export const useMonthlyUsageSliderStyles = makeStyles<
  Theme,
  "root" | "descriptionContainer" | "descriptionImage" | "list"
>((theme) =>
  createStyles({
    descriptionContainer: {
      flexDirection: "column",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
    },
    descriptionImage: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(2),
      },
    },
    list: {
      "& li": {
        "& p": {
          color: theme.palette.grey["600"],
          display: "inline-block",
          verticalAlign: "top",
          width: "93%",
        },
        "&::before": {
          // This bullet is more inline with what design wants (as opposed to the
          // default list style bullet which is too big)
          content: '"•"',
          display: "inline-block",
          marginTop: 1,
          paddingRight: 5,
          verticalAlign: "top",
        },
        marginBottom: 2,
      },
    },
    root: {
      marginBottom: theme.spacing(7),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 500,
      },
    },
  })
);
