import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { ChevronDown, MapPinIcon } from "@design-system/icons";
import { Backdrop, Box, Menu, MenuItem } from "@material-ui/core";
import { useAccountMenuStyles } from "@portal/components/PortalHeader/AccountMenu/AccountMenu.style";
import classnames from "classnames";
import React, { Children, FC, useState } from "react";
import { useIntl } from "react-intl";

const POPUP_ANCHOR_ID = "AccountMenu__PopupAnchor";

interface AccountMenuProps {
  linkClass?: string;
  serviceAddress: string | null | undefined;
}

export const AccountMenu: FC<AccountMenuProps> = ({
  linkClass,
  serviceAddress,
  children,
}) => {
  const classes = useAccountMenuStyles();
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<
    HTMLAnchorElement | HTMLElement | null
  >(null);

  const openMenu = () => {
    setAnchorEl(document.getElementById(POPUP_ANCHOR_ID));
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const menuItems = Children.map(
    children,
    (child, index) =>
      Boolean(child) && (
        <MenuItem
          className={classes.menuItem}
          // eslint-disable-next-line react/no-array-index-key
          key={`AccountMenu__MenuItem-${index}`}
          onClick={() => setAnchorEl(null)}
        >
          <Box className={classes.menuItemSpacer}>{child}</Box>
        </MenuItem>
      )
  );

  const addressLoading = serviceAddress === null;

  return (
    <>
      <Backdrop
        data-testid="RhHeader__Backdrop"
        className={classes.backdrop}
        open={Boolean(anchorEl)}
        style={{
          opacity: 0.25,
        }}
        onClick={closeMenu}
      />

      <Box
        aria-controls="PortalHeader__AccountMenu"
        aria-haspopup="true"
        key="accountMenuLink"
        className={`${classes.clickableContainer} ${linkClass}`}
        onClick={openMenu}
      >
        <RhFlexBox
          className={classnames([
            classes.menuCtaContainer,
            { [classes.addressLoading]: addressLoading },
          ])}
        >
          {serviceAddress && (
            <Box className={classes.mapPinIconContainer}>
              <MapPinIcon
                title={formatMessage({ id: "PortalHeader.mapPinIcon" })}
              />
            </Box>
          )}
          <Box>
            {serviceAddress ?? formatMessage({ id: "PortalHeader.myAccount" })}
          </Box>
          <Box className={classes.chevronIconContainer}>
            <ChevronDown className={classes.chevronIcon} />
          </Box>
        </RhFlexBox>
        <span id={POPUP_ANCHOR_ID} className={classes.popupAnchor} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="PortalHeader__AccountMenu"
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        onClose={closeMenu}
        elevation={1}
        disableAutoFocusItem
      >
        {menuItems}
      </Menu>
    </>
  );
};
