import { scrollToOffsetPosition } from "@common/utils/scrollToOffsetPosition";
import { RightChevron } from "@design-system/icons";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useMyAccountNavStyles } from "@portal/components/MyAccountNav/MyAccountNav.style";
import { MyAccountNavItem } from "@portal/components/MyAccountNav/MyAccountNavItem/MyAccountNavItem";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { signOutPath } from "@portal/routes/routePaths";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

interface MyAccountNavItemValue {
  anchorTag: string;
  translationId: string;
}

export enum AccountNavAnchorTag {
  serviceInfo = "serviceInfo",
  yourPlan = "yourPlan",
  contactInfo = "contactInfo",
  languagePreference = "languagePreference",
  changePassword = "changePassword",
  billingPreferences = "billingPreferences",
  paymentMethods = "paymentMethods",
}

const myAccountNavItemValues: MyAccountNavItemValue[] = [
  {
    anchorTag: `#${AccountNavAnchorTag.serviceInfo}`,
    translationId: "MyAccountNav.serviceInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.yourPlan}`,
    translationId: "MyAccountNav.yourPlan",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.contactInfo}`,
    translationId: "MyAccountNav.contactInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.languagePreference}`,
    translationId: "MyAccountNav.languagePreference",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.changePassword}`,
    translationId: "MyAccountNav.changePassword",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.billingPreferences}`,
    translationId: "MyAccountNav.billingPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.paymentMethods}`,
    translationId: "MyAccountNav.paymentMethods",
  },
];

export const MyAccountNav: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { t } = useRhIntl();
  const classes = useMyAccountNavStyles();

  return (
    <div role="navigation">
      <ul className={classes.navContainer} data-testid="myAccount__nav">
        {myAccountNavItemValues.map(({ anchorTag, translationId }) => {
          const title = t(translationId);

          return (
            <MyAccountNavItem className={classes.linkContainer} key={anchorTag}>
              <NavHashLink
                className={classes.linkRow}
                to={anchorTag}
                onClick={(event) => event.preventDefault()}
                scroll={scrollToOffsetPosition}
              >
                {title}
                {isMobile && <RightChevron title={title} />}
              </NavHashLink>
            </MyAccountNavItem>
          );
        })}
        <MyAccountNavItem className={classes.linkContainer}>
          <Link to={signOutPath()} className={classes.linkRow}>
            {t("MyAccountNav.logout")}
            {isMobile && <RightChevron title={t("MyAccountNav.logout")} />}
          </Link>
        </MyAccountNavItem>
      </ul>
    </div>
  );
};
