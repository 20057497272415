import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { Grid } from "@material-ui/core";
import { MyAccountLegalDocuments } from "@portal/components/MyAccountYourPlanCard/MyAccountLegalDocuments";
import { MyAccountOfferCard } from "@portal/components/MyAccountYourPlanCard/MyAccountOfferCard";
import { useCustomer } from "@portal/hooks/useCustomer";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

export const MyAccountYourPlanCard: FC = () => {
  const { customer } = useCustomer();
  const { t } = useRhIntl();

  if (!customer) {
    return (
      <RhCard>
        <RhCircularProgress />
      </RhCard>
    );
  }

  const title = t("MyAccountYourPlanCard.yourPlan");

  return (
    <RhCard title={title}>
      {customer.hasOrder && (
        <Grid container>
          <Grid item sm={12} md={6}>
            <MyAccountOfferCard order={customer.currentOrder} />
          </Grid>

          <Grid item sm={12} md={6}>
            <MyAccountLegalDocuments
              offerId={customer.currentOrder.offersnapshotId}
            />
          </Grid>
        </Grid>
      )}
    </RhCard>
  );
};
