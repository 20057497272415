import { Customer } from "@common/models/Customer.model";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { AtAGlanceCarousel } from "@portal/components/AtAGlanceCarousel/AtAGlanceCarousel";
import { useAtAGlanceSectionStyles } from "@portal/components/AtAGlanceSection/AtAGlanceSection.styles";
import { useElementWidth } from "@portal/hooks/useElementWidth";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC, useRef, useState } from "react";

interface AtAGlanceSectionProps {
  customer: Customer;
}

export const AtAGlanceSection: FC<AtAGlanceSectionProps> = ({ customer }) => {
  const { t } = useRhIntl();
  const [scrollEnd, setScrollEnd] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const cardRef = useRef<HTMLDivElement | null>(null);
  const { refWidth: cardWidth } = useElementWidth(cardRef);
  const classes = useAtAGlanceSectionStyles();
  const title = t("AtAGlanceSection.atAGlance");
  const onScrollEnd = isMobile ? { onScrollEnd: setScrollEnd } : {};

  return (
    <RhCard ref={cardRef} title={title} className={classes.container}>
      <AtAGlanceCarousel
        pickedBillingDueDate={Boolean(customer.pickedBillingDueDay)}
        optedInEBill={customer.invoiceByEmailOnly}
        {...onScrollEnd}
        containerWidth={cardWidth}
      />
      {isMobile && !scrollEnd && <Box className={classes.fade} />}
    </RhCard>
  );
};
