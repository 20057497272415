import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

interface AveragedBillingDialogProps {
  averagedBillingMonthlyCharge?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const AveragedBillingDialog: FC<AveragedBillingDialogProps> = ({
  averagedBillingMonthlyCharge,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useRhIntl();

  const title = t("averagedBillingDialog.enroll");
  const enrollCta = t("rhythm.input.enroll");
  const cancel = t("rhythm.input.cancel");
  const estimatedMonthlyAverage = t(
    "averagedBillingDialog.estimatedMonthlyAverage",
    {
      amount: () => <strong>${averagedBillingMonthlyCharge}</strong>,
    }
  );

  return (
    <RhDialog open={isOpen} onClose={onClose} size="medium">
      <RhDialogTitle>
        <RhFlexBox marginBottom={1} justifyContent="center" width="100%">
          <RhTypography variant="h2">{title}</RhTypography>
        </RhFlexBox>
        <RhFlexBox alignItems="center" justifyContent="center">
          <RhTypography color="textSecondary" variant="body1">
            {estimatedMonthlyAverage}
          </RhTypography>
        </RhFlexBox>
      </RhDialogTitle>
      <RhDialogContentWithButtons
        primaryButton={{
          label: enrollCta,
          onClick: onSubmit,
        }}
        secondaryButton={{
          label: cancel,
          onClick: onClose,
        }}
      />
    </RhDialog>
  );
};
