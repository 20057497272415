import { addressStates } from "@common/constants/addressStates.constant";
import { RhSelectOption } from "@design-system/components/RhSelect/RhSelect";
import { RhSelectField } from "@design-system/components/RhSelectField/RhSelectField";
import { SelectProps } from "@material-ui/core";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import sortBy from "lodash/sortBy";
import React from "react";

interface StateSelectFieldProps {
  name: string;
  translationId: string;
  selectProps?: SelectProps;
}

const STATE_OPTIONS = sortBy(
  addressStates.map(
    (state) =>
      ({
        label: state.name,
        value: state.abbreviation,
      } as RhSelectOption)
  ),
  "label"
);

export const StateSelectField = ({
  name,
  translationId,
  selectProps,
}: StateSelectFieldProps) => {
  const { t } = useRhIntl();
  const placeholder = t(translationId);

  return (
    <RhSelectField
      label={placeholder}
      name={name}
      options={STATE_OPTIONS}
      selectProps={selectProps}
    />
  );
};
