import queryString from "querystring";

import { rhWindow } from "@common/utils/rhWindow";
import { RHYTHM_MARKETING_URL } from "@portal/constants/urls.constant";
import { UTM_RAF } from "@portal/constants/utm.constant";

export const SIGN_IN_PATHNAME = "sign-in";
export const SIGN_OUT_PATHNAME = "sign-out";
export const SIGN_UP_PATHNAME = "sign-up";
export const MY_ACCOUNT_PATHNAME = "account";
export const NOTIFICATIONS_PATHNAME = "notifications";
export const REWARDS_PATHNAME = "rewards";
export const PAY_BILL_PATHNAME = "pay-bill";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset-password";
// Not really a path but cannot think of a better place to put this url in
export const FAQ_HOME_URL = "https://support.gotrhythm.com/hc";
export const REWARDS_FAQ_URL = `${FAQ_HOME_URL}/en-us/articles/360052366771-Rhythm-Rewards`;

export const HOME = "home";
export const WELCOME_PATHNAME = "welcome";
export const CREATE_PASSWORD = "create-password";
export const EXPIRED_PASSWORD = "expired-password";
export const CSR_SUCCESS = "csr-success";

export const REFERRAL_CODE_QUERY_PARAM = "referralCode";

export const SignUpSteps = [
  "address",
  "availability",
  "availability-address",
  "billing-preferences",
  "call-us",
  "choose-utility",
  "confirm-address",
  "contact",
  "credit-check",
  "deposit",
  "details",
  "name",
  "payment",
  "ivr-payment",
  "plans",
  "summary",
  "updates",
] as const;
export type SignUpStepType = typeof SignUpSteps[number];

const addDomain = (path: string): string =>
  `${rhWindow.location.origin}${path}`;

export const signInPath = () => {
  return `/${SIGN_IN_PATHNAME}`;
};

export const signOutPath = () => {
  return `/${SIGN_OUT_PATHNAME}`;
};

export const signUpBasePath = () => {
  return `/${SIGN_UP_PATHNAME}`;
};

export const signUpUrl = () => {
  return addDomain(signUpBasePath());
};

export const signUpReferralUrl = (referralCode: string) => {
  const queryParams = {
    [REFERRAL_CODE_QUERY_PARAM]: referralCode,
    utm_medium: UTM_RAF,
  };

  return `${RHYTHM_MARKETING_URL}?${queryString.stringify(queryParams)}`;
};

export const signUpStepPath = (step: SignUpStepType) => {
  return `${signUpBasePath()}/${step}`;
};

export const customerHomePath = () => {
  return `/${HOME}`;
};

export const myAccountPath = () => {
  return `/${MY_ACCOUNT_PATHNAME}`;
};

export const payBillPath = () => {
  return `/${PAY_BILL_PATHNAME}`;
};

export const rewardsPath = () => {
  return `/${REWARDS_PATHNAME}`;
};

export const notificationsPath = () => {
  return `/${NOTIFICATIONS_PATHNAME}`;
};

export const faqUrl = (subPage?: string) => {
  // For now, faq is only in english for US, once we add spanish, we need to change the hard coded en-us
  const localeUri = "en-us";
  const base = `${FAQ_HOME_URL}/${localeUri}`;

  if (subPage) {
    return `${base}/${subPage}`;
  }
  return base;
};

export const payBillSuccessPath = () => {
  return `/${PAY_BILL_PATHNAME}/success`;
};

export const activatePath = (activationToken: string) => {
  return `/${WELCOME_PATHNAME}/${activationToken}`;
};

export const createPasswordPath = () => {
  return `/${CREATE_PASSWORD}`;
};

export const signUpCSRSuccessPath = () => {
  return `/${CSR_SUCCESS}`;
};

export const expiredPasswordPath = () => {
  return `/${EXPIRED_PASSWORD}`;
};

export const forgotPasswordPath = () => {
  return `/${FORGOT_PASSWORD}`;
};

export const resetPasswordPath = (recoveryToken: string) => {
  return `/${RESET_PASSWORD}/${recoveryToken}`;
};

export const homePath = (): string => {
  return "/";
};

export const unauthorizedPath = (): string => {
  return "/unauthorized";
};

export const loginCallbackPath = (): string => {
  return "/implicit/callback";
};
