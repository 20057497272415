import {
  RhSubmitButton,
  RhSubmitButtonProps,
} from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useLoggedOutFormStyles } from "@portal/components/LoggedOutForm/LoggedOutForm.style";
import { PortalSubmitButton } from "@portal/components/PortalSubmitButton/PortalSubmitButton";
import React, { FC, PropsWithChildren } from "react";

interface SignUpFormPropsType
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  submitButtonText: string;
  showProgress?: boolean;
}

export const LoggedOutForm: FC<SignUpFormPropsType> = ({
  submitButtonText,
  showProgress,
  children,
  ...formProps
}) => {
  const classes = useLoggedOutFormStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const submitButtonProps: PropsWithChildren<RhSubmitButtonProps> = {
    fullWidth: true,
    size: isMobile ? "medium" : "large",
    children: submitButtonText,
  };

  return (
    <form
      noValidate
      {...formProps}
      className={`${formProps.className} ${classes.form}`}
    >
      {children}

      {showProgress ? (
        <PortalSubmitButton {...submitButtonProps} />
      ) : (
        <RhSubmitButton {...submitButtonProps} />
      )}
    </form>
  );
};
