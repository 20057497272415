import { urls } from "@common/api/urls";
import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { Customer } from "@common/models/Customer.model";
import { Invoice } from "@common/models/Invoice.model";
import { RhApiError } from "@common/types/errorTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { noop } from "@common/utils/genericFunctions";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Link } from "@material-ui/core";
import { useBillInvoicesStyles } from "@portal/components/BillInvoices/BillInvoices.style";
import { InvoicesRow } from "@portal/components/InvoicesRow/InvoicesRow";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { authenticatedFileDownload } from "@portal/utils/authenticatedFileDownload";
import { buildInvoiceFilename } from "@portal/utils/invoiceDownloadHelpers";
import React, { FC, MouseEventHandler } from "react";

interface BillInvoicesProps {
  invoices: Invoice[];
  customer: Customer;
}

export const BillInvoices: FC<BillInvoicesProps> = ({ customer, invoices }) => {
  const { t } = useRhIntl();
  const flash = useRhFlash();
  const classes = useBillInvoicesStyles();

  if (invoices.length === 0) {
    return null;
  }

  const invoicesWithAmountDue = invoices.filter(
    (invoice) => invoice.balance > 0
  );

  const handleDownload = (invoice: Invoice) => {
    return (() => {
      const url = urls.customers.invoice(customer.id, invoice.id);
      const filename = buildInvoiceFilename(customer, invoice);

      authenticatedFileDownload(url, filename, PDF_MIME_TYPE)
        .then(noop)
        .catch((error: RhApiError) => {
          flash.error(t("BillInvoices.errorViewingPDF"));
        });
    }) as MouseEventHandler;
  };

  return (
    <RhFlexBox data-testid="billInvoices__container" flexDirection="column">
      {invoicesWithAmountDue.map((invoice) => (
        <InvoicesRow key={invoice.id} classes={classes.invoiceRow}>
          <RhTypography variant="body1" component="div">
            {invoice.formattedInvoiceDate}
            {invoice.lateFeeApplied ? (
              <RhTypography
                component="div"
                variant="body2"
                className={classes.lateFeeMessage}
              >
                {t("BillingHistory.lateFeesIncluded")}
              </RhTypography>
            ) : null}
            {invoice.rewardCreditApplied > 0 ? (
              <RhTypography
                component="div"
                variant="body2"
                className={classes.lateFeeMessage}
              >
                {t("BillingHistory.rewardCreditIncluded")}
              </RhTypography>
            ) : null}
          </RhTypography>
          <Link
            rel="noopener noreferrer"
            onClick={handleDownload(invoice)}
            target="_self"
          >
            <RhTypography variant="body1" color="textSecondary">
              {t("BillInvoices.viewPdf")}
            </RhTypography>
          </Link>
          <RhTypography variant="body1">
            {formatCurrency(invoice.balance)}
          </RhTypography>
        </InvoicesRow>
      ))}
    </RhFlexBox>
  );
};
