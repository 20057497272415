import { useRhRoundTabItemStyles } from "@design-system/components/RhRoundTabItem/RhRoundTabItem.style";
import { Tab } from "@material-ui/core";
import React, { FC, MouseEventHandler } from "react";

interface RhRoundTabItemProps {
  className?: string;
  label: string;
  onClick?: MouseEventHandler;
}

export const RhRoundTabItem: FC<RhRoundTabItemProps> = ({
  className = "",
  label,
  onClick,
}): JSX.Element => {
  const classes = useRhRoundTabItemStyles();

  return (
    <Tab
      classes={classes}
      className={className}
      label={label}
      onClick={onClick}
    />
  );
};
