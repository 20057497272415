import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core/styles";

export const useRhSliderStyles = makeStyles<Theme>(
  ({ palette }) =>
    createStyles({
      mark: {
        backgroundColor: palette.primary["700"],
        height: 6,
        marginTop: -2,
        width: 1,
      },
      markActive: {
        backgroundColor: palette.primary["700"],
        opacity: 1,
      },
      markLabelActive: {
        color: palette.grey["500"],
      },
      rail: {
        border: `1px solid ${palette.primary["700"]}`,
        color: palette.primary["700"],
        height: 0,
      },
      thumb: {
        height: 16,
        marginTop: -7,
        width: 16,
      },
      track: {
        backgroundColor: "transparent",
        color: palette.primary["700"],
      },
      valueLabel: {
        "& *": {
          background: "transparent",
          color: palette.grey["600"],
        },
        left: "calc(-50% + 12px)",
        top: -22,
      },
    }),
  { index: 1 }
);
