import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { useInvoicesRowStyle } from "@portal/components/InvoicesRow/InvoicesRow.style";
import classNames from "classnames";
import React, { Children, FC, ReactElement } from "react";

interface InvoicesRowProps {
  classes?: string;
}

export const InvoicesRow: FC<InvoicesRowProps> = ({
  classes: extraClasses,
  children,
}) => {
  const classes = useInvoicesRowStyle();

  const wrappedInvoicesRowElement = (child: ReactElement, index: number) => (
    <>
      {React.cloneElement(child, {
        className: classNames(child.props.className, classes.column),
        index,
      })}
    </>
  );

  return (
    <RhFlexBox className={classNames(classes.root, extraClasses)}>
      {Children.map(
        children,
        (child, index) =>
          Boolean(child) &&
          wrappedInvoicesRowElement(child as ReactElement, index)
      )}
    </RhFlexBox>
  );
};
