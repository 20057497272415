import { api } from "@common/api/api";
import {
  ACCEPTABLE_CREDIT_SCORE_OUTCOMES,
  CreditScoreDataType,
} from "@common/types/creditCheckTypes";
import { AddressFormType } from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhPaper } from "@design-system/components/RhPaper/RhPaper";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useSignUpConfirmAddressPageStyles } from "@portal/pages/SignUpConfirmAddressPage/SignUpConfirmAddressPage.style";
import {
  selectCreditCheckContactValues,
  selectCreditCheckValues,
  selectProspectId,
} from "@portal/selectors/signUpSelectors";
import { FORM_ERROR } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

const ServiceAddress: FC<AddressFormType> = ({
  addressLine1,
  unitNumber,
  city,
  state,
  zipCode,
}) => (
  <RhPaper>
    <RhTypography variant="subtitle1" color="textSecondary">
      {addressLine1}
      {unitNumber ? ` ${unitNumber}` : ""}
      <br />
      {city}, {state}
      <br />
      {zipCode}
    </RhTypography>
  </RhPaper>
);

export const SignUpConfirmAddressPage: FC = () => {
  const { t } = useRhIntl();
  const classes = useSignUpConfirmAddressPageStyles();
  const creditCheckValues = useSelector(selectCreditCheckValues);
  const { email } = useSelector(selectCreditCheckContactValues);
  const prospectId = useSelector(selectProspectId);
  const flash = useRhFlash();

  const { signUpClickNextStepHandler } = useSignUpFlow();

  const onAddressConfirmation = (values: CreditScoreDataType) => {
    return api.prospects
      .creditScoreEvaluation({ ...values, prospectId })
      .then(({ depositAmount, outcome, ssnRequired }) => {
        const nextStep = ACCEPTABLE_CREDIT_SCORE_OUTCOMES.has(outcome)
          ? "details"
          : "call-us";

        return api.prospects.trackEnteredSignup(prospectId, email).then(() => {
          signUpClickNextStepHandler({
            signUpData: {
              depositAmount,
              ssnRequired,
              creditEvaluation: outcome,
            },
            nextStep,
            track: true,
          });
        });
      })
      .catch((_error: RhApiError) => {
        const errorMessage = t("SignUpConfirmAddressPage.errorCreditScoreApi");

        flash.error(errorMessage);

        return { [FORM_ERROR]: errorMessage };
      });
  };

  const serviceAddressProps: AddressFormType = {
    addressLine1: creditCheckValues.addressLine1,
    unitNumber: creditCheckValues.unitNumber,
    city: creditCheckValues.city,
    state: creditCheckValues.state,
    zipCode: creditCheckValues.zipCode,
  };
  const confirm = t("SignUpConfirmAddressPage.confirm");

  return (
    <SignUpPageLayout>
      <Box paddingBottom={LOGGED_OUT_CONTAINER_SPACING}>
        <LoggedOutPageHeader
          headerTextId="SignUpConfirmAddressPage.areYouSure"
          subHeaderTextId="SignUpConfirmAddressPage.beVerySure"
        />
      </Box>
      <Box
        className={classes.addressContainer}
        paddingBottom={LOGGED_OUT_CONTAINER_SPACING}
      >
        <ServiceAddress {...serviceAddressProps} />
      </Box>

      <Form<CreditScoreDataType>
        initialValues={creditCheckValues}
        onSubmit={onAddressConfirmation}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={confirm} />
        )}
      />
    </SignUpPageLayout>
  );
};
