import { MOVE_IN, SWITCH } from "@common/types/customerTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhIconRadioField } from "@design-system/components/RhIconRadioField/RhIconRadioField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as MoveInIcon } from "@design-system/icons/move-in.svg";
import { ReactComponent as SwitchIcon } from "@design-system/icons/switch.svg";
import RadioGroup from "@material-ui/core/RadioGroup";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { SignUpServiceStartDateField } from "@portal/components/SignUpServiceStartDateField/SignUpServiceStartDateField";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useServiceStartDates } from "@portal/hooks/useServiceStartDates";
import React, { useEffect } from "react";

interface SignUpEnrollmentFieldsProps {
  trackClick?: (label: string) => void;
}

export const SignUpEnrollmentFields: React.FC<SignUpEnrollmentFieldsProps> = ({
  trackClick = () => null,
}) => {
  const { t } = useRhIntl();
  const flash = useRhFlash();
  const { data: allowedDates, requestMonitor } = useServiceStartDates();

  useEffect(() => {
    if (requestMonitor.didFail) {
      flash.error(t("rhythm.customer.dates.failure"));
    }
  }, [flash, t, requestMonitor.didFail]);

  const minDate = allowedDates?.[0];
  const maxDate = allowedDates?.[allowedDates.length - 1];

  return (
    <LoggedOutFieldsLayout>
      <RhFlexBox justifyContent="space-between" clone>
        <RadioGroup row aria-label="Enrollment Type">
          <RhIconRadioField
            value={MOVE_IN}
            icon={<MoveInIcon />}
            label={t("signUp.enrollmentType.moveIn")}
            onClick={() => trackClick("movingIcon")}
            name="enrollmentType"
          />
          <RhIconRadioField
            value={SWITCH}
            icon={<SwitchIcon />}
            label={t("signUp.enrollmentType.switch")}
            onClick={() => trackClick("switchingIcon")}
            name="enrollmentType"
          />
        </RadioGroup>
      </RhFlexBox>
      {allowedDates ? (
        <SignUpServiceStartDateField
          name="serviceStartDate"
          disabled={!allowedDates}
          minDate={minDate}
          maxDate={maxDate}
          allowedDates={allowedDates}
          InputProps={{
            onClick: () => trackClick("moveInDate"),
          }}
        />
      ) : (
        <RhCircularProgress />
      )}
    </LoggedOutFieldsLayout>
  );
};
