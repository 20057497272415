import { rhWindow } from "@common/utils/rhWindow";
import {
  RH_HEADER_HEIGHT,
  UNIT_OF_SPACING,
} from "@design-system/theme/style.constant";

export const scrollToOffsetPosition = (elem: HTMLElement) => {
  const extraPadding = UNIT_OF_SPACING;
  const appHeaderHeight = RH_HEADER_HEIGHT * UNIT_OF_SPACING;
  const headerOffset = appHeaderHeight + extraPadding;

  rhWindow.scrollTo({
    left: 0,
    top: elem.offsetTop - headerOffset,
    behavior: "smooth",
  });
};
