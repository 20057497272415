import dayjs from "dayjs";

export const useDateFormatter = () => {
  const cache: Record<string, Record<string, string>> = {};

  return (date: Date, format: string): string => {
    if (!cache[format]) {
      cache[format] = {};
    }

    const strDate = String(date);
    let formattedDate = cache[format][strDate];

    if (!formattedDate) {
      formattedDate = dayjs(date).format(format);
      cache[format][strDate] = formattedDate;
    }

    return formattedDate;
  };
};
