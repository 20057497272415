import { useAccountSummary } from "@portal/hooks/useAccountSummary";
import { useCustomer } from "@portal/hooks/useCustomer";
import {
  customerHomePath,
  myAccountPath,
  notificationsPath,
  payBillPath,
  rewardsPath,
} from "@portal/routes/routePaths";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const pagesWithLoader = [
  customerHomePath(),
  payBillPath(),
  rewardsPath(),
  myAccountPath(),
  notificationsPath(),
];

export const useLoader = () => {
  const { pathname } = useLocation();
  const [isPageWithLoader, setIsPageWithLoader] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const { requestMonitor: customerRequestMonitor } = useCustomer();
  const { requestMonitor: accountSummaryStatus } = useAccountSummary();

  useEffect(() => {
    if (pagesWithLoader.includes(pathname)) {
      setIsPageWithLoader(true);
    }
  }, [pathname]);

  if (!isPageWithLoader || !isEnabled) {
    return { isLoading: false };
  }

  if (customerRequestMonitor.didFail || accountSummaryStatus.didFail) {
    setIsEnabled(false);
  }

  if (customerRequestMonitor.didSucceed && accountSummaryStatus.didSucceed) {
    setIsEnabled(false);
  }

  return { isLoading: isEnabled };
};
