import { Link } from "@material-ui/core";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { signUpBasePath } from "@portal/routes/routePaths";
import { userLoggedOut } from "@portal/slices/authenticationSlice";
import React, { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

export const SignUpCSRSuccessPage: FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(userLoggedOut());
  }, [dispatch]);

  const signUpAnotherMessage = formatMessage({
    id: "SignUpCSRSuccessPage.another",
  });

  return (
    <SignUpPageLayout dense={false} hideBackButton>
      <LoggedOutPageHeader
        headerTextId="SignUpCSRSuccessPage.success"
        subHeaderTextId="SignUpCSRSuccessPage.goAway"
      />
      <Link component="a" href={signUpBasePath()} variant="subtitle2">
        {signUpAnotherMessage}
      </Link>
    </SignUpPageLayout>
  );
};
