import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";
import { CreateCSSProperties } from "@material-ui/styles";

type CopyReferralLinkButtonStylesKeys =
  | "input"
  | "copyIcon"
  | "button"
  | "root";

export const useCopyReferralLinkButtonStyles = makeStyles<
  typeof rhythmTheme,
  { dense?: boolean },
  CopyReferralLinkButtonStylesKeys
>((theme) => {
  const denseStyles: Record<
    Exclude<CopyReferralLinkButtonStylesKeys, "copyIcon">,
    CreateCSSProperties<{ dense?: boolean }>
  > = {
    root: {
      flexDirection: "column",
      alignItems: "center",
    },
    input: {
      marginRight: 0,
      color: theme.palette.primary.main,
      width: "100%",
    },
    button: {
      width: "100%",
      margin: `${theme.spacing(2)}px 0 0 0`,
    },
  };

  return createStyles({
    root: ({ dense }) =>
      dense
        ? denseStyles.root
        : {
            flexDirection: "row",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: denseStyles.root,
          },
    input: ({ dense }) =>
      dense
        ? denseStyles.input
        : {
            color: theme.palette.primary.main,
            width: "100%",
            marginRight: theme.spacing(2),
            [theme.breakpoints.down("sm")]: denseStyles.input,
          },
    copyIcon: {
      marginRight: theme.spacing(1),
    },
    button: ({ dense }) =>
      dense
        ? denseStyles.button
        : {
            width: 200,
            marginLeft: "auto",
            [theme.breakpoints.down("sm")]: denseStyles.button,
          },
  });
});
