import { api } from "@common/api/api";
import { formatMaskNumber } from "@common/forms/formatters";
import { RhApiError } from "@common/types/errorTypes";
import {
  formatCurrency,
  formatMonthDayYearDate,
} from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import {
  RhSelect,
  RhSelectOption,
} from "@design-system/components/RhSelect/RhSelect";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { DialogContent } from "@material-ui/core";
import { useAutoPayDialogStyles } from "@portal/components/AutoPayDialog/AutoPayDialog.style";
import { usePaymentMethods } from "@portal/hooks/billingHooks";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import { billingDetailsRefetch } from "@portal/slices/billingDetailsSlice";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface AutoPayDialogProps {
  balance?: number;
  dueDate?: string;
  pastDueBalance?: number;
  onClose: () => void;
  onSuccess: () => void;
}

export const AutoPayDialog: FC<AutoPayDialogProps> = ({
  balance,
  dueDate,
  pastDueBalance,
  onClose,
  onSuccess,
}) => {
  const { t } = useRhIntl();
  const classes = useAutoPayDialogStyles();
  const customerId = useSelector(selectCurrentCustomerId);
  const { paymentMethods } = usePaymentMethods();
  const flash = useRhFlash();

  const defaultPaymentMethod = paymentMethods?.find(
    (paymentMethod) => paymentMethod.defaultPaymentMethod
  );

  const dispatch = useDispatch();

  const [selectedPaymentValue, setSelectedPaymentValue] = useState(
    defaultPaymentMethod?.id || ""
  );

  const handleAutoPaySubmit = () => {
    api.billing.paymentMethods
      .default(selectedPaymentValue, { customerId })
      .then(() => {
        dispatch(billingDetailsRefetch());
        onSuccess();
      })
      .catch((error: RhApiError) => {
        flash.error(t("autoPayDialog.changeDefaultPaymentError"));
        onClose();
      });
  };

  const options: RhSelectOption[] = paymentMethods
    ? paymentMethods.map((paymentMethod) => ({
        label: `${paymentMethod.companyName} ${formatMaskNumber(
          paymentMethod.maskedNumber
        )}`,
        value: paymentMethod.id,
      }))
    : [];

  const autoPaymentConfirmationDateCopy =
    pastDueBalance && pastDueBalance > 0
      ? t("autoPayDialog.youWillBeChargedTotalPastDue")
      : t("autoPayDialog.youWillBeCharged");

  const title = t("autoPayDialog.turnOn");
  const on = t("autoPayDialog.on");
  const selectPaymentText = t("autoPayDialog.selectPayment");
  const noPaymentMethods = t("autoPayDialog.noPaymentMethods");
  const youHaveNoPaymentMethod = t("autoPayDialog.youHaveNoPaymentMethod");
  const confirmButtonText = t("rhythm.input.confirm");
  const cancelButtonText = t("rhythm.input.cancel");

  return !paymentMethods?.length ? (
    <RhDialog open onClose={onClose} size="medium">
      <RhDialogTitle>{noPaymentMethods}</RhDialogTitle>
      <DialogContent>
        <RhTypography color="textSecondary" variant="subtitle1">
          {youHaveNoPaymentMethod}
        </RhTypography>
        <RhFlexBox justifyContent="space-between" marginY={3}>
          <RhButton onClick={onClose} size="large">
            {cancelButtonText}
          </RhButton>
        </RhFlexBox>
      </DialogContent>
    </RhDialog>
  ) : (
    <RhDialog open onClose={onClose} size="medium">
      <RhDialogTitle>
        {title}
        {Boolean(dueDate) && Boolean(balance) && (
          <>
            <RhTypography color="textSecondary" variant="body2">
              {autoPaymentConfirmationDateCopy}
            </RhTypography>
            {Boolean(pastDueBalance) && (
              <>
                <RhTypography
                  className={classes.subheader}
                  color="textSecondary"
                  variant="body2"
                  display="inline"
                >
                  {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    formatCurrency(pastDueBalance!)
                  }
                </RhTypography>
                <RhTypography
                  className={classes.inlineParagraphText}
                  color="textSecondary"
                  variant="body2"
                  display="inline"
                >
                  {on}
                </RhTypography>
              </>
            )}
            <RhTypography
              className={classes.subheader}
              color="textSecondary"
              variant="body2"
            >
              {formatMonthDayYearDate(dueDate as string)}
            </RhTypography>
          </>
        )}
      </RhDialogTitle>
      <RhDialogContentWithButtons
        primaryButton={{
          label: confirmButtonText,
          onClick: handleAutoPaySubmit,
        }}
        secondaryButton={{
          label: cancelButtonText,
          onClick: onClose,
        }}
      >
        <RhSelect
          name="billingPaymentMethodId"
          value={selectedPaymentValue}
          onChange={(e) => {
            setSelectedPaymentValue(e.target.value as string);
          }}
          label={selectPaymentText}
          options={options}
        />
      </RhDialogContentWithButtons>
    </RhDialog>
  );
};
