import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useAddACHStyles = makeStyles<
  typeof rhythmTheme,
  "content" | "root"
>((theme) =>
  createStyles({
    content: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
    },
    root: {
      cursor: "pointer",
    },
  })
);
