import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useMyAccountBillingPreferencesCardStyles = makeStyles<
  typeof rhythmTheme,
  "link" | "preferenceCard" | "subheaderContainer"
>((theme) =>
  createStyles({
    link: {
      color: theme.palette.grey[400],
      fontSize: 12,
      marginLeft: theme.spacing(1),
      textDecoration: "underline",
    },
    preferenceCard: {
      marginBottom: theme.spacing(1.5),
    },
    subheaderContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);
