import { OfferType } from "@common/types/offerTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { CheckIcon } from "@design-system/icons";
import { Box, MobileStepper } from "@material-ui/core";
import { SignUpOfferCarouselProps } from "@portal/components/OfferCarousel/OfferCarousel";
import { useOfferCarouselStyles } from "@portal/components/OfferCarousel/OfferCarousel.style";
import { SignUpOfferCoordinator } from "@portal/components/SignUpOfferCoordinator/SignUpOfferCoordinator";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectChosenOfferId } from "@portal/selectors/signUpSelectors";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SwipeableView from "react-swipeable-views";

const BEGINNING = 0;
const INDEX_NOT_FOUND = -1;
const getCarouselInitialState = (
  offers: OfferType[],
  selectedOfferId: string
) => {
  const selectedOfferIndex = offers.findIndex(
    ({ id }) => id === selectedOfferId
  );

  return selectedOfferIndex === INDEX_NOT_FOUND
    ? BEGINNING
    : selectedOfferIndex;
};

interface SwipeableOffersProps extends SignUpOfferCarouselProps {
  offerPerks: string[];
}

export const SwipeableOffers: FC<SwipeableOffersProps> = ({
  offers,
  onSelectOffer,
  offerPerks,
}) => {
  const { t } = useRhIntl();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const hasPromos = offers.some((offer) => Boolean(offer.promo));
  const classes = useOfferCarouselStyles({ isLoaded, hasPromos });
  const selectedOfferId = useSelector(selectChosenOfferId);
  const [activeIndex, setActiveIndex] = useState<number>(
    getCarouselInitialState(offers, selectedOfferId)
  );

  const handleChangeIndex = (index: number) => {
    setActiveIndex(index);
  };

  const signUpOfferCoordinators = offers.map(
    (offer: OfferType, index: number) => (
      <SignUpOfferCoordinator
        key={offer.id}
        index={index}
        offer={offer}
        onSelectOffer={onSelectOffer}
      />
    )
  );

  useEffect(() => {
    // we need to load the page with 'classes.root' containing a full page height to allow swipeable views
    // time to calculate the height of all the slides. Then we reset the height back to its '100%' to ensure
    // there is no extra room for scrolling.
    setTimeout(() => {
      setIsLoaded(true);
    }, 0);
  }, []);

  return (
    <>
      <SwipeableView
        axis="x"
        index={activeIndex}
        enableMouseEvents
        onChangeIndex={handleChangeIndex}
        className={classes.root}
        slideClassName={classes.slide}
        onTransitionEnd={() => {
          // this forces swipeable view to end on the last offer card and ignore the offerPerks "phantom" card
          activeIndex === signUpOfferCoordinators.length &&
            setActiveIndex(activeIndex - 1);
        }}
      >
        {offers.map((offer: OfferType, offerIndex) => (
          <SignUpOfferCoordinator
            key={offer.id}
            index={offerIndex}
            offer={offer}
            onSelectOffer={onSelectOffer}
            isActiveSlide={offerIndex === activeIndex}
          />
        ))}
        <RhFlexBox
          justifyContent="space-between"
          className={classes.swipeablePerks}
        >
          {offerPerks.map((perk) => (
            <RhFlexBox alignItems="center" key={perk}>
              <Box paddingRight={1}>
                <CheckIcon />
              </Box>
              <RhTypography color="textPrimary">
                {t(`OfferCarousel.${perk}`)}
              </RhTypography>
            </RhFlexBox>
          ))}
        </RhFlexBox>
      </SwipeableView>

      <MobileStepper
        className={classes.mobileStepper}
        elevation={0}
        variant="dots"
        steps={offers.length}
        activeStep={activeIndex}
        position="bottom"
        nextButton={<div />}
        backButton={<div />}
      />
    </>
  );
};
