import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core";

export const useSignUpConfirmAddressPageStyles = makeStyles<
  typeof rhythmTheme,
  "addressContainer"
>((_theme) =>
  createStyles({
    addressContainer: {
      margin: "auto",
      textAlign: "center",
      width: LOGGED_OUT_FORM_WIDTH,
    },
  })
);
