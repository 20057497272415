import { AddressFormType } from "@common/types/customerTypes";

export interface CreditScoreContactDataType {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phone: string;
  email: string;
}

export interface CreditScoreDataType
  extends CreditScoreContactDataType,
    AddressFormType {}

export enum CreditScoreOutcome {
  approved = "Approved",
  manualReview = "Manual Review",
  unavailable = "unavailable",
  // "Denied" is deactivated at the API level — we cannot legally deny service
}

export interface CreditScoreEvaluationType {
  result: "success" | "partial" | "fail";
  depositAmount: number | null;
  outcome: CreditScoreOutcome;
  ssnRequired: boolean;
}

export const ACCEPTABLE_CREDIT_SCORE_OUTCOMES = new Set([
  CreditScoreOutcome.approved,
  CreditScoreOutcome.unavailable,
]);
