import { useOktaAuth } from "@okta/okta-react";
import { useAuthenticatedUserGroups } from "@portal/hooks/useAuthenticatedUserGroups";
import {
  SignUpStepType,
  customerHomePath,
  signUpStepPath,
} from "@portal/routes/routePaths";
import { selectVisitedSignUpPages } from "@portal/selectors/signUpSelectors";
import { isOpsOrCSRs } from "@portal/utils/signUpHelpers";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";

export const SignUpRoute: FC<RouteProps> = (props) => {
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  const { data: groups } = useAuthenticatedUserGroups();

  const requestedStep = props.location?.pathname
    .split("/")
    .slice(-1)[0] as SignUpStepType;

  const { visitedSteps } = useSelector(selectVisitedSignUpPages);

  if (groups === null) {
    return null;
  }

  if (isAuthenticated && !isOpsOrCSRs(groups)) {
    return <Redirect to={customerHomePath()} />;
  } else if (isOpsOrCSRs(groups)) {
    return <Redirect to={`/csr${signUpStepPath(requestedStep)}`} />;
  } else if (visitedSteps.includes(requestedStep)) {
    return <Route {...props} />;
  } else {
    return <Redirect to={signUpStepPath("availability")} />;
  }
};
