/// <reference types="@types/segment-analytics" />
import { isBot } from "@portal/utils/isBot";

// window.analytics is added by a script in Google Tag Manager.
declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export enum EventType {
  enrollmentClick = "enrollmentClick",
}

export enum ActionType {
  click = "click",
  clickedBackPage = "clickedBackPage",
  clickedEnrollmentSubmit = "clickedEnrollmentSubmit",
  clickedNextPage = "clickedNextPage",
  focus = "fieldFocus",
}

export interface AnalyticsEvent {
  event: EventType;
  category: string;
  action: ActionType;
  label: string;
  value?: string | boolean;
}

export type TrackEventHandler = (event: Partial<AnalyticsEvent>) => void;

const shouldTrackEvent = () => {
  return !isBot();
};

export const createTrackEvent = (
  base: Partial<AnalyticsEvent>
): TrackEventHandler => {
  return (options: Partial<AnalyticsEvent>) => {
    return track({ ...base, ...options });
  };
};

export const track = (options: Partial<AnalyticsEvent>) => {
  if (!shouldTrackEvent()) {
    return;
  }

  const { event, ...properties } = options;

  if (event) {
    window.analytics?.track?.(event, properties);
  }
};

export const trackPage = () => {
  if (!shouldTrackEvent()) {
    return;
  }

  window.analytics?.page?.();
};
