import { api } from "@common/api/api";
import { Customer } from "@common/models/Customer.model";
import { RhApiError } from "@common/types/errorTypes";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box, RadioGroup, useMediaQuery, useTheme } from "@material-ui/core";
import { MyAccountSubmitButton } from "@portal/components/MyAccountSubmitButton/MyAccountSubmitButton";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { LanguagePreferenceRadioField } from "@portal/pages/MyAccountPage/LanguagePreferenceRadioField";
import { customerUpdatedLanguagePreference } from "@portal/slices/customerPreferencesSlice";
import {
  AllSupportedPortalLanguages,
  SupportedPortalLanguage,
  englishLanguageCode,
  translations,
} from "@portal/translations/portalTranslations";
import { FormApi } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

type MyAccountLanguageCardProps = {
  customer: Customer;
};

export type MyAccountLanguageCardFormValues = {
  languagePreference: SupportedPortalLanguage;
};

export const MyAccountLanguageCard: FC<MyAccountLanguageCardProps> = ({
  customer,
}) => {
  const dispatch = useDispatch();
  const { t } = useRhIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const flash = useRhFlash();
  const contactFormInitialValues: MyAccountLanguageCardFormValues = {
    languagePreference: customer.languagePreference || englishLanguageCode,
  };

  const changeLanguage = (
    formattedValues: MyAccountLanguageCardFormValues,
    formApi: FormApi<MyAccountLanguageCardFormValues>
  ) => {
    const values: MyAccountLanguageCardFormValues = {
      ...formattedValues,
    };
    const { languagePreference } = values;

    return api.customers
      .update(customer.id, values)
      .then(() => {
        formApi.initialize(formattedValues);
        dispatch(customerUpdatedLanguagePreference({ languagePreference }));
        flash.success(
          // t("account.contact.preferencesUpdated")
          // normally you should not access translations directly but...
          // useIntl will have a stale languagePreference, so cannot be trusted here
          translations[languagePreference][
            "MyAccountLanguageCard.preferencesUpdated"
          ]
        );
      })
      .catch((error: RhApiError) => {
        flash.error(t("MyAccountLanguageCard.errorSwitchingLocale"));
      });
  };

  const title = t("MyAccountLanguageCard.languagePreference");

  return (
    <RhCard title={title}>
      <Form<MyAccountLanguageCardFormValues>
        initialValues={contactFormInitialValues}
        onSubmit={changeLanguage}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box marginBottom={2}>
              <RadioGroup>
                {AllSupportedPortalLanguages.map((languageCode) => (
                  <LanguagePreferenceRadioField
                    key={languageCode}
                    value={languageCode}
                    translationId={`MyAccountLanguageCard.${languageCode}`}
                  />
                ))}
              </RadioGroup>
            </Box>
            <RhFlexBox justifyContent="flex-end">
              <MyAccountSubmitButton isMobile={isMobile} />
            </RhFlexBox>
          </form>
        )}
      />
    </RhCard>
  );
};
