import { borders } from "@design-system/constants/borders";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const usePaymentAmountStyles = rhMakeStyles<
  typeof rhythmTheme,
  "fieldset" | "payOptions"
>((theme) =>
  createStyles({
    fieldset: {
      display: "block",
    },
    payOptions: {
      borderBottom: borders[100],
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);
