import { Box, Grid } from "@material-ui/core";
import React, { Children, FC } from "react";

export const LOGGED_OUT_FIELD_SPACING = 2;
export const LOGGED_OUT_CONTAINER_SPACING = 7;

interface SignUpFieldsLayoutProps {
  dense?: boolean;
}

export const SignUpFieldsLayoutContainer: FC<SignUpFieldsLayoutProps> = ({
  dense,
  children,
}) => (
  <Box my={LOGGED_OUT_CONTAINER_SPACING}>
    <Grid container direction="column" wrap="nowrap" spacing={dense ? 0 : 4}>
      {children}
    </Grid>
  </Box>
);

export const LoggedOutFieldsLayout: FC<SignUpFieldsLayoutProps> = ({
  dense = false,
  children,
}) => (
  <SignUpFieldsLayoutContainer dense={dense}>
    {Children.map(children, (child) => (
      <Grid item>{child}</Grid>
    ))}
  </SignUpFieldsLayoutContainer>
);
