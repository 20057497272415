import { RhDateInput } from "@design-system/components/RhDateInput/RhDateInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, InputProps } from "@material-ui/core";
import { useSignUpServiceStartDateFieldStyles } from "@portal/components/SignUpServiceStartDateField/SignUpServiceStartDateField.style";
import React, { FC } from "react";
import * as RFF from "react-final-form";
import { useIntl } from "react-intl";

interface RhDateFieldProps {
  name: string;
  allowedDates: string[];
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
  InputProps?: InputProps;
}

export const SignUpServiceStartDateField: FC<RhDateFieldProps> = ({
  name,
  allowedDates,
  ...restProps
}) => {
  const classes = useSignUpServiceStartDateFieldStyles();

  const { formatMessage } = useIntl();
  const label = formatMessage({
    id: "SignUpServiceStartDateField.whenToStartService",
  });
  const hint = formatMessage({ id: "SignUpServiceStartDateField.noSetup" });

  const firstDate = allowedDates[0];

  const {
    input: { value, type: unusedType, ...restInput },
  } = RFF.useField<string>(name, {
    type: "date",
    initialValue: firstDate,
  });
  const todayLabel = formatMessage({ id: "rhythm.input.today" });
  const okLabel = formatMessage({ id: "rhythm.input.ok" });
  const cancelLabel = formatMessage({ id: "rhythm.input.cancel" });

  return (
    <Box position="relative" width="100%">
      <RhDateInput
        label={label}
        value={value}
        placeholder={label}
        allowedDates={allowedDates}
        dateFormat="ddd MM/DD/YYYY"
        todayLabel={todayLabel}
        okLabel={okLabel}
        cancelLabel={cancelLabel}
        {...restProps}
        {...restInput}
      />
      <RhTypography className={classes.hint} variant="body2">
        {hint}
      </RhTypography>
    </Box>
  );
};
