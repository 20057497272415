import { OfferType } from "@common/types/offerTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { CheckIcon } from "@design-system/icons";
import {
  OFFER_CAROUSEL_WIDTH,
  PAGE_GUTTER,
} from "@design-system/theme/style.constant";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { SwipeableOffers } from "@portal/components/OfferCarousel/SwipeableOffers";
import { SignUpOfferCoordinator } from "@portal/components/SignUpOfferCoordinator/SignUpOfferCoordinator";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { ReactComponent as TandemBikeLeft } from "@portal/pages/SignUpOfferPage/tandemBikeLeft.svg";
import { ReactComponent as TandemBikeRight } from "@portal/pages/SignUpOfferPage/tandemBikeRight.svg";
import React, { CSSProperties, FC } from "react";

export interface SignUpOfferCarouselProps {
  offers: OfferType[];
  onSelectOffer: (offer: OfferType) => void;
}

export const OfferCarousel: FC<SignUpOfferCarouselProps> = ({
  offers,
  onSelectOffer,
}) => {
  const { t } = useRhIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const signUpOfferCoordinators = offers.map(
    (offer: OfferType, index: number) => (
      <SignUpOfferCoordinator
        key={offer.id}
        index={index}
        offer={offer}
        onSelectOffer={onSelectOffer}
      />
    )
  );

  const tandemBikeStyle = {
    position: "absolute",
    width: 195,
    top: 451,
    zIndex: 0,
  } as CSSProperties;
  const hasPromo = offers.some((offer) => Boolean(offer.promo));

  const offerPerks = ["fairPrices", "satisfactionGuarantee", "noHiddenFees"];

  return (
    <Box mx={`-${PAGE_GUTTER}px`} width={isMobile ? "100vw" : "100%"}>
      {isMobile ? (
        <SwipeableOffers
          offers={offers}
          onSelectOffer={onSelectOffer}
          offerPerks={offerPerks}
        />
      ) : (
        <Box maxWidth={OFFER_CAROUSEL_WIDTH}>
          <RhFlexBox
            justifyContent="space-evenly"
            mb={LOGGED_OUT_CONTAINER_SPACING + (hasPromo ? 5 : 0)}
          >
            {offerPerks.map((perk) => (
              <RhFlexBox key={perk} alignItems="center">
                <Box
                  paddingRight={1}
                  style={{ color: theme.palette.primary.main }}
                >
                  <CheckIcon />
                </Box>
                <RhTypography color="primary">
                  {t(`OfferCarousel.${perk}`)}
                </RhTypography>
              </RhFlexBox>
            ))}
          </RhFlexBox>
          <RhFlexBox justifyContent="center">
            {signUpOfferCoordinators}
          </RhFlexBox>

          <Box
            style={{
              ...tandemBikeStyle,
              left: -theme.spacing(2),
            }}
          >
            <TandemBikeLeft />
          </Box>
          <Box
            style={{
              ...tandemBikeStyle,
              right: -theme.spacing(2),
            }}
          >
            <TandemBikeRight />
          </Box>
        </Box>
      )}
    </Box>
  );
};
