import { IdType } from "@common/types/apiTypes";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import {
  OfferRatesAndCharges,
  OfferType,
  PriceMethodTypes,
  PromoType,
} from "@common/types/offerTypes";

export const AVERAGE_MONTHLY_USAGE = 2000; // TODO hardcoded for now
export const monthlyUsageAmounts = [500, 1000, 2000];

export class Offer {
  private readonly descriptions: Record<SupportedPortalLanguage, string>;
  private readonly longDescriptions: Record<SupportedPortalLanguage, string>;
  public campaignId: IdType;
  public endDate?: string;
  public id: IdType;
  private price: string;
  public rhythmKwhRate: string;
  public startDate: string;
  public tdspKwhRate: string;
  public tdspMonthlyCharge: string;
  public termMonths: number;
  public title: string;
  public zuoraRatePlanId: IdType;
  public priceMethod: PriceMethodTypes;
  public promo: PromoType | undefined;
  public active: boolean;

  constructor(offer: OfferType) {
    this.campaignId = offer.campaignId;
    this.endDate = offer.endDate;
    this.id = offer.id;
    this.price = offer.price;
    this.rhythmKwhRate = offer.rhythmKwhRate;
    this.startDate = offer.startDate;
    this.tdspKwhRate = offer.tdspKwhRate;
    this.tdspMonthlyCharge = offer.tdspMonthlyCharge;
    this.termMonths = offer.termMonths;
    this.title = offer.title;
    this.zuoraRatePlanId = offer.zuoraRatePlanId;
    this.priceMethod = offer.priceMethod;
    this.promo = offer.promo;
    this.active = offer.active;
    this.descriptions = {
      en: offer.descriptionEn,
      es: offer.descriptionEs,
    };
    this.longDescriptions = {
      en: offer.longDescriptionEn,
      es: offer.longDescriptionEs,
    };
  }

  get status(): string {
    if (this.active) {
      return "Active";
    } else {
      return "Expired";
    }
  }

  description(locale: SupportedPortalLanguage): string {
    return this.descriptions[locale];
  }

  longDescription(locale: SupportedPortalLanguage): string {
    return this.longDescriptions[locale];
  }

  get ratesAndCharges(): OfferRatesAndCharges {
    return {
      rhythmKwhRate: this.rhythmKwhRate,
      tdspMonthlyCharge: this.tdspMonthlyCharge,
      tdspKwhRate: this.tdspKwhRate,
    };
  }

  averageMonthlyPrice = (
    monthlyUsage: number = AVERAGE_MONTHLY_USAGE
  ): number => {
    const totalRate =
      parseFloat(this.rhythmKwhRate) + parseFloat(this.tdspKwhRate);
    const totalCharge = parseFloat(this.tdspMonthlyCharge);

    return totalRate * monthlyUsage + totalCharge;
  };

  averagePricePerKWH = (
    monthlyUsage: number = AVERAGE_MONTHLY_USAGE
  ): number => {
    return this.averageMonthlyPrice(monthlyUsage) / monthlyUsage;
  };
}
