import { useRhInnerCardStyles } from "@design-system/components/RhInnerCard/RhInnerCard.styles";
import classNames from "classnames";
import React, { FC } from "react";

interface RhInnerCardProps {
  className?: string;
  noBorder?: boolean;
}

export const RhInnerCard: FC<RhInnerCardProps> = ({
  children,
  className = "",
  noBorder = false,
  ...props
}) => {
  const classes = useRhInnerCardStyles();
  const borderClass = noBorder ? "" : classes.border;

  return (
    <div
      className={classNames(className, classes.container, borderClass)}
      {...props}
    >
      {children}
    </div>
  );
};
