import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { green } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { fontFamily } from "@design-system/theme/typography";
import { Theme, createStyles } from "@material-ui/core";

export const USAGE_CHART_HEIGHT = 300;
export const CHART_PADDING = 40;
export const CHART_PADDING_TOP = 8;
export const useEnergyUsageChartStyles = makeStyles<
  typeof rhythmTheme,
  "chartHeader" | "usageInfo" | "usageInfoContainer"
>((theme) =>
  createStyles({
    chartHeader: {
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        marginLeft: 20,
        marginRight: 20,
      },
    },
    usageInfo: {
      "&>:first-of-type": {
        marginBottom: theme.spacing(1),
      },
      minWidth: 176,
      padding: `0 0 ${theme.spacing(3)}px ${theme.spacing(5)}px`,
      [theme.breakpoints.down("sm")]: {
        minWidth: 0,
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
          1
        )}px`,
      },
    },
    usageInfoContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
    },
  })
);

// Styles for graph. Not css because graph is an svg. Not a css thing
export function getGraphStyleProps(isMobile: boolean, theme: Theme) {
  const axisTickLabelsProps = {
    fill: theme.palette.grey[900],
    fontFamily,
    fontSize: 10,
    fontWeight: 600,
  };
  const props = {
    area: {
      data: {
        fill: green[50],
        stroke: green[500],
        strokeWidth: 1,
      },
    },
    chart: {
      bottom: theme.spacing(4),
      left: 25,
      right: CHART_PADDING,
      top: 0,
    },
    victoryLabelProps: {
      dy: 0,
      x: 0,
    },
    xAxis: {
      axis: {
        stroke: theme.palette.common.white,
      },
      tickLabels: {
        ...axisTickLabelsProps,
      },
    },
    yAxis: {
      axis: {
        stroke: theme.palette.common.white,
      },
      tickLabels: {
        ...axisTickLabelsProps,
      },
    },
  };

  if (isMobile) {
    props.chart.left = 0;
    props.victoryLabelProps.x = 15;
  }

  return props;
}
