import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhToggle } from "@design-system/components/RhToggle/RhToggle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { Box, SwitchProps } from "@material-ui/core";
import { usePreferencesSwitchInputStyles } from "@portal/components/PreferencesSwitchInput/PreferencesSwitchInput.style";
import React, { FC, ReactElement } from "react";
import { useIntl } from "react-intl";

interface PreferencesSwitchInputProps
  extends Omit<
    SwitchProps,
    "color" | "classes" | "icon" | "checkedIcon" | "size" | "id" | "className"
  > {
  detail: ReactElement | string;
  name: string;
  label: string;
}

export const PreferencesSwitchInput: FC<PreferencesSwitchInputProps> = ({
  detail,
  label,
  name,
  checked,
  disabled,
  onChange,
}) => {
  const classes = usePreferencesSwitchInputStyles();

  const { formatMessage } = useIntl();

  const onOffText = formatMessage({
    id: checked ? "preferencesSwitchInput.on" : "preferencesSwitchInput.off",
  });

  return (
    <RhInnerCard className={classes.innerCard}>
      <Box pr={2} mb={1}>
        <RhTypography
          color="textPrimary"
          variant="subtitle2"
          fontWeight={FontWeight.Semibold}
        >
          {label}
        </RhTypography>
        <RhTypography
          color="textSecondary"
          variant="body1"
          className={classes.detail}
        >
          {detail}
        </RhTypography>
      </Box>

      <RhFlexBox className={classes.switchContainer}>
        <RhTypography
          component="label"
          htmlFor={name}
          data-testid={name}
          className={classes.labelText}
        >
          {onOffText}
        </RhTypography>
        <RhToggle
          id={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          name={name}
        />
      </RhFlexBox>
    </RhInnerCard>
  );
};
