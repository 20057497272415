import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useSignUpSwitchInputStyles = makeStyles<
  typeof rhythmTheme,
  "root" | "input" | "label" | "icon" | "paper" | "descriptor"
>((theme) =>
  createStyles({
    descriptor: {
      paddingBottom: theme.spacing(1),
    },
    icon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(2),
    },
    input: {
      marginLeft: "auto",
    },
    label: {
      marginTop: "-2px",
      paddingBottom: theme.spacing(0.5),
    },
    paper: {
      borderBottom: "none",
      borderLeft: "none",
      borderRight: "none",
      padding: `${theme.spacing(2)}px 0`,
    },
    root: {
      alignItems: "flex-start",
      flexFlow: "row nowrap",
    },
  })
);
