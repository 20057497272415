import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";
import * as RFF from "react-final-form";

export const ForgotPasswordFields = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { t } = useRhIntl();

  const { submitSucceeded } = RFF.useFormState({
    subscription: {
      submitSucceeded: true,
    },
  });

  const submittedMessage = t("forgotPassword.submitted");
  const email = t("forgotPassword.email");
  const next = t("forgotPassword.nextCTA");

  return submitSucceeded ? (
    <LoggedOutFieldsLayout>
      <RhTypography variant="subtitle1" align="center" color="primary">
        {submittedMessage}
      </RhTypography>
    </LoggedOutFieldsLayout>
  ) : (
    <>
      <LoggedOutFieldsLayout>
        <RhTextField name="email" type="email">
          {email}
        </RhTextField>
      </LoggedOutFieldsLayout>
      <RhSubmitButton fullWidth size={isMobile ? "medium" : "large"}>
        {next}
      </RhSubmitButton>
    </>
  );
};
