import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { useFeatureFlags } from "@portal/hooks/useFeatureFlags";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectCampaignPromo } from "@portal/selectors/signUpSelectors";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";

export const CampaignPromoAnnouncement: FC = () => {
  const { campaignPromosFeature } = useFeatureFlags();
  const campaignPromo = useSelector(selectCampaignPromo);
  const { announceNotice } = useRhAnnouncement();
  const { t } = useRhIntl();

  useEffect(() => {
    if (campaignPromo && campaignPromosFeature) {
      const promoCodeApplied = t("CampaignPromoAnnouncement.promoCodeApplied", {
        promoCode: campaignPromo.code,
      });

      announceNotice(promoCodeApplied);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignPromo]);
  return null;
};
