import { usePaymentMethodRadioFieldStyles } from "@portal/components/PaymentMethodRadioField/PaymentMethodRadioField.style";
import { PaymentMethodView } from "@portal/components/PaymentMethodView/PaymentMethodView";
import { PaymentMethod } from "@portal/models/PaymentMethod.model";
import React, { FC } from "react";
import { useField } from "react-final-form";

interface CreditCardFieldProps {
  paymentMethod: PaymentMethod;
  name: string;
}

export const PaymentMethodRadioField: FC<CreditCardFieldProps> = ({
  name,
  paymentMethod,
}) => {
  const classes = usePaymentMethodRadioFieldStyles();
  const { input } = useField<string>(name, {
    type: "radio",
    value: paymentMethod.id,
  });

  return (
    <label htmlFor={paymentMethod.id} className={classes.label}>
      <input id={paymentMethod.id} {...input} hidden />
      <PaymentMethodView
        paymentMethod={paymentMethod}
        highlighted={input.checked}
      />
    </label>
  );
};
