import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core";

export const useMonthlyUsageSliderInputStyles = makeStyles<Theme>((theme) =>
  createStyles({
    labelText: {
      color: theme.palette.grey["600"],
      fontSize: 11,
      fontWeight: FontWeight.Bold,
    },
    popper: {
      zIndex: 10,
    },
    tooltip: {
      backgroundColor: "transparent",
      color: theme.palette.grey["700"],
      fontSize: 11,
      fontWeight: FontWeight.Bold,
      padding: "0 !important",
      transform: "translateX(-22px) !important",
    },
    tooltipPlacementBottom: {
      margin: "8px 8px 8px",
    },
  })
);
