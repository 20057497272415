// NOTE: THIS PAGE IS TEMPORARY AND WILL BE SERVED UP WHEN THE FEATURE FLAG 'addAchFeature' IS FALSE
// SEE PortalRoutes.tsx FOR LOGIC
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import {
  LOGGED_OUT_CONTAINER_SPACING,
  LOGGED_OUT_FIELD_SPACING,
} from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { ZuoraCardForm } from "@portal/components/ZuoraCardForm/ZuoraCardForm";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { ActionType } from "@portal/services/segment.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export interface SignUpPaymentFormValues {
  billingPaymentMethodId: string | null;
}

export const SignUpZuoraPaymentPage: FC = () => {
  const { formatMessage } = useIntl();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { autopay } = useSelector<PortalStoreState, { autopay: boolean }>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ signUp: { autopay } }) => ({ autopay })
  );
  const [zuoraFormLoading, setZuoraFormLoading] = useState<boolean>(false);
  const flash = useRhFlash();

  const skipPaymentSteps = () => {
    trackEvent({ action: ActionType.click, label: "noPaymentInfo" });
    signUpClickNextStepHandler({
      signUpData: { billingPaymentMethodId: null },
      nextStep: "summary",
      track: true,
    });
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerTextId="SignUpPaymentPage.creditCardPlease"
        subHeaderTextId="SignUpPaymentPage.takeCareOfThisNow"
      />
      <Box mt={LOGGED_OUT_CONTAINER_SPACING} width={LOGGED_OUT_FORM_WIDTH}>
        <ZuoraCardForm
          onSuccess={({ refId }) => {
            signUpClickNextStepHandler({
              signUpData: { billingPaymentMethodId: refId },
              nextStep: "summary",
              track: true,
            });
          }}
          onFailure={flash.error}
          onFormLoading={setZuoraFormLoading}
        />
      </Box>
      {!zuoraFormLoading && !autopay && (
        <Box mt={LOGGED_OUT_FIELD_SPACING} style={{ textAlign: "center" }}>
          <RhTypography
            component="a"
            color="primary"
            onClick={skipPaymentSteps}
          >
            {formatMessage({ id: "SignUpPaymentPage.skipPaymentInfo" })}
          </RhTypography>
        </Box>
      )}
    </SignUpPageLayout>
  );
};
