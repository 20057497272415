import { ButtonGroup, ButtonGroupProps } from "@material-ui/core";
import React, { FC } from "react";

type RhButtonGroupProps = ButtonGroupProps;

export const RhButtonGroup: FC<RhButtonGroupProps> = ({
  children,
  ...restProps
}) => {
  return <ButtonGroup {...restProps}>{children}</ButtonGroup>;
};
