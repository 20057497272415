import { RefObject, useEffect, useState } from "react";

import { useDebounce } from "./useDebounce";

export function useElementWidth(ref: RefObject<HTMLElement>) {
  const [refWidth, setRefWidth] = useState(ref?.current?.clientWidth || 0);

  function handleResize() {
    setRefWidth(ref?.current?.clientWidth || 0);
  }

  const handleResizeDebounced = useDebounce(handleResize, 300);

  useEffect(() => {
    window.addEventListener("resize", handleResizeDebounced);
    return () => window.removeEventListener("resize", handleResizeDebounced);
  }, [handleResizeDebounced]);

  setTimeout(() => {
    setRefWidth(ref?.current?.clientWidth || 0);
  });

  return { refWidth };
}
