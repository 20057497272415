import { Offer } from "@common/models/Offer.model";
import { OfferType } from "@common/types/offerTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useMiniOfferCardStyles } from "@portal/components/OfferMiniChooser/MiniOfferCard.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import classnames from "classnames";
import React, { FC } from "react";
import { useSelector } from "react-redux";

interface MiniOfferCardProps {
  offer: OfferType;
  onSelect: (offer: OfferType) => void;
  layoutClassname?: string;
}

export const MiniOfferCard: FC<MiniOfferCardProps> = ({
  offer: rawOffer,
  onSelect,
  layoutClassname = "",
}) => {
  const offer = new Offer(rawOffer);
  const { t } = useRhIntl();
  const classes = useMiniOfferCardStyles();
  const { estimatedMonthlyUsage } = useSelector(selectSignUpState);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { title } = offer;
  const price = formatCurrency(
    offer.averageMonthlyPrice(estimatedMonthlyUsage),
    0
  );
  const perMonth = t("OfferMiniChooser.perMonth", {
    price,
    priceTag: (content: string) => (
      <RhTypography component="span" variant="h4" fontWeight={FontWeight.Bold}>
        {content}
      </RhTypography>
    ),
  });
  const selectPlanText = t("OfferMiniChooser.selectPlan");

  return (
    <RhCard className={classnames(layoutClassname, classes.cardRoot)}>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.title}>
            <RhTypography variant="body1" fontWeight={FontWeight.Bold}>
              {title}
            </RhTypography>
          </div>
          <div className={classes.body}>
            <RhTypography variant="body1">{perMonth}</RhTypography>
          </div>
        </div>
        <div className={classes.actions}>
          <RhOutlineButton
            fullWidth={isDesktop}
            size="small"
            onClick={(_event) => onSelect(rawOffer)}
            color="primary"
          >
            {selectPlanText}
          </RhOutlineButton>
        </div>
      </div>
    </RhCard>
  );
};
