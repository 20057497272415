import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const BILLING_PREFERENCE_DETAIL_MAX_WIDTH = "356px";

export const usePreferencesSwitchInputStyles = makeStyles<
  typeof rhythmTheme,
  | "detail"
  | "input"
  | "innerCard"
  | "switchContainer"
  | "switchBase"
  | "thumb"
  | "track"
  | "checked"
  | "labelText"
>((theme) =>
  createStyles({
    checked: {
      "&$checked + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
    detail: {
      display: "block",
      maxWidth: BILLING_PREFERENCE_DETAIL_MAX_WIDTH,
    },
    innerCard: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },
    input: {
      height: "20px",
      padding: 0,
      width: "40px",
    },
    labelText: {
      color: theme.palette.grey[500],
      cursor: "pointer",
      display: "block",
      fontWeight: "bold",
      marginRight: theme.spacing(1),
      textTransform: "uppercase",
    },
    switchBase: {
      left: 1,
      padding: 0,
      top: 1,
    },
    switchContainer: {
      alignItems: "center",
      alignSelf: "flex-start",
    },
    thumb: {
      boxShadow: "none",
      color: theme.palette.common.white,
      height: "18px",
      width: "18px",
    },
    track: {
      backgroundColor: theme.palette.grey[300],
      opacity: 1,
    },
  })
);
