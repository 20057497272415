import { api } from "@common/api/api";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import {
  CreditCardFormError,
  ZuoraInlineFormError,
} from "@portal/components/ZuoraCardForm/ZuoraInlineFormError.service";
import { useDebounce } from "@portal/hooks/useDebounce";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import { getZuoraParams } from "@portal/utils/baseZuoraParams.util";
import { zuora } from "@portal/utils/zuora.util";
import dayjs from "dayjs";
import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

interface ZuoraCardFormProps {
  onSuccess(zuoraResponse: ZuoraAddPaymentResponseType): void;
  onFormLoading?(isLoading: boolean): void;
  onFailure?(error: string): void;
}

export const ZuoraCardForm: FC<ZuoraCardFormProps> = ({
  onSuccess,
  onFormLoading,
  onFailure,
}) => {
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const [isZuoraFormRendering, setIsZuoraFormRendering] = useState<boolean>(
    false
  );
  const customerId = useSelector(selectCurrentCustomerId);
  const zuoraCardForm = useRef<HTMLDivElement>(null);

  const setZuoraFormLoading = (loading: boolean) => {
    setIsZuoraFormRendering(loading);
    onFormLoading?.(loading);
  };

  /**
   * Zuora can return multiple empty error messages (like when a customer hits submit without filling cc form)
   * We are just debouncing this so at most only one flash error is shown. If the partially fill the form,
   * then another message will be shown
   */
  const debouncedClientErrorMessageCallback = useDebounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      const zuoraInlineFormErrorService = new ZuoraInlineFormError(
        errorType,
        errorCode,
        errorDescription
      );

      const { error } = zuoraInlineFormErrorService;

      const errorMessage = t(`ZuoraCardForm.inlineFormError.${error}`);

      if (error === CreditCardFormError.tooManyTries) {
        onFailure?.(errorMessage);
      } else {
        flash.error(errorMessage);
      }
    },
    50
  );

  useEffect(() => {
    setZuoraFormLoading(true);
    api.billing
      .fetchRSASignature()
      .then((signature) => {
        zuora.setEventHandler("onloadCallback", () => {
          setZuoraFormLoading(false);
        });

        zuora.renderWithErrorHandler(
          {
            ...getZuoraParams(dayjs().locale()),
            ...signature,
          },
          {},
          async (response: ZuoraAddPaymentResponseType) => {
            const errorMessage = t("ZuoraCardForm.errorAddingCard");

            if (!response.success) {
              onFailure?.(errorMessage);

              return;
            }

            if (customerId) {
              const [error] = await handleAjaxCall(
                api.billing.paymentMethods.update(response.refId, {
                  customerId,
                })
              );

              if (error) {
                onFailure?.(errorMessage);

                return;
              }
            }

            // Everything went well
            onSuccess(response);
          },
          debouncedClientErrorMessageCallback
        );
      })
      .catch(() => {
        onFailure?.(t("ZuoraCardForm.authorizationFailed"));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isZuoraFormRendering && <RhCircularProgress marginBottom={4} />}
      <div ref={zuoraCardForm}>
        <Box
          id="zuora_payment"
          data-testid="zuoraPaymentForm"
          height="100%"
          width="100%"
        />
      </div>
    </>
  );
};
