import {
  AccountSummaryType,
  InvoiceType,
} from "@common/types/accountSummaryTypes";
import { AjaxStatus, AjaxStatuses } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import {
  RewardsRedeemedActionPayload,
  rewardsRedeemed,
} from "@portal/slices/rewardsSlice";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import merge from "lodash/merge";

export type AccountSummaryStateType = {
  data: AccountSummaryType | null;
  error: RhApiError | null;
  requestStatus: AjaxStatus;
};

export const initialAccountSummaryState = Object.freeze<AccountSummaryStateType>(
  {
    data: null,
    error: null,
    requestStatus: AjaxStatuses.Idle,
  }
);

// Handlers
export const accountSummaryUpdatedHandler: CaseReducer<
  AccountSummaryStateType,
  PayloadAction<Partial<AccountSummaryType>>
> = (state, action) => ({
  error: state.error,
  requestStatus: state.requestStatus,
  data: merge({}, state.data, action.payload),
});

export const rewardsRedeemedHandler: CaseReducer<
  AccountSummaryStateType,
  PayloadAction<RewardsRedeemedActionPayload>
> = (state, action) => {
  if (!state.data) {
    return state;
  }

  const intCredit = action.payload.amountUsed * 100;
  let intCreditRemaining = intCredit;

  const newInvoices = state.data.invoices.reduceRight<InvoiceType[]>(
    (updatedInvoices, invoice) => {
      const intInvoiceBalance = invoice.balance * 100;
      const intRewardCreditApplied = invoice.rewardCreditApplied * 100;

      if (intInvoiceBalance <= 0 || !intCreditRemaining) {
        return [invoice, ...updatedInvoices];
      }
      const intAmountToApply =
        intInvoiceBalance < intCreditRemaining
          ? intInvoiceBalance
          : intCreditRemaining;

      intCreditRemaining -= intAmountToApply;

      return [
        {
          ...invoice,
          balance: (intInvoiceBalance - intAmountToApply) / 100,
          rewardCreditApplied:
            (intRewardCreditApplied + intAmountToApply) / 100,
        },
        ...updatedInvoices,
      ];
    },
    []
  );

  return {
    ...state,
    data: {
      ...state.data,
      totalBalance: (state.data.totalBalance * 100 - intCredit) / 100,
      pastDueBalance: Math.max(
        (state.data.pastDueBalance * 100 - intCredit) / 100,
        0
      ),
      invoices: newInvoices,
    },
  };
};

export const accountSummaryRequestedHandler: CaseReducer<
  AccountSummaryStateType,
  PayloadAction
> = (state) => ({
  ...state,
  requestStatus: AjaxStatuses.Pending,
});

export const accountSummaryReceivedHandler: CaseReducer<
  AccountSummaryStateType,
  PayloadAction<AccountSummaryType>
> = (_state, action) => ({
  requestStatus: AjaxStatuses.Success,
  data: action.payload,
  error: null,
});

export const accountSummaryErroredHandler: CaseReducer<
  AccountSummaryStateType,
  PayloadAction<RhApiError>
> = (state, action) => ({
  requestStatus: AjaxStatuses.Failure,
  error: action.payload,
  data: state.data,
});

const accountSummaryReducers = {
  updated: accountSummaryUpdatedHandler,
  requested: accountSummaryRequestedHandler,
  received: accountSummaryReceivedHandler,
  errored: accountSummaryErroredHandler,
};

const accountSummarySlice = createSlice<
  AccountSummaryStateType,
  typeof accountSummaryReducers
>({
  name: "accountSummary",
  initialState: initialAccountSummaryState,
  reducers: accountSummaryReducers,
  extraReducers: {
    [rewardsRedeemed.type]: rewardsRedeemedHandler,
  },
});

export const accountSummaryReducer = accountSummarySlice.reducer;
export const accountSummaryUpdated = accountSummarySlice.actions.updated;
export const accountSummaryRequested = accountSummarySlice.actions.requested;
export const accountSummaryErrored = accountSummarySlice.actions.errored;
export const accountSummaryReceived = accountSummarySlice.actions.received;

export const accountSummaryFetch = createAction(
  `${accountSummarySlice.name}/fetch`
);
export const accountSummaryRefetch = createAction(
  `${accountSummarySlice.name}/refetch`
);
