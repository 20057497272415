import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useMyAccountServiceInfoStyles = rhMakeStyles<
  typeof rhythmTheme,
  "subSection"
>((theme) =>
  createStyles({
    subSection: {
      flex: 1,
    },
  })
);
