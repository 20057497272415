import debounce from "@material-ui/core/utils/debounce";
import { useCallback, useEffect, useRef } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CallbackType = (...args: any[]) => void;

const DEFAULT_DELAY_MS = 250;

export const useDebounce = (
  cb: CallbackType,
  delay: number = DEFAULT_DELAY_MS
) => {
  const debounced = debounce((...args) => cbRef.current(...args), delay);
  const cbRef = useRef(cb);

  useEffect(() => {
    cbRef.current = cb;
    return debounced.clear;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounced, [delay]);
};
