import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { trueWhite } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useRhButtonToggleStyles = makeStyles<
  typeof rhythmTheme,
  { color: "primary" | "secondary"; fullWidth?: boolean },
  "root" | "item" | "active"
>((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: ({ fullWidth }) => (fullWidth ? "100%" : "auto"),
    },
    item: {
      flexBasis: "50%",
      fontWeight: FontWeight.Regular,
      backgroundColor: ({ color }) => theme.palette[color]["50"],
      borderColor: ({ color }) => theme.palette[color]["200"],
      color: ({ color }) => theme.palette[color]["300"],
      "&:hover": {
        zIndex: 1,
      },
    },
    active: {
      backgroundColor: `${trueWhite} !important`,
      color: ({ color }) => theme.palette[color].main,
    },
  })
);
