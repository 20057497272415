import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { Box } from "@material-ui/core";
import { usePickYourOwnDueDayBoxStyles } from "@portal/components/MyAccountBillingPreferencesCard/PickYourOwnDueDayBox.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

interface PickYourOwnDueDayBoxProps {
  name: string;
  pickedBillingDueDay: number | null;
  openModal: () => void;
}
export const PickYourOwnDueDayBox: FC<PickYourOwnDueDayBoxProps> = ({
  name,
  pickedBillingDueDay,
  openModal,
}) => {
  const { t } = useRhIntl();
  const classes = usePickYourOwnDueDayBoxStyles();

  const title = t("MyAccountBillingPreferencesCard.pickYourOwnDate.title");

  const description = t(
    "MyAccountBillingPreferencesCard.pickYourOwnDate.description"
  );

  const buttonText = t(
    "MyAccountBillingPreferencesCard.pickYourOwnDate.selectDueDate"
  );

  const displayDayMessage = t(
    "MyAccountBillingPreferencesCard.pickYourOwnDate.displayDay",
    {
      day: pickedBillingDueDay,
    }
  );

  return (
    <RhInnerCard className={classes.innerCard}>
      <Box pr={2} mb={1}>
        <RhTypography
          color="textPrimary"
          variant="subtitle2"
          fontWeight={FontWeight.Semibold}
        >
          {title}
        </RhTypography>
        <RhTypography
          color="textSecondary"
          variant="body1"
          display="block"
          className={classes.detail}
        >
          {description}
        </RhTypography>
      </Box>

      {pickedBillingDueDay ? (
        <RhTypography className={classes.buttonOrMessage}>
          {displayDayMessage}
        </RhTypography>
      ) : (
        <RhButton
          color="primary"
          size="small"
          onClick={openModal}
          className={classes.buttonOrMessage}
        >
          {buttonText}
        </RhButton>
      )}
    </RhInnerCard>
  );
};
