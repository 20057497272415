import { ReactNode } from "react";
import { useIntl } from "react-intl";

export const useRhIntl = () => {
  const { formatMessage } = useIntl();

  type TArgs = Parameters<typeof formatMessage>;
  type TValues = TArgs[1];
  type TOpts = TArgs[2];

  function t(id: string, values: void): string;
  function t(id: string, values: TValues, opts?: TOpts): ReactNode;
  function t(id: string, values: unknown, opts?: TOpts) {
    if (values) {
      return formatMessage({ id }, values as TValues, opts);
    } else {
      return formatMessage({ id });
    }
  }

  return {
    t,
  };
};
