import { DUE_DATE_FORMAT } from "@common/constants/date.constant";
import { Invoice } from "@common/models/Invoice.model";
import {
  AccountSummaryType,
  InvoiceType,
} from "@common/types/accountSummaryTypes";
import dayjs from "dayjs";

export class AccountSummary {
  public totalBalance: number;
  public pastDueBalance: number;
  public accountDueDate: string;
  public rawInvoices: InvoiceType[];
  public nextDueDate: string;

  public constructor(accountSummaryAttrs: AccountSummaryType | null) {
    this.rawInvoices = accountSummaryAttrs?.invoices ?? [];
    this.totalBalance = accountSummaryAttrs?.totalBalance ?? 0;
    this.pastDueBalance = accountSummaryAttrs?.pastDueBalance ?? 0;
    this.accountDueDate = accountSummaryAttrs?.accountDueDate ?? "";
    this.nextDueDate = accountSummaryAttrs?.nextDueDate ?? "";
  }

  public get invoices(): Invoice[] {
    return this.rawInvoices
      .filter((invoice) => invoice.status === "Posted")
      .map((invoice) => new Invoice(invoice))
      .sort((a, b) => {
        return dayjs(a.dueDate) <= dayjs(b.dueDate) ? 1 : -1;
      });
  }

  public get currentDueBalance(): number {
    return this.totalBalance - this.pastDueBalance;
  }

  public get hasInvoices(): boolean {
    return Boolean(this.invoices.length);
  }

  public get hasPastDueInvoices(): boolean {
    const today = dayjs().startOf("day");

    const invoicesWithBalancePastDue = this.invoices.filter((invoice) => {
      const invoiceDueDate = dayjs(invoice.dueDate);

      return invoiceDueDate.isBefore(today) && invoice.balance > 0;
    });

    return invoicesWithBalancePastDue.length > 0;
  }

  public get noBalanceDue(): boolean {
    return this.totalBalance <= 0;
  }

  public get formattedAccountDueDate(): string {
    return dayjs(this.accountDueDate).format(DUE_DATE_FORMAT);
  }

  public get formattedNextDueDate(): string {
    return dayjs(this.nextDueDate).format(DUE_DATE_FORMAT);
  }
  /**
   * Gives an invoice that has a due date today or in the future
   * @returns invoice to pay or null if not found
   */
  public get invoiceToPay(): Invoice | null {
    const today = dayjs().startOf("day");

    const invoiceToPay = this.invoices.find((invoice) => {
      const invoiceDueDate = dayjs(invoice.dueDate).startOf("day");

      return invoiceDueDate.isSame(today) || invoiceDueDate.isAfter(today);
    });

    return invoiceToPay || null;
  }
}
