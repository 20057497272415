import { Method } from "axios";

export const AREA_NOT_SERVICED = "pricing.offers.unavailable";
export const IRONHIDE_CUSTOMER = "prospect.already_ironhide_customer";
export const INVALID_ADDRESS = "edi.meter.invalid_address";
export const PRICING_OFFERS_MULTIPLE_UTILITIES =
  "pricing.offers.multipleUtilities";
export const METER_IN_USE = "edi.meter.esi_id";
export const MULTIPLE_METERS = "edi.meter.multiple_meters";
export const ALLOY_API_UNAVAILABLE = "rhythm.prospect.alloy.api_not_available";
export const CUSTOMER_ENROLLMENT_ERROR = "customer.enroll";
export const CUSTOMER_NOT_FOUND_ERROR = "customer.error.not_found";
export const CUSTOMER_USAGE_NOT_FOUND_ERROR = "customer.usage.error.not_found";
export const OKTA_REQUEST_ERROR = "okta.request.failed";
export const OKTA_PASSWORD_REQUIREMENTS_ERROR =
  "okta.request.passwordDoesNotMeetRequirements";
export const GENERAL_ERROR = "error.general";
export const CUSTOMER_UPDATE_FAILED = "error.customerUpdateFailed";
export const ZUORA_DEPOSIT_ERROR = "zuora.createDepositOrder.failed";
export const VOUCHERIFY_NOT_READY = "voucherify.error.account_not_ready";

export interface RhApiErrorResponseData {
  errors: {
    code: string;
  }[];
  errorCode: string | null;
  stateToken?: string;
}
export interface RhApiError {
  data: RhApiErrorResponseData;
  status?: number;
  statusText?: string;
  config: {
    baseURL?: string;
    data?: string;
    params?: string;
    method?: Method;
    url?: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormError extends Record<string, string[] | FormError> {}
