import {
  InputStyles,
  RhTextField,
} from "@design-system/components/RhTextField/RhTextField";
import { ReactComponent as EyeCon } from "@design-system/icons/eye-icon.svg";
import { IconButton, InputAdornment } from "@material-ui/core";
import { FieldValidator } from "final-form";
import React, { FC, useState } from "react";

export interface RhPasswordFieldProps<Type> {
  autoComplete?: string;
  autoFocus?: boolean;
  labels?: {
    hidePassword?: string;
    showPassword?: string;
  };
  name: string;
  placeholder?: string;
  validate?: FieldValidator<Type>;
  styles?: InputStyles;
}

export const RhPasswordField: FC<RhPasswordFieldProps<string>> = ({
  children,
  labels = { hidePassword: "Hide Password", showPassword: "Show Password" },
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <RhTextField
      {...restProps}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="primary"
              onClick={toggleShowPassword}
              aria-label={
                showPassword ? labels.hidePassword : labels.showPassword
              }
            >
              <EyeCon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    >
      {children}
    </RhTextField>
  );
};
