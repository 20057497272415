import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { prospectRefetch } from "@portal/slices/signUpSlice";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const POLLING_INTERVAL = 2000;
const POLLING_TIMEOUT = 2 * 60 * 60 * 1000;

interface ProspectBilling {
  prospectId: string | null;
  billingPaymentMethodId: string | null;
}

export const usePollForProspectBilling = (): ProspectBilling => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const flash = useRhFlash();

  const { prospectId, billingPaymentMethodId } = useSelector<
    PortalStoreState,
    ProspectBilling
  >(({ signUp }) => ({
    prospectId: signUp.prospectId,
    billingPaymentMethodId: signUp.billingPaymentMethodId,
  }));

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (prospectId && !billingPaymentMethodId) {
      intervalId = setInterval(
        () => dispatch(prospectRefetch()),
        POLLING_INTERVAL
      );
    }
    const stopPolling = () => {
      clearInterval(intervalId);
    };

    setTimeout(stopPolling, POLLING_TIMEOUT);

    return stopPolling;
  }, [prospectId, billingPaymentMethodId, dispatch]);

  useEffect(() => {
    if (!prospectId) {
      flash.error(
        formatMessage({ id: "SignUpIVRPaymentPage.apiErrorMissingProspectId" })
      );
    }
  }, [prospectId, flash, formatMessage]);

  return { prospectId, billingPaymentMethodId };
};
