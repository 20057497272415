import { api } from "@common/api/api";
import {
  PaymentMethodType,
  PaymentMethodsResponseType,
} from "@common/types/paymentMethodTypes";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import { selectBillingDetailsState } from "@portal/selectors/billingDetailsSelectors";
import {
  billingDetailsErrored,
  billingDetailsFetch,
  billingDetailsReceived,
  billingDetailsRefetch,
  billingDetailsRequested,
  paymentMethodSetAsDefault,
  paymentMethodSetAsDefaultRequest,
} from "@portal/slices/billingDetailsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  SagaReturnType,
  all,
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

export function* fetchPaymentMethodsWorker() {
  const { data } = yield select(selectBillingDetailsState);

  if (!data) {
    yield call(refetchPaymentMethodsWorker);
  }
}

export function* refetchPaymentMethodsWorker() {
  yield put(billingDetailsRequested());

  try {
    const billingDetails: PaymentMethodsResponseType = yield call(
      api.billing.paymentMethods.list
    );

    yield put(billingDetailsReceived(billingDetails.paymentMethods));
  } catch (err) {
    yield put(billingDetailsErrored(err));
  }
}

export function* setDefaultPaymentMethodWorker({
  payload: { id: paymentMethodId },
}: PayloadAction<Pick<PaymentMethodType, "id">>) {
  const customerId: SagaReturnType<
    typeof selectCurrentCustomerId
  > = yield select(selectCurrentCustomerId);

  if (customerId) {
    try {
      yield call(api.billing.paymentMethods.default, paymentMethodId, {
        customerId,
      });

      yield put(paymentMethodSetAsDefault({ id: paymentMethodId }));
      yield call(refetchPaymentMethodsWorker);
    } catch (err) {
      yield put(billingDetailsErrored(err));
    }
  }
}

export function* billingDetailsWatcher() {
  yield all([
    takeLatest(billingDetailsFetch.type, fetchPaymentMethodsWorker),
    takeLatest(billingDetailsRefetch.type, refetchPaymentMethodsWorker),
    takeLatest(
      paymentMethodSetAsDefaultRequest.type,
      setDefaultPaymentMethodWorker
    ),
  ]);
}
