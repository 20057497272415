import { boxShadows } from "@design-system/constants/boxShadows";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core/styles";

const cardSize = {
  height: 158,
  width: 164,
};

interface RhIconRadioFieldProps {
  checked: boolean;
  hasErrors: boolean;
}
export const useRhIconRadioFieldStyles = makeStyles<
  Theme,
  RhIconRadioFieldProps,
  | "cardRoot"
  | "cardLabel"
  | "iconContainer"
  | "labelContainer"
  | "radioContainer"
>((theme) => {
  const getColor = ({ checked }: RhIconRadioFieldProps) => {
    if (checked) {
      return theme.palette.primary.main;
    }
    return theme.palette.grey["400"];
  };

  const getBorderColor = ({ hasErrors, checked }: RhIconRadioFieldProps) => {
    if (hasErrors) {
      return theme.palette.error.main;
    } else if (checked) {
      return theme.palette.primary.main;
    } else {
      return theme.palette.grey["100"];
    }
  };

  const getHoverColor = ({ checked, hasErrors }: RhIconRadioFieldProps) =>
    checked ? getColor({ checked, hasErrors }) : theme.palette.primary.light;

  return createStyles({
    cardLabel: {
      color: theme.palette.text.primary,
      lineHeight: 1.2,
      padding: theme.spacing(0.5),
      textAlign: "center",
    },
    cardRoot: {
      "&:hover": {
        borderColor: getHoverColor,
        boxShadow: boxShadows.active,
        color: getHoverColor,
      },
      alignItems: "center",
      borderColor: getBorderColor,
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 1,
      boxShadow: boxShadows.default,
      color: getColor,
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: 0,
      padding: `0 ${theme.spacing(1)}px`,
      ...cardSize,
    },
    iconContainer: {
      alignItems: "center",
      height: 43,
      justifyContent: "center",
      marginTop: 27,
      width: 43,
    },
    labelContainer: {
      alignItems: "center",
      height: 36,
      justifyContent: "center",
      marginTop: 7,
      overflow: "hidden",
    },
    radioContainer: {
      marginTop: -1,
    },
  });
});
