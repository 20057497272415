import { CustomerPreferencesStateType } from "@portal/slices/customerPreferencesSlice";
import {
  SupportedPortalLanguage,
  englishLanguageCode,
} from "@portal/translations/portalTranslations";

export const selectCustomerLanguagePreference = ({
  customerPreferences: { languagePreference },
}: {
  customerPreferences: CustomerPreferencesStateType;
}): SupportedPortalLanguage => {
  if (languagePreference) {
    return languagePreference;
  }
  if (window.navigator?.language) {
    return window.navigator.language.substr(0, 2) as SupportedPortalLanguage;
  }
  return englishLanguageCode;
};
