import { RhCard } from "@design-system/components/RhCard/RhCard";
import { PaymentMethodsSection } from "@portal/components/PaymentMethodsSection/PaymentMethodsSection";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

export const MyAccountPaymentMethodsCard: FC = () => {
  const { t } = useRhIntl();

  const title = t("MyAccountPaymentMethodsCard.title");

  return (
    <RhCard title={title}>
      <PaymentMethodsSection />
    </RhCard>
  );
};
