import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import { LOGGED_OUT_FIELD_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { useLoggedOutPageHeaderStyles } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC, ReactNode } from "react";

interface SignUpHeaderLayoutProps {
  headerTextId: string;
  headerTextValues?: Record<string, string>;
  subHeaderTextId?: string;
  illustration?: ReactNode;
}

export const LoggedOutPageHeader: FC<SignUpHeaderLayoutProps> = ({
  headerTextId,
  headerTextValues,
  subHeaderTextId,
  illustration,
}) => {
  const { t } = useRhIntl();
  const classes = useLoggedOutPageHeaderStyles();
  const headerText = t(headerTextId, headerTextValues);
  const subHeaderText = subHeaderTextId && t(subHeaderTextId);

  return (
    <Box component="header" className={classes.root}>
      {illustration && (
        <div className={classes.illustrationContainer}>{illustration}</div>
      )}
      <RhTypography variant="h1" align="center">
        {headerText}
      </RhTypography>
      {subHeaderText && (
        <Box mt={LOGGED_OUT_FIELD_SPACING}>
          <RhTypography
            variant="subtitle2"
            align="center"
            color="textSecondary"
          >
            {subHeaderText}
          </RhTypography>
        </Box>
      )}
    </Box>
  );
};
