// NOTE: THIS PAGE IS BEHIND THE FEATURE FLAG 'addAchFeature' AND IS ONLY SERVED UP WHEN IT'S TRUE
// SEE PortalRoutes.tsx FOR LOGIC
import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { RSASignatureResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhIconRadioField } from "@design-system/components/RhIconRadioField/RhIconRadioField";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as BankIcon } from "@design-system/icons/bank-icon.svg";
import { ReactComponent as CreditCardsIcon } from "@design-system/icons/credit-cards-icon.svg";
import { Box } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useSignUpPaymentPageStyles } from "@portal/pages/SignUpPaymentPage/SignUpPaymentPage.style";
import { SignUpPlaidLink } from "@portal/pages/SignUpPaymentPage/SignUpPlaidLink";
import { SignUpZuoraCardForm } from "@portal/pages/SignUpPaymentPage/SignUpZuoraCardForm";
import {
  selectProspectId,
  selectSignUpContactFormValues,
} from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

export interface SignUpPaymentFormValues {
  billingPaymentMethodId: string | null;
}

enum ActivePaymentFormType {
  CreditDebit = "CreditDebit",
  ACH = "ACH",
}

type PaymentType = keyof typeof ActivePaymentFormType | null;

interface SignUpSelectPaymentFormValues {
  paymentType: PaymentType;
}

const SignUpSelectPaymentFormValidator = generateValidationErrorCollector<SignUpSelectPaymentFormValues>(
  {
    paymentType: [isRequired],
  }
);

export const SignUpPaymentPage: FC = () => {
  const { t } = useRhIntl();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const [
    activePaymentForm,
    setActivePaymentForm,
  ] = useState<ActivePaymentFormType | null>(null);
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | null>(null);
  const [plaidTokenError, setPlaidTokenError] = useState<RhApiError | null>(
    null
  );
  const [
    zuoraRsaSignatureResponse,
    setZuoraRsaSignatureResponse,
  ] = useState<RSASignatureResponseType | null>(null);
  const [zuoraError, setZuoraError] = useState<RhApiError | null>(null);
  const flash = useRhFlash();
  const classes = useSignUpPaymentPageStyles();
  const { autopay } = useSelector<PortalStoreState, { autopay: boolean }>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ signUp: { autopay } }) => ({ autopay })
  );
  const prospectId = useSelector(selectProspectId);
  const { email } = useSelector(selectSignUpContactFormValues);

  useEffect(() => {
    setRSASignature().catch(setZuoraError);
    if (prospectId) {
      setBillingLinkToken(prospectId).catch(setPlaidTokenError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRSASignature = () =>
    api.billing.fetchRSASignature().then((resp) => {
      setZuoraRsaSignatureResponse(resp);
      setZuoraError(null);
    });
  const setBillingLinkToken = (id: IdType) =>
    api.prospects.billingLinkToken(id, email).then(({ linkToken }) => {
      setPlaidLinkToken(linkToken);
      setPlaidTokenError(null);
    });

  if (!prospectId) {
    return null;
  }

  const skipPaymentSteps = () => {
    trackEvent({ action: ActionType.click, label: "noPaymentInfo" });
    signUpClickNextStepHandler({
      signUpData: { billingPaymentMethodId: null },
      nextStep: "summary",
      track: true,
    });
  };

  const handleBackButtonClick = () => {
    setActivePaymentForm(null);
  };

  const onSubmit = ({ paymentType }: SignUpSelectPaymentFormValues) => {
    switch (paymentType) {
      case ActivePaymentFormType.CreditDebit:
        // if there is an error, try re-fetching to see if the server fixed the issue
        if (zuoraError) {
          return setRSASignature()
            .then(() => setActivePaymentForm(ActivePaymentFormType.CreditDebit))
            .catch((_error: RhApiError) => {
              flash.error(t("ZuoraCardForm.authorizationFailed"));
              return { paymentType: t("ZuoraCardForm.authorizationFailed") };
            });
        }

        return setActivePaymentForm(ActivePaymentFormType.CreditDebit);
      case ActivePaymentFormType.ACH:
        // if there is an error, try re-fetching to see if the server fixed the issue
        if (plaidTokenError) {
          return setBillingLinkToken(prospectId)
            .then(() => setActivePaymentForm(ActivePaymentFormType.ACH))
            .catch((_error: RhApiError) => {
              flash.error(t("AddACH.plaidIssues"));
              return { paymentType: t("AddACH.plaidIssues") };
            });
        }

        return setActivePaymentForm(ActivePaymentFormType.ACH);
      default:
    }
  };

  return (
    <SignUpPageLayout
      handleBackButtonClick={
        activePaymentForm ? handleBackButtonClick : undefined
      }
    >
      {!activePaymentForm && (
        <>
          <LoggedOutPageHeader headerTextId="SignUpPaymentPage.preferredPaymentMethod" />
          <Form<SignUpSelectPaymentFormValues>
            initialValues={{ paymentType: null }}
            onSubmit={onSubmit}
            validate={SignUpSelectPaymentFormValidator}
            render={({ handleSubmit }) => (
              <LoggedOutForm
                onSubmit={handleSubmit}
                submitButtonText={t("SignUpPaymentPage.submitCta")}
              >
                <LoggedOutFieldsLayout>
                  <RhFlexBox justifyContent="space-between" clone>
                    <RadioGroup row aria-label="Payment Type">
                      <RhIconRadioField
                        value={ActivePaymentFormType.ACH}
                        icon={<BankIcon />}
                        label={t("SignUpPaymentPage.bankAccount")}
                        name="paymentType"
                      />
                      <RhIconRadioField
                        value={ActivePaymentFormType.CreditDebit}
                        icon={<CreditCardsIcon />}
                        label={t("SignUpPaymentPage.creditOrDebit")}
                        name="paymentType"
                      />
                    </RadioGroup>
                  </RhFlexBox>
                  {!autopay && (
                    <Box className={classes.skipPaymentContainer}>
                      <RhTypography
                        component="a"
                        color="primary"
                        onClick={skipPaymentSteps}
                      >
                        {t("SignUpPaymentPage.skipPaymentInfo")}
                      </RhTypography>
                    </Box>
                  )}
                </LoggedOutFieldsLayout>
              </LoggedOutForm>
            )}
          />
        </>
      )}
      {plaidLinkToken && (
        <SignUpPlaidLink
          token={plaidLinkToken}
          triggerOpen={activePaymentForm === ActivePaymentFormType.ACH}
          onPlaidModalStateChange={(isOpen) => {
            if (!isOpen) {
              setActivePaymentForm(null);
            }
          }}
        />
      )}
      {zuoraRsaSignatureResponse && (
        <div hidden={activePaymentForm !== ActivePaymentFormType.CreditDebit}>
          <SignUpZuoraCardForm signature={zuoraRsaSignatureResponse} />
        </div>
      )}
    </SignUpPageLayout>
  );
};
