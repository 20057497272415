import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhRoundTabItemStyles = makeStyles<
  typeof rhythmTheme,
  "root" | "wrapper"
>(({ palette, breakpoints }) =>
  createStyles({
    root: {
      "&.selected": {
        "& $wrapper": {
          color: palette.primary.main,
        },
      },
      fontSize: 12,
      height: 28,
      minHeight: 0,
      opacity: 1,
      [breakpoints.up("md")]: {
        minWidth: 70,
      },
    },
    wrapper: () => ({ color: palette.grey[900] }),
  })
);
