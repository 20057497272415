import { DynamicLink } from "@common/components/DynamicLink/DynamicLink";
import {
  rafPointsAmount,
  rafPointsValue,
} from "@common/constants/rewards.constant";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { CopyReferralLinkButton } from "@portal/components/CopyReferralLinkButton/CopyReferralLinkButton";
import { useReferAFriendDialogStyles } from "@portal/components/ReferAFriendDialog/ReferAFriendDialog.style";
import { REWARDS_FAQ_URL } from "@portal/routes/routePaths";
import React from "react";
import { useIntl } from "react-intl";

export interface ReferAFriendDialogProps {
  onClose: () => void;
}

export const ReferAFriendDialog = ({ onClose }: ReferAFriendDialogProps) => {
  const classes = useReferAFriendDialogStyles();
  const { formatMessage } = useIntl();

  const title = formatMessage({ id: "ReferAFriendDialog.title" });
  const subtitle = formatMessage(
    { id: "ReferAFriendDialog.subtitle" },
    {
      pointsAmount: rafPointsAmount,
      pointsValue: rafPointsValue,
      linkChunk: (content) => (
        <DynamicLink external to={REWARDS_FAQ_URL}>
          {content}
        </DynamicLink>
      ),
    }
  );

  return (
    <RhDialog
      open
      onClose={onClose}
      size="medium"
      classes={{ paper: classes.dialogContainer }}
    >
      <RhTypography variant="subtitle1" color="textPrimary">
        {title}
      </RhTypography>
      <RhTypography variant="body2" className={classes.subtitle}>
        {subtitle}
      </RhTypography>
      <CopyReferralLinkButton dense />
    </RhDialog>
  );
};
