import {
  StepperCheckIcon,
  StepperFourIcon,
  StepperOneIcon,
  StepperThreeIcon,
  StepperTwoIcon,
} from "@design-system/icons";
import { Box, StepIconProps } from "@material-ui/core";
import { useSignUpStepperIconStyles } from "@portal/components/SignUpStepper/SignUpStepperIcon.style";
import { StepperKeyType } from "@portal/components/SignUpStepper/signUpStepperTypes";
import React, { FC } from "react";
import { useIntl } from "react-intl";

export const StepIcons: Record<
  StepperKeyType | "completed",
  typeof StepperOneIcon
> = {
  plans: StepperOneIcon,
  contact: StepperTwoIcon,
  payment: StepperThreeIcon,
  summary: StepperFourIcon,
  completed: StepperCheckIcon,
};

export const SignUpStepperIcon: FC<StepIconProps> = ({
  completed,
  icon,
  active,
}) => {
  const { formatMessage } = useIntl();

  // MUI recommends co-opting the icon prop if you need to lookup a custom SVG
  // that is: pass the icon NAME, then use that to look up your actual icon
  const iconName = icon as StepperKeyType;

  const completedText = formatMessage({ id: "SignUpStepper.completed" });
  const thisStep = formatMessage({ id: "SignUpStepper.thisStep" });
  const stepText = formatMessage({ id: `SignUpStepper.${iconName}` });

  const completedIconTitle = `${stepText} ${completedText}`;
  const activeNumberTitle = `${thisStep}: ${stepText}`;
  const inactiveNumberTitle = stepText;

  const NumberIcon = StepIcons[iconName];
  const CompletedIcon = StepIcons.completed;

  const { root } = useSignUpStepperIconStyles({ active, completed });

  let title;

  if (completed) {
    title = completedIconTitle;
  } else {
    title = active ? activeNumberTitle : inactiveNumberTitle;
  }

  return (
    <Box className={root}>
      {completed ? (
        <CompletedIcon
          data-testid={`SignUpStepperIcon-${iconName}-completed`}
          title={title}
        />
      ) : (
        <NumberIcon
          data-testid={`SignUpStepperIcon-${iconName}-number`}
          title={title}
        />
      )}
    </Box>
  );
};
