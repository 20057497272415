import { CreateCustomerRequest } from "@common/api/apiRequests";
import { unformatDateOfBirth } from "@common/forms/formatters";
import { SignUpStateType } from "@portal/slices/signUpSlice";

export const toCreateCustomerRequest = (
  data: SignUpStateType & {
    csrSignUp?: boolean;
  }
): CreateCustomerRequest => {
  return {
    customerData: {
      autopay: data.autopay,
      billingPaymentMethodId: data.billingPaymentMethodId,
      csrSignUp: data.csrSignUp || false,
      dateOfBirth: unformatDateOfBirth(data.dateOfBirth),
      depositAmount: data.depositAmount || 0,
      email: data.email,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      enrollmentType: data.enrollmentType!,
      esiId: data.esiId,
      firstName: data.firstName,
      invoiceByPrint: data.invoiceByPrint,
      languagePreference: data.languagePreference || "en",
      lastName: data.lastName,
      offersnapshotId: data.offersnapshotId,
      phone: data.phone,
      prospectId: data.prospectId,
      referralFromFriendCode: data.referralFromFriendCode,
      sendMarketingPromos: data.sendMarketingPromos,
      serviceAddress: {
        addressLine1: data.serviceAddress.addressLine1,
        unitNumber: data.serviceAddress.unitNumber || "",
        city: data.serviceAddress.city,
        state: data.serviceAddress.state,
        zipCode: data.serviceAddress.zipCode,
      },
      serviceStartDate: data.serviceStartDate,
    },
  };
};
