import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { RhApiError } from "@common/types/errorTypes";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box, Link } from "@material-ui/core";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { PortalPasswordField } from "@portal/components/PortalPasswordField/PortalPasswordField";
import { IRONHIDE_SIGN_IN_URL } from "@portal/constants/urls.constant";
import { useFeatureFlags } from "@portal/hooks/useFeatureFlags";
import { forgotPasswordPath } from "@portal/routes/routePaths";
import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import { FORM_ERROR } from "final-form";
import React, { FC, useEffect } from "react";
import { Form } from "react-final-form";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

export interface SignInFormValues {
  email: string;
  password: string;
}

const initialValues = Object.freeze<SignInFormValues>({
  email: "",
  password: "",
});

const signInFormValidator = generateValidationErrorCollector<SignInFormValues>({
  email: [isRequired, isValidEmail],
  password: [isRequired],
});

export const SignInPage: FC = () => {
  const { formatMessage } = useIntl();
  const flash = useRhFlash();
  const { announceNotice, clearAnnouncement } = useRhAnnouncement();

  useEffect(() => {
    announceNotice(
      <div>
        {formatMessage(
          { id: "SignInPage.areYouAnEntrustCustomer" },
          {
            link: (message) => (
              <a href={IRONHIDE_SIGN_IN_URL} rel="noreferrer">
                {message}
              </a>
            ),
          }
        )}
      </div>
    );
    return clearAnnouncement;
  }, [announceNotice, clearAnnouncement, formatMessage]);

  const invalidMessage = formatMessage({
    id: "SignInPage.error.invalidEmailOrPassword",
  });

  const onSubmit = ({ email, password }: SignInFormValues) => {
    return portalAuthClient
      .signIn(email, password)
      .catch((_error: RhApiError) => {
        flash.error(invalidMessage);
        return { [FORM_ERROR]: [invalidMessage] };
      });
  };

  const email = formatMessage({ id: "SignInPage.email" });
  const password = formatMessage({ id: "SignInPage.password" });
  const submitCTA = formatMessage({ id: "SignInPage.submitCTA" });
  const forgotPassword = formatMessage({ id: "SignInPage.forgotPassword" });
  const greeting = formatMessage({ id: "SignInPage.greeting" });

  const { signInGreeting } = useFeatureFlags();

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/assets/SignInPage.css" />
      </Helmet>
      <LoggedOutPageLayout>
        {signInGreeting && <h1>{greeting}</h1>}
        <LoggedOutPageHeader headerTextId="SignInPage.logIn" />
        <Form<SignInFormValues>
          onSubmit={onSubmit}
          validate={signInFormValidator}
          initialValues={{ ...initialValues }}
          render={({ handleSubmit }) => (
            <>
              <LoggedOutForm
                onSubmit={handleSubmit}
                data-testid="signInForm"
                submitButtonText={submitCTA}
                showProgress
              >
                <LoggedOutFieldsLayout>
                  <RhTextField name="email" autoComplete="email" autoFocus>
                    {email}
                  </RhTextField>
                  <PortalPasswordField name="password">
                    {password}
                  </PortalPasswordField>
                </LoggedOutFieldsLayout>
              </LoggedOutForm>
              <Box mt={3}>
                <Link
                  variant="button"
                  align="center"
                  component={RouterLink}
                  to={forgotPasswordPath()}
                >
                  {forgotPassword}
                </Link>
              </Box>
            </>
          )}
        />
      </LoggedOutPageLayout>
    </>
  );
};
