import { useRhIntl } from "@portal/hooks/useRhIntl";
import { BaseErrorPage } from "@portal/pages/BaseErrorPage/BaseErrorPage";
import React, { FC } from "react";

export const Error404Page: FC = () => {
  const { t } = useRhIntl();

  const code = "404";
  const header = t("Error404Page.thisIsAwkward");
  const message = t("Error404Page.notHere");

  return (
    <BaseErrorPage
      code={code}
      header={header}
      message={message}
      showLinkToHome
    />
  );
};
