import { OrderType } from "@common/types/orderTypes";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { CalendarIcon, LockIcon, WindTurbineIcon } from "@design-system/icons";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useMyAccountYourPlanCardStyles } from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.style";
import { formatDateRange, formatShortDate } from "@portal/forms/formatters";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import cx from "classnames";
import React, { FC } from "react";

interface MyAccountOfferCardProps {
  order: OrderType;
}

export const MyAccountOfferCard: FC<MyAccountOfferCardProps> = ({ order }) => {
  const { t } = useRhIntl();
  const classes = useMyAccountYourPlanCardStyles();

  const features = [
    {
      Icon: CalendarIcon,
      text: formatDateRange(
        formatShortDate(order.startDate),
        formatShortDate(order.endDate)
      ),
    },
    {
      Icon: LockIcon,
      text: t("OfferFeatures.fixed"),
    },
    {
      Icon: WindTurbineIcon,
      text: t("OfferFeatures.wind"),
    },
  ];

  return (
    <RhFlexBox>
      <RhCard
        noShadow
        title={
          <>
            <Box className={classes.title}>
              <RhTypography variant="h3">{order.title}</RhTypography>
            </Box>
            <RhTypography variant="body1" color="textSecondary">
              {order.description}
            </RhTypography>
          </>
        }
        className={cx(classes.planInfo, classes.infoColumn)}
      >
        <List dense>
          {features.map(({ Icon, text }) => (
            <ListItem key={text}>
              <ListItemIcon className={classes.listItemIcon}>
                <Icon title={text} />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </RhCard>
    </RhFlexBox>
  );
};
