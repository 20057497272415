import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhApiError } from "@common/types/errorTypes";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { availabilityErrorPaths } from "@portal/pages/SignUpAvailabilityPage/SignUpAvailabilityPage";
import {
  REFERRAL_CODE_QUERY_PARAM,
  SignUpSteps,
  signUpStepPath,
} from "@portal/routes/routePaths";
import { customerUpdatedLanguagePreference } from "@portal/slices/customerPreferencesSlice";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const getSignUpQueryParams = (search: string) => {
  const query = new URLSearchParams(search);

  return {
    campaignSlug: query.get("rcid") ?? null,
    languagePreference:
      (query.get("locale") as SupportedPortalLanguage) ?? null,
    referralCode: query.get(REFERRAL_CODE_QUERY_PARAM) ?? null,
    serviceAddress: {
      addressLine1: query.get("streetLine") ?? "",
      unitNumber: query.get("secondary") ?? "",
      city: query.get("city") ?? "",
      state: query.get("state") ?? "",
      zipCode: query.get("zipcode") ?? "",
    },
  };
};

export const useSignUpQueryParams = () => {
  const { signUpClickNextStepHandler, currentStep } = useSignUpFlow();
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    campaignSlug,
    languagePreference,
    referralCode,
    serviceAddress,
  } = getSignUpQueryParams(search);

  useEffect(() => {
    const inSignUpFlow = SignUpSteps.includes(currentStep);

    if (inSignUpFlow && (campaignSlug || referralCode)) {
      dispatch(
        setSignUpInfo({
          campaignSlug,
          referralFromFriendCode: referralCode,
        })
      );
    }

    if (languagePreference) {
      dispatch(customerUpdatedLanguagePreference({ languagePreference }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    { requestMonitor },
    { setPending, setSuccess },
  ] = useAjaxState<boolean>(!serviceAddress.zipCode);

  useEffect(() => {
    // if a customer is directed to the /sign-up/plans path with a zipcode param from the marketing site
    // we allow them to enter the sign up flow without going through the availability step
    if (pathname.includes(signUpStepPath("plans")) && serviceAddress.zipCode) {
      setPending();
      dispatch(setSignUpInfo({ visitedSteps: ["availability", "plans"] }));
      api.pricing.offers
        .availability(serviceAddress)
        .then(({ dunsNumber }) => {
          signUpClickNextStepHandler({
            signUpData: { serviceAddress, dunsNumber },
            track: false,
          });
          setSuccess(true);
        })
        .catch((error: RhApiError) => {
          const errorCode = error?.data.errorCode;

          if (errorCode && availabilityErrorPaths[errorCode]) {
            signUpClickNextStepHandler({
              signUpData: { serviceAddress },
              nextStep: availabilityErrorPaths[errorCode],
              track: true,
            });
          }
          setSuccess(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return requestMonitor;
};
