import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useNotificationOptionSwitchesStyles = makeStyles<
  typeof rhythmTheme,
  "commMediumContainer" | "optionText" | "optionRow" | "commMediumHeader"
>((theme) =>
  createStyles({
    commMediumContainer: {
      marginBottom: theme.spacing(5),
    },
    commMediumHeader: {
      marginBottom: theme.spacing(2),
    },
    optionRow: {
      "&:last-child": {
        borderBottom: borders[100],
      },
      alignItems: "center",
      borderTop: borders[100],
      flexDirection: "row",
      justifyContent: "space-between",
      padding: `${theme.spacing(2)}px 0`,
    },
    optionText: {
      marginRight: theme.spacing(1.5),
    },
  })
);
