import { DynamicLink } from "@common/components/DynamicLink/DynamicLink";
import { ReactComponent as FacebookLogo } from "@common/images/facebook.svg";
import { ReactComponent as InstagramLogo } from "@common/images/instagram.svg";
import { ReactComponent as LinkedInLogo } from "@common/images/linkedin.svg";
import { ReactComponent as RhythmLogo } from "@common/images/rhythmLogoDarkPurple.svg";
import { ReactComponent as TwitterLogo } from "@common/images/twitter.svg";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { MAIN_CONTAINER_WIDTH } from "@design-system/theme/style.constant";
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import { usePortalFooterStyles } from "@portal/components/PortalFooter/PortalFooter.style";
import {
  FACEBOOK_URL,
  FAQ_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_USE_URL,
  TWITTER_URL,
} from "@portal/constants/urls.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { customerHomePath } from "@portal/routes/routePaths";
import React, { FC } from "react";

export const PortalFooter: FC = () => {
  const classes = usePortalFooterStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useRhIntl();
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  const resources = t("PortalFooter.resources");
  const privacyPolicy = t("PortalFooter.privacyPolicy");
  const termsOfUse = t("PortalFooter.termsOfUse");
  const faq = t("PortalFooter.FAQ");
  const allRightsReserved = t("PortalFooter.allRightsReserved");

  return isAuthenticated ? (
    <Box
      className={classes.root}
      width="100vw"
      paddingTop={4}
      paddingBottom={4}
      marginTop={10}
      bottom="0"
    >
      <Box
        maxWidth={isMobile ? "100%" : MAIN_CONTAINER_WIDTH}
        width={isMobile ? "auto" : "100%"}
        marginX="auto"
      >
        <RhFlexBox
          flexDirection="column"
          marginX="auto"
          maxWidth={isMobile ? "300px" : "100%"}
        >
          <Grid container>
            <Grid item xs={12} md={2}>
              <Box width="140px" marginX="auto" marginBottom={isMobile ? 3 : 0}>
                <DynamicLink
                  to={customerHomePath()}
                  external={false}
                  aria-label={t("PortalFooter.home")}
                >
                  <RhythmLogo style={{ height: "54px", width: "140px" }} />
                </DynamicLink>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box marginBottom={2}>
                <RhTypography variant="h3">{resources}</RhTypography>
              </Box>
              <List dense>
                <ListItem>
                  <Link
                    href={PRIVACY_POLICY_URL}
                    component="a"
                    color="textPrimary"
                  >
                    <RhTypography>{privacyPolicy}</RhTypography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    href={TERMS_OF_USE_URL}
                    component="a"
                    color="textPrimary"
                  >
                    <RhTypography>{termsOfUse}</RhTypography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href={FAQ_URL} component="a" color="textPrimary">
                    <RhTypography>{faq}</RhTypography>
                  </Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={false} md={5} />
            <Grid item xs={12} md={3}>
              <RhFlexBox
                justifyContent={isMobile ? "center" : "flex-start"}
                marginTop={isMobile ? 4 : 0}
              >
                <Box marginRight={1}>
                  <Link
                    href={FACEBOOK_URL}
                    component="a"
                    aria-label={t("PortalFooter.facebook")}
                  >
                    <FacebookLogo />
                  </Link>
                </Box>
                <Box marginRight={1}>
                  <Link
                    href={TWITTER_URL}
                    component="a"
                    aria-label={t("PortalFooter.twitter")}
                  >
                    <TwitterLogo />
                  </Link>
                </Box>
                <Box marginRight={1}>
                  <Link
                    href={LINKEDIN_URL}
                    component="a"
                    aria-label={t("PortalFooter.linkedin")}
                  >
                    <LinkedInLogo />
                  </Link>
                </Box>
                <Box>
                  <Link
                    href={INSTAGRAM_URL}
                    component="a"
                    aria-label={t("PortalFooter.instagram")}
                  >
                    <InstagramLogo />
                  </Link>
                </Box>
              </RhFlexBox>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <RhFlexBox
              justifyContent="center"
              marginTop={4}
              maxWidth={isMobile ? "300px" : "100%"}
            >
              <RhTypography
                color="textSecondary"
                align="center"
                variant="body1"
              >
                {allRightsReserved}
              </RhTypography>
            </RhFlexBox>
          </Grid>
        </RhFlexBox>
      </Box>
    </Box>
  ) : (
    <Box
      width="100vw"
      paddingTop={2}
      paddingBottom={2}
      marginTop={10}
      bottom="0"
    >
      <RhFlexBox
        margin="auto"
        justifyContent="center"
        maxWidth={isMobile ? "300px" : "100%"}
      >
        <RhTypography
          color="textSecondary"
          className={classes.signUpRoot}
          align="center"
          variant="body2"
        >
          {allRightsReserved}
        </RhTypography>
      </RhFlexBox>
    </Box>
  );
};
