import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useBalanceDetailsStyles = makeStyles<
  typeof rhythmTheme,
  "balanceDueTitle" | "balanceDueAmount" | "nextBillExplainer"
>((theme) =>
  createStyles({
    balanceDueAmount: {
      marginBottom: theme.spacing(1),
    },
    balanceDueTitle: {
      marginBottom: theme.spacing(1),
      textAlign: "center",
    },
    nextBillExplainer: {
      marginBottom: theme.spacing(1),
    },
  })
);
