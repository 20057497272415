import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import {
  LOGGED_OUT_CONTAINER_SPACING,
  LoggedOutFieldsLayout,
} from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectProspectId } from "@portal/selectors/signUpSelectors";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import React, { FC, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface SignUpNoCoverageFormValues {
  email: string;
}

const signUpNoCoverageFormValidator = generateValidationErrorCollector<SignUpNoCoverageFormValues>(
  {
    email: [isRequired, isValidEmail],
  }
);

export const SignUpNoCoveragePage: FC = () => {
  const history = useHistory();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { t } = useRhIntl();
  const dispatch = useDispatch();
  const prospectId = useSelector(selectProspectId);

  if (showConfirmation) {
    return (
      <SignUpPageLayout>
        <LoggedOutPageHeader
          headerTextId="SignUpNoCoveragePage.youreOnTheList"
          subHeaderTextId="SignUpNoCoveragePage.wellLetYouKnow"
        />
        <Box mt={LOGGED_OUT_CONTAINER_SPACING} width={LOGGED_OUT_FORM_WIDTH}>
          <RhButton fullWidth color="primary" onClick={() => history.push("/")}>
            {t("SignUpNoCoveragePage.backToSite")}
          </RhButton>
        </Box>
      </SignUpPageLayout>
    );
  }
  const onSubmit = ({ email }: SignUpNoCoverageFormValues) => {
    dispatch(setSignUpInfo({ email }));
    setShowConfirmation(true);
    return api.prospects.trackServiceUnavailable(prospectId, email);
  };

  const email = t("SignUpNoCoveragePage.email");
  const submitCTA = t("SignUpNoCoveragePage.submitCTA");

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerTextId="SignUpNoCoveragePage.rhythmHasntMadeIt"
        subHeaderTextId="SignUpNoCoveragePage.butWellLetYouKnow"
      />
      <Form<SignUpNoCoverageFormValues>
        onSubmit={onSubmit}
        validate={signUpNoCoverageFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
            <LoggedOutFieldsLayout>
              <RhTextField name="email" autoComplete="email">
                {email}
              </RhTextField>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
