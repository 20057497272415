import { urls } from "@common/api/urls";
import {
  InvoiceItemType,
  InvoiceType,
} from "@common/types/accountSummaryTypes";
import { IdType } from "@common/types/apiTypes";
import dayjs from "dayjs";

export class Invoice {
  public id: IdType;
  public invoiceNumber: string;
  public invoiceDate: string;
  public dueDate: string;
  public amount: number;
  public balance: number;
  public status: string;
  public invoiceItems?: InvoiceItemType[];
  public lateFeeApplied: boolean | undefined;
  public rewardCreditApplied: number;

  public constructor({
    amount,
    balance,
    dueDate,
    id,
    invoiceDate,
    invoiceNumber,
    items,
    lateFeeApplied,
    status,
    rewardCreditApplied,
  }: InvoiceType) {
    this.amount = amount;
    this.balance = balance;
    this.dueDate = dueDate;
    this.id = id;
    this.invoiceDate = invoiceDate;
    this.invoiceItems = items;
    this.invoiceNumber = invoiceNumber;
    this.lateFeeApplied = lateFeeApplied;
    this.status = status;
    this.rewardCreditApplied = rewardCreditApplied;
  }

  public get kWhUsageQuantity(): number | undefined {
    if (!this.invoiceItems) {
      return undefined;
    }

    return this.invoiceItems.reduce((total, invoiceItem) => {
      if (invoiceItem.unitOfMeasure && invoiceItem.unitOfMeasure === "KWH") {
        // eslint-disable-next-line no-param-reassign
        total += invoiceItem.quantity;
      }
      return total;
    }, 0);
  }

  public get formattedInvoiceDate(): string {
    return dayjs(this.invoiceDate).format("MMMM D, YYYY");
  }

  public pdfUrl(customerId: string): string | undefined {
    return urls.customers.invoice(customerId, this.id);
  }
}
