import { urls } from "@common/api/urls";
import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { AccountSummary } from "@common/models/AccountSummary.model";
import { Customer } from "@common/models/Customer.model";
import { Invoice } from "@common/models/Invoice.model";
import { RhApiError } from "@common/types/errorTypes";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import { noop } from "@common/utils/genericFunctions";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Link } from "@material-ui/core";
import { useBillingHistoryStyles } from "@portal/components/BillingHistory/BillingHistory.style";
import { InvoicesRow } from "@portal/components/InvoicesRow/InvoicesRow";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { CustomerHomeSectionErrorMessage } from "@portal/pages/CustomerHomePage/CustomerHomeSectionErrorMessage/CustomerHomeSectionErrorMessage";
import { authenticatedFileDownload } from "@portal/utils/authenticatedFileDownload";
import { buildInvoiceFilename } from "@portal/utils/invoiceDownloadHelpers";
import React, { FC, MouseEvent } from "react";

interface BillingHistoryProps {
  accountSummary: AccountSummary;
  customer: Customer;
}

const MAX_INVOICES = 6;

export const BillingHistory: FC<BillingHistoryProps> = ({
  accountSummary,
  customer,
}) => {
  const classes = useBillingHistoryStyles();
  const { t } = useRhIntl();
  const flash = useRhFlash();

  const emptyStateMessage = t("BillingHistory.noPriorBills");
  const title = t("BillingHistory.billingHistory");
  const invoices = accountSummary.invoices.slice(0, MAX_INVOICES);

  const handleDownload = (invoice: Invoice) => {
    return (ev: MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault();
      const url = urls.customers.invoice(customer.id, invoice.id);
      const filename = buildInvoiceFilename(customer, invoice);

      authenticatedFileDownload(url, filename, PDF_MIME_TYPE)
        .then(noop)
        .catch((_error: RhApiError) => {
          flash.error(t("BillingHistory.errorDownloadingInvoice"));
        });
    };
  };

  const billingHistoryInvoices = (historyInvoices: Invoice[]) =>
    historyInvoices.map((invoice) => (
      <Link
        href="#"
        rel="noopener noreferrer"
        onClick={handleDownload(invoice)}
        target="_self"
        className={classes.linkRow}
        key={invoice.id}
      >
        <InvoicesRow>
          <RhTypography variant="body1">
            {invoice.formattedInvoiceDate}
          </RhTypography>
          <RhTypography variant="body1" color="textPrimary">
            {formatCurrency(invoice.amount)}
          </RhTypography>
          {invoice.kWhUsageQuantity && (
            <RhTypography variant="body1" color="textSecondary">
              {formatKwh(invoice.kWhUsageQuantity, 0)}
            </RhTypography>
          )}
        </InvoicesRow>
      </Link>
    ));

  const cardHeaderClass = accountSummary.hasInvoices ? classes.cardHeader : "";

  return (
    <RhCard title={title} headerClassName={cardHeaderClass}>
      <RhFlexBox flexDirection="column" data-testid="billingHistory__container">
        {accountSummary.hasInvoices ? (
          <>{billingHistoryInvoices(invoices)}</>
        ) : (
          <CustomerHomeSectionErrorMessage>
            {emptyStateMessage}
          </CustomerHomeSectionErrorMessage>
        )}
      </RhFlexBox>
    </RhCard>
  );
};
