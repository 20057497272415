import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const useOfferLearnMoreHeaderLayoutStyle = makeStyles<
  Theme,
  "selectButtonContainer" | "summary" | "summaryDescription"
>((theme) =>
  createStyles({
    selectButtonContainer: {
      [theme.breakpoints.only("xs")]: {
        backgroundColor: theme.palette.common.white,
        bottom: 0,
        left: 0,
        padding: theme.spacing(2),
        position: "fixed",
        width: "100%",
        zIndex: 2,
      },
    },
    summary: {
      marginBottom: theme.spacing(3),
    },
    summaryDescription: {
      marginBottom: theme.spacing(2),
    },
  })
);
