import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { UtilityType } from "@common/types/ediTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhRadioCardInput } from "@design-system/components/RhRadioCardInput/RhRadioCardInput";
import { Box, RadioGroup } from "@material-ui/core";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { SignUpPremiseFormValues } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import {
  selectSignUpPremiseFormValues,
  selectSignUpState,
} from "@portal/selectors/signUpSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { Show500Page } from "@portal/utils/errors";
import React, { FC, useEffect } from "react";
import { Form, useField } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

interface SignUpChooseUtilityFormValues {
  dunsNumber: string;
}

export const UtilityChoiceField: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  const { input } = useField<string>("dunsNumber", {
    type: "radio",
    value,
  });

  return (
    <RhRadioCardInput
      inputId={`utility-choice-${value}`}
      label={label}
      {...input}
    />
  );
};

const signUpChooseUtilityValidator = generateValidationErrorCollector<SignUpChooseUtilityFormValues>(
  {
    dunsNumber: [isRequired],
  }
);

export const SignUpChooseUtilityPage: FC = () => {
  const { formatMessage } = useIntl();
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const { serviceAddress } = useSelector<
    PortalStoreState,
    SignUpPremiseFormValues
  >(selectSignUpPremiseFormValues);

  const initialValues = {
    dunsNumber: undefined,
  };
  const { campaignSlug } = useSelector(selectSignUpState);

  const onSubmit = ({ dunsNumber }: SignUpChooseUtilityFormValues) => {
    return api.pricing.offers
      .search(dunsNumber, campaignSlug ?? "")
      .then(() => {
        signUpClickNextStepHandler({
          signUpData: { dunsNumber },
          nextStep: "plans",
          track: true,
        });
      })
      .catch(() => {
        signUpClickNextStepHandler({
          signUpData: { dunsNumber },
          nextStep: "updates",
          track: true,
        });
      });
  };

  const [
    availableUtilities,
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<UtilityType[]>();

  useEffect(() => {
    setPending();
    api.utilities
      .search({ zipCode: serviceAddress.zipCode })
      .then(setSuccess)
      .catch((err) => {
        setFailure(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAddress.zipCode]);
  const submitCTA = formatMessage({ id: "SignUpPremiseFields.submitCTA" });

  if (availableUtilities.requestMonitor.isPending) {
    return <RhCircularProgress />;
  }
  if (availableUtilities.requestMonitor.didFail) {
    throw new Show500Page(
      `Could not fetch available utilities: ${availableUtilities.error?.data?.errorCode}`
    );
  }
  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerTextId="SignUpChooseUtilityPage.pleaseSelectAUtility"
        subHeaderTextId="SignUpChooseUtilityPage.notSure"
      />

      <Form<SignUpChooseUtilityFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={signUpChooseUtilityValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
            <Box marginTop={6} marginBottom={5}>
              <RadioGroup>
                {(availableUtilities.data ?? []).map(({ dunsNumber, name }) => (
                  <UtilityChoiceField
                    key={`utility-${dunsNumber}`}
                    label={name}
                    value={dunsNumber}
                  />
                ))}
              </RadioGroup>
            </Box>
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
