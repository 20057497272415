import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import {
  LOGGED_OUT_TOP_MARGIN,
  LOGGED_OUT_TOP_MARGIN_MOBILE,
} from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core";

export const useBaseErrorPageStyle = makeStyles<
  typeof rhythmTheme,
  { withIllustration?: boolean },
  | "errorCode"
  | "errorMessage"
  | "errorTitle"
  | "bodyContainer"
  | "illustrationContainer"
  | "root"
>((theme) =>
  createStyles({
    bodyContainer: {
      flex: 1,
      [theme.breakpoints.only("xs")]: {
        textAlign: "center !important" as "center",
      },
      textAlign: ({ withIllustration }) =>
        withIllustration ? "left" : "center",
      width: 350,
    },
    errorCode: {
      color: theme.palette.grey[300],
      marginBottom: theme.spacing(1),
    },
    errorMessage: {
      marginBottom: theme.spacing(4),
    },
    errorTitle: {
      marginBottom: theme.spacing(2),
    },
    illustrationContainer: {
      marginRight: ({ withIllustration }) =>
        withIllustration ? theme.spacing(7) : "inherit",
      [theme.breakpoints.only("xs")]: {
        "& > svg": {
          maxHeight: 220,
        },
        marginBottom: theme.spacing(3),
        marginRight: "0 !important",
      },
    },
    root: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(LOGGED_OUT_TOP_MARGIN_MOBILE),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(LOGGED_OUT_TOP_MARGIN),
      },
      [theme.breakpoints.only("xs")]: {
        flexDirection: "column",
      },
    },
  })
);
