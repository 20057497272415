import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { RH_HEADER_HEIGHT } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core";

export const useCustomerHomePageStyle = makeStyles<
  typeof rhythmTheme,
  "billingSection" | "billingSectionSticky" | "gridContainer"
>((theme) =>
  createStyles({
    billingSection: {
      [theme.breakpoints.up("md")]: {
        height: "100%",
        position: "absolute",
        right: 0,
        top: 0,
      },
    },
    billingSectionSticky: {
      [theme.breakpoints.up("md")]: {
        position: "sticky",
        top: theme.spacing(RH_HEADER_HEIGHT + 2.5),
      },
    },
    gridContainer: {
      "& > .MuiGrid-item": {
        padding: `0 ${theme.spacing(2.5)}px ${theme.spacing(2.5)}px`,

        [theme.breakpoints.only("xs")]: {
          padding: `0 ${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
        },
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
      position: "relative",
    },
  })
);
