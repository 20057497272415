import {
  MAP_HEIGHT,
  MAP_WIDTH,
  useStaticMapStyles,
} from "@portal/components/StaticMap/StaticMap.style";
import { MapAddress, getMapUrl } from "@portal/services/map.service";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

interface StaticMapProps {
  mapAddress: MapAddress;
  isAddressValidFn?(mapAddress: MapAddress): boolean;
}

export const EMPTY_MAP_SRC = "/assets/empty.png";

const defaultIsAddressValidFn = (mapAddress?: MapAddress) => {
  return (
    mapAddress &&
    mapAddress.addressLine1 &&
    mapAddress.city &&
    mapAddress.state &&
    mapAddress.zipCode
  );
};

export const StaticMap: FC<StaticMapProps> = ({
  mapAddress,
  isAddressValidFn = defaultIsAddressValidFn,
}): JSX.Element => {
  const classes = useStaticMapStyles();
  const { formatMessage } = useIntl();
  const [mapUrl, setMapUrl] = useState<string | null>(null);
  const [isMapAddressValid, setIsMapAddressValid] = useState<boolean>(false);

  useEffect(() => {
    const isAddressValid = isAddressValidFn(mapAddress);

    if (isAddressValid) {
      getMapUrl(mapAddress, MAP_WIDTH, MAP_HEIGHT)
        .then((newUrl) => {
          const isNewUrlValid = newUrl !== null;

          setIsMapAddressValid(isNewUrlValid);
          if (isNewUrlValid) {
            setMapUrl(newUrl);
          }
        })
        .catch(() => {
          setIsMapAddressValid(false);
        });
    } else {
      setIsMapAddressValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapAddress.addressLine1,
    mapAddress.city,
    mapAddress.state,
    mapAddress.zipCode,
  ]);

  const mapAlt = formatMessage({ id: "StaticMap.imageAlt" });

  return (
    <div
      className={classes.mapWrapper}
      style={{ height: isMapAddressValid ? `${MAP_HEIGHT}px` : "0" }}
    >
      <img className={classes.map} src={mapUrl || EMPTY_MAP_SRC} alt={mapAlt} />
    </div>
  );
};
