import { useOktaAuth } from "@okta/okta-react";
import { useAuthenticatedUserGroups } from "@portal/hooks/useAuthenticatedUserGroups";
import { customerHomePath } from "@portal/routes/routePaths";
import { isOpsOrCSRs } from "@portal/utils/signUpHelpers";
import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

export const CSROrOpsSignUpRoute: FC<RouteProps> = (props) => {
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  const { data: groups } = useAuthenticatedUserGroups();

  if (!groups) {
    return null;
  }

  if (!isAuthenticated || !isOpsOrCSRs(groups)) {
    return <Redirect to={customerHomePath()} />;
  }

  return <Route {...props} />;
};
