import { rhWindow } from "@common/utils/rhWindow";
import { scrollToOffsetPosition } from "@common/utils/scrollToOffsetPosition";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { UpArrow } from "@design-system/icons";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { MyAccountBillingPreferencesCard } from "@portal/components/MyAccountBillingPreferencesCard/MyAccountBillingPreferencesCard";
import { MyAccountContact } from "@portal/components/MyAccountContact/MyAccountContact";
import { MyAccountLanguageCard } from "@portal/components/MyAccountLanguageCard/MyAccountLanguageCard";
import {
  AccountNavAnchorTag,
  MyAccountNav,
} from "@portal/components/MyAccountNav/MyAccountNav";
import { MyAccountPasswordChange } from "@portal/components/MyAccountPasswordChange/MyAccountPasswordChange";
import { MyAccountPaymentMethodsCard } from "@portal/components/MyAccountPaymentMethodsCard/MyAccountPaymentMethodsCard";
import { MyAccountServiceInfo } from "@portal/components/MyAccountServiceInfo/MyAccountServiceInfo";
import { MyAccountYourPlanCard } from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { useCustomer } from "@portal/hooks/useCustomer";
import { useMyAccountPageStyles } from "@portal/pages/MyAccountPage/MyAccountPage.style";
import { Show500Page } from "@portal/utils/errors";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

interface MyAccountPageProps {
  location?: { state: { anchorId: keyof typeof AccountNavAnchorTag } };
}

export const MyAccountPage: FC<MyAccountPageProps> = ({ location }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.only("md"));
  const classes = useMyAccountPageStyles({ isSmallDesktop, isTablet, isPhone });
  const [backButtonPresent, setBackButtonPresent] = useState(false);
  const anchorId = location?.state?.anchorId;

  const { requestMonitor, customer, error } = useCustomer();
  const handleScroll = useCallback(() => {
    if (!isPhone) {
      return;
    }
    const elemTop: number =
      document.getElementById("myAccountHeader")?.offsetTop || 0;

    if (elemTop < document.documentElement.scrollTop && !backButtonPresent) {
      setBackButtonPresent(true);
    }
    if (elemTop > document.documentElement.scrollTop && backButtonPresent) {
      setBackButtonPresent(false);
    }
  }, [backButtonPresent, isPhone]);

  useEffect(() => {
    rhWindow.addEventListener("scroll", handleScroll);
    return () => rhWindow.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  useEffect(() => {
    const anchorElement = document.getElementById(anchorId ?? "");

    if (anchorElement) {
      scrollToOffsetPosition(anchorElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }
  if (requestMonitor.didFail || !customer) {
    throw new Show500Page(`Could not get customer: ${error?.data?.errorCode}`);
  }

  if ((isSmallDesktop || isTablet) && backButtonPresent) {
    setBackButtonPresent(false);
  }

  const scrollToTop = () =>
    rhWindow.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <PortalPageLayout>
      <header id="myAccountHeader" className={classes.header}>
        <RhTypography variant="h1">
          {formatMessage({ id: "MyAccountPage.account" })}
        </RhTypography>
      </header>
      <Grid container spacing={5} className={classes.gridContainer}>
        <Grid item xs={12} sm={4} md={3} className={classes.links}>
          <MyAccountNav />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <div id={AccountNavAnchorTag.serviceInfo}>
            <MyAccountServiceInfo customer={customer} />
          </div>

          <div id={AccountNavAnchorTag.yourPlan}>
            <MyAccountYourPlanCard />
          </div>
          <div id={AccountNavAnchorTag.contactInfo}>
            <MyAccountContact customer={customer} />
          </div>
          <div id={AccountNavAnchorTag.languagePreference}>
            <MyAccountLanguageCard customer={customer} />
          </div>
          <div id={AccountNavAnchorTag.changePassword}>
            <MyAccountPasswordChange customer={customer} />
          </div>
          <div id={AccountNavAnchorTag.billingPreferences}>
            <MyAccountBillingPreferencesCard
              hasHistoricUsage={customer.hasHistoricUsage}
              averagedBillingMonthlyCharge={
                customer.averagedBillingMonthlyCharge
              }
              canPickBillingDueDay={customer.meter.isSmt}
              initialPreferences={{
                invoiceByPrint: Boolean(customer.invoiceByPrint),
                autopay: Boolean(customer.autopay),
                averagedBilling: Boolean(customer.averagedBilling),
                pickedBillingDueDay: customer.pickedBillingDueDay,
              }}
            />
          </div>
          <div id={AccountNavAnchorTag.paymentMethods}>
            <MyAccountPaymentMethodsCard />
          </div>
          {backButtonPresent && (
            <Box
              className={classes.backToTopButtonContainer}
              data-testid="myAccountPage__backToTop"
            >
              <RhButton
                variant="text"
                color="primary"
                onClick={scrollToTop}
                size="small"
              >
                <UpArrow className={classes.upArrow} />
                {formatMessage({ id: "MyAccountPage.backToTop" })}
              </RhButton>
            </Box>
          )}
        </Grid>
      </Grid>
    </PortalPageLayout>
  );
};
