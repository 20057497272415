import {
  RSASignatureResponseType,
  ZuoraAddPaymentResponseType,
} from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import {
  CreditCardFormError,
  ZuoraInlineFormError,
} from "@portal/components/ZuoraCardForm/ZuoraInlineFormError.service";
import { useDebounce } from "@portal/hooks/useDebounce";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useSignUpPaymentPageStyles } from "@portal/pages/SignUpPaymentPage/SignUpPaymentPage.style";
import { getZuoraParams } from "@portal/utils/baseZuoraParams.util";
import { zuora } from "@portal/utils/zuora.util";
import dayjs from "dayjs";
import React, { FC, useEffect, useRef, useState } from "react";

interface SignUpZuoraCardFormProps {
  signature: RSASignatureResponseType;
}

export const SignUpZuoraCardForm: FC<SignUpZuoraCardFormProps> = ({
  signature,
}) => {
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const [isZuoraFormRendering, setIsZuoraFormRendering] = useState<boolean>(
    true
  );
  const zuoraCardForm = useRef<HTMLDivElement>(null);
  const debouncedClientErrorMessageCallback = useDebounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      const zuoraInlineFormErrorService = new ZuoraInlineFormError(
        errorType,
        errorCode,
        errorDescription
      );

      const { error } = zuoraInlineFormErrorService;

      const errorMessage = t(`ZuoraCardForm.inlineFormError.${error}`);

      if (error === CreditCardFormError.tooManyTries) {
        flash.error(errorMessage);
      }
    },
    50
  );
  const classes = useSignUpPaymentPageStyles();

  useEffect(() => {
    zuora.setEventHandler("onloadCallback", () => {
      setIsZuoraFormRendering(false);
    });

    zuora.renderWithErrorHandler(
      {
        ...getZuoraParams(dayjs().locale()),
        ...signature,
      },
      {},
      async ({
        success,
        refId: billingPaymentMethodId,
      }: // eslint-disable-next-line require-await
      ZuoraAddPaymentResponseType) => {
        const errorMessage = t("ZuoraCardForm.errorAddingCard");

        if (!success) {
          flash.error(errorMessage);
          return;
        }

        signUpClickNextStepHandler({
          signUpData: { billingPaymentMethodId },
          nextStep: "summary",
          track: true,
        });
      },
      debouncedClientErrorMessageCallback
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoggedOutPageHeader
        headerTextId="SignUpPaymentPage.creditCardPlease"
        subHeaderTextId="SignUpPaymentPage.takeCareOfThisNow"
      />
      <Box
        mt={LOGGED_OUT_CONTAINER_SPACING}
        mx="auto"
        width={LOGGED_OUT_FORM_WIDTH}
      >
        {isZuoraFormRendering && <RhCircularProgress marginBottom={4} />}
        <div ref={zuoraCardForm}>
          <div
            id="zuora_payment"
            data-testid="zuoraPaymentForm"
            className={classes.zuoraCardFormContainer}
          />
        </div>
      </Box>
    </>
  );
};
