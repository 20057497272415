import { OktaGroups } from "@common/constants/okta.constant";
import { ProspectType } from "@common/types/prospectTypes";
import {
  formatDateOfBirth,
  unformatDateOfBirth,
} from "@portal/forms/formatters";
import { SignUpStateType } from "@portal/slices/signUpSlice";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export const convertSignUpStatePayloadToProspectType = (
  signUpData: Partial<SignUpStateType>
): Partial<ProspectType> => {
  const {
    email,
    serviceAddress,
    offersnapshotId,
    firstName,
    lastName,
    phone,
    dateOfBirth,
    autopay,
    billingPaymentMethodId,
    enrollmentType,
    invoiceByPrint,
    serviceStartDate,
    sendMarketingPromos,
    estimatedMonthlyUsage,
    areaNotCovered,
    googleClientId,
    segmentAnonId,
  } = signUpData;

  const data: Partial<ProspectType> = {
    email,
    ...serviceAddress,
    firstName,
    lastName,
    phone,
    dateOfBirth: !isNil(dateOfBirth)
      ? unformatDateOfBirth(formatDateOfBirth(dateOfBirth || ""))
      : undefined,
    autopay,
    billingPaymentMethodId,
    enrollmentType,
    eBillOnly: !isNil(invoiceByPrint) ? !invoiceByPrint : undefined,
    serviceStartDate,
    estimatedUsageKilowattHours: estimatedMonthlyUsage,
    marketingEmailOptIn: !isNil(sendMarketingPromos)
      ? sendMarketingPromos
      : undefined,
    areaNotCovered,
    googleClientId,
    segmentAnonId,
    offersnapshotId,
  };

  return omitBy(data, isNil);
};

export const isOpsOrCSRs = (groups: OktaGroups[] | null = []) => {
  if (!groups) {
    return false;
  }
  return groups.includes(OktaGroups.CSRs) || groups.includes(OktaGroups.Ops);
};

export const isCustomer = (groups: OktaGroups[] | null = []) => {
  if (!groups) {
    return false;
  }
  return groups.includes(OktaGroups.Customers);
};
