import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhApiError } from "@common/types/errorTypes";
import { PaymentMethodRetrieveType } from "@common/types/paymentMethodTypes";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { RhCheckbox } from "@design-system/components/RhCheckbox/RhCheckbox";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@portal/components/LegalDocuments/LegalDocumentLinks";
import {
  LOGGED_OUT_CONTAINER_SPACING,
  LOGGED_OUT_FIELD_SPACING,
} from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { PortalSubmitButton } from "@portal/components/PortalSubmitButton/PortalSubmitButton";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { SignUpSummaryInfo } from "@portal/components/SignUpSummaryInfo/SignUpSummaryInfo";
import { useAuthenticatedUserGroups } from "@portal/hooks/useAuthenticatedUserGroups";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useSignUpSummaryPageStyles } from "@portal/pages/SignUpSummaryPage/SignUpSummaryPage.style";
import {
  CREATE_PASSWORD,
  CSR_SUCCESS,
  signUpStepPath,
} from "@portal/routes/routePaths";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { selectSelectedOffer } from "@portal/selectors/selectedOfferSelectors";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { SignUpStateType } from "@portal/slices/signUpSlice";
import { toCreateCustomerRequest } from "@portal/transformers/toCreateCustomerRequest";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { Show500Page } from "@portal/utils/errors";
import { isOpsOrCSRs } from "@portal/utils/signUpHelpers";
import { FORM_ERROR } from "final-form";
import React, { FC, useEffect } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

interface SignUpSummaryFormValues {
  acknowledgedContractDocuments: boolean;
}

export const SignUpSummaryPage: FC = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const newCustomer = useSelector<PortalStoreState, SignUpStateType>(
    selectSignUpState
  );
  const selectedOffer = useSelector(selectSelectedOffer);
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );

  const classes = useSignUpSummaryPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const announcement = useRhAnnouncement();

  const history = useHistory();

  const { data: groups } = useAuthenticatedUserGroups();

  const [
    { data: paymentMethod, requestMonitor: paymentMethodRequestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<PaymentMethodRetrieveType | null>();

  const { billingPaymentMethodId } = newCustomer;

  useEffect(() => {
    setPending();

    if (billingPaymentMethodId) {
      api.billing.paymentMethods
        .retrieve(billingPaymentMethodId)
        .then(setSuccess)
        .catch(setFailure);
    } else {
      setSuccess(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingPaymentMethodId]);

  useEffect(() => {
    if (!selectedOffer) {
      const errorMessage = t("SignUpSummaryPage.errorGettingSelectedOffer", {
        link: (message) => (
          <Link
            to={signUpStepPath("plans")}
            onClick={announcement.clearAnnouncement}
          >
            {message}
          </Link>
        ),
      });

      announcement.announceError(<div>{errorMessage}</div>);
      throw new Show500Page("Could not get selected offer");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffer]);

  if (!selectedOffer) {
    // Throw error after announcing error in hook above
    return null;
  }

  const handleClick = () => {
    trackEvent({
      action: ActionType.clickedEnrollmentSubmit,
      label: "confirmationSignUp",
    });
    const customerData = {
      ...newCustomer,
      languagePreference: locale,
      csrSignUp: isOpsOrCSRs(groups),
    };

    const data = toCreateCustomerRequest(customerData);

    return api.customers
      .create(data)
      .then((confirmedCustomer) => {
        signUpClickNextStepHandler({
          signUpData: { ...confirmedCustomer, campaignPromo: null },
          nextStep: isOpsOrCSRs(groups) ? CSR_SUCCESS : CREATE_PASSWORD,
          track: true,
        });
        announcement.clearAnnouncement();
      })
      .catch((error: RhApiError) => {
        if (error.data.errorCode === "zuora.createDepositOrder.failed") {
          history.push(signUpStepPath("call-us"));
          return;
        }

        if (error.data.errorCode === "zuora.createDepositOrderPayment.failed") {
          const messageWithLink = t(
            "SignUpSummaryPage.tryDifferentPaymentMethod",
            {
              link: (message) => (
                <Link
                  to={signUpStepPath("deposit")}
                  onClick={announcement.clearAnnouncement}
                >
                  {message}
                </Link>
              ),
            }
          );

          announcement.announceError(<div>{messageWithLink}</div>);

          return;
        }

        const errorMessage = t("SignUpSummaryPage.apiErrorSigningUpCustomer");

        flash.error(errorMessage);

        return {
          [FORM_ERROR]: [errorMessage],
        };
      });
  };

  const submitCTA = t(
    newCustomer.depositAmount
      ? "SignUpSummaryPage.submitCTAWithDeposit"
      : "SignUpSummaryPage.submitCTA"
  );
  const byCheckingThisBox = t("SignUpSummaryPage.byCheckingThisBox", {
    efl: (chunk) => (
      <EFLLink offerId={selectedOffer.id} locale={locale}>
        {chunk}
      </EFLLink>
    ),
    tos: (chunk) => <TOSLink locale={locale}>{chunk}</TOSLink>,
    yrac: (chunk) => <YRACLink locale={locale}>{chunk}</YRACLink>,
  });

  return (
    <SignUpPageLayout dense={false}>
      {paymentMethodRequestMonitor.isWaiting ? (
        <Box marginTop={3}>
          <RhCircularProgress />
        </Box>
      ) : (
        <Box className={classes.wrapper}>
          <LoggedOutPageHeader
            headerTextId="SignUpSummaryPage.allDone"
            headerTextValues={{ firstName: newCustomer.firstName }}
          />
          <RhFlexBox flexDirection="column" mt={LOGGED_OUT_CONTAINER_SPACING}>
            <Box
              className={classes.summaryInfoSection}
              marginBottom={LOGGED_OUT_FIELD_SPACING}
            >
              <SignUpSummaryInfo
                signUpData={newCustomer}
                paymentMethodDetails={paymentMethod}
                offerDetails={selectedOffer}
              />
            </Box>
            <Form<SignUpSummaryFormValues>
              initialValues={{ acknowledgedContractDocuments: false }}
              onSubmit={handleClick}
              render={({ handleSubmit, values }) => (
                <form
                  onSubmit={handleSubmit}
                  onChange={(event) => {
                    const {
                      target: { checked },
                    } = (event as unknown) as React.ChangeEvent<HTMLInputElement>;

                    if (checked) {
                      trackEvent({
                        label: "legalCheckBox",
                        action: ActionType.click,
                      });
                    }
                  }}
                >
                  <RhFlexBox flex="auto" className={classes.byCheckingThisBox}>
                    <RhCheckbox
                      label=""
                      aria-label={t(
                        "SignUpSummaryPage.acknowledgeCheckboxLabel"
                      )}
                      name="acknowledgedContractDocuments"
                      className={classes.checkbox}
                    />
                    <RhTypography>{byCheckingThisBox}</RhTypography>
                  </RhFlexBox>
                  <Box mt={LOGGED_OUT_CONTAINER_SPACING}>
                    <PortalSubmitButton
                      fullWidth
                      disabled={!values.acknowledgedContractDocuments}
                      size={isMobile ? "medium" : "large"}
                    >
                      {submitCTA}
                    </PortalSubmitButton>
                  </Box>
                </form>
              )}
            />
          </RhFlexBox>
        </Box>
      )}
    </SignUpPageLayout>
  );
};
