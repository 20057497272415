import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isValidUSAddress } from "@common/forms/validators";
import {
  ACCEPTABLE_CREDIT_SCORE_OUTCOMES,
  CreditScoreOutcome,
} from "@common/types/creditCheckTypes";
import { AddressFormType } from "@common/types/customerTypes";
import {
  AREA_NOT_SERVICED,
  FormError,
  IRONHIDE_CUSTOMER,
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
  RhApiError,
} from "@common/types/errorTypes";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { IronhideCustomerMessage } from "@portal/components/IronhideCustomerMessage/IronhideCustomerMessage";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { SignUpPremiseFields } from "@portal/components/SignUpPremiseFields/SignUpPremiseFields";
import { StaticMap } from "@portal/components/StaticMap/StaticMap";
import {
  DUNS_NOT_FOUND,
  ESI_ID_NOT_FOUND,
} from "@portal/constants/offer.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import {
  selectCreditCheckContactValues,
  selectProspectId,
  selectSignUpPremiseFormValues,
  selectSignUpState,
} from "@portal/selectors/signUpSelectors";
import { MapAddress } from "@portal/services/map.service";
import { ActionType } from "@portal/services/segment.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { FORM_ERROR } from "final-form";
import React, { FC, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

const signUpPremiseFormValidator = generateValidationErrorCollector<SignUpPremiseFormValues>(
  {
    serviceAddress: isValidUSAddress,
  }
);

export interface SignUpPremiseFormValues {
  esiId: string;
  creditEvaluation: CreditScoreOutcome | null;
  dunsNumber: string;
  depositAmount: number | null;
  serviceAddress: AddressFormType;
  ssnRequired: boolean;
}

const MULTIPLE_METER_ERRORS = new Set([
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
]);

export const SignUpPremisePage: FC = () => {
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { announceError } = useRhAnnouncement();
  const [mapAddress, setMapAddress] = useState<MapAddress>({
    addressLine1: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const currentValues = useSelector<PortalStoreState, SignUpPremiseFormValues>(
    selectSignUpPremiseFormValues
  );
  const prospectId = useSelector(selectProspectId);
  const customerContactValues = useSelector(selectCreditCheckContactValues);
  const { dunsNumber: currentDunsNumber } = useSelector(selectSignUpState);

  useEffect(() => {
    const { addressLine1, city, state, zipCode } = currentValues.serviceAddress;

    setMapAddress({ addressLine1, city, state, zipCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async ({
    serviceAddress,
  }: SignUpPremiseFormValues): Promise<void | FormError> => {
    try {
      const { esiId, dunsNumber } = await api.edi.meterAvailability({
        streetLine: serviceAddress.addressLine1,
        secondary: serviceAddress.unitNumber,
        city: serviceAddress.city,
        state: serviceAddress.state,
        zipcode: serviceAddress.zipCode,
      });

      if (dunsNumber !== currentDunsNumber) {
        // the user changed their address enough that
        // we the current offers they were given are no
        // longer valid.
        // So send the user back to choose a new plan

        const plansHaveChangedMessage = t("SignUpPremisePage.plansHaveChanged");

        flash.error(plansHaveChangedMessage);
        signUpClickNextStepHandler({
          signUpData: {
            serviceAddress,
            dunsNumber,
          },
          nextStep: "plans",
          track: true,
        });
      } else if (esiId) {
        const {
          depositAmount,
          outcome,
          ssnRequired,
        } = await api.prospects.creditScoreEvaluation({
          ...customerContactValues,
          ...serviceAddress,
          prospectId,
        });

        await api.prospects.trackEnteredSignup(
          prospectId,
          customerContactValues.email
        );
        signUpClickNextStepHandler({
          signUpData: {
            serviceAddress,
            esiId,
            dunsNumber,
            depositAmount,
            creditEvaluation: outcome,
            ssnRequired,
          },
          nextStep:
            ACCEPTABLE_CREDIT_SCORE_OUTCOMES.has(outcome) || ssnRequired
              ? "details"
              : "call-us",
          track: true,
        });
      } else {
        signUpClickNextStepHandler({
          signUpData: {
            serviceAddress,
            dunsNumber,
            esiId: ESI_ID_NOT_FOUND,
          },
          nextStep: "confirm-address",
          track: true,
        });
      }
    } catch (caughtError: unknown) {
      const error = caughtError as RhApiError;

      if (error.data.errorCode === AREA_NOT_SERVICED) {
        signUpClickNextStepHandler({ nextStep: "updates", track: true });
      } else if (MULTIPLE_METER_ERRORS.has(error.data.errorCode || "")) {
        signUpClickNextStepHandler({
          signUpData: {
            serviceAddress,
            dunsNumber: DUNS_NOT_FOUND,
            esiId: ESI_ID_NOT_FOUND,
          },
          nextStep: "confirm-address",
          track: true,
        });
      } else if (error.data.errorCode === IRONHIDE_CUSTOMER) {
        announceError(<IronhideCustomerMessage />);
      } else {
        const errorMessage = t(
          "SignUpPremisePage.unknownErrorApiMeterAvailability"
        );

        flash.error(errorMessage);

        return { [FORM_ERROR]: [errorMessage] };
      }
    }
  };

  const headerTextId = "SignUpPremisePage.whereShouldWeSendEnergy";
  const submitCTA = t("SignUpPremiseFields.submitCTA");

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader headerTextId={headerTextId} />
      <Form<SignUpPremiseFormValues>
        initialValues={currentValues}
        onSubmit={onSubmit}
        validate={signUpPremiseFormValidator}
        render={({ handleSubmit, values, valid }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
            <StaticMap mapAddress={mapAddress} isAddressValidFn={() => valid} />
            <SignUpPremiseFields
              onAddressChange={() => {
                setMapAddress(values.serviceAddress);
              }}
              onFocus={(label) => {
                trackEvent({
                  action: ActionType.focus,
                  label,
                });
              }}
            />
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
