import { KILOWATT_HOURS_ABBREV } from "@common/constants/other.constant";
import { Offer, monthlyUsageAmounts } from "@common/models/Offer.model";
import {
  formatCurrency,
  formatDollarsToCents,
  formatRhythmCharge,
} from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useOfferUsageTableStyles } from "@portal/components/OfferUsageTable/OfferUsageTable.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

const tableHeaders = ["monthly", "price", "estimate"] as const;

type TableHeaderType = typeof tableHeaders[number];
const tableHeaderMessageIds: Record<TableHeaderType, string> = {
  monthly: "signUp.offer.learnMore.lookLike.monthly",
  price: "signUp.offer.learnMore.lookLike.price",
  estimate: "signUp.offer.learnMore.lookLike.estimate",
};

interface UsagePriceTableProps {
  offer: Offer;
}

export const OfferUsageTable: FC<UsagePriceTableProps> = ({ offer }) => {
  const { t } = useRhIntl();
  const classes = useOfferUsageTableStyles();
  const rhythmCharge1 = t("OfferUsageTable.rhythmCharge1", {
    charge: formatRhythmCharge(Number(offer.rhythmKwhRate)),
  });
  const rhythmCharge2 = t("OfferUsageTable.rhythmCharge2");

  return (
    <>
      <Table size="small" classes={{ root: classes.table }}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((messageKey) => (
              <TableCell
                classes={{ root: classes.tableHeadCell }}
                key={messageKey}
                align="left"
              >
                <RhTypography variant="body2">
                  {t(tableHeaderMessageIds[messageKey])}
                </RhTypography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody color="textSecondary">
          {monthlyUsageAmounts.map((monthlyUsage) => {
            const formattedMonthlyUsage = `${monthlyUsage} ${KILOWATT_HOURS_ABBREV}`;
            const formattedPricePerKWH = formatDollarsToCents(
              offer.averagePricePerKWH(monthlyUsage),
              true,
              1
            );
            const formattedMonthlyPrice = formatCurrency(
              offer.averageMonthlyPrice(monthlyUsage),
              0
            );

            return (
              <TableRow key={monthlyUsage} classes={{ root: classes.tableRow }}>
                <TableCell align="left" classes={{ root: classes.tableCell }}>
                  <RhTypography variant="body2">
                    {formattedMonthlyUsage}
                  </RhTypography>
                </TableCell>
                <TableCell align="left" classes={{ root: classes.tableCell }}>
                  <RhTypography variant="body2">
                    {formattedPricePerKWH}
                  </RhTypography>
                </TableCell>
                <TableCell align="left" classes={{ root: classes.tableCell }}>
                  <RhTypography variant="body2">
                    {formattedMonthlyPrice}
                  </RhTypography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box my={2}>
        <div>{rhythmCharge1}</div>
        <div>{rhythmCharge2}</div>
      </Box>
    </>
  );
};
