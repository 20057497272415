import { RH_CARD_HEADER_HEIGHT_PX } from "@design-system/components/RhCard/RhCard";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useAtAGlanceSectionStyles = rhMakeStyles<typeof rhythmTheme>(
  (theme) =>
    createStyles({
      container: {
        position: "relative",
      },
      fade: {
        position: "absolute",
        left: `calc(100% - ${theme.spacing(9)}px)`,
        bottom: 0,
        width: theme.spacing(9),
        height: `calc(100% - ${RH_CARD_HEADER_HEIGHT_PX}px)`,
        background:
          "linear-gradient(90deg, #FFFFFF 30.21%, rgba(255, 255, 255, 0) 100%)",
        transform: "rotate(-180deg)",
      },
    })
);
