import { useTheme } from "@material-ui/core";
import React, { FC } from "react";

export type CrossHairTextFormatType =
  | "monthWithHourDateFormat"
  | "monthDateFormat";

interface EnergyUsageChartCrossHairProps {
  dateFormat: string;
  dateFormatter: (date: Date, format: string) => string;
  scale?: {
    y: {
      range(): number[];
    };
  };
  datum?: {
    x: Date;
  };
  x?: number;
}

export const EnergyUsageChartCrossHair: FC<EnergyUsageChartCrossHairProps> = ({
  x,
  scale,
  dateFormatter,
  dateFormat,
  datum,
}) => {
  const theme = useTheme();
  const range = scale?.y.range() || [];

  return (
    <>
      <text y={20} fill={theme.palette.grey[900]}>
        <tspan x={x ? x + 8 : 0} dx="0" dy="0" textAnchor="middle">
          {datum && dateFormatter(datum.x, dateFormat)}
        </tspan>
      </text>
      <line
        style={{
          stroke: theme.palette.grey[600],
          strokeWidth: 1,
        }}
        x1={x}
        x2={x}
        y1={Math.max(...range)}
        y2={Math.min(...range) + 30}
      />
      <circle
        cx={x}
        cy={30}
        r={3}
        strokeWidth={1}
        stroke={theme.palette.grey[700]}
        fill={theme.palette.common.white}
      />
    </>
  );
};
