import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useBillInvoicesStyles = makeStyles<
  typeof rhythmTheme,
  "lateFeeMessage" | "invoiceRow"
>((theme) =>
  createStyles({
    invoiceRow: {
      "&:first-child": {
        borderTop: borders[100],
      },
      alignItems: "flex-start",
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    lateFeeMessage: {
      marginTop: 4,
    },
  })
);
