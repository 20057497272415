import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhIconButtonStyles = makeStyles<
  typeof rhythmTheme,
  "root" | "disabled"
>((theme) =>
  createStyles({
    disabled: {
      color: theme.palette.text.disabled,
    },
    root: {
      "&:active": {
        backgroundColor: theme.palette.primary[100],
        color: theme.palette.primary.main,
      },
      "&:hover,&:focus": {
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.primary.main,
      },
      color: theme.palette.text.secondary,
      fontSize: "2.2rem",
    },
  })
);
