import {
  ANNOUNCEMENT_HEIGHT,
  MAIN_CONTAINER_WIDTH,
  PORTAL_TOP_MARGIN,
} from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import React, { FC } from "react";

export const PortalPageLayout: FC = ({ children }) => {
  return (
    <Box
      maxWidth={MAIN_CONTAINER_WIDTH}
      width="100%"
      marginTop={ANNOUNCEMENT_HEIGHT + PORTAL_TOP_MARGIN}
      marginLeft="auto"
      marginRight="auto"
      marginBottom={9}
    >
      {children}
    </Box>
  );
};
