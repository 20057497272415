import { getEnvApplicationSettings } from "@common/services/getEnvApplicationSettings.service";
import { SnippetOptions } from "@fullstory/browser";
import { OktaAuthOptions } from "@okta/okta-auth-js";
import { allEnvApplicationSettings } from "@portal/settings/env";

interface GoogleTagManagerConfigObject {
  gtmId: string;
  auth?: string;
  preview?: string;
}

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings
);

export const APP_BASE_URL = envApplicationSettings.appBaseUrl;
export const AXIOS_BASE_URL = envApplicationSettings.axiosBaseUrl;
export const AXIOS_TIMEOUT_MS: number = envApplicationSettings.axiosTimeoutMs;
export const CDN_URL = envApplicationSettings.cdnUrl;

export const OKTA_CONFIG = Object.freeze<OktaAuthOptions>({
  clientId: envApplicationSettings.oktaClientId,
  issuer: envApplicationSettings.oktaIssuer,
  redirectUri: envApplicationSettings.oktaRedirectUri,
  scopes: ["openid", "profile", "email", "groups"],
  pkce: true,
});

export const ZUORA_URL = envApplicationSettings.zuoraSandboxBaseUrl;
export const ZUORA_PAYMENT_PAGE_ID = envApplicationSettings.zuoraPaymentPageId;
export const SMARTY_STREETS_WEBKEY = envApplicationSettings.smartyStreetsWebkey;

export const MAPBOX_TOKEN = envApplicationSettings.mapboxToken;
export const MAPBOX_API_URL = "https://api.mapbox.com";
export const MAPBOX_GEOCODING_API = `${MAPBOX_API_URL}/geocoding/v5/mapbox.places`;
export const MAPBOX_STATIC_API = `${MAPBOX_API_URL}/styles/v1/mlcamilli/ckhao6zpm2ff41aqho05d6j9b/static`;

export const GoogleTagManagerConfig: GoogleTagManagerConfigObject = {
  gtmId: envApplicationSettings.googleTagManagerId,
};

export const FullStoryConfig: SnippetOptions | null = envApplicationSettings.fullStoryOrgId
  ? Object.freeze({
      orgId: envApplicationSettings.fullStoryOrgId,
    })
  : null;

if (envApplicationSettings.googleTagManagerAuth) {
  GoogleTagManagerConfig.auth = envApplicationSettings.googleTagManagerAuth;
}
if (envApplicationSettings.googleTagManagerPreview) {
  GoogleTagManagerConfig.preview =
    envApplicationSettings.googleTagManagerPreview;
}

export const SENTRY_CONFIG = Object.freeze({
  dsn: envApplicationSettings.sentryDSN,
  release: envApplicationSettings.sentryRelease,
  tracesSampleRate: envApplicationSettings.sentryTracesSampleRate,
  orgSlug: envApplicationSettings.sentryOrgSlug,
});

export const FAVICON_PATH = envApplicationSettings.faviconPath;
