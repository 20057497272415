import { GetKwhUsageResponseType } from "@common/api/customerResponseTypes";
import {
  SHORT_MONTH_DATE_FORMAT,
  SHORT_MONTH_WITH_HOUR_DATE_FORMAT,
} from "@common/constants/date.constant";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhRoundTabItem } from "@design-system/components/RhRoundTabItem/RhRoundTabItem";
import { RhRoundTabs } from "@design-system/components/RhRoundTabs/RhRoundTabs";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { EnergyUsageChartCrossHair } from "@portal/components/EnergyUsageChart//EnergyUsageChartCrossHair";
import {
  ChartType,
  DataPoint,
  buildChartData,
} from "@portal/components/EnergyUsageChart/EnergyUsageChart.helpers";
import {
  CHART_PADDING,
  CHART_PADDING_TOP,
  USAGE_CHART_HEIGHT,
  getGraphStyleProps,
  useEnergyUsageChartStyles,
} from "@portal/components/EnergyUsageChart/EnergyUsageChart.style";
import { useDateFormatter } from "@portal/hooks/useDateFormatter";
import { useElementWidth } from "@portal/hooks/useElementWidth";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryAxisProps,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
} from "victory";

interface EnergyUsageChartProps {
  rawChartData: GetKwhUsageResponseType;
}
interface TabInfo {
  label: string;
  type: ChartType;
}

export const EnergyUsageChart: FC<EnergyUsageChartProps> = ({
  rawChartData,
}) => {
  const classes = useEnergyUsageChartStyles();
  const { t } = useRhIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const parentDivContainer = useRef<HTMLDivElement>(null);
  const { refWidth } = useElementWidth(parentDivContainer);
  const [currentDate, setCurrentDate] = useState<DataPoint | null>(null);

  const [chartType, setChartType] = useState<ChartType | undefined>();
  const [availableTabs, setAvailableTabs] = useState<TabInfo[]>();

  const chartData = useMemo(() => {
    return buildChartData(rawChartData);
  }, [rawChartData]);

  const dateFormatter = useMemo(useDateFormatter, [chartData]);

  const totalUsage = t("EnergyUsageChart.totalUsage");
  const totalCost = t("EnergyUsageChart.totalCost");

  const isAvailable = ({ type }: TabInfo): boolean => Boolean(chartData[type]);

  useEffect(() => {
    // set the default tab based on data availability
    if (chartData === undefined) {
      return;
    }
    const bill = t("EnergyUsageChart.bill");
    const week = t("EnergyUsageChart.week");
    const month = t("EnergyUsageChart.month");
    const availableTabData: TabInfo[] = [
      { label: week, type: ChartType.SevenDayUsage },
      { label: month, type: ChartType.ThirtyDayUsage },
      { label: bill, type: ChartType.BillUsage },
    ].filter(isAvailable);

    setAvailableTabs(availableTabData);
    setChartType(availableTabData[0].type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  if (
    chartData === undefined ||
    availableTabs === undefined ||
    chartType === undefined
  ) {
    return null;
  }

  const currentChart = chartData[chartType];

  if (!currentChart) {
    // This should never happen because we choose chartType from the
    // available data but this will make typescript happy that we're
    // checking for this before trying to render something
    return null;
  }

  const kwhUsage = currentDate
    ? formatKwh(currentDate.y, 0)
    : formatKwh(currentChart.totalKwh, 0);

  const costUsage = currentDate
    ? formatCurrency(currentDate.cost)
    : formatCurrency(currentChart.totalCost);

  const graphStyle = getGraphStyleProps(isMobile, theme);

  const crossHairFormat =
    chartType === ChartType.SevenDayUsage
      ? SHORT_MONTH_WITH_HOUR_DATE_FORMAT
      : SHORT_MONTH_DATE_FORMAT;

  const yAxis = (
    <VictoryAxis
      dependentAxis
      domain={{ y: [0, currentChart.maxKwh * 1.2] }}
      tickCount={10}
      style={graphStyle.yAxis}
      tickLabelComponent={
        <VictoryLabel
          {...graphStyle.victoryLabelProps}
          textAnchor="start"
          verticalAnchor="end"
        />
      }
    />
  );

  const xRangeInDays = currentChart.timePeriodInDays;
  const xAxisProps: VictoryAxisProps = {
    style: graphStyle.xAxis,
    fixLabelOverlap: true,
    tickCount: 7,
  };

  // NOTE: empirically, we start duplicating tick marks when we have less than 5 days of
  // data so we make this formatter switch at the 5 day boundary.  For less than 5 days
  // of data, let Victory Chart handle the tick label formatting.
  if (chartType !== ChartType.SevenDayUsage || xRangeInDays >= 5) {
    xAxisProps.tickFormat = (tick) =>
      dateFormatter(tick, SHORT_MONTH_DATE_FORMAT);
    xAxisProps.tickLabelComponent = <VictoryLabel dx={8} />;
  }
  // For the non seven day chart, we've rollup up data to each day (there is no
  // time element of those data points, so we just want to limit the number of
  // tick marks to be the smallest unique value to avoid duplicate ticks.
  if (chartType !== ChartType.SevenDayUsage && xRangeInDays < 7) {
    xAxisProps.tickCount = Math.max(1, Math.min(xRangeInDays, 7));
  }
  const xAxis = <VictoryAxis {...xAxisProps} />;

  return (
    <div ref={parentDivContainer}>
      <RhFlexBox className={classes.chartHeader}>
        <Box flexGrow={3} mb={2}>
          <RhRoundTabs align="left">
            {availableTabs.map(({ label, type }) => (
              <RhRoundTabItem
                key={label}
                label={label}
                onClick={() => {
                  setChartType(type);
                }}
              />
            ))}
          </RhRoundTabs>
        </Box>
        <RhFlexBox className={classes.usageInfoContainer}>
          <div className={classes.usageInfo}>
            <RhTypography variant="body2" color="textPrimary">
              {totalUsage}
            </RhTypography>
            <RhTypography variant="h3" color="textPrimary">
              {kwhUsage}
            </RhTypography>
          </div>
          <div className={classes.usageInfo}>
            <RhTypography variant="body2" color="textPrimary">
              {totalCost}
            </RhTypography>
            <RhTypography variant="h3" color="textPrimary">
              {costUsage}
            </RhTypography>
          </div>
        </RhFlexBox>
      </RhFlexBox>
      <div
        style={{
          height: USAGE_CHART_HEIGHT + CHART_PADDING_TOP,
          width: refWidth + CHART_PADDING,
        }}
        onMouseLeave={() => {
          setCurrentDate(null);
        }}
      >
        <VictoryChart
          width={refWidth + CHART_PADDING}
          padding={graphStyle.chart}
          height={USAGE_CHART_HEIGHT}
          scale={{ x: "time" }}
          containerComponent={
            <VictoryVoronoiContainer
              onActivated={([{ x, y, cost }]: DataPoint[]) => {
                setCurrentDate({ x, y, cost });
              }}
              voronoiDimension="x"
              labels={() => "x"}
              labelComponent={
                <EnergyUsageChartCrossHair
                  dateFormatter={dateFormatter}
                  dateFormat={crossHairFormat}
                />
              }
            />
          }
        >
          <VictoryArea data={currentChart.data} style={graphStyle.area} />
          {xAxis}
          {yAxis}
        </VictoryChart>
      </div>
    </div>
  );
};
