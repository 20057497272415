import { borders } from "@design-system/constants/borders";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useBillSummaryStyles = rhMakeStyles<
  typeof rhythmTheme,
  "amountDue" | "container" | "header"
>((theme) =>
  createStyles({
    amountDue: {
      borderBottom: borders[100],
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(2.5),
    },
    container: {
      width: "100%",
    },
    header: {
      marginBottom: theme.spacing(2.5),
      textAlign: "center",
    },
  })
);
