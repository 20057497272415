import { AccountSummary } from "@common/models/AccountSummary.model";
import { Customer } from "@common/models/Customer.model";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import { AutoPayInfo } from "@portal/components/AutoPayInfo/AutoPayInfo";
import { BillInvoices } from "@portal/components/BillInvoices/BillInvoices";
import { useBillSummaryStyles } from "@portal/pages/PayBillPage/BillSummary/BillSummary.style";
import React, { FC } from "react";
import { useIntl } from "react-intl";

interface BillSummaryProps {
  customer: Customer;
  accountSummary: AccountSummary | null;
}

export const BillSummary: FC<BillSummaryProps> = ({
  customer,
  accountSummary,
}) => {
  const { formatMessage } = useIntl();

  const classes = useBillSummaryStyles();

  return (
    <div className={classes.container}>
      <RhTypography variant="h1" className={classes.header}>
        {formatMessage({ id: "payBill.title.payBill" })}
      </RhTypography>
      <RhFlexBox justifyContent="center" marginBottom={3}>
        <AutoPayInfo
          autopayEnabled={customer.autopay}
          invoiceToPay={accountSummary?.invoiceToPay ?? null}
          billingPaymentMethodId={customer.billingPaymentMethodId}
        />
      </RhFlexBox>
      <Box marginBottom={3}>
        <BillInvoices
          customer={customer}
          invoices={accountSummary?.invoices ?? []}
        />
      </Box>
      <div className={classes.amountDue}>
        <RhTypography variant="subtitle1">
          {formatMessage({ id: "payBill.amountDue" })}
        </RhTypography>
        <RhTypography variant="h3">
          {formatCurrency(accountSummary?.totalBalance ?? 0)}
        </RhTypography>
      </div>
    </div>
  );
};
