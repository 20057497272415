import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useEnergyUsageChartMissingStyles = makeStyles<
  typeof rhythmTheme,
  "card" | "subtitle"
>((theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.grey[50],
      height: "100%",
      padding: `${theme.spacing(8)}px ${theme.spacing(3)}px`,
      textAlign: "center",
    },
    subtitle: {
      paddingTop: theme.spacing(0.5),
    },
  })
);
