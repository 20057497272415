import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core";

const TOGGLE_WIDTH = 320;

export const useCostEstimatorStyles = makeStyles<
  Theme,
  | "header"
  | "monthlyUsageInputContainer"
  | "root"
  | "subtitle"
  | "title"
  | "toggleContainer"
>((theme) =>
  createStyles({
    header: {
      flexDirection: "column",
      textAlign: "center",
    },
    monthlyUsageInputContainer: {
      marginBottom: theme.spacing(5),
      width: TOGGLE_WIDTH,
    },
    root: {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: theme.spacing(8),
      padding: `0 ${theme.spacing(2)}px`,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        minWidth: 500,
        width: "auto",
      },
    },
    subtitle: {
      color: theme.palette.grey["600"],
      marginBottom: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    toggleContainer: {
      marginBottom: theme.spacing(2),
      width: TOGGLE_WIDTH,
    },
  })
);
