import {
  FormError,
  OKTA_PASSWORD_REQUIREMENTS_ERROR,
  OKTA_REQUEST_ERROR,
} from "@common/types/errorTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { PortalPasswordField } from "@portal/components/PortalPasswordField/PortalPasswordField";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useResetPasswordStyles } from "@portal/pages/ResetPasswordPage/ResetPasswordPage.style";
import { useResetPasswordPageParams } from "@portal/pages/ResetPasswordPage/useResetPasswordPageParams";
import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";

interface ResetPasswordFormValues {
  password: string;
}

export const ResetPasswordPage = () => {
  const { token } = useResetPasswordPageParams();
  const { t } = useRhIntl();
  const classes = useResetPasswordStyles();
  const flash = useRhFlash();

  const inputCaption = t("common.password.minimumRequirements");

  const resetPassword = async ({
    password,
  }: ResetPasswordFormValues): Promise<FormError | void> => {
    const [error] = await handleAjaxCall<void>(
      portalAuthClient.resetPassword({
        recoveryToken: token,
        password,
      })
    );

    if (error) {
      let errorMessageId: string;

      switch (error.data.errorCode) {
        case OKTA_REQUEST_ERROR:
        case OKTA_PASSWORD_REQUIREMENTS_ERROR: {
          errorMessageId = "ResetPasswordPage.invalidPassword";
          break;
        }
        default: {
          errorMessageId = "ResetPasswordPage.errorResettingPassword";
          break;
        }
      }

      const errorMessage = t(errorMessageId);

      flash.error(errorMessage);

      return { [FORM_ERROR]: [errorMessage] };
    }
  };

  const password = t("resetPassword.password");
  const next = t("resetPassword.nextCTA");

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader headerTextId="resetPassword.title" />
      <Form<ResetPasswordFormValues>
        onSubmit={resetPassword}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={next}>
            <LoggedOutFieldsLayout>
              <PortalPasswordField name="password">
                {password}
              </PortalPasswordField>
              <RhTypography
                variant="caption"
                classes={{ root: classes.caption }}
              >
                {inputCaption}
              </RhTypography>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </LoggedOutPageLayout>
  );
};
