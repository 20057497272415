import { maskCurrency } from "@common/forms/currency.mask";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { AmountType } from "@portal/enums/AmountType.enum";
import { useCustomerPaymentFieldStyles } from "@portal/pages/PayBillPage/CustomPaymentField/CustomPaymentField.style";
import { PayBillFormValues } from "@portal/pages/PayBillPage/PayBillPage";
import React, { FC, useEffect } from "react";
import { useField, useFormState } from "react-final-form";

export const CustomPaymentField: FC = () => {
  const { values } = useFormState<PayBillFormValues>({
    subscription: { values: true },
  });
  const {
    input: { onChange },
  } = useField<string>("maskedCustomAmount");

  const classes = useCustomerPaymentFieldStyles();
  const isAmountTotal = values.amountType === AmountType.Total;

  useEffect(() => {
    if (isAmountTotal) {
      const stringifiedTotal = values.totalAmount?.toString() ?? "0";

      onChange(maskCurrency(stringifiedTotal));
    }
  }, [isAmountTotal, onChange, values.totalAmount]);

  return isAmountTotal ? null : (
    <div className={classes.root}>
      <RhTextField
        autoFocus
        name="maskedCustomAmount"
        format={maskCurrency}
        styles={{ textAlign: "end", paddingRight: 0 }}
        inputMode="numeric"
      />
    </div>
  );
};
