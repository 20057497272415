import { selectBillingDetailsState } from "@portal/selectors/billingDetailsSelectors";
import { billingDetailsFetch } from "@portal/slices/billingDetailsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// if I got CCs, give them to me. If we don't, go fetch them, then give them to me
export const usePaymentMethods = () => {
  const { data: paymentMethods, error, requestMonitor } = useSelector(
    selectBillingDetailsState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(billingDetailsFetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    paymentMethods,
    error,
    requestMonitor,
  };
};
