import { formatCurrency } from "@common/utils/dataFormatters";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { formatShortDate } from "@portal/forms/formatters";
import { useGetCustomerRewards } from "@portal/hooks/rewardsHooks";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useRewardHistoryStyle } from "@portal/pages/RewardsPage/RewardsHistoryTable.style";
import classNames from "classnames";
import React, { FC } from "react";

export const RewardsHistoryTable: FC = () => {
  const { t } = useRhIntl();
  const classes = useRewardHistoryStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { rewards } = useGetCustomerRewards();

  if (!rewards?.history.length) {
    return null;
  }

  const title = t("RewardsHistoryTable.title");

  return (
    <RhCard title={title}>
      <RhFlexBox className={classes.table}>
        {rewards.history.map(({ name, points, createdAt, value }, index) => {
          const date = formatShortDate(createdAt);
          const defaultDescription = t("RewardsHistoryTable.defaultRewardName");
          const description = name
            ? t(`RewardsHistoryTable.reward.${name}`)
            : defaultDescription;
          const pointsAmount = t(
            `RewardsHistoryTable.${isMobile ? "mobilePoints" : "points"}`,
            { points }
          );
          const pointsValue = formatCurrency(value);

          return (
            <RhFlexBox
              key={`${name}-${points}-${createdAt}`}
              className={classes.row}
            >
              <RhTypography
                component="div"
                className={classNames(classes.rowElement, classes.date)}
                variant="body1"
              >
                {date}
              </RhTypography>
              <RhTypography
                component="div"
                className={classNames(classes.rowElement, classes.description)}
                variant="body1"
              >
                {description}
              </RhTypography>
              <RhTypography
                component="div"
                className={classNames(classes.rowElement, classes.pointsAmount)}
                variant="body1"
              >
                {pointsAmount}
              </RhTypography>
              <RhTypography
                component="div"
                className={classNames(classes.rowElement, classes.pointsValue)}
                variant="body1"
              >
                {pointsValue}
              </RhTypography>
            </RhFlexBox>
          );
        })}
      </RhFlexBox>
    </RhCard>
  );
};
