import { OktaGroups } from "@common/constants/okta.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";

export type OktaGetUserResponse = {
  email: string;
  emailVerified: boolean;
  groups: OktaGroups[];
  sub: string;
};

export const useAuthenticatedUserGroups = () => {
  const {
    authState: { isAuthenticated },
    authService: { getUser },
  } = useOktaAuth();

  const [ajaxState, { setSuccess, setPending, setFailure }] = useAjaxState<
    OktaGroups[]
  >();

  useEffect(() => {
    if (isAuthenticated) {
      setPending();
      getUser()
        .then((user: OktaGetUserResponse) => setSuccess(user?.groups))
        .catch(setFailure);
    } else if (isAuthenticated === false) {
      setSuccess([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return ajaxState;
};
