import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useAutoPayDialogStyles = makeStyles<
  typeof rhythmTheme,
  "button" | "subheader" | "inlineParagraphText"
>((_theme) =>
  createStyles({
    button: {
      width: 180,
    },
    inlineParagraphText: {
      display: "inline",
      marginLeft: 5,
    },
    subheader: {
      display: "inline",
      fontWeight: "bold",
      marginLeft: 5,
    },
  })
);
