import { AccountSummary } from "@common/models/AccountSummary.model";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { AutoPayInfo } from "@portal/components/AutoPayInfo/AutoPayInfo";
import { useBalanceDetailsStyles } from "@portal/components/BalanceDetails/BalanceDetails.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

interface BalanceDetailsProps {
  accountSummary: AccountSummary;
  autopayEnabled?: boolean;
}

export const BalanceDetails: FC<BalanceDetailsProps> = ({
  accountSummary,
  autopayEnabled = false,
}) => {
  const { t } = useRhIntl();
  const classes = useBalanceDetailsStyles();

  let balanceDueColor: "primary" | "error" | "textSecondary" = "primary";
  let balanceDueDate = t("account.balance.balanceDue", {
    date: accountSummary.formattedAccountDueDate,
  });
  let displayBalance = accountSummary.currentDueBalance;

  if (accountSummary.hasPastDueInvoices) {
    balanceDueColor = "error";
    balanceDueDate = t("account.balance.balanceDueLate");
    displayBalance = accountSummary.pastDueBalance;
  }

  if (accountSummary.noBalanceDue) {
    balanceDueColor = "textSecondary";
    balanceDueDate = t("account.balance.noBalanceDue");
  }

  return (
    <RhFlexBox flexDirection="column" alignItems="center">
      <RhTypography className={classes.balanceDueTitle} variant="subtitle2">
        {balanceDueDate}
      </RhTypography>
      <RhTypography
        variant="h1"
        color={balanceDueColor}
        className={classes.balanceDueAmount}
      >
        {formatCurrency(displayBalance)}
      </RhTypography>
      {accountSummary.hasPastDueInvoices &&
        accountSummary.currentDueBalance > 0 && (
          <RhTypography className={classes.nextBillExplainer}>
            {t("account.balance.nextBillExplainer", {
              amount: formatCurrency(accountSummary.currentDueBalance),
              date: accountSummary.formattedNextDueDate,
            })}
          </RhTypography>
        )}
      <AutoPayInfo
        autopayEnabled={autopayEnabled}
        invoiceToPay={accountSummary.invoiceToPay}
      />
    </RhFlexBox>
  );
};
