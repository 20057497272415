import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useMyAccountPasswordChangeStyles = rhMakeStyles<
  typeof rhythmTheme,
  "fieldsContainer" | "passwordRequirements"
>((theme) =>
  createStyles({
    fieldsContainer: {
      "& > *": {
        marginBottom: theme.spacing(2.5),
      },
      [theme.breakpoints.up("md")]: {
        width: `calc(50% - ${theme.spacing(2)}px)`,
      },
    },
    passwordRequirements: {
      marginTop: theme.spacing(2),
    },
  })
);
