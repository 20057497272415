import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { RightChevron } from "@design-system/icons";
import { useAddACHStyles } from "@portal/components/AddACH/AddACH.style";
import { PlaidLink } from "@portal/components/AddACH/PlaidLink";
import { useCustomer } from "@portal/hooks/useCustomer";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC, useEffect, useState } from "react";

export const AddACH: FC = () => {
  const { customer } = useCustomer();

  const { t } = useRhIntl();
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [fetchingLinkToken, setFetchingLinkToken] = useState<boolean>(false);
  const [linkTokenFailed, setLinkTokenFailed] = useState<boolean>(false);
  const [isPlaidModalOpen, setIsPlaidModalOpen] = useState<boolean>(false);
  const classes = useAddACHStyles();

  async function fetchLinkToken(customerId?: IdType) {
    if (customerId) {
      setFetchingLinkToken(true);
      const [error, response] = await handleAjaxCall(
        api.customers.plaid.getLinkToken(customerId)
      );

      setFetchingLinkToken(false);
      if (error) {
        setLinkTokenFailed(true);
      }

      if (response) {
        setLinkToken(response.linkToken);
      }
    }
  }

  useEffect(() => {
    if (linkToken === null) {
      fetchLinkToken(customer?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkToken, customer?.id]);

  if (!customer?.id || linkTokenFailed) {
    return null;
  }

  const addACH = () => {
    setIsPlaidModalOpen(true);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={classes.root} onClick={addACH}>
      <RhInnerCard>
        <div className={classes.content}>
          {fetchingLinkToken ? (
            <RhCircularProgress marginBottom={0} marginTop={0} />
          ) : (
            <>
              <RhTypography
                variant="subtitle2"
                fontWeight={FontWeight.Semibold}
              >
                {t("AddACH.addNewACH")}
              </RhTypography>

              <RightChevron />
            </>
          )}
        </div>
      </RhInnerCard>
      {linkToken && (
        <PlaidLink
          token={linkToken}
          customerId={customer.id}
          isPlaidModalOpen={isPlaidModalOpen}
          onPlaidModalClose={() => {
            setLinkToken(null);
            setIsPlaidModalOpen(false);
          }}
        />
      )}
    </div>
  );
};
