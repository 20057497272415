import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import {
  ANNOUNCEMENT_HEIGHT,
  LOGGED_OUT_PAGE_DENSE_WIDTH,
} from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { CampaignPromoAnnouncement } from "@portal/components/CampaignPromoAnnouncement/CampaignPromoAnnouncement";
import { SignUpBackButton } from "@portal/components/SignUpBackButton/SignUpBackButton";
import { useSignUpPageLayoutStyles } from "@portal/components/SignUpPageLayout/SignUpPageLayout.style";
import { SignUpStepper } from "@portal/components/SignUpStepper/SignUpStepper";
import React, { FC } from "react";

interface SignUpFormPageProps {
  dense?: boolean;
  hideBackButton?: boolean;
  handleBackButtonClick?: () => void;
}

export const SignUpPageLayout: FC<SignUpFormPageProps> = ({
  dense = true,
  hideBackButton = false,
  handleBackButtonClick,
  children,
}) => {
  const classes = useSignUpPageLayoutStyles();

  return (
    <Box mt={ANNOUNCEMENT_HEIGHT + 3}>
      <CampaignPromoAnnouncement />
      <SignUpStepper />
      <RhFlexBox
        alignItems="center"
        flexDirection="column"
        maxWidth={dense ? LOGGED_OUT_PAGE_DENSE_WIDTH : "100%"}
      >
        {children}
        {!hideBackButton && (
          <RhFlexBox
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            height="100%"
            width="100%"
          >
            <Box className={classes.backButtonContainer}>
              <SignUpBackButton handleClick={handleBackButtonClick} />
            </Box>
          </RhFlexBox>
        )}
      </RhFlexBox>
    </Box>
  );
};
