import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { ProspectType } from "@common/types/prospectTypes";
import { selectProspectId } from "@portal/selectors/signUpSelectors";
import {
  SetSignUpInfoAction,
  prospectReceived,
  prospectRefetch,
  setSignUpInfo,
} from "@portal/slices/signUpSlice";
import { isBot } from "@portal/utils/isBot";
import { convertSignUpStatePayloadToProspectType } from "@portal/utils/signUpHelpers";
import {
  SagaReturnType,
  call,
  debounce,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

const DEBOUNCE_DELAY_MS = 250;

export function* refetchProspectWorker() {
  const prospectId: IdType = yield select(selectProspectId);

  // eslint-disable-next-line no-useless-catch
  try {
    const prospect: ProspectType = yield call(
      api.prospects.retrieve,
      prospectId
    );

    yield put(prospectReceived(prospect));
  } catch (err) {
    // This catch is here to remind us that we might want
    // to do something more than just notify Sentry
    throw err;
  }
}

export function* upsertProspectWorker({ payload }: SetSignUpInfoAction) {
  if (isBot()) {
    // If this is a bot, don't create a prospect -- there's no chance
    // it'll sign up!
    return;
  }
  const prospectId: SagaReturnType<typeof selectProspectId> = yield select(
    selectProspectId
  );
  const prospectData = convertSignUpStatePayloadToProspectType(payload);

  if (prospectId) {
    // eslint-disable-next-line no-useless-catch
    try {
      yield call(api.prospects.update, prospectId, prospectData);
    } catch (err) {
      // This catch is here to remind us that we might want
      // to do something more than just notify Sentry
      throw err;
    }
  } else {
    try {
      const { id }: ProspectType = yield call(
        api.prospects.create,
        prospectData
      );

      yield put(prospectReceived({ id }));
    } catch (err) {
      yield put(prospectReceived({ id: "" }));
      throw err;
    }
  }
}

export function* refetchProspectWatcher() {
  yield takeLatest(prospectRefetch.type, refetchProspectWorker);
}
export function* upsertProspectWatcher() {
  yield debounce(DEBOUNCE_DELAY_MS, setSignUpInfo.type, upsertProspectWorker);
}
