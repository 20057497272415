import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useOfferUsageTableStyles = makeStyles<
  typeof rhythmTheme,
  "tableRow" | "table" | "tableCell" | "tableHeadCell"
>((theme) =>
  createStyles({
    table: {
      width: "fit-content",
    },
    tableCell: {
      color: theme.palette.text.secondary,
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    tableHeadCell: {
      color: theme.palette.text.primary,
      lineHeight: 1,
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1),
      textTransform: "none",
    },
    tableRow: {
      "&:last-of-type td": {
        borderBottomWidth: 0,
      },
      "&:nth-child(2n+1)": {
        backgroundColor: theme.palette.common.white,
      },
    },
  })
);
