import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

type SignUpSummaryPageStyleClasses =
  | "byCheckingThisBox"
  | "checkbox"
  | "wrapper"
  | "summaryInfoSection";

export const useSignUpSummaryPageStyles = makeStyles<
  typeof rhythmTheme,
  SignUpSummaryPageStyleClasses
>((theme) =>
  createStyles({
    byCheckingThisBox: {
      alignItems: "flex-start",
    },
    checkbox: {
      marginRight: 4,
    },
    summaryInfoSection: {
      borderBottom: borders[100],
    },
    wrapper: {
      [theme.breakpoints.only("xs")]: {
        width: 312,
      },
      maxWidth: 435,
    },
  })
);
