import { RhCheckbox } from "@design-system/components/RhCheckbox/RhCheckbox";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useMyAccountMailingAddressFieldsStyles } from "@portal/components/MyAccountMailingAddressForm/MyAccountMailingAddressFields.style";
import { MyAccountSubmitButton } from "@portal/components/MyAccountSubmitButton/MyAccountSubmitButton";
import { StateSelectField } from "@portal/components/StateSelectField/StateSelectField";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";
import { useFormState } from "react-final-form";

export const MyAccountMailingAddressFields: FC = () => {
  const classes = useMyAccountMailingAddressFieldsStyles();
  const { t } = useRhIntl();
  const { values } = useFormState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const address = t("MyAccountMailingAddressForm.address");
  const unitNumber = t("MyAccountMailingAddressForm.unitNumber");
  const city = t("MyAccountMailingAddressForm.city");
  const zipCode = t("MyAccountMailingAddressForm.zipCode");

  return (
    <>
      <RhCheckbox
        name="mailingAddressSameAsServiceAddress"
        label={t(
          "MyAccountMailingAddressForm.mailingAddressSameAsServiceAddress"
        )}
      />
      {!values.mailingAddressSameAsServiceAddress && (
        <div className={classes.fieldsContainer}>
          <RhTextField
            name="addressLine1"
            autoComplete="address-line1"
            autoFocus
          >
            {address}
          </RhTextField>
          <RhTextField name="unitNumber" autoComplete="address-line2">
            {unitNumber}
          </RhTextField>
          <RhTextField name="city" autoComplete="address-level2">
            {city}
          </RhTextField>
          <div data-testid="stateSelectField__state">
            <StateSelectField
              name="state"
              translationId="MyAccountMailingAddressForm.state"
            />
          </div>
          <RhTextField
            name="zipCode"
            autoComplete="postal-code"
            inputMode="numeric"
          >
            {zipCode}
          </RhTextField>
        </div>
      )}

      <RhFlexBox justifyContent="flex-end">
        <MyAccountSubmitButton isMobile={isMobile} />
      </RhFlexBox>
    </>
  );
};
