import { borders } from "@design-system/constants/borders";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhInnerCardStyles = rhMakeStyles<
  typeof rhythmTheme,
  "container" | "border"
>((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2.5),
      minHeight: "100%",
    },
    border: {
      border: borders[100],
      borderRadius: "6px",
    },
  })
);
