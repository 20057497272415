import {
  rafPointsAmount,
  rafPointsValue,
} from "@common/constants/rewards.constant";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { CopyReferralLinkButton } from "@portal/components/CopyReferralLinkButton/CopyReferralLinkButton";
import { REWARDS_FAQ_URL } from "@portal/routes/routePaths";
import React, { FC } from "react";
import { useIntl } from "react-intl";

export const ReferAFriendSection: FC = () => {
  const { formatMessage } = useIntl();

  const incentiveText = formatMessage(
    {
      id: "ReferAFriendSection.incentiveText",
    },
    {
      pointsAmount: rafPointsAmount,
      pointsValue: rafPointsValue,
      linkChunk: (content) => (
        <Link href={REWARDS_FAQ_URL} target="_blank" rel="noopener noreferrer">
          {content}
        </Link>
      ),
    }
  );

  const title = formatMessage({
    id: "ReferAFriendSection.referAFriend",
  });

  return (
    <RhCard title={title}>
      <Box marginBottom={2}>
        <RhTypography variant="subtitle2">{incentiveText}</RhTypography>
      </Box>
      <CopyReferralLinkButton />
    </RhCard>
  );
};
