import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { billingDetailsRefetch } from "@portal/slices/billingDetailsSlice";
import { FC, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useDispatch } from "react-redux";

interface PlaidLinkProps {
  customerId: IdType;
  isPlaidModalOpen: boolean;
  onPlaidModalClose(): void;
  token: string;
}

export interface PlaidSuccessResponse {
  // eslint-disable-next-line camelcase
  account_id: string;
}

export const PlaidLink: FC<PlaidLinkProps> = ({
  customerId,
  isPlaidModalOpen,
  onPlaidModalClose,
  token,
}: PlaidLinkProps) => {
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const dispatch = useDispatch();

  const { open, ready, error } = usePlaidLink({
    onSuccess: async (
      publicToken: string,
      { account_id: plaidAccountId }: PlaidSuccessResponse
    ) => {
      const [responseError] = await handleAjaxCall(
        api.customers.payments.saveACH(customerId, {
          publicToken,
          plaidAccountId,
        })
      );

      if (error || responseError) {
        flash.error(t("AddACH.plaidIssues"));
      } else {
        dispatch(billingDetailsRefetch());
      }
      onPlaidModalClose();
    },
    token,
    onLoad: () => {},
    onExit: () => {
      onPlaidModalClose();
    },
  });

  useEffect(() => {
    if (ready && isPlaidModalOpen) {
      open();
    }
  }, [ready, open, isPlaidModalOpen]);

  return null;
};
