import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useBillingSectionStyles = makeStyles<
  typeof rhythmTheme,
  "billingSectionContainer"
>((theme) =>
  createStyles({
    billingSectionContainer: {
      [theme.breakpoints.up("md")]: {
        maxWidth: 350,
      },
    },
  })
);
