import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useInvoicesRowStyle = makeStyles<
  typeof rhythmTheme,
  "root" | "column"
>((theme) =>
  createStyles({
    column: {
      "&:first-child": {
        flexBasis: 145,
        marginRight: "auto",
        padding: 0,
        textAlign: "start",
      },
      "&:last-child": {
        flexBasis: 150,
        flexShrink: 1,
        padding: 0,
        textAlign: "end",
        [theme.breakpoints.down("sm")]: {
          flexBasis: 110,
        },
      },
      flexBasis: 71,
      flexShrink: 1,
      textAlign: "end",
    },
    root: {
      alignItems: "center",
      borderBottom: borders[100],
      justifyContent: "end",
      minHeight: "53px",
    },
  })
);
