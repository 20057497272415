import { selectCustomer } from "@portal/selectors/customerSelector";
import { customerFetch } from "@portal/slices/customerSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCustomer = () => {
  const { customer, status, error } = useSelector(selectCustomer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!customer && !status.isPending) {
      dispatch(customerFetch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    customer,
    requestMonitor: status,
    error,
  };
};
