import { blankAddress } from "@common/constants/blankAddress.constant";
import { DOB_DATE_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import { CampaignPromoType } from "@common/types/campaignTypes";
import {
  AddressFormType,
  LanguagePreferenceType,
} from "@common/types/customerTypes";
import { ProspectType } from "@common/types/prospectTypes";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { SignUpCreatePasswordPageValues } from "@portal/pages/CreatePasswordPage/CreatePasswordPage";
import { ExpiredPasswordTokenFormValues } from "@portal/pages/ExpiredPasswordTokenPage/ExpiredPasswordTokenPage";
import { SignUpBillingPreferencesFormValues } from "@portal/pages/SignUpBillingPreferencesPage/SignUpBillingPreferencesPage";
import { SignUpContactFormValues } from "@portal/pages/SignUpContactPage/SignUpContactPage";
import { SignUpEnrollmentFormValues } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentFormValuesTypes";
import { SignUpNameFormValues } from "@portal/pages/SignUpNamePage/SignUpNamePage";
import { SignUpOfferFormValues } from "@portal/pages/SignUpOfferPage/SignUpOfferPage";
import { SignUpPaymentFormValues } from "@portal/pages/SignUpPaymentPage/SignUpPaymentPage";
import { SignUpPremiseFormValues } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import { SignUpStepType } from "@portal/routes/routePaths";
import {
  userLoggedIn,
  userLoggedOut,
} from "@portal/slices/authenticationSlice";
import { customerUpdatedLanguagePreference } from "@portal/slices/customerPreferencesSlice";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import merge from "lodash/merge";

export interface SignUpStepValues {
  visitedSteps: SignUpStepType[];
}

// Types
export interface SignUpStateType
  extends SignUpNameFormValues,
    SignUpContactFormValues,
    SignUpPremiseFormValues,
    SignUpOfferFormValues,
    SignUpEnrollmentFormValues,
    SignUpPaymentFormValues,
    SignUpBillingPreferencesFormValues,
    SignUpCreatePasswordPageValues,
    ExpiredPasswordTokenFormValues,
    SignUpStepValues {
  campaignSlug: string | null;
  campaignPromo: CampaignPromoType | null;
  referralFromFriendCode: string | null;
  prospectId: IdType | null;
  estimatedMonthlyUsage: number;
  areaNotCovered: boolean | null;
  googleClientId: string | null;
  segmentAnonId: string | null;
  languagePreference: LanguagePreferenceType | null;
}

export type SetSignUpInfoAction = PayloadAction<Partial<SignUpStateType>>;

// Handlers
export const setSignUpInfoHandler: CaseReducer<
  SignUpStateType,
  SetSignUpInfoAction
> = (state, action) => {
  return merge({}, state, action.payload);
};

export const prospectReceivedHandler: CaseReducer<
  SignUpStateType,
  PayloadAction<Partial<ProspectType>>
> = (state, { payload }) => {
  return merge({}, state, {
    areaNotCovered: payload.areaNotCovered,
    autopay: payload.autopay,
    billingPaymentMethodId: payload.billingPaymentMethodId,
    dateOfBirth: payload.dateOfBirth
      ? dayjs(payload.dateOfBirth, "YYYY-MM-DD").format(DOB_DATE_FORMAT)
      : state.dateOfBirth,
    email: payload.email,
    enrollmentType: payload.enrollmentType,
    firstName: payload.firstName,
    googleClientId: payload.googleClientId,
    invoiceByPrint: !payload.eBillOnly,
    lastName: payload.lastName,
    marketingEmailOptIn: payload.marketingEmailOptIn,
    offersnapshotId: payload.offersnapshotId,
    phone: payload.phone,
    prospectId: payload.id,
    segmentAnonId: payload.segmentAnonId,
    serviceAddress: {
      addressLine1: payload.addressLine1,
      unitNumber: payload.unitNumber,
      city: payload.city,
      state: payload.state,
      zipCode: payload.zipCode,
    } as AddressFormType,
    serviceStartDate: payload.serviceStartDate,
  });
};

export const clearSignUpInfoHandler: CaseReducer<
  SignUpStateType,
  PayloadAction
> = () => ({ ...initialSignUpState });

export const completedSignUpInfoHandler: CaseReducer<
  SignUpStateType,
  SetSignUpInfoAction
> = (state, action) => {
  const {
    payload: { activationToken, stateToken },
  } = action;
  const { firstName } = state;

  // clear out everything except what's needed for `CreatePassword`
  // this ensures a customer can't back into the sign up flow after creating an account
  return merge({}, initialSignUpState, {
    firstName,
    activationToken,
    stateToken,
  });
};

// Reducer
export const initialSignUpState = Object.freeze<SignUpStateType>({
  activationToken: "",
  areaNotCovered: null,
  autopay: false,
  billingPaymentMethodId: null,
  campaignPromo: null,
  campaignSlug: "",
  confirmEmail: "",
  creditEvaluation: null,
  dateOfBirth: "",
  depositAmount: null,
  dunsNumber: "",
  email: "",
  enrollmentType: null,
  esiId: "",
  estimatedMonthlyUsage: DEFAULT_AVERAGE_MONTHLY_USAGE,
  firstName: "",
  googleClientId: null,
  id: "",
  invoiceByPrint: true,
  languagePreference: null,
  lastName: "",
  offersnapshotId: "",
  phone: "",
  prospectId: null,
  referralFromFriendCode: null,
  segmentAnonId: null,
  sendMarketingPromos: true,
  serviceAddress: {
    ...blankAddress,
  },
  serviceStartDate: "",
  sessionToken: "",
  ssnRequired: false,
  stateToken: "",
  visitedSteps: ["availability"],
});

const reducers = {
  updated: setSignUpInfoHandler,
  completed: completedSignUpInfoHandler,
  prospectIdChanged: setSignUpInfoHandler,
  prospectReceived: prospectReceivedHandler,
};

export const signUpSlice = createSlice<SignUpStateType, typeof reducers>({
  name: "signUp",
  initialState: initialSignUpState,
  reducers,
  extraReducers: {
    [userLoggedIn.type]: clearSignUpInfoHandler,
    [userLoggedOut.type]: clearSignUpInfoHandler,
    [customerUpdatedLanguagePreference.type]: setSignUpInfoHandler,
  },
});

export const signUpSliceName = signUpSlice.name;
export const signUpReducer = signUpSlice.reducer;
export const setSignUpInfo = signUpSlice.actions.updated;
export const completedSignUp = signUpSlice.actions.completed;
export const { prospectReceived } = signUpSlice.actions;

export const prospectRefetch = createAction(`${signUpSlice.name}/refetch`);
