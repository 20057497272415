import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ReactComponent as CloudPlug } from "@portal/components/OfferFeatures/cloudPlug.svg";
import { useOfferFeaturesStyles } from "@portal/components/OfferFeatures/OfferFeatures.style";
import { useFormattedFeatures } from "@portal/components/OfferFeatures/useFormattedFeatures";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

interface OfferFeaturesProps {
  dense?: boolean;
  termMonths?: number;
  mouseOver?: boolean;
  isActiveSlide?: boolean;
}

export const OfferFeatures: FC<OfferFeaturesProps> = ({
  dense,
  termMonths,
  mouseOver,
  isActiveSlide,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const features = useFormattedFeatures(termMonths);
  const classes = useOfferFeaturesStyles();
  const { t } = useRhIntl();
  const planDetails = t("OfferFeatures.planDetails");
  const ourEnergy = t("OfferFeatures.ourEnergy");
  const ourPromise = t("OfferFeatures.ourPromise");
  const fairForAll = t("OfferFeatures.fairForAll");
  const itDoesntMatter = t("OfferFeatures.sameGreatRates");
  const easyEnergyPromise = t("OfferFeatures.easyEnergyPromise");
  const weHopeYouLoveUs = t("OfferFeatures.weHopeYouLoveUs");
  const noHiddenFees = t("OfferFeatures.noHiddenFees");
  const whatYouSeeIsWhatYouGet = t("OfferFeatures.whatYouSeeIsWhatYouGet");
  const estimateYourBill = t("OfferFeatures.estimateYourBill");

  return dense ? (
    <List dense>
      {features.map(({ HoverIcon, Icon, text }) => (
        <ListItem key={text} className={classes.listItem}>
          <ListItemIcon
            className={`${classes.listItemIcon} ${
              isActiveSlide || mouseOver ? "hover-active" : ""
            }`}
          >
            <HoverIcon title={text} />
          </ListItemIcon>
          <ListItemIcon
            className={`${classes.hoverListItemIcon} ${
              isActiveSlide || mouseOver ? "hover-active" : ""
            }`}
          >
            <Icon title={text} />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  ) : (
    <>
      <Box mb={3}>
        <RhTypography variant="h2">{planDetails}</RhTypography>
      </Box>
      <List dense>
        {features.map(({ HoverIcon, text, subtitle }) => (
          <ListItem key={text} className={classes.listItem}>
            <ListItemIcon className={`${classes.listItemIcon} hover-active`}>
              <HoverIcon title={text} />
            </ListItemIcon>
            <ListItemText primary={text} secondary={subtitle} />
          </ListItem>
        ))}
      </List>

      <Box className={classes.rhythmPromiseDetails}>
        <RhFlexBox
          alignItems="center"
          flexDirection={isMobile ? "column-reverse" : "row"}
        >
          <Box mt={2} mb={2}>
            <RhTypography variant="h2">{ourEnergy}</RhTypography>
            <Box mt={1} pr={2}>
              <RhTypography
                className={classes.secondaryDarken}
                color="textSecondary"
                variant="body1"
              >
                {ourPromise}
              </RhTypography>
            </Box>
          </Box>
          <Box>
            <CloudPlug />
          </Box>
        </RhFlexBox>
        <List dense>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={fairForAll}
              secondary={itDoesntMatter}
              secondaryTypographyProps={{ className: classes.secondaryDarken }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={easyEnergyPromise}
              secondary={weHopeYouLoveUs}
              secondaryTypographyProps={{ className: classes.secondaryDarken }}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={noHiddenFees}
              secondary={whatYouSeeIsWhatYouGet}
              secondaryTypographyProps={{ className: classes.secondaryDarken }}
            />
          </ListItem>
        </List>
      </Box>
      <Box my={3}>
        <RhTypography variant="h2">{estimateYourBill}</RhTypography>
      </Box>
    </>
  );
};
