import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core/styles";

export const useSignUpPageLayoutStyles = makeStyles<
  Theme,
  "backButtonContainer"
>((theme) =>
  createStyles({
    backButtonContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1),
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        left: 0,
        paddingLeft: theme.spacing(10),
        position: "absolute",
        top: "320px",
        transform: "translateY(-50%)",
      },
    },
  })
);
