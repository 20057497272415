import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidAdultBirthday } from "@common/forms/validators";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpNameFields } from "@portal/components/SignUpNameFields/SignUpNameFields";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectSignUpNameFormValues } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export type SignUpNameFormValues = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
};

const initialValues: SignUpNameFormValues = Object.freeze<SignUpNameFormValues>(
  {
    dateOfBirth: "",
    firstName: "",
    lastName: "",
  }
);

const signUpNameFormValidator = generateValidationErrorCollector<SignUpNameFormValues>(
  {
    dateOfBirth: [isRequired, isValidAdultBirthday],
    firstName: [isRequired],
    lastName: [isRequired],
  }
);

export const SignUpNamePage: FC = () => {
  const { formatMessage } = useIntl();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const currentValues = useSelector(selectSignUpNameFormValues);

  const onSubmit = (values: SignUpNameFormValues) => {
    signUpClickNextStepHandler({
      signUpData: values,
      nextStep: "contact",
      track: true,
    });
  };

  const submitCTA = formatMessage({ id: "signUpNameFields.submitCTA" });

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader headerTextId="signUpNamePage.tellUsAboutYourself" />
      <Form<SignUpNameFormValues>
        initialValues={{ ...initialValues, ...currentValues }}
        onSubmit={onSubmit}
        validate={signUpNameFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
            <SignUpNameFields
              onFocus={(event) => {
                trackEvent({
                  action: ActionType.focus,
                  label: event.target.id,
                });
              }}
            />
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
