import { OfferType } from "@common/types/offerTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { MiniOfferCard } from "@portal/components/OfferMiniChooser/MiniOfferCard";
import { useOfferMiniChooserStyles } from "@portal/components/OfferMiniChooser/OfferMiniChooser.style";
import React, { FC } from "react";

interface OfferMiniChooserProps {
  offers: OfferType[];
  onSelectOffer: (offer: OfferType) => void;
}

export const OfferMiniChooser: FC<OfferMiniChooserProps> = ({
  offers,
  onSelectOffer: onSelect,
}) => {
  const classes = useOfferMiniChooserStyles();

  return (
    <RhFlexBox className={classes.root}>
      {offers.map((offer) => (
        <MiniOfferCard
          key={`mini-offer-card-${offer.id}`}
          offer={offer}
          onSelect={onSelect}
          layoutClassname={classes.card}
        />
      ))}
    </RhFlexBox>
  );
};
