import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core";

export const useOfferMiniChooserStyles = makeStyles<Theme, "root" | "card">(
  (theme) =>
    createStyles({
      root: {
        flexDirection: "column",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
          maxWidth: 540,
        },
      },
      card: {
        flex: 1,
        marginBottom: `${theme.spacing(1)}px !important`,
        [theme.breakpoints.up("sm")]: {
          width: "173px !important",
          "&:last-child": {
            marginRight: 0,
          },
        },
      },
    })
);
