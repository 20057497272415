import { Offer } from "@common/models/Offer.model";
import { RhAccordion } from "@design-system/components/RhAccordion/RhAccordion";
import { RhAccordionDetails } from "@design-system/components/RhAccordionDetails/RhAccordionDetails";
import { RhAccordionSummary } from "@design-system/components/RhAccordionSummary/RhAccordionSummary";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import {
  ActionType,
  TrackEventHandler,
} from "@portal/services/segment.service";
import React, { FC } from "react";

interface OfferLearnMoreFAQProps {
  offer: Offer;
  trackEvent?: TrackEventHandler;
}

export const OfferLearnMoreFAQ: FC<OfferLearnMoreFAQProps> = ({
  offer,
  trackEvent,
}) => {
  const { t } = useRhIntl();
  const faqs = t("OfferLearnMoreFAQ.FAQs");
  const cancelEarly = t("OfferLearnMoreFAQ.cancelEarly");
  const cancelEarlyAnswer1 = t("OfferLearnMoreFAQ.cancelEarlyAnswer1");
  const cancelEarlyAnswer2 = t("OfferLearnMoreFAQ.cancelEarlyAnswer2");
  const fees = t("OfferLearnMoreFAQ.fees");
  const feesAnswer = t("OfferLearnMoreFAQ.feesAnswer");
  const contractEnds = t("OfferLearnMoreFAQ.contractEnds");
  const contractEndsAnswer1 = t("OfferLearnMoreFAQ.contractEndsAnswer1");
  const contractEndsAnswer2 = t("OfferLearnMoreFAQ.contractEndsAnswer2");
  const accountProblem = t("OfferLearnMoreFAQ.accountProblem");
  const accountProblemAnswer = t("OfferLearnMoreFAQ.accountProblemAnswer");

  return (
    <>
      <Box mt={3}>
        <RhTypography variant="h2">{faqs}</RhTypography>
      </Box>
      <RhAccordion
        onChange={(_, expanded) => {
          if (trackEvent && expanded) {
            trackEvent({ action: ActionType.click, label: "faqAccordian1" });
          }
        }}
      >
        <RhAccordionSummary summary={cancelEarly} />
        <RhAccordionDetails>
          <Box>{cancelEarlyAnswer1}</Box>
          <Box mt={1}>{cancelEarlyAnswer2}</Box>
        </RhAccordionDetails>
      </RhAccordion>

      <RhAccordion
        onChange={(_, expanded) => {
          if (trackEvent && expanded) {
            trackEvent({ action: ActionType.click, label: "faqAccordian2" });
          }
        }}
      >
        <RhAccordionSummary summary={fees} />
        <RhAccordionDetails>{feesAnswer}</RhAccordionDetails>
      </RhAccordion>

      <RhAccordion
        onChange={(_, expanded) => {
          if (trackEvent && expanded) {
            trackEvent({ action: ActionType.click, label: "faqAccordian3" });
          }
        }}
      >
        <RhAccordionSummary summary={contractEnds} />
        <RhAccordionDetails>
          <Box>{contractEndsAnswer1}</Box>
          <Box mt={1}>{contractEndsAnswer2}</Box>
        </RhAccordionDetails>
      </RhAccordion>

      <RhAccordion
        onChange={(_, expanded) => {
          if (trackEvent && expanded) {
            trackEvent({ action: ActionType.click, label: "faqAccordian4" });
          }
        }}
      >
        <RhAccordionSummary summary={accountProblem} />
        <RhAccordionDetails>
          <Box>{accountProblemAnswer}</Box>
        </RhAccordionDetails>
      </RhAccordion>
    </>
  );
};
