import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, DialogContent } from "@material-ui/core";
import { RedeemRewardsDialogContentProps } from "@portal/components/RedeemRewards/RedeemRewards.component";
import { useRedeemRewardsStyle } from "@portal/components/RedeemRewards/RedeemRewards.style";
import { ReactComponent as CoinConfetti } from "@portal/components/RedeemRewards/rewards-success-coin-explode.svg";
import React, { FC } from "react";
import { useIntl } from "react-intl";

export const RewardRedemptionSuccess: FC<RedeemRewardsDialogContentProps> = ({
  value,
  originalAccountBalance,
  onCancel,
}) => {
  const classes = useRedeemRewardsStyle({ withIllustration: true });

  const { formatMessage } = useIntl();
  const illustrationAltText = formatMessage({
    id: "RedeemRewards.coinConfetti",
  });

  return (
    <>
      <Box className={classes.illustrationContainer}>
        <CoinConfetti title={illustrationAltText} />
      </Box>
      <RhDialogTitle className={classes.dialogTitle}>
        {formatMessage(
          { id: "RedeemRewards.yourCreditHasBeenApplied" },
          {
            value: formatCurrency(value),
          }
        )}
      </RhDialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <RhTypography
          variant="subtitle2"
          color="textSecondary"
          className={classes.dialogBody}
        >
          {formatMessage(
            {
              id: "RedeemRewards.previousBalance",
            },
            {
              prevBalance: formatCurrency(originalAccountBalance),
            }
          )}
          <br />
          {formatMessage(
            {
              id: "RedeemRewards.newBalance",
            },
            {
              newBalance: formatCurrency(originalAccountBalance - value),
            }
          )}
        </RhTypography>
        <RhFlexBox className={classes.buttonsContainer}>
          <Box width="100%">
            <RhButton fullWidth color="primary" onClick={onCancel}>
              {formatMessage({ id: "RedeemRewards.backToHome" })}
            </RhButton>
          </Box>
        </RhFlexBox>
      </DialogContent>
    </>
  );
};
