import { DynamicLink } from "@common/components/DynamicLink/DynamicLink";
import { ReactComponent as RhythmLogo } from "@common/images/rhythmLogoDarkPurple.svg";
import { RhHeader } from "@design-system/components/RhHeader/RhHeader";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import { AccountMenu } from "@portal/components/PortalHeader/AccountMenu/AccountMenu";
import { ReferAFriendDialog } from "@portal/components/ReferAFriendDialog/ReferAFriendDialog";
import { RHYTHM_MARKETING_URL } from "@portal/constants/urls.constant";
import { useAuthenticatedUserGroups } from "@portal/hooks/useAuthenticatedUserGroups";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import {
  customerHomePath,
  faqUrl,
  myAccountPath,
  notificationsPath,
  rewardsPath,
  signOutPath,
  signUpBasePath,
} from "@portal/routes/routePaths";
import { selectCustomer } from "@portal/selectors/customerSelector";
import { isOpsOrCSRs } from "@portal/utils/signUpHelpers";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { usePortalHeaderStyles } from "./PortalHeader.style";

export const PortalHeader = () => {
  const { pathname } = useLocation();
  const classes = usePortalHeaderStyles();
  const { t } = useRhIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isInSignUp = pathname.includes(signUpBasePath());
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();
  const { data: groups } = useAuthenticatedUserGroups();
  const [isReferAFriendDialogOpen, setIsReferAFriendDialogOpen] = useState(
    false
  );
  const { customer, status } = useSelector(selectCustomer);

  const onOpenReferAFriend = () => {
    setIsReferAFriendDialogOpen(true);
  };
  const onCloseReferAFriend = () => {
    setIsReferAFriendDialogOpen(false);
  };

  const serviceAddress = status.isPending
    ? null
    : customer?.activePremiseAddress.line1;

  // Links that may be rendered in the portal header
  const homeLink = (
    <NavLink
      className={classes.navLink}
      key={customerHomePath()}
      to={customerHomePath()}
    >
      {t("PortalHeader.home")}
    </NavLink>
  );
  const rewardsLink = (
    <NavLink className={classes.navLink} key={rewardsPath()} to={rewardsPath()}>
      {t("PortalHeader.rewards")}
    </NavLink>
  );
  const helpLink = (
    <a
      key={faqUrl()}
      href={faqUrl()}
      target="_blank"
      rel="noreferrer noopener"
      className={classes.navLink}
    >
      {t("PortalHeader.help")}
    </a>
  );
  const accountLink = (
    <NavLink
      className={classes.navLink}
      key={myAccountPath()}
      to={myAccountPath()}
    >
      {t("PortalHeader.account")}
    </NavLink>
  );
  const notificationsLink = (
    <NavLink
      className={classes.navLink}
      key={notificationsPath()}
      to={notificationsPath()}
    >
      {t("PortalHeader.notifications")}
    </NavLink>
  );
  const referAFriendLink = (
    <RhOutlineButton
      key="referAFriend"
      color="primary"
      onClick={onOpenReferAFriend}
      size="small"
      style={{ backgroundColor: "unset" }}
    >
      {t("PortalHeader.referAFriend")}
    </RhOutlineButton>
  );
  const logoutLink = (
    <NavLink className={classes.navLink} key={signOutPath()} to={signOutPath()}>
      {t("PortalHeader.logout")}
    </NavLink>
  );
  const signUpLink = (
    <NavLink key={signUpBasePath()} to={signUpBasePath()}>
      {t("PortalHeader.signUp")}
    </NavLink>
  );

  // logic determining the links to show under different scenarios
  let links: JSX.Element[] = [];

  if (isAuthenticated) {
    if (isOpsOrCSRs(groups)) {
      const banner = (
        <div key="csrOrOpsBanner" className={classes.csrOrOpsBanner}>
          <RhTypography
            fontWeight={FontWeight.Bold}
            variant="h1"
            color="secondary"
          >
            {t("PortalHeader.banner")}
          </RhTypography>
        </div>
      );
      const baseLinks = [banner, homeLink];
      const accountMenu = (
        <AccountMenu
          key="PortalHeader__AccountMenu"
          linkClass={classes.navLink}
          serviceAddress={serviceAddress}
        >
          {logoutLink}
        </AccountMenu>
      );

      links = isMobile
        ? [...baseLinks, logoutLink]
        : [...baseLinks, accountMenu];
    } else {
      const baseLinks = [homeLink, rewardsLink, helpLink];
      const accountMenu = (
        <AccountMenu
          key="PortalHeader__AccountMenu"
          linkClass={classes.navLink}
          serviceAddress={serviceAddress}
        >
          {accountLink}
          {notificationsLink}
          {logoutLink}
        </AccountMenu>
      );

      links = isMobile
        ? [
            ...baseLinks,
            accountLink,
            notificationsLink,
            referAFriendLink,
            logoutLink,
          ]
        : [...baseLinks, referAFriendLink, accountMenu];
    }
  } else if (!isInSignUp) {
    links = [signUpLink];
  }

  const logo = (
    <DynamicLink
      to={isAuthenticated ? customerHomePath() : RHYTHM_MARKETING_URL}
      external={!isAuthenticated}
      target="_self"
      aria-label={t("PortalHeader.home")}
    >
      <RhythmLogo />
    </DynamicLink>
  );

  return (
    <>
      <RhHeader items={links} logo={logo} divideLastItem={isAuthenticated} />
      {isAuthenticated && isReferAFriendDialogOpen && (
        <ReferAFriendDialog onClose={onCloseReferAFriend} />
      )}
    </>
  );
};
