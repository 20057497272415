import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import React, { FC } from "react";

export const MyAccountNavItem: FC<{
  className: string;
}> = ({ className, children }) => (
  <li className={className}>
    <RhTypography component="div" variant="body1">
      {children}
    </RhTypography>
  </li>
);
