import { api } from "@common/api/api";
import { LoyaltyProgramRedemptionResponseType } from "@common/types/apiResponseTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useRedeemRewardsStyle } from "@portal/components/RedeemRewards/RedeemRewards.style";
import { RewardRedemptionCTA } from "@portal/components/RedeemRewards/RewardRedemptionCTA";
import { RewardRedemptionSuccess } from "@portal/components/RedeemRewards/RewardRedemptionSuccess";
import { useCustomer } from "@portal/hooks/useCustomer";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectAccountSummary } from "@portal/selectors/accountSummarySelectors";
import { selectRewards } from "@portal/selectors/rewardsSelectors";
import { accountSummaryFetch } from "@portal/slices/accountSummarySlice";
import { rewardsRedeemed } from "@portal/slices/rewardsSlice";
import React, { FC, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

export interface RedeemRewardsDialogContentProps {
  currentAccountBalance: number;
  rewardPointBalance: number;
  value: number;
  originalAccountBalance: number;
  onApplyCredit: () => void;
  onCancel: () => void;
}

const RewardLoadingComponent: FC<RedeemRewardsDialogContentProps> = () => {
  const classes = useRedeemRewardsStyle({});

  return <RhCircularProgress className={classes.loadingDialogBody} />;
};

type RewardsDialogContentComponent =
  | "RewardLoadingComponent"
  | "RewardRedemptionCTA"
  | "RewardRedemptionSuccess";

const rewardsLifecycleComponents: Record<
  RewardsDialogContentComponent,
  FC<RedeemRewardsDialogContentProps>
> = {
  RewardLoadingComponent,
  RewardRedemptionCTA,
  RewardRedemptionSuccess,
};

export const RedeemRewards: FC = () => {
  const { t } = useRhIntl();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [activeComponent, setActiveComponent] = useState<
    "RewardLoadingComponent" | "RewardRedemptionCTA" | "RewardRedemptionSuccess"
  >("RewardRedemptionCTA");
  const dispatch = useDispatch();
  const { customer, requestMonitor: customerRequestMonitor } = useCustomer();
  const { accountSummary, status: accountSummaryStatus } = useSelector(
    selectAccountSummary
  );
  const { rewards } = useSelector(selectRewards);
  const flash = useRhFlash();

  useEffect(() => {
    dispatch(accountSummaryFetch());
  }, [customer?.id, dispatch]);

  const currentPointsBalance = rewards?.balance ?? 0;
  const currentPointsValue = rewards?.value ?? 0;
  const currentAccountBalance = accountSummary?.totalBalance ?? 0;

  const [originalValues, setOriginalValues] = useState({
    accountBalance: currentAccountBalance,
    pointsBalance: currentPointsBalance,
    pointsValue: currentPointsValue,
  });

  if (
    !rewards ||
    !customer ||
    accountSummaryStatus.isWaiting ||
    customerRequestMonitor.isWaiting
  ) {
    return <RhCircularProgress />;
  }

  // NOTE: in the future, customers will be able to select the points to redeem
  // for now they can only redeem their full quantity of points
  const selectedPointsToRedeem = originalValues.pointsBalance;
  const selectedPointValueToRedeem = originalValues.pointsValue;

  const onSuccessfulRedemption = ({
    balance: newRewardsBalance,
  }: LoyaltyProgramRedemptionResponseType) => {
    batch(() => {
      dispatch(
        rewardsRedeemed({
          newRewardsBalance,
          amountUsed: selectedPointValueToRedeem,
        })
      );
      setActiveComponent("RewardRedemptionSuccess");
    });
  };

  const applyCredit = () => {
    setActiveComponent("RewardLoadingComponent");
    api.customers
      .loyaltyProgramRedeem(customer.id, selectedPointsToRedeem)
      .then(onSuccessfulRedemption)
      .catch((error: RhApiError) => {
        setActiveComponent("RewardRedemptionCTA");
        flash.error(t("RedeemRewards.issueRedeemingRewards"));
      });
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog = () => {
    batch(() => {
      setActiveComponent("RewardRedemptionCTA");
      setIsDialogOpen(true);
      setOriginalValues({
        accountBalance: currentAccountBalance,
        pointsBalance: rewards.balance ?? 0,
        pointsValue: rewards.value ?? 0,
      });
    });
  };

  const RewardsDialogContent = rewardsLifecycleComponents[activeComponent];

  const redeemRewardsText = t("RedeemRewards.buttonCTA");

  return (
    <>
      <RhButton
        color="primary"
        disabled={currentPointsBalance === 0 || !accountSummary}
        onClick={openDialog}
        type="button"
      >
        {redeemRewardsText}
      </RhButton>
      <RhDialog
        open={isDialogOpen}
        onClose={() =>
          !(activeComponent === "RewardLoadingComponent") && closeDialog()
        }
      >
        <RewardsDialogContent
          currentAccountBalance={currentAccountBalance}
          originalAccountBalance={originalValues.accountBalance}
          rewardPointBalance={originalValues.pointsBalance}
          value={originalValues.pointsValue}
          onCancel={closeDialog}
          onApplyCredit={applyCredit}
        />
      </RhDialog>
    </>
  );
};
