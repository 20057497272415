import { CampaignPromoType } from "@common/types/campaignTypes";
import {
  CreditScoreContactDataType,
  CreditScoreDataType,
} from "@common/types/creditCheckTypes";
import { SignUpAvailabilityFormValues } from "@portal/pages/SignUpAvailabilityPage/SignUpAvailabilityPage";
import { SignUpBillingPreferencesFormValues } from "@portal/pages/SignUpBillingPreferencesPage/SignUpBillingPreferencesPage";
import { SignUpContactFormValues } from "@portal/pages/SignUpContactPage/SignUpContactPage";
import { SignUpNameFormValues } from "@portal/pages/SignUpNamePage/SignUpNamePage";
import { SignUpPremiseFormValues } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import { SignUpStepType } from "@portal/routes/routePaths";
import { SignUpStateType } from "@portal/slices/signUpSlice";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { createSelector } from "@reduxjs/toolkit";

export const selectSignUpState = (state: PortalStoreState): SignUpStateType =>
  state.signUp;

export const selectVisitedSignUpPages = createSelector<
  PortalStoreState,
  SignUpStateType,
  { visitedSteps: SignUpStepType[] }
>(selectSignUpState, ({ visitedSteps }) => ({ visitedSteps }));

export const selectChosenOfferId = createSelector(
  selectSignUpState,
  ({ offersnapshotId }) => offersnapshotId
);

export const selectProspectId = createSelector(
  selectSignUpState,
  ({ prospectId }) => prospectId
);

export const selectSignUpAvailabilityFormValues = createSelector<
  PortalStoreState,
  SignUpStateType,
  SignUpAvailabilityFormValues
>(selectSignUpState, ({ serviceAddress }) => ({
  zipCode: serviceAddress?.zipCode,
}));

export const selectSignUpPremiseFormValues = createSelector<
  PortalStoreState,
  SignUpStateType,
  SignUpPremiseFormValues
>(
  selectSignUpState,
  ({
    dunsNumber,
    esiId,
    serviceAddress,
    depositAmount,
    creditEvaluation,
    ssnRequired,
  }) => ({
    dunsNumber,
    esiId,
    creditEvaluation,
    serviceAddress: {
      ...serviceAddress,
      // TODO: remove this when we move outside of TX
      state: serviceAddress.state || "TX", // for MLP, assume the address is in TX
    },
    depositAmount,
    ssnRequired,
  })
);

export const selectBillingPreferencesFormValues = createSelector<
  PortalStoreState,
  SignUpStateType,
  SignUpBillingPreferencesFormValues
>(selectSignUpState, ({ invoiceByPrint, autopay }) => ({
  invoiceByPrint,
  autopay,
}));

export const selectSignUpNameFormValues = createSelector<
  PortalStoreState,
  SignUpStateType,
  SignUpNameFormValues
>(selectSignUpState, ({ dateOfBirth, firstName, lastName }) => ({
  dateOfBirth,
  firstName,
  lastName,
}));

export const selectSignUpContactFormValues = createSelector<
  PortalStoreState,
  SignUpStateType,
  SignUpContactFormValues & { firstName: string }
>(
  selectSignUpState,
  ({ confirmEmail, firstName, email, phone, sendMarketingPromos }) => ({
    firstName,
    confirmEmail,
    email,
    sendMarketingPromos,
    phone,
  })
);

export const selectCreditCheckContactValues = createSelector<
  PortalStoreState,
  SignUpNameFormValues,
  SignUpContactFormValues,
  CreditScoreContactDataType
>(
  selectSignUpNameFormValues,
  selectSignUpContactFormValues,
  ({ firstName, lastName, dateOfBirth }, { email, phone }) => ({
    firstName,
    lastName,
    dateOfBirth,
    email,
    phone,
  })
);

export const selectDepositAmount = createSelector<
  PortalStoreState,
  SignUpPremiseFormValues,
  number | null
>(selectSignUpPremiseFormValues, ({ depositAmount }) => depositAmount);

export const selectCreditCheckValues = createSelector<
  PortalStoreState,
  SignUpNameFormValues,
  SignUpContactFormValues,
  SignUpPremiseFormValues,
  CreditScoreDataType
>(
  selectSignUpNameFormValues,
  selectSignUpContactFormValues,
  selectSignUpPremiseFormValues,
  (
    { firstName, lastName, dateOfBirth },
    { email, phone },
    { serviceAddress }
  ) => ({
    firstName,
    lastName,
    dateOfBirth,
    email,
    phone,
    ...serviceAddress,
  })
);

export const selectCampaignPromo = createSelector<
  PortalStoreState,
  SignUpStateType,
  CampaignPromoType | null
>(selectSignUpState, ({ campaignPromo }) => campaignPromo);
