import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhButtonGroup } from "@design-system/components/RhButtonGroup/RhButtonGroup";
import { useRhButtonToggleStyles } from "@design-system/components/RhButtonToggle/RhButtonToggle.style";
import classnames from "classnames";
import React, { FC, useState } from "react";

interface Labels {
  left: string;
  right: string;
}
type Sides = keyof Labels;

interface RhButtonToggleProps {
  labels: Labels;
  fullWidth?: boolean;
  defaultValue?: string;
  onChange?: (value: string) => void;
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary";
}

export const RhButtonToggle: FC<RhButtonToggleProps> = ({
  labels,
  fullWidth,
  defaultValue,
  onChange = () => {},
  size = "small",
  color = "primary",
}) => {
  const classes = useRhButtonToggleStyles({ color, fullWidth });

  const [current, setCurrent] = useState<string | undefined>(defaultValue);
  const choose = (val: string): void => {
    setCurrent(val);
    onChange(val);
  };
  const isCurrent = (label: string) => current === label;
  const renderButton = (label: string, side: Sides): JSX.Element => {
    return (
      <RhButton
        key={side}
        className={classnames([
          classes.item,
          { [classes.active]: isCurrent(label) },
        ])}
        onClick={() => choose(labels[side])}
      >
        {label}
      </RhButton>
    );
  };

  return (
    <RhButtonGroup color={color} size={size} className={classes.root}>
      {(["left", "right"] as Sides[]).map((side) =>
        renderButton(labels[side], side)
      )}
    </RhButtonGroup>
  );
};
