import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  isRequired,
  isValidConfirmationEmail,
  isValidEmail,
  isValidPhoneNumber,
} from "@common/forms/validators";
import { RhApiError } from "@common/types/errorTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { LOGGED_OUT_FIELD_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpContactFields } from "@portal/components/SignUpContactFields/SignUpContactFields";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectSignUpContactFormValues } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

export type SignUpContactFormValues = {
  confirmEmail: string;
  email: string;
  phone: string;
  sendMarketingPromos: boolean;
};
const initialValues = Object.freeze<SignUpContactFormValues>({
  confirmEmail: "",
  email: "",
  phone: "",
  sendMarketingPromos: true,
});

export const signUpContactFormValidator = generateValidationErrorCollector<SignUpContactFormValues>(
  {
    confirmEmail: [
      isRequired,
      isValidEmail,
      (value, { email }) => isValidConfirmationEmail(value, email),
    ],
    email: [isRequired, isValidEmail],
    phone: [isRequired, isValidPhoneNumber],
  }
);

export const SignUpContactPage: FC = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { t } = useRhIntl();
  const { firstName, ...currentValues } = useSelector(
    selectSignUpContactFormValues
  );
  const flash = useRhFlash();

  const onSubmit = (values: SignUpContactFormValues) => {
    return api.customers
      .available(values.email)
      .then(() => {
        signUpClickNextStepHandler({
          signUpData: values,
          nextStep: "address",
          track: true,
        });
      })
      .catch((error: RhApiError) => {
        if (error.data.errorCode === "rhythm.customer.email.conflict") {
          return { email: [t("signUpContactPage.customerExists")] };
        } else {
          flash.error(t("signUpContactPage.errorSendingContact"));
        }
      });
  };

  const submitCTA = t("signUpContactFields.submitCTA");

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerTextId="signUpContactPage.letsKeepInTouch"
        headerTextValues={{ firstName }}
      />
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING}>
        <Form<SignUpContactFormValues>
          initialValues={{ ...initialValues, ...currentValues }}
          onSubmit={onSubmit}
          validate={signUpContactFormValidator}
          render={({ handleSubmit }) => (
            <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
              <SignUpContactFields
                onFocus={(event) => {
                  trackEvent({
                    action: ActionType.focus,
                    label: event.target.id,
                  });
                }}
                handleMarketingPromoChange={(event) => {
                  const {
                    target: { checked },
                  } = (event as unknown) as React.ChangeEvent<HTMLInputElement>;

                  trackEvent({
                    action: ActionType.click,
                    label: "disableEmailUpdates",
                    value: checked,
                  });
                }}
              />
            </LoggedOutForm>
          )}
        />
      </Box>
      <Box width={LOGGED_OUT_FORM_WIDTH}>
        <RhTypography variant="body2" color="textSecondary">
          {t("signUpContactPage.creditCheckExplainer")}
        </RhTypography>
      </Box>
    </SignUpPageLayout>
  );
};
