import { AddressFormType } from "@common/types/customerTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import {
  MAPBOX_GEOCODING_API,
  MAPBOX_STATIC_API,
  MAPBOX_TOKEN,
} from "@portal/settings/config";
import axios from "axios";

const MINIMUM_ACCURACY = 0.85;

export const mapAxiosInstance = axios.create();

function getGeoCodingUrl(encodedAddress: string) {
  return `${MAPBOX_GEOCODING_API}/${encodedAddress}.json?access_token=${MAPBOX_TOKEN}`;
}

interface MapBoxGeoCodingApi {
  features: {
    center: [number, number];
    relevance: number;
  }[];
}
interface Geocode {
  latitude: number;
  longitude: number;
}

export type MapAddress = Omit<AddressFormType, "unitNumber">;

export async function getMapUrl(
  { addressLine1, city, state, zipCode }: MapAddress,
  width: number,
  height: number
) {
  const geoLocalization = await getGeoCode({
    addressLine1,
    city,
    state,
    zipCode,
  });

  if (geoLocalization) {
    return getStaticMapUrl(geoLocalization, width, height);
  }
  return null;
}

function getStaticMapUrl(
  { latitude, longitude }: Geocode,
  width: number,
  height: number
) {
  const zoom = 16;
  const imageSize = `${width}x${height}@2x`;
  const pinColor = rhythmTheme.palette.primary.main.replace("#", "");

  return `${MAPBOX_STATIC_API}/pin-s+${pinColor}(${longitude},${latitude})/${longitude},${latitude},${zoom}/${imageSize}?logo=false&access_token=${MAPBOX_TOKEN}`;
}

async function getGeoCode({
  addressLine1,
  city,
  state,
  zipCode,
}: MapAddress): Promise<Geocode | null> {
  const encodedAddress = encodeURIComponent(
    `${addressLine1}, ${city}, ${state} ${zipCode}, USA`
  );

  const [error, response] = await handleAjaxCall(
    mapAxiosInstance.get<MapBoxGeoCodingApi>(getGeoCodingUrl(encodedAddress))
  );

  if (error) {
    return null;
  }

  const {
    features: [
      {
        center: [longitude, latitude] = [null, null],
        relevance = 0,
        //
      } = {},
    ] = [],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = response!.data;

  if (latitude === null || longitude === null || relevance < MINIMUM_ACCURACY) {
    return null;
  }

  return {
    latitude,
    longitude,
  };
}
