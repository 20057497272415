import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { SecureRoute } from "@okta/okta-react";
import { useAuthenticatedUserGroups } from "@portal/hooks/useAuthenticatedUserGroups";
import { useCustomer } from "@portal/hooks/useCustomer";
import { signOutPath, signUpBasePath } from "@portal/routes/routePaths";
import { Show500Page } from "@portal/utils/errors";
import { isCustomer, isOpsOrCSRs } from "@portal/utils/signUpHelpers";
import React, { FC } from "react";
import { Redirect, RouteProps } from "react-router-dom";

interface RhSecurePortalRouteProps {
  Component: FC;
  exact: boolean;
  path: string;
}

export const RhSecurePortalRoute: FC<RhSecurePortalRouteProps> = ({
  Component,
  exact,
  path,
}) => {
  const { data: groups } = useAuthenticatedUserGroups();
  const { error } = useCustomer();

  if (groups === null) {
    return null;
  }

  if (isOpsOrCSRs(groups)) {
    return <Redirect to={`/csr${signUpBasePath()}`} />;
  }

  return (
    <SecureRoute
      exact={exact}
      path={path}
      render={(props: RouteProps) => {
        if (!isCustomer(groups)) {
          return null;
        }

        if (error) {
          if (error.status === HttpStatusCodes.HTTP_403_FORBIDDEN) {
            return <Redirect to={signOutPath()} />;
          } else {
            throw new Show500Page(
              `Could not get customer: ${error.data.errorCode}`
            );
          }
        }
        return <Component {...props} />;
      }}
    />
  );
};
