import { userLoggedOut } from "@portal/slices/authenticationSlice";
import { customerReceived } from "@portal/slices/customerSlice";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";

// Types
export const CUSTOMER_UPDATED_LANGUAGE_PREFERENCE = "customer_preferences.updated_language_preference" as const;

export type CustomerPreferencesStateType = {
  languagePreference: SupportedPortalLanguage | null;
};

export const initialCustomerPreferencesState = Object.freeze<CustomerPreferencesStateType>(
  {
    languagePreference: null,
  }
);

// Actions
export const customerUpdatedLanguagePreference = createAction<
  CustomerPreferencesStateType,
  typeof CUSTOMER_UPDATED_LANGUAGE_PREFERENCE
>(CUSTOMER_UPDATED_LANGUAGE_PREFERENCE);

// Handlers
export const customerUpdatedLanguagePreferenceHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction<CustomerPreferencesStateType>
> = (state, action) => {
  return { ...state, languagePreference: action.payload.languagePreference };
};

export const clearCustomerLanguagePreferenceHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction
> = (state, _action) => {
  return { ...state, ...initialCustomerPreferencesState };
};

// Reducer
export const customerPreferencesReducer = createReducer<CustomerPreferencesStateType>(
  initialCustomerPreferencesState,
  {
    [customerUpdatedLanguagePreference.type]: customerUpdatedLanguagePreferenceHandler,
    [customerReceived.type]: customerUpdatedLanguagePreferenceHandler,
    [userLoggedOut.type]: clearCustomerLanguagePreferenceHandler,
  }
);
