import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { useRhIconRadioFieldStyles } from "@design-system/components/RhIconRadioField/RhIconRadioField.style";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Card, FormControl, FormLabel } from "@material-ui/core";
import React, { ReactElement } from "react";
import { useField } from "react-final-form";

interface RhIconRadioFieldProps<T> {
  icon: ReactElement;
  label: string;
  value: T;
  onClick?: React.MouseEventHandler<HTMLDivElement | MouseEvent>;
  name: string;
}

export const RhIconRadioField = <T extends string>({
  icon,
  label,
  value,
  onClick,
  name,
}: RhIconRadioFieldProps<T>) => {
  const { input, meta } = useField<T>(name, {
    type: "radio",
    value,
  });
  const hasSyncError = Boolean(meta.error);
  const hasAsyncError = Boolean(meta.submitError);
  const hasValidationError =
    hasSyncError || (!meta.dirtySinceLastSubmit && hasAsyncError);

  const hasErrors = Boolean(meta.touched && hasValidationError);

  const classes = useRhIconRadioFieldStyles({
    checked: Boolean(input.checked),
    hasErrors,
  });

  return (
    <FormControl>
      <FormLabel htmlFor={value}>
        <Card
          onMouseUp={onClick}
          classes={{ root: classes.cardRoot }}
          elevation={input.checked ? 2 : 0}
        >
          <RhFlexBox className={classes.iconContainer}>{icon}</RhFlexBox>
          <RhFlexBox className={classes.labelContainer}>
            <RhTypography
              variant="body1"
              fontWeight="Bold"
              className={classes.cardLabel}
              align="center"
            >
              {label}
            </RhTypography>
          </RhFlexBox>
          <RhFlexBox className={classes.radioContainer}>
            <RhRadioInput
              {...input}
              inputProps={{ "aria-checked": input.checked }}
            />
          </RhFlexBox>
        </Card>
      </FormLabel>
    </FormControl>
  );
};
