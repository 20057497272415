import { Version } from "@common/components/Version/Version";
import { RhAnnouncement } from "@design-system/components/RhAnnouncement/RhAnnouncement";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import {
  PAGE_GUTTER,
  RH_HEADER_HEIGHT,
} from "@design-system/theme/style.constant";
import { useAppStyles } from "@portal/App.style";
import { Loader } from "@portal/components/Loader/Loader";
import { PortalHeader } from "@portal/components/PortalHeader/PortalHeader";
import { useDynamicIntl } from "@portal/hooks/useDynamicIntl";
import { usePageAnalytics } from "@portal/hooks/usePageAnalytics";
import { PortalRoutes } from "@portal/routes/PortalRoutes";
import { FAVICON_PATH } from "@portal/settings/config";
import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { Helmet } from "react-helmet";
import { RawIntlProvider } from "react-intl";

import { PortalFooter } from "./components/PortalFooter/PortalFooter";
import { Error500Page } from "./pages/Error500Page/Error500Page";

export const App = () => {
  const intl = useDynamicIntl();
  const classes = useAppStyles();
  const { content, severity } = useRhAnnouncement();

  usePageAnalytics();

  const siteTitle = intl.formatMessage({
    id: "navigation.title.rhythm",
  });

  return (
    <>
      <RawIntlProvider value={intl}>
        <Loader />
        {content && (
          <RhAnnouncement className={classes.announcement} severity={severity}>
            {content}
          </RhAnnouncement>
        )}
        <Helmet>
          <title>{siteTitle}</title>
          <link rel="icon" type="image/x-icon" href={FAVICON_PATH} />
        </Helmet>
        <RhFlexBox
          alignItems="center"
          component="main"
          flexDirection="column"
          flexWrap="nowrap"
          minHeight="100%"
          maxWidth={`calc(100% - ${PAGE_GUTTER * 2}px)`}
          mx="auto"
          paddingTop={RH_HEADER_HEIGHT}
          position="relative"
        >
          <PortalHeader />
          <RhFlexBox
            alignItems="center"
            flexDirection="column"
            flexGrow={1}
            width="100%"
          >
            <ErrorBoundary fallback={() => <Error500Page />}>
              <PortalRoutes />
            </ErrorBoundary>
          </RhFlexBox>
          <PortalFooter />
        </RhFlexBox>
      </RawIntlProvider>
      <Version />
    </>
  );
};
