import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { RH_HEADER_HEIGHT } from "@design-system/theme/style.constant";
import { createStyles } from "@material-ui/core/styles";

export const useMyAccountNavStyles = makeStyles<
  typeof rhythmTheme,
  "linkContainer" | "linkRow" | "navContainer"
>((theme) =>
  createStyles({
    linkContainer: {
      paddingBottom: theme.spacing(2),
    },
    linkRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    navContainer: {
      listStyleType: "none",
      margin: 0,
      padding: 0,
      position: "sticky",
      top: theme.spacing(RH_HEADER_HEIGHT + 2.5),
    },
  })
);
