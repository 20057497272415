import { EnvTypes } from "@common/enums/envTypes.enum";

export interface EnvSettings {
  appBaseUrl: string;
  axiosBaseUrl: string;
  axiosTimeoutMs: number;
  cdnUrl: string;
  faviconPath: string;
  fullStoryOrgId?: string;
  googleTagManagerId: string;
  googleTagManagerAuth?: string;
  googleTagManagerPreview?: string;
  launchDarklyClientId: string;
  mapboxToken: string;
  marketingUrl: string;
  oktaClientId: string;
  oktaIssuer: string;
  oktaRedirectUri: string;
  zuoraSandboxBaseUrl: string;
  zuoraPaymentPageId: string;
  sentryDSN: string;
  sentryOrgSlug?: string;
  sentryRelease: string;
  sentryTracesSampleRate: number;
  smartyStreetsWebkey: string;
}

export const allEnvApplicationSettings: Record<EnvTypes, EnvSettings> = {
  local: {
    appBaseUrl: "http://localhost:3000",
    axiosBaseUrl: "http://localhost:8080",
    axiosTimeoutMs: 60000,
    cdnUrl: "https://cdn.staging.gotrhythm.com",
    faviconPath: "/favicon-red.ico",
    googleTagManagerId: "GTM-PZNH4K6",
    googleTagManagerAuth: "bBLmalvlGCETUZC6HL6hXg",
    googleTagManagerPreview: "env-6",
    launchDarklyClientId: "600b2fc3a99bda0a5faa967f",
    mapboxToken:
      "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
    marketingUrl: "http://localhost:1337",
    oktaClientId: "0oamxpidyk9CtuGXw1d5",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "http://localhost:3000/implicit/callback",
    zuoraPaymentPageId: "2c92c0f87304c86701730ae0018914d3",
    zuoraSandboxBaseUrl: "https://apisandbox.zuora.com",
    sentryDSN: "",
    sentryRelease: "",
    sentryTracesSampleRate: 0,
    smartyStreetsWebkey: "759553677128412",
  },
  production: {
    appBaseUrl: "https://app.gotrhythm.com",
    axiosBaseUrl: "https://api.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.gotrhythm.com",
    faviconPath: "/favicon.ico",
    fullStoryOrgId: "ZPQE0",
    googleTagManagerId: "GTM-PZNH4K6",
    launchDarklyClientId: "600b2e71df705609ed1d1e0a",
    mapboxToken:
      "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
    marketingUrl: "https://www.gotrhythm.com",
    oktaClientId: "0oa17m8ta3OQSmDmr4x7",
    oktaIssuer: "https://login.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://app.gotrhythm.com/implicit/callback",
    zuoraPaymentPageId: "2c92a0fd756acf9001756b6968ad7813",
    zuoraSandboxBaseUrl: "https://zuora.com",
    sentryDSN:
      "https://084dd7b410eb4f9c932cbde4a088e85e@o415325.ingest.sentry.io/5306261",
    sentryOrgSlug: "rhythm",
    sentryRelease: process.env.NX_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.2,
    smartyStreetsWebkey: "759554553482003",
  },
  staging: {
    appBaseUrl: "https://app.staging.gotrhythm.com",
    axiosBaseUrl: "https://api.staging.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.staging.gotrhythm.com",
    faviconPath: "/favicon-yellow.ico",
    googleTagManagerId: "GTM-PZNH4K6",
    googleTagManagerAuth: "8bluL4kfK5e3qFKjZWIP-Q",
    googleTagManagerPreview: "env-1",
    launchDarklyClientId: "600b2fd042af4409f41221d8",
    mapboxToken:
      "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
    marketingUrl: "https://staging.gotrhythm.com",
    oktaClientId: "0oaj5ilgwWg4oAcF51d5",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://app.staging.gotrhythm.com/implicit/callback",
    zuoraPaymentPageId: "2c92c0f97304da0401730ae3c6dc523a",
    zuoraSandboxBaseUrl: "https://apisandbox.zuora.com",
    sentryDSN:
      "https://084dd7b410eb4f9c932cbde4a088e85e@o415325.ingest.sentry.io/5306261",
    sentryRelease: process.env.NX_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.5,
    smartyStreetsWebkey: "759554319946871",
  },
  test: {
    appBaseUrl: "https://app.test.gotrhythm.com",
    axiosBaseUrl: "https://api.test.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.test.gotrhythm.com",
    faviconPath: "/favicon-yellow.ico",
    googleTagManagerId: "GTM-PZNH4K6",
    googleTagManagerAuth: "bBLmalvlGCETUZC6HL6hXg",
    googleTagManagerPreview: "env-6",
    launchDarklyClientId: "600b2e71df705609ed1d1e09",
    mapboxToken:
      "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
    marketingUrl: "https://test.gotrhythm.com",
    oktaClientId: "0oa1hrp9jqxnK7rUu1d6",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://app.test.gotrhythm.com/implicit/callback",
    zuoraPaymentPageId: "2c92c0f8757974d501757ac97d46603d",
    zuoraSandboxBaseUrl: "https://apisandbox.zuora.com",
    sentryDSN:
      "https://084dd7b410eb4f9c932cbde4a088e85e@o415325.ingest.sentry.io/5306261",
    sentryRelease: process.env.NX_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.5,
    smartyStreetsWebkey: "759551764177924",
  },
};
