import { Customer } from "@common/models/Customer.model";
import { Invoice } from "@common/models/Invoice.model";
import { InvoiceType } from "@common/types/accountSummaryTypes";
import { CustomerType } from "@common/types/customerTypes";

export const buildInvoiceFilename = (
  customer: Customer | CustomerType,
  invoice: Invoice | InvoiceType
) => {
  const accountNumber = customer.billingAccountNumber;
  const { invoiceDate } = invoice;

  return `${accountNumber}-invoice-${invoiceDate}.pdf`;
};
