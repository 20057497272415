import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const usePortalHeaderStyles = makeStyles<
  typeof rhythmTheme,
  "iconLink" | "navLink" | "divider" | "csrOrOpsBanner"
>((theme) =>
  createStyles({
    csrOrOpsBanner: {
      margin: "auto",
      textAlign: "center",
    },
    divider: {
      height: "100%",
    },
    iconLink: {
      [theme.breakpoints.up("md")]: {
        "& svg": {
          marginRight: theme.spacing(1),
        },
        alignItems: "center",
        display: "flex",
      },
      "& svg": {
        [theme.breakpoints.up("md")]: {
          display: "initial",
        },
        display: "none",
      },
    },
    navLink: {
      "&:hover": {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: "none !important",
      },
      color: theme.palette.common.black,
    },
  })
);
