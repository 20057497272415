import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useRhRadioCardInputStyles = makeStyles<
  typeof rhythmTheme,
  "card" | "option"
>((theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(1),
    },
    option: {
      marginRight: theme.spacing(1.5),
      padding: 0,
    },
  })
);
