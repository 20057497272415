import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { FormControl, FormControlLabel, RadioGroup } from "@material-ui/core";
import { usePaymentAmountStyles } from "@portal/components/PaymentAmount/PaymentAmount.style";
import { AmountType } from "@portal/enums/AmountType.enum";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { CustomPaymentField } from "@portal/pages/PayBillPage/CustomPaymentField/CustomPaymentField";
import { PayBillFormValues } from "@portal/pages/PayBillPage/PayBillPage";
import { RemainingBillBalance } from "@portal/pages/PayBillPage/RemainingBillBalance/RemainingBillBalance";
import React, { FC } from "react";
import { useField, useFormState } from "react-final-form";

export const PaymentAmount: FC = () => {
  const { t } = useRhIntl();
  const classes = usePaymentAmountStyles();

  const { values } = useFormState<PayBillFormValues>({
    subscription: { values: true },
  });
  const { input: totalInput } = useField<AmountType>("amountType", {
    type: "radio",
    value: AmountType.Total,
  });
  const { input: customInput } = useField<AmountType>("amountType", {
    type: "radio",
    value: AmountType.Custom,
  });

  return (
    <div className={classes.payOptions}>
      <FormControl component="fieldset" className={classes.fieldset}>
        <RadioGroup aria-label={t("account.billing.SelectAmount")}>
          <FormControlLabel
            disabled={values.totalAmount <= 0}
            control={<RhRadioInput {...totalInput} />}
            label={t("account.billing.PayTotalAmount")}
          />
          <RhFlexBox
            justifyContent="space-between"
            marginBottom={values.amountType === AmountType.Custom ? 2 : 0}
          >
            <FormControlLabel
              id="custom-amount-label"
              value={AmountType.Custom}
              control={<RhRadioInput {...customInput} />}
              label={t("account.billing.PayCustomAmount")}
            />
            <CustomPaymentField labelled-by="custom-amount-label" />
          </RhFlexBox>
          <RemainingBillBalance />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
