import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { Box, Grid } from "@material-ui/core";
import { SignUpFieldsLayoutContainer } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { StateSelectField } from "@portal/components/StateSelectField/StateSelectField";
import React from "react";
import { useIntl } from "react-intl";

export const SignUpMultipleUtilitiesFields = () => {
  const { formatMessage } = useIntl();
  const address = formatMessage({ id: "SignUpPremiseFields.address" });
  const unitNumber = formatMessage({ id: "SignUpPremiseFields.unitNumber" });
  const city = formatMessage({ id: "SignUpPremiseFields.city" });
  const zipCode = formatMessage({ id: "SignUpPremiseFields.zipCode" });

  return (
    <SignUpFieldsLayoutContainer>
      <Grid item>
        <RhFlexBox>
          <Box flexBasis="75%" paddingRight="15px">
            <RhTextField
              name="serviceAddress.addressLine1"
              autoComplete="address-line1"
              autoFocus
            >
              {address}
            </RhTextField>
          </Box>
          <Box flex={1}>
            <RhTextField
              name="serviceAddress.unitNumber"
              autoComplete="address-line2"
            >
              {unitNumber}
            </RhTextField>
          </Box>
        </RhFlexBox>
      </Grid>
      <Grid item>
        <RhTextField name="serviceAddress.city" autoComplete="address-level2">
          {city}
        </RhTextField>
      </Grid>
      <Grid item>
        <RhFlexBox alignItems="flex-end">
          <Box flexBasis="75%" paddingRight="15px">
            <StateSelectField
              name="serviceAddress.state"
              translationId="SignUpPremiseFields.state"
            />
          </Box>
          <Box flex={1}>
            <RhTextField
              name="serviceAddress.zipCode"
              autoComplete="postal-code"
              inputMode="numeric"
            >
              {zipCode}
            </RhTextField>
          </Box>
        </RhFlexBox>
      </Grid>
    </SignUpFieldsLayoutContainer>
  );
};
