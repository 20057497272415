import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  isRequired,
  isValidEmail,
  isValidPhoneNumber,
} from "@common/forms/validators";
import { MyAccountContactFormValues } from "@portal/components/MyAccountContact/MyAccountContact";

export const myAccountContactFormValidator = generateValidationErrorCollector<
  MyAccountContactFormValues
>({
  email: [isRequired, isValidEmail],
  phone: [isRequired, isValidPhoneNumber],
});
