import { api } from "@common/api/api";
import { formatDateOfBirthFromDatabase } from "@common/forms/formatters";
import { maskPhone, unmaskPhone } from "@common/forms/phone.mask";
import { Customer } from "@common/models/Customer.model";
import { RhApiError } from "@common/types/errorTypes";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useMyAccountContactStyles } from "@portal/components/MyAccountContact/MyAccountContact.style";
import { myAccountContactFormValidator } from "@portal/components/MyAccountContact/MyAccountContactFormValidator";
import { MyAccountSubmitButton } from "@portal/components/MyAccountSubmitButton/MyAccountSubmitButton";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { customerUpdated } from "@portal/slices/customerSlice";
import { FormApi } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

type MyAccountContactProps = {
  customer: Customer;
};

export type MyAccountContactFormValues = {
  email: string;
  phone: string;
};

export const MyAccountContact: FC<MyAccountContactProps> = ({ customer }) => {
  const { t } = useRhIntl();
  const classes = useMyAccountContactStyles();

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const flash = useRhFlash();

  const contactFormInitialValues: MyAccountContactFormValues = {
    email: customer.email,
    phone: customer.phone,
  };

  const handleSubmit = (
    formattedValues: MyAccountContactFormValues,
    formApi: FormApi<MyAccountContactFormValues>
  ) => {
    const { email, phone } = formattedValues;

    const values: MyAccountContactFormValues = {
      email,
      phone: unmaskPhone(phone),
    };

    return api.customers
      .update(customer.id, values)
      .then((updatedCustomer) => {
        formApi.initialize(formattedValues);
        dispatch(customerUpdated(updatedCustomer));
        flash.success(t("MyAccountContact.preferencesUpdated"));
      })
      .catch((error: RhApiError) => {
        flash.error(t("MyAccountContact.errorSavingContactInfo"));
      });
  };

  const nameLabel = t("MyAccountContact.name");

  const dateOfBirthLabel = t("MyAccountContact.dateOfBirth");
  const dateOfBirth = formatDateOfBirthFromDatabase(customer.dateOfBirth);

  const email = t("MyAccountContact.email");
  const phone = t("MyAccountContact.phone");
  const title = t("MyAccountContact.contact");

  return (
    <RhCard title={title}>
      <Form<MyAccountContactFormValues>
        initialValues={contactFormInitialValues}
        onSubmit={handleSubmit}
        validate={myAccountContactFormValidator}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className={classes.fields}>
              <RhFlexBox flexDirection="column">
                <div className={classes.label}>{nameLabel}</div>
                <div className={classes.readOnlyValue}>{customer.fullName}</div>
              </RhFlexBox>
              <RhFlexBox flexDirection="column">
                <div className={classes.label}>{dateOfBirthLabel}</div>
                <div className={classes.readOnlyValue}>{dateOfBirth}</div>
              </RhFlexBox>
              <RhTextField
                name="email"
                type="email"
                autoComplete="email"
                disabled
              >
                {email}
              </RhTextField>
              <RhTextField
                autoComplete="phone"
                format={maskPhone}
                name="phone"
                type="tel"
                placeholder="___-___-____"
                inputMode="numeric"
              >
                {phone}
              </RhTextField>
            </div>
            <RhFlexBox justifyContent="flex-end">
              <MyAccountSubmitButton
                isMobile={isMobile}
                onError={flash.error}
              />
            </RhFlexBox>
          </form>
        )}
      />
    </RhCard>
  );
};
