import { Offer } from "@common/models/Offer.model";
import { formatCurrency } from "@common/utils/dataFormatters";
import { scrollToOffsetPosition } from "@common/utils/scrollToOffsetPosition";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { CalculatorIcon } from "@design-system/icons";
import { Box } from "@material-ui/core";
import { COST_ESTIMATOR_SCROLL_TO_POINT } from "@portal/components/CostEstimator/CostEstimator";
import { useOfferCardStyles } from "@portal/components/OfferCard/OfferCard.style";
import { OfferFeatures } from "@portal/components/OfferFeatures/OfferFeatures";
import { OfferRateAndEstimate } from "@portal/components/OfferRateAndEstimate/OfferRateAndEstimate";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { ActionType } from "@portal/services/segment.service";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import classnames from "classnames";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { NavHashLink } from "react-router-hash-link";

interface OfferCardProps {
  offer: Offer;
  selectButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
  moreButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
  isActiveSlide?: boolean;
}

export const OfferCard: FC<OfferCardProps> = ({
  offer,
  selectButtonProps,
  moreButtonProps,
  isActiveSlide,
}) => {
  const hasPromo = Boolean(offer?.promo);
  const classes = useOfferCardStyles({ hasPromo });
  const [mouseOver, setMouseOver] = useState(false);

  const { trackEvent } = useSignUpFlow();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );
  const { t } = useRhIntl();
  const calculateCtaMessage = t("OfferCard.calculateYourBill");

  const handleCalculateBillClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    trackEvent({
      action: ActionType.click,
      label: "calculateYourBillCTA",
    });
  };

  return (
    <RhFlexBox
      data-testid="OfferCard__container"
      flexDirection="column"
      justifyContent="flex-end"
      className={`${classes.cardRoot} ${isActiveSlide ? "active" : ""}`}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {hasPromo && (
        <RhFlexBox className={classes.promo}>
          <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
            {t("OfferCard.promoText", {
              promoAmount: formatCurrency(offer?.promo?.value || 0, 0),
            })}
          </RhTypography>
        </RhFlexBox>
      )}

      <Box className={classes.cardHeader}>
        <Box mb={1}>
          <RhTypography variant="h3">{offer.title}</RhTypography>
        </Box>
        <RhTypography
          variant="body1"
          color="textSecondary"
          className={classes.description}
        >
          {offer.description(locale)}
        </RhTypography>
      </Box>

      <RhDivider light />

      <Box className={classes.offerFeatures}>
        <OfferFeatures
          dense
          termMonths={offer.termMonths}
          mouseOver={mouseOver}
          isActiveSlide={isActiveSlide}
        />
      </Box>

      <RhDivider light />

      <RhFlexBox className={classes.rateInfo}>
        <div className={classes.rateInfoChild}>
          <OfferRateAndEstimate offer={offer} />
        </div>
        <NavHashLink
          to={`#${COST_ESTIMATOR_SCROLL_TO_POINT}`}
          className={classnames(
            classes.rateInfoCalculateCta,
            classes.rateInfoChild
          )}
          onClick={handleCalculateBillClick}
          scroll={scrollToOffsetPosition}
        >
          <CalculatorIcon />
          <RhTypography
            variant="body3"
            className={classes.rateInfoCalculateCtaText}
          >
            {calculateCtaMessage}
          </RhTypography>
        </NavHashLink>
      </RhFlexBox>

      <RhDivider light />

      <RhFlexBox
        className={classes.cardActions}
        data-testid="OfferCard__cardActions"
      >
        <RhButton
          color="primary"
          size="small"
          onClick={selectButtonProps.onClick}
        >
          {selectButtonProps.text}
        </RhButton>
        <RhButton
          color="primary"
          size="small"
          variant="text"
          onClick={moreButtonProps.onClick}
        >
          {moreButtonProps.text}
        </RhButton>
      </RhFlexBox>
    </RhFlexBox>
  );
};
