import {
  CalendarIcon,
  FilledCalendarIcon,
  FilledLockIcon,
  FilledWindTurbineIcon,
  LockIcon,
  WindTurbineIcon,
} from "@design-system/icons";
import { useIntl } from "react-intl";

export const useFormattedFeatures = (termMonths?: number) => {
  const { formatMessage } = useIntl();

  const features = [
    {
      Icon: LockIcon,
      HoverIcon: FilledLockIcon,
      text: formatMessage({ id: "OfferFeatures.fixed" }),
      subtitle: formatMessage({ id: "OfferFeatures.fixedNoMatter" }),
    },
    {
      Icon: WindTurbineIcon,
      HoverIcon: FilledWindTurbineIcon,
      text: formatMessage({ id: "OfferFeatures.wind" }),
      subtitle: formatMessage({ id: "OfferFeatures.windFeelGood" }),
    },
  ];

  if (termMonths !== undefined) {
    features.unshift({
      Icon: CalendarIcon,
      HoverIcon: FilledCalendarIcon,
      text: formatMessage({ id: "OfferFeatures.term" }, { termMonths }),
      subtitle: formatMessage({
        id: `OfferFeatures.term${termMonths}`,
      }),
    });
  }

  return features;
};
