import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useSignUpServiceStartDateFieldStyles = makeStyles<
  typeof rhythmTheme,
  "hint"
>((_theme) =>
  createStyles({
    hint: {
      marginTop: 12,
    },
  })
);
