import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { Box } from "@material-ui/core";
import {
  LOGGED_OUT_CONTAINER_SPACING,
  LOGGED_OUT_FIELD_SPACING,
} from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { usePollForProspectBilling } from "@portal/pages/SignUpIVRPaymentPage/usePollForProspectBilling";
import {
  selectBillingPreferencesFormValues,
  selectDepositAmount,
} from "@portal/selectors/signUpSelectors";
import { SignUpStateType } from "@portal/slices/signUpSlice";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export const SignUpIVRPaymentPage: FC = () => {
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const { formatMessage } = useIntl();
  const { prospectId, billingPaymentMethodId } = usePollForProspectBilling();
  const depositAmount = useSelector(selectDepositAmount);
  const { autopay } = useSelector(selectBillingPreferencesFormValues);

  const confirmCTA = formatMessage({ id: "SignUpIVRPaymentPage.confirm" });
  const awaitingPaymentMethod = formatMessage({
    id: "SignUpIVRPaymentPage.awaitingPaymentMethod",
  });
  const prospectText = formatMessage(
    { id: "SignUpIVRPaymentPage.prospect" },
    {
      idChunk: () => (
        <RhTypography
          component="span"
          variant="subtitle1"
          color="primary"
          fontWeight={FontWeight.Bold}
        >
          {prospectId ?? "unknown"}
        </RhTypography>
      ),
    }
  );

  const depositRequired = formatMessage(
    {
      id: "SignUpIVRPaymentPage.depositRequired",
    },
    { amount: formatCurrency(depositAmount ?? 0) }
  );

  const handleClick = (billingPaymentMethod: Partial<SignUpStateType>) => {
    signUpClickNextStepHandler({
      signUpData: billingPaymentMethod,
      nextStep: "summary",
      track: true,
    });
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader headerTextId="SignUpIVRPaymentPage.creditCardPlease" />
      <Box my={LOGGED_OUT_CONTAINER_SPACING}>
        <Box my={LOGGED_OUT_FIELD_SPACING}>
          <RhTypography variant="subtitle1" align="center">
            {prospectText}
          </RhTypography>
        </Box>
        {Boolean(depositAmount) && (
          <RhTypography variant="h3">{depositRequired}</RhTypography>
        )}
        {!billingPaymentMethodId && (
          <Box my={LOGGED_OUT_FIELD_SPACING}>
            <RhCircularProgress />
            <RhTypography>{awaitingPaymentMethod}</RhTypography>
          </Box>
        )}
      </Box>
      <RhButton
        color="primary"
        onClick={() => handleClick({})}
        disabled={!billingPaymentMethodId}
      >
        {confirmCTA}
      </RhButton>
      {!depositAmount && !autopay && (
        <Box marginTop={4} textAlign="center">
          <RhTypography
            component="a"
            color="primary"
            onClick={() => handleClick({ billingPaymentMethodId: null })}
          >
            {formatMessage({ id: "SignUpIVRPaymentPage.skipPaymentMethod" })}
          </RhTypography>
        </Box>
      )}
    </SignUpPageLayout>
  );
};
