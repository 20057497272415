import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

export const SignOut = () => {
  const ldClient = useLDClient();

  useEffect(() => {
    portalAuthClient.signOut().then(() => {
      ldClient?.identify({
        key: "anonymous",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
