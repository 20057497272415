import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { SignUpEnrollmentFields } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentFields";
import { signUpEnrollmentFormValidator } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentFormValidator";
import { SignUpEnrollmentFormValues } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentFormValuesTypes";
import { ActionType } from "@portal/services/segment.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const initialValues: Partial<SignUpEnrollmentFormValues> = {
  enrollmentType: null,
};

export const SignUpEnrollmentPage: FC = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { formatMessage } = useIntl();
  const currentValues = useSelector<
    PortalStoreState,
    Partial<SignUpEnrollmentFormValues>
  >(({ signUp: { enrollmentType, serviceStartDate } }) => {
    const values: Partial<SignUpEnrollmentFormValues> = {
      enrollmentType,
    };
    // if no value, don't assign key so date field can initialize state itself

    if (serviceStartDate) {
      values.serviceStartDate = serviceStartDate;
    }

    return values;
  });

  const onSubmit = (values: SignUpEnrollmentFormValues) => {
    signUpClickNextStepHandler({
      signUpData: values,
      nextStep: "billing-preferences",
      track: true,
    });
  };

  const submitCTA = formatMessage({ id: "signUp.enrollmentType.submitCTA" });

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader headerTextId="signUp.enrollmentType.header" />
      <Form<SignUpEnrollmentFormValues>
        initialValues={{ ...initialValues, ...currentValues }}
        onSubmit={onSubmit}
        validate={signUpEnrollmentFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm onSubmit={handleSubmit} submitButtonText={submitCTA}>
            <SignUpEnrollmentFields
              trackClick={(label) => {
                trackEvent({
                  action: ActionType.click,
                  label,
                });
              }}
            />
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
