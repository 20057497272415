import { api } from "@common/api/api";
import { unmaskCurrency } from "@common/forms/currency.mask";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { CreatePaymentResponseType } from "@common/types/apiResponseTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box, Grid } from "@material-ui/core";
import { PaymentAmount } from "@portal/components/PaymentAmount/PaymentAmount";
import { PaymentMethodsSection } from "@portal/components/PaymentMethodsSection/PaymentMethodsSection";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { AmountType } from "@portal/enums/AmountType.enum";
import { usePaymentMethods } from "@portal/hooks/billingHooks";
import { useCustomer } from "@portal/hooks/useCustomer";
import { BillSummary } from "@portal/pages/PayBillPage/BillSummary/BillSummary";
import { payBillSuccessPath } from "@portal/routes/routePaths";
import { selectAccountSummary } from "@portal/selectors/accountSummarySelectors";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import {
  accountSummaryFetch,
  accountSummaryRefetch,
} from "@portal/slices/accountSummarySlice";
import { Show500Page } from "@portal/utils/errors";
import { FORM_ERROR } from "final-form";
import React, { FC, useEffect } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export interface PayBillFormValues {
  maskedCustomAmount: string;
  amountType: AmountType | null;
  totalAmount: number;
  selectedPaymentMethodId: string;
}

export const PayBillPage: FC = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const customerId = useSelector(selectCurrentCustomerId);
  const { paymentMethods } = usePaymentMethods();
  const flash = useRhFlash();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountSummaryFetch());
  }, [customerId, dispatch]);

  const {
    customer,
    requestMonitor: customerRequestMonitor,
    error: customerError,
  } = useCustomer();

  const {
    accountSummary,
    status: accountSummaryStatus,
    error: accountSummaryError,
  } = useSelector(selectAccountSummary);

  const [
    { requestMonitor: createPaymentMonitor },
    {
      setPending: setCreatePaymentPending,
      setFailure: setCreatePaymentFailure,
    },
  ] = useAjaxState<CreatePaymentResponseType>({ invoiceNumber: "" });

  if (accountSummaryStatus.didFail || customerRequestMonitor.didFail) {
    let message = "Could not fetch account summary and customer";

    if (customerError) {
      message = `Could not fetch customer : ${customerError.data.errorCode}`;
    } else if (accountSummaryError) {
      message = `Could not fetch account summary : ${accountSummaryError.data.errorCode}`;
    }
    throw new Show500Page(message);
  }

  if (customerRequestMonitor.isWaiting || accountSummaryStatus.isWaiting) {
    return <RhCircularProgress />;
  }
  if (!customer) {
    return null;
  }

  const submitDisabled =
    createPaymentMonitor.isPending ||
    !paymentMethods ||
    paymentMethods.length <= 0;

  const handleCreatePayment = ({
    maskedCustomAmount,
    selectedPaymentMethodId,
  }: PayBillFormValues) => {
    if (!selectedPaymentMethodId) {
      flash.error(
        formatMessage({ id: "payBill.error.mustSelectPaymentMethod" })
      );

      return;
    }
    setCreatePaymentPending();
    // TODO: specify which payment method is to be used
    return api.customers.payments
      .create(customerId, {
        amount: Number(unmaskCurrency(maskedCustomAmount)),
        paymentMethodId: selectedPaymentMethodId,
      })
      .then((_response) => {
        dispatch(accountSummaryRefetch());
        history.push(payBillSuccessPath());
      })
      .catch((error: RhApiError) => {
        setCreatePaymentFailure(error);

        const errorMessage = formatMessage({
          id: "PayBillPage.error.payment",
        });

        flash.error(errorMessage);

        return { [FORM_ERROR]: [errorMessage] };
      });
  };

  const totalBalance = accountSummary?.totalBalance ?? 0;
  const initialValues: PayBillFormValues = {
    maskedCustomAmount: String(totalBalance > 0 ? totalBalance : 0),
    amountType: totalBalance <= 0 ? AmountType.Custom : AmountType.Total,
    totalAmount: totalBalance,
    selectedPaymentMethodId: customer.billingPaymentMethodId,
  };

  const submitPaymentCTA = formatMessage({ id: "payBill.submitPaymentCTA" });

  return (
    <PortalPageLayout>
      <RhFlexBox justifyContent="center">
        <Box clone margin="auto">
          <Grid container item xs={12} sm={6} md={4}>
            <BillSummary customer={customer} accountSummary={accountSummary} />
            <Form<PayBillFormValues>
              onSubmit={handleCreatePayment}
              initialValues={initialValues}
              render={({ handleSubmit }) => (
                <Box component="form" width="100%" onSubmit={handleSubmit}>
                  <PaymentAmount />
                  <Box marginBottom={3}>
                    <PaymentMethodsSection
                      allowMethodChange
                      sectionTitleText={formatMessage({
                        id: "payBill.payFrom",
                      })}
                      variant="subtitle1"
                    />
                  </Box>

                  <RhSubmitButton
                    fullWidth
                    size="large"
                    disabled={submitDisabled}
                  >
                    {submitPaymentCTA}
                  </RhSubmitButton>
                </Box>
              )}
            />
          </Grid>
        </Box>
      </RhFlexBox>
    </PortalPageLayout>
  );
};
