import { api } from "@common/api/api";
import { blankAddress } from "@common/constants/blankAddress.constant";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isValidUSAddress } from "@common/forms/validators";
import { Customer } from "@common/models/Customer.model";
import { AddressFormType } from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { MyAccountMailingAddressFields } from "@portal/components/MyAccountMailingAddressForm/MyAccountMailingAddressFields";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { customerUpdated } from "@portal/slices/customerSlice";
import { FormApi } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface MyAccountMailingAddressFormValues extends AddressFormType {
  mailingAddressSameAsServiceAddress: boolean;
}

const mailingAddressValidator = generateValidationErrorCollector<MyAccountMailingAddressFormValues>(
  isValidUSAddress
);

interface MyAccountMailingAddressFormProps {
  customer: Customer;
}

export const MyAccountMailingAddressForm: FC<MyAccountMailingAddressFormProps> = ({
  customer,
}) => {
  const { t } = useRhIntl();
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const onSubmit = (
    mailingAddress: MyAccountMailingAddressFormValues,
    formApi: FormApi<MyAccountMailingAddressFormValues>
  ) => {
    return api.customers
      .update(customer.id, mailingAddress)
      .then((updatedCustomer) => {
        formApi.initialize(mailingAddress);
        dispatch(customerUpdated(updatedCustomer));
        flash.success(t("MyAccountMailingAddressForm.mailingAddressUpdated"));
      })
      .catch((error: RhApiError) => {
        flash.error(t("MyAccountMailingAddressForm.errorUpdatingAddress"));
      });
  };

  const initialFormValues = customer.mailingAddress
    ? {
        ...customer.mailingAddress,
        mailingAddressSameAsServiceAddress: false,
      }
    : {
        ...blankAddress,
        mailingAddressSameAsServiceAddress: true,
      };

  return (
    <Form<MyAccountMailingAddressFormValues>
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      validate={mailingAddressValidator}
      render={({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <MyAccountMailingAddressFields />
        </form>
      )}
    />
  );
};
