import { api } from "@common/api/api";
import { RhApiError } from "@common/types/errorTypes";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import {
  rewardsErrored,
  rewardsReceived,
  rewardsRefetch,
  rewardsRequested,
} from "@portal/slices/rewardsSlice";
import {
  SagaReturnType,
  all,
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

export function* refetchRewardsWorker() {
  const customerId: SagaReturnType<
    typeof selectCurrentCustomerId
  > = yield select(selectCurrentCustomerId);

  if (customerId) {
    yield put(rewardsRequested());
    try {
      const rewards: SagaReturnType<typeof api.customers.rewards> = yield call(
        api.customers.rewards,
        customerId
      );

      yield put(rewardsReceived(rewards));
    } catch (error: unknown) {
      yield put(rewardsErrored(error as RhApiError));
    }
  }
}

export function* rewardsWatcher() {
  yield all([takeLatest(rewardsRefetch.type, refetchRewardsWorker)]);
}
