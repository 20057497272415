import { ZUORA_PAYMENT_PAGE_ID, ZUORA_URL } from "@portal/settings/config";

export const getZuoraParams = (locale: string) => ({
  id: ZUORA_PAYMENT_PAGE_ID,
  style: "inline",
  submitEnabled: "true",
  param_supportedTypes: "AmericanExpress,JCB,Visa,MasterCard,Discover,Dankort",
  url: `${ZUORA_URL}/apps/PublicHostedPageLite.do`,
  locale,
});
