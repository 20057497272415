import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { darkPurple } from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useBillingHistoryStyles = makeStyles<
  typeof rhythmTheme,
  "cardHeader" | "linkRow"
>((theme) =>
  createStyles({
    cardHeader: {
      marginBottom: 0,
    },
    linkRow: {
      "&:hover": {
        backgroundColor: darkPurple[50],
        cursor: "pointer",
        textDecoration: "none",
      },
    },
  })
);
