import { OfferType } from "@common/types/offerTypes";
import { RhButtonToggle } from "@design-system/components/RhButtonToggle/RhButtonToggle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { SliderOnChangeFunction } from "@design-system/types/domEventTypes";
import { useCostEstimatorStyles } from "@portal/components/CostEstimator/CostEstimator.style";
import { MonthlyUsageInput } from "@portal/components/MonthlyUsageInput/MonthlyUsageInput";
import {
  MonthlyUsageSlider,
  monthlyUsageSliderMarksValues,
} from "@portal/components/MonthlyUsageSlider/MonthlyUsageSlider";
import { OfferMiniChooser } from "@portal/components/OfferMiniChooser/OfferMiniChooser";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import { findClosest } from "@portal/utils/arrayUtils";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface CostEstimatorProps {
  offers: OfferType[];
  onSelectOffer: (offer: OfferType) => void;
}

export const COST_ESTIMATOR_SCROLL_TO_POINT = "cost-estimator";

export const CostEstimator: FC<CostEstimatorProps> = ({
  offers,
  onSelectOffer,
}) => {
  const classes = useCostEstimatorStyles();
  const { t } = useRhIntl();
  const [defaultValue, setDefaultValue] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { estimatedMonthlyUsage } = useSelector(selectSignUpState);
  const { trackEvent } = useSignUpFlow();

  const title = t("CostEstimator.howMuchWillItCost");
  const subtitle = t("CostEstimator.greatQuestion");
  const methodHelpMeEstimate = t("CostEstimator.helpMeEstimate");
  const methodKnowMyUsage = t("CostEstimator.knowMyUsage");
  const [method, setMethod] = useState<string>(methodHelpMeEstimate);

  const onSliderChange: SliderOnChangeFunction = (_, value) => {
    if (Array.isArray(value)) {
      return;
    }
    dispatch(setSignUpInfo({ estimatedMonthlyUsage: value }));
  };
  const onInputChange = (value: number) => {
    dispatch(setSignUpInfo({ estimatedMonthlyUsage: value }));
  };
  const handleMethodToggle = (newMethod: string) => {
    setMethod(newMethod);
    let label: string;

    if (newMethod === methodHelpMeEstimate) {
      // set estimated usage to something that is on the slider
      dispatch(
        setSignUpInfo({
          estimatedMonthlyUsage: findClosest(
            monthlyUsageSliderMarksValues,
            estimatedMonthlyUsage
          ),
        })
      );
      label = "toggleHelpMeEstimate";
    } else {
      label = "toggleKnowMyUsage";
    }

    trackEvent({
      action: ActionType.click,
      label,
    });
  };

  useEffect(() => {
    // Setup the initial estimated monthly usage if it hasn't been set or
    // it it's already in redux
    if (estimatedMonthlyUsage) {
      setDefaultValue(estimatedMonthlyUsage);
    } else {
      setDefaultValue(DEFAULT_AVERAGE_MONTHLY_USAGE);
    }
  }, [estimatedMonthlyUsage]);

  if (!defaultValue) {
    return null;
  }

  return (
    <RhFlexBox className={classes.root}>
      <RhFlexBox className={classes.header}>
        <RhTypography variant="h2" className={classes.title}>
          {title}
        </RhTypography>
        <RhTypography variant="subtitle2" className={classes.subtitle}>
          {subtitle}
        </RhTypography>
      </RhFlexBox>
      <div
        className={classes.toggleContainer}
        id={COST_ESTIMATOR_SCROLL_TO_POINT}
      >
        <RhButtonToggle
          fullWidth
          labels={{ left: methodHelpMeEstimate, right: methodKnowMyUsage }}
          onChange={handleMethodToggle}
          defaultValue={methodHelpMeEstimate}
        />
      </div>
      {method === methodHelpMeEstimate ? (
        <MonthlyUsageSlider
          onChange={onSliderChange}
          initialValue={estimatedMonthlyUsage}
        />
      ) : (
        <div className={classes.monthlyUsageInputContainer}>
          <MonthlyUsageInput
            onChange={onInputChange}
            initialValue={estimatedMonthlyUsage}
          />
        </div>
      )}
      <OfferMiniChooser offers={offers} onSelectOffer={onSelectOffer} />
    </RhFlexBox>
  );
};
