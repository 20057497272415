import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useEnergyRowSectionStyles = makeStyles<
  typeof rhythmTheme,
  { bold?: boolean },
  "usageRow"
>((theme) =>
  createStyles({
    usageRow: {
      borderBottom: borders[100],
      color: ({ bold }) =>
        bold ? theme.palette.primary.main : theme.palette.grey[500],
      marginBottom: theme.spacing(2),
      paddingBottom: `${theme.spacing(2)}px`,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 20,
        marginRight: 20,
        width: "auto",
      },
    },
  })
);
