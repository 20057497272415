import { Offer } from "@common/models/Offer.model";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { OfferFeatures } from "@portal/components/OfferFeatures/OfferFeatures";
import { useOfferLearnMoreHeaderLayoutStyle } from "@portal/components/OfferLearnMoreHeaderLayout/OfferLearnMoreHeaderLayout.style";
import { OfferRateAndEstimate } from "@portal/components/OfferRateAndEstimate/OfferRateAndEstimate";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { FC } from "react";
import { useSelector } from "react-redux";

interface OfferLearnMoreLayoutProps {
  offer: Offer;
  selectButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
}

export const OfferLearnMoreHeaderLayout: FC<OfferLearnMoreLayoutProps> = ({
  offer,
  selectButtonProps,
}) => {
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );
  const classes = useOfferLearnMoreHeaderLayoutStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <Box mb={3}>
        <RhTypography variant="h1">{offer.title}</RhTypography>
      </Box>
      <Grid container classes={{ root: classes.summary }} spacing={2}>
        <Grid item md={8} classes={{ root: classes.summaryDescription }}>
          <RhTypography variant="subtitle1">
            {offer.longDescription(locale)}
          </RhTypography>
        </Grid>

        <Grid item xs={12} md={4}>
          <RhFlexBox
            alignItems={isMobile ? "center" : "flex-start"}
            flexDirection={isMobile ? "row" : "column"}
          >
            <Box mb={isMobile ? 0 : 2} mr={isMobile ? 2 : 0} flex={1}>
              <OfferRateAndEstimate offer={offer} />
            </Box>
            {isMobile ? (
              <Box flex={1}>
                <RhButton
                  color="primary"
                  fullWidth
                  onClick={selectButtonProps.onClick}
                  size="small"
                >
                  {selectButtonProps.text}
                </RhButton>
              </Box>
            ) : (
              <Box className={classes.selectButtonContainer} boxShadow={0}>
                <RhButton
                  color="primary"
                  fullWidth
                  onClick={selectButtonProps.onClick}
                  size="small"
                >
                  {selectButtonProps.text}
                </RhButton>
              </Box>
            )}
          </RhFlexBox>
        </Grid>
      </Grid>
      <RhDivider light />
      {isMobile && (
        <Box className={classes.selectButtonContainer} boxShadow={4}>
          <RhButton
            color="primary"
            fullWidth
            onClick={selectButtonProps.onClick}
            size="small"
          >
            {selectButtonProps.text}
          </RhButton>
        </Box>
      )}
      <Box mt={3} mb={1}>
        <OfferFeatures termMonths={offer.termMonths} />
      </Box>
    </>
  );
};
