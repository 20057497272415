import { boxShadows } from "@design-system/constants/boxShadows";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core";

export const useMiniOfferCardStyles = makeStyles<
  Theme,
  "actions" | "body" | "cardRoot" | "content" | "title" | "root"
>((theme) =>
  createStyles({
    actions: {
      alignSelf: "center",
      [theme.breakpoints.up("sm")]: {
        alignSelf: "initial",
        justifyContent: "initial",
      },
    },
    body: {
      "& div": {
        marginBottom: "4px",
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
    // all these `important`s are because we need to
    // override RhCard styles
    cardRoot: {
      boxShadow: `${boxShadows.tight} !important`,
      padding: "12px !important",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 14px !important",
      },
    },
    content: {
      flex: 1,
      [theme.breakpoints.up("sm")]: {
        textAlign: "center",
      },
    },
    title: {
      marginBottom: "6px",
    },
    root: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "column",
      },
    },
  })
);
