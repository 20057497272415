import { getEnvApplicationSettings } from "@common/services/getEnvApplicationSettings.service";
import { allEnvApplicationSettings } from "@portal/settings/env";

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings
);

export const RHYTHM_MARKETING_URL = envApplicationSettings.marketingUrl;
export const PRIVACY_POLICY_URL = "https://gotrhythm.com/privacy-notice";
export const TERMS_OF_USE_URL = "https://gotrhythm.com/terms-of-use";
export const FAQ_URL = "https://support.gotrhythm.com";
export const FACEBOOK_URL = "https://www.facebook.com/gotrhythmHQ/";
export const TWITTER_URL = "https://twitter.com/GotRhythmHQ/";
export const LINKEDIN_URL = "https://www.linkedin.com/company/rhythmhq/";
export const INSTAGRAM_URL = "https://www.instagram.com/gotrhythmhq/";
export const IRONHIDE_SIGN_IN_URL = "https://account.gotrhythm.com";
