import {
  GetKwhUsageResponseType,
  UsageDataType,
  UsageSummary,
} from "@common/api/customerResponseTypes";
import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import dayjs from "dayjs";

export enum ChartType {
  SevenDayUsage,
  ThirtyDayUsage,
  BillUsage,
}

export interface DataPoint {
  x: Date;
  y: number;
  cost: number;
}

interface UsageData {
  maxKwh: number;
  totalKwh: number;
  totalCost: number;
  timePeriodInDays: number;
  data: DataPoint[];
}

export function buildChartData(
  chartDataResponse: GetKwhUsageResponseType
): Record<ChartType, UsageData | undefined> {
  return {
    [ChartType.SevenDayUsage]: buildUsageData(
      chartDataResponse.sevenDaysUsage,
      chartDataResponse.sevenDaysUsageSummary
    ),
    [ChartType.ThirtyDayUsage]: buildUsageData(
      chartDataResponse.thirtyDaysUsage,
      chartDataResponse.thirtyDaysUsageSummary,
      true
    ),
    [ChartType.BillUsage]: buildUsageData(
      chartDataResponse.billUsage,
      chartDataResponse.billUsageSummary,
      true
    ),
  };
}

function sumByDay(points: UsageDataType[]): UsageDataType[] {
  const sumByDate = points.reduce<{
    [date: string]: {
      kwh: number;
      cost: number;
    };
  }>((accumulator, current: UsageDataType) => {
    const date = dayjs(current.datetime).format(ISO8601_DATE_FORMAT);

    if (accumulator[date]) {
      accumulator[date].kwh += current.kwh;
      accumulator[date].cost += current.cost;
    } else {
      accumulator[date] = {
        kwh: current.kwh,
        cost: current.cost,
      };
    }

    return accumulator;
  }, {});

  return Object.entries(sumByDate).map(([datetime, data]) => ({
    datetime,
    kwh: data.kwh,
    cost: data.cost,
  }));
}

function buildUsageData(
  points: UsageDataType[],
  summary: UsageSummary,
  groupByDay = false
): UsageData | undefined {
  if (!points.length) {
    return;
  }
  const finalPoints = groupByDay ? sumByDay(points) : points;
  const startTime = dayjs(finalPoints[0].datetime);
  const endTime = dayjs(finalPoints[finalPoints.length - 1].datetime);
  const timePeriodInDays = endTime.diff(startTime, "days");

  return {
    maxKwh: Math.max(...finalPoints.map((d) => d.kwh)),
    totalKwh: summary.kwh || 0,
    totalCost: summary.cost || 0,
    timePeriodInDays,
    data: finalPoints.map((data) => {
      const x = dayjs(data.datetime);

      return {
        x: x.toDate(),
        y: data.kwh,
        cost: data.cost,
      };
    }),
  };
}
