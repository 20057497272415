import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useAtAGlanceCarouselStyles = rhMakeStyles<
  typeof rhythmTheme,
  "root" | "itemContainer"
>((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("md")]: {
        overflowX: "scroll",
        msOverflowStyle: "none" /* Internet Explorer 10+ */,
        scrollbarWidth: "none" /* Firefox */,
        "&::-webkit-scrollbar": {
          display: "none" /* Safari and Chrome */,
        },
      },
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: 0.5,
      paddingRight: 0.5,
    },
    itemContainer: {
      "&:before": {
        position: "absolute",
        top: 2,
        bottom: 2,
        width: 1,
        content: "''",
        backgroundColor: theme.palette.grey[100],
        left: 0,
      },
    },
  })
);
