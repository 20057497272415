import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/styles";

export const useRedeemRewardsStyle = makeStyles<
  typeof rhythmTheme,
  { withIllustration?: boolean },
  | "dialogContainer"
  | "dialogTitle"
  | "dialogBody"
  | "loadingDialogBody"
  | "buttonsContainer"
  | "buttonContainer"
  | "illustrationContainer"
>((theme) =>
  createStyles({
    buttonContainer: {
      padding: theme.spacing(1),
      width: 175,
    },
    buttonsContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      paddingBottom: theme.spacing(4),
    },
    dialogBody: {
      paddingBottom: theme.spacing(3),
      textAlign: "center",
    },
    dialogContainer: {
      textAlign: "center",
    },
    dialogTitle: {
      // !important to win over the styles applied at the `RhDialogTitle#classes.title` level
      padding: ({ withIllustration }) =>
        withIllustration
          ? `0 ${theme.spacing(4)}px ${theme.spacing(1)}px !important`
          : "inherit",
    },
    illustrationContainer: {
      "& > svg": {
        width: 124,
      },
      margin: "auto",
      paddingTop: theme.spacing(3),
      width: 124,
    },
    loadingDialogBody: {
      alignItems: "center",
      height: 290,
      justifyContent: "center",
    },
  })
);
