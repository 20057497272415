import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const usePaymentMethodStyles = rhMakeStyles<
  typeof rhythmTheme,
  { highlighted?: boolean; isDisabled?: boolean },
  | "cardExpiration"
  | "cardInfo"
  | "cardOption"
  | "cardOptions"
  | "defaultCard"
  | "defaultPaymentText"
  | "root"
>((theme) =>
  createStyles({
    cardExpiration: {
      marginLeft: theme.spacing(1),
    },
    cardInfo: {
      alignItems: "center",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
    },
    cardOption: {
      "&:hover": {
        cursor: "pointer",
      },
      height: 20,
      marginLeft: theme.spacing(2),
    },
    cardOptions: {
      alignItems: "center",
      flexFlow: "row nowrap",
      justifyContent: "flex-end",
    },
    defaultCard: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px !important`,
    },
    defaultPaymentText: {
      height: 20,
      marginRight: "auto",
    },
    root: {
      "&.cardHighlighted": {
        borderColor: theme.palette.primary.main,
      },
      borderColor: theme.palette.grey[100],
      marginBottom: theme.spacing(2.5),
      pointerEvents: ({ isDisabled }) => (isDisabled ? "none" : "initial"),
    },
  })
);
