import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useAtAGlanceItemStyles = rhMakeStyles<
  typeof rhythmTheme,
  { disabled: boolean },
  "root" | "textPrimary"
>((theme) =>
  createStyles({
    root: {
      flexDirection: "column",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
        1
      )}px ${theme.spacing(1)}px`,
      border: "none",
      borderColor: theme.palette.grey[100],
      width: theme.spacing(25),
      "&:hover": {
        backgroundColor: ({ disabled }) =>
          disabled ? "transparent" : theme.palette.grey[50],
      },
    },
    textPrimary: {
      color: ({ disabled }) =>
        disabled ? theme.palette.grey[400] : theme.palette.text.primary,
    },
  })
);
