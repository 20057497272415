import { api } from "@common/api/api";
import { RhApiError } from "@common/types/errorTypes";
import { selectCustomer } from "@portal/selectors/customerSelector";
import { anonymize, identify } from "@portal/services/fullstory.service";
import { userLoggedOut } from "@portal/slices/authenticationSlice";
import {
  customerErrored,
  customerFetch,
  customerReceived,
  customerRefetch,
  customerRequested,
} from "@portal/slices/customerSlice";
import {
  SagaReturnType,
  all,
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

export function* fetchCustomerWorker() {
  const { customer } = yield select(selectCustomer);

  if (!customer) {
    yield call(refetchCustomerWorker);
  }
}

export function* refetchCustomerWorker() {
  yield put(customerRequested());
  try {
    const customer: SagaReturnType<typeof api.customers.me> = yield call(
      api.customers.me
    );

    yield put(customerReceived(customer));
  } catch (err: unknown) {
    const apiError = err as RhApiError;

    yield put(customerErrored(apiError));
  }
}

export function* identifyWorker() {
  const { customer } = yield select(selectCustomer);

  if (customer) {
    yield call(identify, customer);
  }
}

export function* anonymizeWorker() {
  yield call(anonymize);
}

export function* customerWatcher() {
  yield all([
    takeLatest(customerFetch.type, fetchCustomerWorker),
    takeLatest(customerRefetch.type, refetchCustomerWorker),
    takeLatest(customerReceived.type, identifyWorker),
    takeLatest(userLoggedOut.type, anonymizeWorker),
  ]);
}
