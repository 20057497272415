import { AnnouncementSeverity } from "@design-system/components/RhAnnouncement/RhAnnouncement";
import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useState,
} from "react";

type Content = null | string | JSX.Element;

export interface RhAnnouncementProviderContext {
  announceError(content: string | JSX.Element | ReactNode): void;
  announceNotice(content: string | JSX.Element | ReactNode): void;
  clearAnnouncement(): void;
  content: Content;
  severity: AnnouncementSeverity;
}

export const RhAnnouncementContext = createContext<RhAnnouncementProviderContext>(
  {
    announceError: () => () => undefined,
    announceNotice: () => () => undefined,
    clearAnnouncement: () => undefined,
    content: null,
    severity: AnnouncementSeverity.Error,
  }
);

export const RhAnnouncementProvider: FC = ({ children }) => {
  const [content, setContent] = useState<Content>(null);
  const [severity, setSeverity] = useState<AnnouncementSeverity>(
    AnnouncementSeverity.Error
  );

  const errorFn = useCallback((newContent: string | JSX.Element) => {
    setContent(newContent);
    setSeverity(AnnouncementSeverity.Error);
  }, []);

  const noticeFn = useCallback((newContent: string | JSX.Element) => {
    setContent(newContent);
    setSeverity(AnnouncementSeverity.Notice);
  }, []);

  const clearAnnouncement = useCallback(() => {
    setContent(null);
  }, []);

  const actions: RhAnnouncementProviderContext = {
    announceError: errorFn,
    announceNotice: noticeFn,
    clearAnnouncement,
    content,
    severity,
  };

  return (
    <RhAnnouncementContext.Provider value={actions}>
      {children}
    </RhAnnouncementContext.Provider>
  );
};
