import { EnvTypes } from "@common/enums/envTypes.enum";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import { useOktaAuth } from "@okta/okta-react";
import { selectCustomer } from "@portal/selectors/customerSelector";
import { customerRefetch } from "@portal/slices/customerSlice";
import { isBot } from "@portal/utils/isBot";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useAuthenticateUser = () => {
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();
  const env = getEnv();

  const ldClient = useLDClient();
  const { customer } = useSelector(selectCustomer);
  const customerId = customer?.id;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(customerRefetch());

      if (ldClient && customer?.id && env !== EnvTypes.local && !isBot()) {
        ldClient.identify({
          key: customer.id,
          email: customer.email,
          firstName: customer.firstName,
          lastName: customer.lastName,
          name: customer.fullName,
          custom: {
            source: customer.source,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldClient, isAuthenticated, dispatch, customerId, env]);
};
